import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getMonth, parseISO, isBefore } from "date-fns";
import { PeopleOutline, CheckCircleOutline } from "@mui/icons-material";

const AssembleiaStatus = ({ mesAtual }) => {
	const API_URL = process.env.REACT_APP_API_URL; //|| "http://localhost:3001";
	const [naoConvocados, setNaoConvocados] = useState(0);
	const [convocados, setConvocados] = useState(0);
	const [loading, setLoading] = useState(false);

	const fetchAssembleias = useCallback(async () => {
		setLoading(true);
		//console.log(`Fetching data for month: ${mesAtual}`);
		try {
			const response = await axios.get(
				`${API_URL}/assembleia/euro_assembleias`
			);
			//console.log("Response data:", response.data);
			const data = response.data.euro_assembleias;
			const currentDate = new Date();

			const naoConvocadosCount = data.filter((assembleia) => {
				if (assembleia.dt_convocacao) {
					const convocacaoDate = parseISO(assembleia.dt_convocacao);
					return (
						getMonth(convocacaoDate) === mesAtual &&
						assembleia.status_assembleia === 1 &&
						isBefore(convocacaoDate, currentDate)
					);
				}
				return false;
			}).length;

			const convocadosCount = data.filter((assembleia) => {
				if (assembleia.dt_convocacao) {
					const convocacaoDate = parseISO(assembleia.dt_convocacao);
					return (
						getMonth(convocacaoDate) === mesAtual &&
						assembleia.status_assembleia >= 2
					);
				}
				return false;
			}).length;

			//console.log("Não convocadas:", naoConvocadosCount);
			//console.log("Convocadas:", convocadosCount);

			setNaoConvocados(naoConvocadosCount);
			setConvocados(convocadosCount);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		setLoading(false);
	}, [API_URL, mesAtual]);

	useEffect(() => {
		fetchAssembleias();
	}, [fetchAssembleias]);

	return (
		<div className="assembleia-status-wrapper">
			{loading ? (
				<p>Loading...</p>
			) : (
				<>
					<div className="assembleia-status">
						<PeopleOutline style={{ fontSize: 48, color: "#e04c4c" }} />
						<p>{naoConvocados}</p>
						<h4>Não Convocadas</h4>
					</div>
					<div className="assembleia-status">
						<CheckCircleOutline style={{ fontSize: 48, color: "#4250d4" }} />
						<p>{convocados}</p>
						<h4>Convocadas</h4>
					</div>
				</>
			)}
		</div>
	);
};

export default AssembleiaStatus;
