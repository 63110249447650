import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
} from "@mui/material";
import "./VisaoGeralFechamento.css"; // Certifique-se de que o CSS esteja importado

const API_URL = process.env.REACT_APP_API_URL;

const VisaoGeralFechamento = () => {
	const [anoSelecionado, setAnoSelecionado] = useState(
		new Date().getFullYear()
	);
	const [mesSelecionado, setMesSelecionado] = useState(
		new Date().getMonth() + 1
	);
	const [dados, setDados] = useState([]);
	const [loading, setLoading] = useState(false);
	const [sortConfig, setSortConfig] = useState({
		key: "colaborador",
		direction: "ascending",
	});

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				console.log(
					`Fetching data for month: ${mesSelecionado}, year: ${anoSelecionado}`
				);
				const response = await axios.get(`${API_URL}/financeiro/visaogeral`, {
					params: { mes: mesSelecionado, ano: anoSelecionado },
				});
				console.log("Data fetched successfully:", response.data);
				const sortedData = response.data.fechamento_condominios.sort((a, b) => {
					if (a.colaborador < b.colaborador) return -1;
					if (a.colaborador > b.colaborador) return 1;
					return 0;
				});
				setDados(sortedData);
			} catch (error) {
				console.error("Erro ao buscar dados da visão geral:", error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [mesSelecionado, anoSelecionado]);

	useEffect(() => {
		console.log("Dados atualizados:", dados);
	}, [dados]);

	const sortData = (key) => {
		let direction = "ascending";
		if (sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}
		setSortConfig({ key, direction });
		setDados((prevDados) => {
			return [...prevDados].sort((a, b) => {
				let aValue = a[key];
				let bValue = b[key];

				// Para colunas que contêm números na forma "x/y"
				if (typeof aValue === "string" && aValue.includes("/")) {
					aValue = parseInt(aValue.split("/")[0]);
					bValue = parseInt(bValue.split("/")[0]);
				}

				if (aValue < bValue) {
					return direction === "ascending" ? -1 : 1;
				}
				if (aValue > bValue) {
					return direction === "ascending" ? 1 : -1;
				}
				return 0;
			});
		});
	};

	const calculateTotals = () => {
		const totals = {
			reinf_enviado: { num: 0, denom: 0 },
			leitura_enviado: { num: 0, denom: 0 },
			consumos_individuais_enviado: { num: 0, denom: 0 },
			reservas_enviado: { num: 0, denom: 0 },
			balancetes_enviado: { num: 0, denom: 0 },
			boleto_enviado: { num: 0, denom: 0 },
		};

		dados.forEach((item) => {
			Object.keys(totals).forEach((key) => {
				const [num, denom] = item[key].split("/").map(Number);
				totals[key].num += num;
				totals[key].denom += denom;
			});
		});

		return totals;
	};

	const totals = calculateTotals();

	return (
		<div className="container">
			<div className="filters">
				<FormControl className="form-control">
					<InputLabel>Mês</InputLabel>
					<Select
						value={mesSelecionado}
						onChange={(e) => setMesSelecionado(e.target.value)}
						label="Mês"
					>
						{Array.from({ length: 12 }, (_, i) => i + 1).map((mes) => (
							<MenuItem key={mes} value={mes}>
								{mes}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl className="form-control">
					<InputLabel>Ano</InputLabel>
					<Select
						value={anoSelecionado}
						onChange={(e) => setAnoSelecionado(e.target.value)}
						label="Ano"
					>
						{Array.from(
							{ length: 10 },
							(_, i) => new Date().getFullYear() - i
						).map((ano) => (
							<MenuItem key={ano} value={ano}>
								{ano}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			{loading ? (
				<div className="loading-container">
					<CircularProgress size={80} thickness={4.0} />
				</div>
			) : (
				<div className="table-container">
					<table className="custom-table">
						<thead>
							<tr>
								<th
									onClick={() => sortData("colaborador")}
									className={sortConfig.key === "colaborador" ? "active" : ""}
								>
									Colaborador{" "}
									{sortConfig.key === "colaborador" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("reinf_enviado")}
									className={sortConfig.key === "reinf_enviado" ? "active" : ""}
								>
									ReInf{" "}
									{sortConfig.key === "reinf_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("leitura_enviado")}
									className={
										sortConfig.key === "leitura_enviado" ? "active" : ""
									}
								>
									Leitura{" "}
									{sortConfig.key === "leitura_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("consumos_individuais_enviado")}
									className={
										sortConfig.key === "consumos_individuais_enviado"
											? "active"
											: ""
									}
								>
									Consumos Individuais{" "}
									{sortConfig.key === "consumos_individuais_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("reservas_enviado")}
									className={
										sortConfig.key === "reservas_enviado" ? "active" : ""
									}
								>
									Reservas{" "}
									{sortConfig.key === "reservas_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("balancetes_enviado")}
									className={
										sortConfig.key === "balancetes_enviado" ? "active" : ""
									}
								>
									Balancetes{" "}
									{sortConfig.key === "balancetes_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
								<th
									onClick={() => sortData("boleto_enviado")}
									className={
										sortConfig.key === "boleto_enviado" ? "active" : ""
									}
								>
									Boleto{" "}
									{sortConfig.key === "boleto_enviado" &&
										(sortConfig.direction === "ascending" ? "▲" : "▼")}
								</th>
							</tr>
						</thead>
						<tbody>
							{dados.map((condominio, index) => (
								<tr key={index}>
									<td>{condominio.colaborador}</td>
									<td>{condominio.reinf_enviado}</td>
									<td>{condominio.leitura_enviado}</td>
									<td>{condominio.consumos_individuais_enviado}</td>
									<td>{condominio.reservas_enviado}</td>
									<td>{condominio.balancetes_enviado}</td>
									<td>{condominio.boleto_enviado}</td>
								</tr>
							))}
							<tr className="totals-row">
								<td>Total</td>
								<td>{`${totals.reinf_enviado.num}/${totals.reinf_enviado.denom}`}</td>
								<td>{`${totals.leitura_enviado.num}/${totals.leitura_enviado.denom}`}</td>
								<td>{`${totals.consumos_individuais_enviado.num}/${totals.consumos_individuais_enviado.denom}`}</td>
								<td>{`${totals.reservas_enviado.num}/${totals.reservas_enviado.denom}`}</td>
								<td>{`${totals.balancetes_enviado.num}/${totals.balancetes_enviado.denom}`}</td>
								<td>{`${totals.boleto_enviado.num}/${totals.boleto_enviado.denom}`}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default VisaoGeralFechamento;
