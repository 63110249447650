import React, { useState, useEffect, useContext } from "react";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import "./EditUserPage.css";

const EditUserPage = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const [absenceMessage, setAbsenceMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [userData, setUserData] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAbsenceModalOpen, setAbsenceModalOpen] = useState(false);
  const [absenceData, setAbsenceData] = useState({
    motivo: "Atestado",
    dataInicio: "",
    dataFim: "",
  });
  const API_URL = process.env.REACT_APP_API_URL;
  const today = new Date().toISOString().split("T")[0];
  const [errorMessage, setErrorMessage] = useState("");
  const [userOptions, setUserOptions] = useState([]); // Lista de usuários retornados pela busca
  const [selectedUser, setSelectedUser] = useState(null); // Usuário selecionado no Autocomplete

  useEffect(() => {
    loadAllUsers();
  }, []);

  const loadAllUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/search-users-by-name`); // Sem parâmetros para buscar todos
      setUserOptions(response.data);
    } catch (error) {
      console.error("Erro ao carregar todos os usuários:", error);
    }
  };

  const handleSearch = async () => {
    console.log("handleSearch foi chamado");

    // Verifica se o campo está vazio
    if (!selectedUser) {
      setErrorMessage("Selecione um usuário");
      return;
    }

    try {
      const response = await axios.get(
        `${API_URL}/user/get-user/${selectedUser.id_func}`
      );
      //console.log('Resposta do servidor ao buscar usuário:', response);
      if (response.data && response.data.user) {
        setErrorMessage(""); // Limpa a mensagem de erro, se houver
        const user = response.data.user;

        // Buscando a ausência do usuário
        try {
          const absenceResponse = await axios.get(
            `${API_URL}/ausencia/${user.id_func}`
          );
          //console.log('Resposta do servidor ao buscar ausência:', absenceResponse);

          if (
            absenceResponse.data &&
            absenceResponse.data.absence &&
            absenceResponse.data.absence.motivo
          ) {
            user.motivo = absenceResponse.data.absence.motivo;
          } else {
            user.motivo = "N/A";
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log(
              "Nenhum registro de ausência encontrado para este usuário."
            );
            user.motivo = "N/A";
          } else {
            console.error("Erro ao buscar ausência do usuário:", error);
          }
        }

        setUserData(user);
      } else {
        setErrorMessage("ID não existe.");
      }
    } catch (error) {
      // Aqui, podemos adicionar uma verificação extra para o erro 404
      if (error.response && error.response.status === 404) {
        setErrorMessage("ID não existe.");
      } else {
        console.error("Erro ao buscar usuário:", error);
      }
    }
  };

  const searchUsersByName = async (query) => {
    console.log("Buscando usuários com o nome:", query); // Log de depuração

    try {
      const response = await axios.get(`${API_URL}/user/search-users-by-name`, {
        params: { nome: query },
      });
      console.log("Resposta da busca:", response.data); // Log de depuração
      if (Array.isArray(response.data)) {
        setUserOptions(response.data);
      } else {
        console.error("A resposta da API não é um array:", response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  const toggleAbsenceArea = async () => {
    if (userData.situacao === "Inativo") {
      alert("Não é possível adicionar ausência para usuários inativos.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_URL}/ausencia/check-active-absences/${userData.id_func}`
      );
      if (response.data.activeAbsencesCount > 0) {
        const absenceResponse = await axios.get(
          `${API_URL}/ausencia/${userData.id_func}`
        );
        if (absenceResponse.data.absence) {
          const formatDate = (dateString) => {
            const date = new Date(dateString);
            return `${date.getUTCDate()}/${
              date.getUTCMonth() + 1
            }/${date.getUTCFullYear()}`;
          };
          setAbsenceMessage(
            `O usuário ${
              userData.nome
            } já possui uma ausência registrada de ${formatDate(
              absenceResponse.data.absence.dt_inicio
            )} até ${formatDate(absenceResponse.data.absence.dt_fim)}`
          );
          setTimeout(() => {
            setAbsenceMessage("");
          }, 2000);
        } else {
          alert("Este usuário já possui uma ausência.");
        }
        return;
      }
    } catch (error) {
      console.error("Erro ao verificar ausências ativas:", error);
      return;
    }

    const today = new Date().toISOString().split("T")[0];
    setIsAbsenceAreaVisible(!isAbsenceAreaVisible);
    setAbsenceData({ motivo: "Atestado", dataInicio: today, dataFim: "" }); // Defina 'Atestado' como o valor padrão para motivo
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/user/${userData.id}`);
      alert("Usuário excluído com sucesso!");
      setUserData(null);
    } catch (error) {
      console.error("Erro ao excluir usuário:", error);
    }
  };

  const handleAbsenceSave = async () => {
    if (new Date(absenceData.dataFim) < new Date(absenceData.dataInicio)) {
      alert("A data final não pode ser anterior à data inicial.");
      return;
    }

    try {
      // Buscar o contador atual de ID_aus
      const counterResponse = await axios.get(
        `${API_URL}/ausencia/get-next-aus-id`
      );
      const nextAusId = counterResponse.data.nextId;

      console.log("Enviando dados para registrar ausência:", {
        id_aus: nextAusId,
        id: userData.id_func,
        nome: userData.nome,
        ativo: true,
        ...absenceData,
      });

      // Salvar na coleção 'Ausencia'
      await axios.post(`${API_URL}/ausencia/add-absence`, {
        id_aus: nextAusId,
        id: userData.id_func,
        nome: userData.nome,
        ativo: true,
        ...absenceData,
      });

      // Feche o modal de ausência aqui
      setIsAbsenceAreaVisible(false);

      alert(
        `Ausência adicionada para o usuário ${userData.nome} de ${absenceData.dataInicio} até ${absenceData.dataFim}`
      );

      await axios.put(`${API_URL}/update-user/${userData.id}`, {
        situacao: "Ausente",
      });
      alert("Ausência registrada com sucesso!");

      // Chame handleSearch para atualizar os dados do usuário
      console.log("Atualizando dados do usuário com ID:", searchValue); // Log de depuração
      handleSearch();
    } catch (error) {
      console.error("Erro ao registrar ausência:", error);
    }
  };

  // Estado para controlar a visibilidade da área expansível
  const [isAbsenceAreaVisible, setIsAbsenceAreaVisible] = useState(false);

  const AbsenceModal = ({ isOpen, onClose, onSave, data, setData }) => {
    const today = new Date().toISOString().split("T")[0];
    const [absenceData, setAbsenceData] = useState({
      motivo: "",
      dataInicio: today,
      dataFim: "",
    });
    if (!isOpen) return null;
    return (
      <div style={styles.absenceArea}>
        <label style={styles.modalLabel}>
          Motivo:
          <select
            style={styles.modalInput}
            value={data.motivo}
            onChange={(e) => setData({ ...data, motivo: e.target.value })}
          >
            <option value="Assunto Pessoal">Assunto Pessoal</option>
            <option value="Atestado">Atestado</option>
            <option value="Ferias">Férias</option>
            <option value="Folga">Folga</option>
          </select>
        </label>
        <div style={styles.dateGroup}>
          <label style={styles.modalLabel}>
            Data Inicial:
            <input
              style={styles.modalInput}
              type="date"
              value={data.dataInicio}
              onChange={(e) => setData({ ...data, dataInicio: e.target.value })}
            />
          </label>
          <label style={styles.modalLabel}>
            Data Final:
            <input
              style={styles.modalInput}
              type="date"
              value={data.dataFim}
              onChange={(e) => setData({ ...data, dataFim: e.target.value })}
            />
          </label>
        </div>
        <button style={styles.modalButton} onClick={handleAbsenceSave}>
          Confirmar
        </button>
      </div>
    );
  };

  const ConfirmModal = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
      <div style={styles.modalContainer}>
        <div style={styles.modalContent}>
          <p>{message}</p>
          <button style={styles.confirmButton} onClick={onConfirm}>
            Sim
          </button>
          <button style={styles.cancelButton} onClick={onClose}>
            Não
          </button>
        </div>
      </div>
    );
  };

  const handleInactivate = async () => {
    try {
      await axios.put(`${API_URL}/update-user/${userData._id}`, {
        situacao: "Inativo",
      });
      alert("Usuário inativado com sucesso!");
      setUserData({ ...userData, situacao: "Inativo" }); // Atualize o estado localmente também
    } catch (error) {
      console.error("Erro ao inativar o usuário:", error);
    }
  };

  const handleInactivateUser = async () => {
    try {
      // Atualiza a situação do usuário para 'Inativo'
      await axios.put(`${API_URL}/update-user/${userData.id_func}`, {
        situacao: "Inativo",
      });

      // Adiciona a ausência perpétua
      const counterResponse = await axios.get(
        `${API_URL}/ausencia/get-next-aus-id`
      );
      const nextAusId = counterResponse.data.nextId;
      const perpetualAbsence = {
        id_aus: nextAusId,
        id: userData.id_func,
        nome: userData.nome,
        ativo: true,
        motivo: "Inativação",
        dataInicio: new Date().toISOString().split("T")[0], // Data atual
        dataFim: "3001-12-08",
      };
      await axios.post(`${API_URL}/ausencia/add-absence`, perpetualAbsence);

      alert("Usuário inativado com sucesso e ausência perpétua adicionada.");
      setUserData({ ...userData, situacao: "Inativo" }); // Atualiza o estado
    } catch (error) {
      console.error("Erro ao inativar o usuário:", error);
    }
  };

  return (
    <div className="container-ausencia" style={styles.container}>
      <div style={styles.form}>
        <h2 style={styles.title}>Adicionar Ausência</h2>

        <div style={styles.inputGroup}>
          <Autocomplete
            options={userOptions}
            getOptionLabel={(option) => option.nome || ""}
            onInputChange={(event, newValue, reason) => {
              if (reason === "input") searchUsersByName(newValue);
            }}
            onChange={(event, newValue) => setSelectedUser(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Escolha o usuário" />
            )}
          />

          {errorMessage && <p style={styles.errorText}>{errorMessage}</p>}
          <button className="botao-ausencia" onClick={handleSearch}>
            Pesquisar
          </button>
        </div>

        {userData && typeof userData === "object" && userData.nome && (
          <div className="userInfo">
            <h3>Detalhes do Usuário</h3>
            <div className="userDetails">
              <div className="userFields">
                <p>
                  <strong>Nome:</strong> {userData.nome}
                </p>
                <p>
                  <strong>Usuário:</strong> {userData.user}
                </p>
              </div>
              <div className="userFields">
                <p>
                  <strong>Área:</strong> {userData.area}
                </p>
                <p>
                  <strong>Função:</strong> {userData.funcao}
                </p>
              </div>
              <div className="userFields">
                <p>
                  <strong>Situação:</strong> {userData.situacao}
                </p>
                <p>
                  <strong>Motivo:</strong> {userData.motivo || "N/A"}
                </p>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button style={styles.absenceButton} onClick={toggleAbsenceArea}>
                Adicionar Ausência
              </button>
              {/*{(userData.funcao === "Gestor" ||
                userData.funcao === "admin") && (
                <button
                  style={styles.inactivateButton}
                  onClick={() => setDeleteModalOpen(true)}
                >
                  Inativar Usuário
                </button>
              )} */}
            </div>

            {absenceMessage && (
              <p style={{ color: "red", marginTop: "10px" }}>
                {absenceMessage}
              </p>
            )}

            {isAbsenceAreaVisible && (
              <div style={styles.absenceArea}>
                <div className="motivo-ausencia">
                  <label style={styles.modalLabel}>Motivo:</label>
                  <select
                    className="selectInput"
                    value={absenceData.motivo}
                    onChange={(e) =>
                      setAbsenceData({ ...absenceData, motivo: e.target.value })
                    }
                  >
                    <option value="Assunto Pessoal">Assunto Pessoal</option>
                    <option value="Atestado">Atestado</option>
                    <option value="Ferias">Férias</option>
                    <option value="Folga">Folga</option>
                  </select>
                </div>
                <div style={styles.dateGroup}>
                  <div style={styles.dateField}>
                    <label style={styles.modalLabel}>Data Inicial:</label>
                    <input
                      className="dateInput"
                      type="date"
                      value={absenceData.dataInicio}
                      onChange={(e) =>
                        setAbsenceData({
                          ...absenceData,
                          dataInicio: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={styles.dateField}>
                    <label style={styles.modalLabel}>Data Final:</label>
                    <input
                      className="dateInput"
                      type="date"
                      value={absenceData.dataFim}
                      onChange={(e) =>
                        setAbsenceData({
                          ...absenceData,
                          dataFim: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="modalButtonContainer">
                  <button
                    className="confirm-aus-botao"
                    onClick={handleAbsenceSave}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "90%",
    maxWidth: "650px",
    backgroundColor: "white",
    padding: "40px 20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  title: {
    fontSize: "50px",
    color: "#343434",
    textAlign: "center",
    marginBottom: "20px",
    borderBottom: "2px solid #0e94ff",
    paddingBottom: "10px",
  },
  inputGroup: {
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "15px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
    marginBottom: "15px",
    boxSizing: "border-box",
  },
  button: {
    display: "block",
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#0e94ff",
    color: "white",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
  },
  absenceButton: {
    display: "block",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px 20px",
    backgroundColor: "orange",
    color: "white",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "10px auto",
  },
  absenceArea: {
    marginTop: "10px",
    padding: "15px",
    backgroundColor: "#FFEB99", // Amarelo mais escuro
    borderRadius: "5px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    animation: "expand 0.3s ease-out",
  },

  modalLabel: {
    display: "block" /* Mantém a etiqueta e o campo em linhas separadas */,
    marginBottom: "5px" /* Espaçamento entre a etiqueta e o campo */,
    fontSize: "16px",
    color: "#343434",
    fontWeight: "500",
  },

  selectInput: {
    width: "100%" /* Ocupa toda a largura disponível */,
    padding: "8px" /* Espaçamento interno para facilidade de uso */,
    marginBottom: "10px" /* Espaçamento entre este e o próximo campo */,
    border: "1px solid #ccc" /* Borda sutil */,
    borderRadius: "4px" /* Cantos arredondados */,
  },

  dateGroup: {
    display: "flex",
    justifyContent: "space-between" /* Espaçamento entre os campos de data */,
    marginBottom: "10px",
  },

  dateField: {
    display: "flex",
    flexDirection: "column" /* Organiza label e input em coluna */,
    flexBasis: "48%" /* Ajuste a largura conforme necessário */,
  },

  dateInput: {
    width: "10%" /* Ocupa toda a largura disponível dentro do flex container */,
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginTop: "5px" /* Espaçamento entre label e input */,
  },

  modalButton: {
    display: "block",
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "white",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  inactivateButton: {
    display: "block",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px 20px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "10px auto",
  },
  modalContainer: {
    // estilos para a modal
    backgroundColor: "rgba(255,0,0,0.1)", // Vermelho claro
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
  },
  confirmButton: {
    backgroundColor: "#D32F2F", // Vermelho escuro
    padding: "10px 20px",
    color: "white",
    border: "none",
    borderRadius: "5px",
    marginRight: "10px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  cancelButton: {
    backgroundColor: "#E0E0E0", // Cinza claro
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  errorText: {
    color: "red",
    marginTop: "5px",
    fontSize: "14px",
    textAlign: "left",
  },
};

export default EditUserPage;
