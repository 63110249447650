/* ***********************************
Autor: Matheus Botelho
Data: 28/03/2024
Atividade: SSI 0002 - Automação Cadastro
Função: Modal para pesquisar cadastros específicos
************************************ */

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styles from './SearchModal.module.css'; // Certifique-se de que o caminho está correto
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

Modal.setAppElement('#root'); // Adapte conforme a necessidade do seu projeto

const ModalPesquisa = ({ isOpen, onRequestClose, onSelectCadastro }) => {
  const [nomeResp, setNomeResp] = useState('');
  const [nomeCond, setNomeCond] = useState('');
  const [resultados, setResultados] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const buscarCadastros = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/alteracao-cadastro/dados-pesquisa`,
          {
            params: { nome_resp: nomeResp, nome_cond: nomeCond },
          }
        );
        setResultados(response.data);
      } catch (error) {
        console.error('Erro ao buscar cadastros', error);
      }
    };

    buscarCadastros();
  }, [nomeResp, nomeCond, API_URL]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modalPesquisa}
      overlayClassName={styles.modalOverlay}
    >
      <button
        className={styles.closeButton}
        onClick={onRequestClose}
      >
        <CloseRoundedIcon />
      </button>{' '}
      {/* Botão para fechar o modal */}
      <h2 className={styles.modalTitle}>Buscar Cadastro</h2>
      <input
        type="text"
        placeholder="Nome do Responsável"
        className={styles.searchInput}
        value={nomeResp}
        onChange={(e) => setNomeResp(e.target.value)}
      />
      <input
        type="text"
        placeholder="Nome do Condomínio"
        className={styles.searchInput}
        value={nomeCond}
        onChange={(e) => setNomeCond(e.target.value)}
      />
      <ul className={styles.resultadosLista}>
        {resultados.length > 0 ? (
          resultados.map((cadastro) => (
            <li
              key={cadastro.id}
              onClick={() => onSelectCadastro(cadastro.id)}
            >
              {cadastro.nome_resp} - {cadastro.nome_cond} - ID: {cadastro.id}
            </li>
          ))
        ) : (
          <li>Nenhum resultado encontrado.</li>
        )}
      </ul>
    </Modal>
  );
};

export default ModalPesquisa;
