import React from 'react';
import Sidebar from './Navbars/Sidebar';
import Topbar from './Navbars/Topbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MainLayout.css';

const MainLayout = ({ children }) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        marginLeft: '100px',
        minHeight: '100vh',
      }}
    >
      <Topbar />
      <Sidebar />
      <div style={{ flex: 1, overflowY: 'hidden', padding: '0 1em' }}>
        <ToastContainer
          className="notificacao-euro"
          position="bottom-right"
          autoClose={5000}
        />
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
