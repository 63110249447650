import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Tabs, Tab, Grid, TextField, LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

// Seu componente de modal
function YourModalComponent({ children, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md" // Aumentar o tamanho horizontal do modal
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        {"Informações detalhadas"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function IndicadorFinanceiro() {
  const [value, setValue] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [condominios, setCondominios] = useState([]);
  const [despesas, setDespesas] = useState([]);
  const [selectedDays, setSelectedDays] = useState('');
  const [despesasPorFuncionario, setDespesasPorFuncionario] = useState({});
  const [lancamentosPorCondominio, setLancamentosPorCondominio] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [condominioIdToName, setCondominioIdToName] = useState({});
  const [lancamentosLabels, setLancamentosLabels] = useState([]);
  const [lancamentosSeries, setLancamentosSeries] = useState([]);
  const [despesasPorCondominio, setDespesasPorCondominio] = useState({});
  const [totalLancamentos, setTotalLancamentos] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    if (selectedDays) {
      setLoadingProgress(0);
      let idCondToId = {}; // Novo objeto para mapear id_cond para id
      const fetchUsuarios = async () => {
        let tempDespesasPorFuncionario = {};
        let tempLancamentosPorCondominio = {};
        let tempCondominioIdToName = {}; // Novo objeto temporário para mapear IDs de condomínios para nomes
  
        try {
          const res = await fetch(`${API_URL}/indicador-financeiro/usuarios-financeiro`);
          const data = await res.json();
         // console.log('Usuários obtidos:', data);
          setUsuarios(data);
  
          const totalFuncionarios = data.length;
          let funcionariosProcessados = 0;
  
          for (const usuario of data) {
           // console.log(`Processando usuário: ${usuario.id}`);
            const res = await fetch(`${API_URL}/indicador-financeiro/condominios-responsaveis/${usuario.id}`);
            const data = await res.json();
           // console.log('Condomínios responsáveis:', data);
            setCondominios(prev => [...prev, ...data]);
  
            for (const condominio of data) {
            //  console.log(`Processando condomínio: ${condominio.id}`);
              // Preencher os mapeamentos
              tempCondominioIdToName[condominio.id] = condominio.CONDOMINIO;
              idCondToId[condominio.id_cond] = condominio.id; // Preenchendo o novo mapeamento
  
              const res = await fetch(`${API_URL}/indicador-financeiro/despesas-condominio?idCond=${condominio.id_cond}&days=${selectedDays}`);
              const data = await res.json();
            //  console.log('Despesas do condomínio:', data);
              
              // Atualize o estado aqui
              setDespesasPorCondominio(prevDespesas => ({
                ...prevDespesas,
                [condominio.id]: data.numeroDeDespesas // ou data.despesas, dependendo do que você quer mostrar
              }));
              
  
              if (Array.isArray(data.despesas)) {
                setDespesas(prev => [...prev, ...data.despesas]);
              }
  
              const funcId = condominio.FINANCEIRO;
              const realId = idCondToId[condominio.id_cond]; // Obter o id real a partir do mapeamento
  
              if (tempDespesasPorFuncionario[funcId]) {
                if (!isNaN(data.numeroDeDespesas)) {
                  tempDespesasPorFuncionario[funcId] += data.numeroDeDespesas;
                }
              } else {
                if (!isNaN(data.numeroDeDespesas)) {
                  tempDespesasPorFuncionario[funcId] = data.numeroDeDespesas;
                }
              }
  
              if (tempLancamentosPorCondominio[realId]) {
                if (!isNaN(data.numeroDeDespesas)) {
                  tempLancamentosPorCondominio[realId] += data.numeroDeDespesas;
                }
              } else {
                if (!isNaN(data.numeroDeDespesas)) {
                  tempLancamentosPorCondominio[realId] = data.numeroDeDespesas;
                } else {
                  tempLancamentosPorCondominio[realId] = 0;
                }
              }
            }
  
            funcionariosProcessados++;
            const progress = (funcionariosProcessados / totalFuncionarios) * 100;
            setLoadingProgress(progress);
          }
  
          // Atualiza o estado para o mapeamento de ID de condomínio para nome
          setCondominioIdToName(tempCondominioIdToName);
  
          setDespesasPorFuncionario(tempDespesasPorFuncionario);
          setLancamentosPorCondominio(tempLancamentosPorCondominio);
        } catch (error) {
          console.error('Erro ao recuperar dados:', error);
        }
      };
      fetchUsuarios();
    }
  }, [selectedDays]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [sortConfig, setSortConfig] = useState({ key: 'nome', direction: 'ascending' });

  const sortData = (key) => {
    setSortConfig((currentSortConfig) => {
      if (currentSortConfig.key === key) {
        // Se clicar na mesma coluna, inverte a direção
        const nextDirection = currentSortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        return { key, direction: nextDirection };
      }
      // Se clicar em uma coluna diferente, começa com 'ascending'
      return { key, direction: 'ascending' };
    });
  };
  
  const orderedData = useMemo(() => {
    let sortableItems = [...(series ?? [])];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [series, sortConfig]);
  

  // Novos estados para o modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Função para fechar o modal
  const closeModal = () => {
    setModalOpen(false);
  };

  const funcionarioIdToName = {};
  usuarios.forEach((usuario) => {
    funcionarioIdToName[usuario.id] = usuario.nome;
  });

  // Combine os dados em um array, para poder ordená-los juntos
  const despesasELabels = Object.keys(despesasPorFuncionario).map(id_func => {
    return {
      id_func,
      nome: funcionarioIdToName[id_func] || id_func,
      totalDespesas: despesasPorFuncionario[id_func]
    };
  });

  // Agora ordene esse array com base em 'totalDespesas' em ordem decrescente
  despesasELabels.sort((a, b) => b.totalDespesas - a.totalDespesas);

  // Depois disso, você pode atualizar 'series' e 'seriesLabels' para corresponder à nova ordem
  var series = despesasELabels.map(item => item.totalDespesas);
  var seriesLabels = despesasELabels.map(item => item.nome);
  
  // Calcular o total de despesas
  const totalDespesas = series.reduce((acc, val) => acc + val, 0);

  // Criar rótulos personalizados
  const customSeriesLabels = series.map((despesa, index) => {
    const nome = seriesLabels[index];
    const porcentagem = ((despesa / totalDespesas) * 100).toFixed(2);
    return `${nome} - Total de lançamentos: ${despesa} - ${porcentagem}% do total`;
  });

  const nomeToIdFunc = {};
  usuarios.forEach((usuario) => {
    nomeToIdFunc[usuario.nome] = usuario.id_func;
  });
  
  const totalDespesasUsuario = selectedUser?.condominios?.reduce((acc, condominio) => {
    return acc + (despesasPorCondominio[condominio.id] || 0);
  }, 0) || 0;  
  
// Função auxiliar para truncar o nome do condomínio se for muito longo
function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

// Modificação na função toTitleCase para aplicar o truncate
function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      // Vamos usar 25 como o limite de caracteres para este exemplo
      return truncateString(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(), 25);
    }
  );
}

// Esta função processa os dados para o gráfico
const processChartData = () => {
  // Convertendo o objeto 'lancamentosPorCondominio' para uma matriz que pode ser ordenada
  const lancamentosArray = Object.entries(lancamentosPorCondominio).map(([id, lancamentos]) => {
    return { id, lancamentos, nome: condominioIdToName[id] };
  });

  // Ordenando os lançamentos em ordem decrescente
  lancamentosArray.sort((a, b) => b.lancamentos - a.lancamentos);

  // Pegando os top 20 lançamentos
  const topLancamentos = lancamentosArray.slice(0, 20);

  // Mapeie os labels para o title case
  let newLabels = topLancamentos.map(item => toTitleCase(item.nome));
  let newSeries = topLancamentos.map(item => item.lancamentos);

  // Somando todos os lançamentos que não estão no top 20
  const outrosLancamentos = lancamentosArray.slice(20).reduce((acc, curr) => acc + curr.lancamentos, 0);

  // Incluindo "Outros" se houver lançamentos fora do top 20
  if (outrosLancamentos > 0) {
    newLabels.push('Outros');
    newSeries.push(outrosLancamentos);
  }

  // Aqui você provavelmente terá alguma lógica para atualizar o gráfico com os novos labels e series
  // updateChart(newLabels, newSeries);

  // Retorna os dados processados (opcional dependendo de como você usa a função)
  return { labels: newLabels, series: newSeries };
};

  useEffect(() => {
    // Call processChartData() without checking lancamentosSeries.length
    const { labels, series } = processChartData();
    setLancamentosLabels(labels);
    setLancamentosSeries(series);
  }, [lancamentosPorCondominio]); // Dependência para quando os lançamentos por condomínio mudarem
  
  const openModal = (userInfo) => {
   // console.log("ID do usuário selecionado:", userInfo.id_func);
  
    if (!userInfo.id_func) {
      console.error("ID do usuário não disponível");
      return;
    }
  
    let userCondominios = condominios.filter(cond => cond.FINANCEIRO === userInfo.id_func);
  
   // console.log("Condomínios antes da ordenação:", userCondominios);
  
    // Adiciona um log para mostrar os valores de LANCAMENTOS
    userCondominios.forEach(cond => {
      const lancamentos = lancamentosPorCondominio[cond.id]; // Usa o estado lancamentosPorCondominio
     // console.log(`Condomínio: ${cond.CONDOMINIO}, LANCAMENTOS: ${lancamentos}`);
      cond.LANCAMENTOS = lancamentos || 0; // Atribui os lançamentos ao objeto do condomínio, default para 0 se não existir
    });
  
    // Ordenando os condomínios em ordem decrescente de lançamentos
    userCondominios.sort((a, b) => {
      const lancamentosA = a.LANCAMENTOS; // Aqui já é número por causa do ajuste acima
      const lancamentosB = b.LANCAMENTOS;
  
      return lancamentosB - lancamentosA;
    });
  
   // console.log("Condomínios após a ordenação:", userCondominios);
  
    // Calculando o total de despesas do usuário
    const totalDespesasUsuario = userCondominios.reduce((total, cond) => {
      const despesa = despesasPorCondominio[cond.id] || 0;
      return total + despesa;
    }, 0);
  
    // Adicione esses condomínios ao objeto userInfo, junto com o total de despesas.
    userInfo.condominios = userCondominios;
    userInfo.totalDespesas = totalDespesasUsuario;
  
   // console.log(`Informações do usuário ${userInfo.nome} após processamento:`, userInfo);
  
    setSelectedUser(userInfo);
    setModalOpen(true);
  };
  

return (
  <div style={{ paddingBottom: '50px' }}> {/* Isso adiciona um espaço no final do componente */}
    {/* Logs para debug */}
   {/* {console.log("Renderizando modal com:", selectedUser)}
    {console.log("isModalOpen:", isModalOpen)} */}

    {/* Modal para exibir informações detalhadas */}
    {isModalOpen && (
      <YourModalComponent onClose={closeModal}>
        <h2 style={{ textAlign: 'center' }}>{selectedUser?.nome || 'Nome não disponível'}</h2>
        <table>
          <thead>
            <tr>
              <th>Nome do Condomínio</th>
              <th style={{ textAlign: 'center', paddingRight: '10px' }}>Lançamentos</th> {/* Texto atualizado e espaço à direita */}
              <th style={{ textAlign: 'center' }}>% do Total</th>
            </tr>
          </thead>
          <tbody>
          {selectedUser?.condominios?.map((condominio, index) => {
          const despesa = despesasPorCondominio[condominio.id] || 0;
          const porcentagem = ((despesa / selectedUser.totalDespesas) * 100).toFixed(2);

              return (
                <tr key={index}>
                  <td>{condominio.CONDOMINIO}</td>
                  <td style={{ textAlign: 'center', paddingRight: '10px' }}>{despesa}</td> {/* Espaço à direita */}
                  <td style={{ textAlign: 'center' }}>{porcentagem}%</td>
                </tr>
              );
            }) || <tr><td colSpan="3">Informações de condomínios não disponíveis</td></tr>}
          </tbody>
        </table>
    </YourModalComponent>

    )}

    <Tabs value={value} onChange={handleChange}>
      <Tab label="Despesas" />
      <Tab label="Visão Geral" />
    </Tabs>

    {value === 0 && (
  <>
    <Typography variant="h3" align="center" style={{ margin: '10px 0' }}>
      Financeiro
    </Typography>
    {selectedDays === '' ? (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6">
          Selecione o período:
        </Typography>
        <TextField
          select
          label="Período"
          variant="outlined"
          value={selectedDays}
          onChange={(e) => setSelectedDays(e.target.value)}
          style={{ width: '200px' }}
          SelectProps={{
            native: true,
            displayEmpty: true,
            placeholder: 'Período'
          }}
        >
          <option value="" disabled hidden>Período</option>
          <option value={30}>30 dias</option>
          <option value={60}>60 dias</option>
          <option value={90}>90 dias</option>
          <option value={180}>180 dias</option>
        </TextField>
      </div>
    ) : (
      <Grid container spacing={3}>
        <Grid item xs={12}>
        {loadingProgress < 100 ? (
          <LinearProgress variant="determinate" value={loadingProgress} style={{ width: '80%', margin: '10px auto' }} />
        ) : (
          <>
            <Typography variant="h6" align="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
              Lançamentos por Colaborador
            </Typography>
            <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
              {/* Tabela com margem esquerda e ocupando menos colunas */}
              <Grid item xs={10} md={5} style={{ marginLeft: '24px', marginRight: '30px' }}>
              <div style={{ maxHeight: '450px', overflowY: 'auto', overflowX: 'hidden' }}>
                  <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: '20px',
                    marginRight: '20px'
                  }}>
                    <thead>
                      <tr>
                        <th style={{ fontWeight: 'normal', borderBottom: '1px solid black' }}>Colaborador</th>
                        <th style={{ textAlign: 'center', paddingRight: '10px', fontWeight: 'normal', borderBottom: '1px solid black' }}>Lançamentos</th>
                        <th style={{ textAlign: 'center', fontWeight: 'normal', borderBottom: '1px solid black' }}>% do Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {series.map((despesa, index) => {
                        const userInfo = {
                          id_func: nomeToIdFunc[seriesLabels[index]],
                          nome: seriesLabels[index],
                          totalDespesas: despesa,
                          percentual: ((despesa / totalDespesas) * 100).toFixed(2),
                        };
                        return (
                          <tr key={index} onClick={() => openModal(userInfo)}>
                            <td>{userInfo.nome}</td>
                            <td style={{ textAlign: 'center' }}>{userInfo.totalDespesas}</td>
                            <td style={{ textAlign: 'center' }}>{userInfo.percentual}%</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  </div>
                </Grid>
                
                {/* Gráfico 1 */}
                <Grid item xs={10} md={5}>
                  <ReactApexChart
                    options={{
                      labels: customSeriesLabels,
                      dataLabels: { enabled: true },
                      grid: { show: true },
                      legend: { show: false }
                    }}
                    series={series}
                    type="donut"
                    width="100%"
                    height="450" // Altura ajustada para alinhar com a legenda
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
              {/* Título centralizado para a seção */}
              <Grid item xs={10}>
              <Typography variant="h6" align="center" style={{ marginTop: '20px', marginBottom: '20px' }}>
                  Lançamentos por Condomínio
                </Typography>
              </Grid>
              {/* Tabela de legenda para o segundo gráfico à esquerda */}
              <Grid item xs={10} md={5} style={{ marginLeft: '24px', marginRight: '30px' }}>
              <div style={{ maxHeight: '450px', overflowY: 'auto', overflowX: 'hidden' }}>
                  <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    marginBottom: '20px',
                    marginRight: '20px'
                  }}>
                    <thead>
                      <tr>
                        <th style={{ fontWeight: 'normal', borderBottom: '1px solid black' }}>Empreendimentos</th>
                        <th style={{ textAlign: 'center', fontWeight: 'normal', borderBottom: '1px solid black' }}>Lançamentos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lancamentosLabels.map((label, index) => {
                        const value = lancamentosSeries[index];
                        return (
                          <tr key={label}>
                            <td>{label}</td>
                            <td style={{ textAlign: 'center' }}>{value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Grid>

              {/* Gráfico 2*/}
              <Grid item xs={10} md={5}>
              <ReactApexChart
                options={{
                  labels: lancamentosLabels,
                  dataLabels: { enabled: true },
                  grid: { show: true },
                  legend: { show: false } // Desativa a legenda padrão
                }}
                series={lancamentosSeries}
                type="donut"
                width="100%"
                height="450" // Altura ajustada para alinhar com a legenda
              />
              </Grid>
            </Grid>

            </>
          )}
        </Grid>
      </Grid>
    )}
  </>
)}

{value === 1 && (
  <div>
    <Typography variant="h6">
      Conteúdo da aba "Visão Geral" aqui.
    </Typography>
  </div>
)}
</div>
);
}  