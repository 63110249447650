import React, { useEffect, useState } from 'react';
import {
  format,
  parseISO,
  isFuture,
  isToday,
  startOfDay,
  isBefore,
  subDays,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import './Agenda.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Tooltip from '@mui/material/Tooltip';

function Agenda() {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [daysToShow, setDaysToShow] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  //SSI 0009 - 22/04/2024

  useEffect(() => {
    fetchEvents(); // Carrega os eventos quando o componente é montado
  }, [daysToShow]);

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(new Date()), 300000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Chama fetchEvents quando searchPerformed muda, garantindo que estados foram atualizados
    fetchEvents();
  }, [searchPerformed]);

  const fetchEvents = () => {
    setLoading(true);
    const url = `${API_URL}/calendar/events?daysAgo=${daysToShow}&search=${encodeURIComponent(
      searchTerm
    )}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) throw new Error('Erro ao buscar eventos.');
        return response.json();
      })
      .then((data) => {
        setEvents(data.items || []);
        setSearchPerformed(!!searchTerm);
      })
      .catch((error) => {
        setError(error.toString());
        setSearchPerformed(false);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = () => {
    if (searchPerformed) {
      setSearchTerm('');
      setDaysToShow(0);
      setSearchPerformed(false);
    } else {
      setDaysToShow(730);
      setSearchPerformed(true);
    }
  }; // FIM DA ALTERAÇÃO

  const handlePreviousDay = () => {
    if (!searchTerm.trim()) {
      // Garante que a navegação só funcione quando não houver busca ativa
      setDaysToShow((prevDaysToShow) => prevDaysToShow + 1);
    }
  };

  const handleNextDay = () => {
    if (!searchTerm.trim() && daysToShow > 0) {
      // Mesma lógica de checagem aqui
      setDaysToShow((prevDaysToShow) => prevDaysToShow - 1);
    }
  };

  const eventStatus = (start, end, isAllDayEvent) => {
    const currentDate = startOfDay(new Date()); // Começo do dia atual para comparação
    const startTime = parseISO(start);
    const eventDate = isAllDayEvent
      ? parseISO(start).setHours(0, 0, 0, 0)
      : startOfDay(startTime);

    // Para eventos de dia inteiro ou eventos específicos, compara se estão no passado
    if (isFuture(startTime) || isToday(startTime)) return 'future';
    if (isBefore(eventDate, currentDate)) return 'past'; // Usa isBefore para verificar se a data do evento é antes da data atual
    return 'current';
  };

  const groupEventsByDay = (events) => {
    const grouped = {};
    events.forEach((event) => {
      let dateKey;
      if (event.start.dateTime) {
        const date = parseISO(event.start.dateTime);
        dateKey = format(date, 'yyyy-MM-dd');
      } else if (event.start.date) {
        const date = parseISO(event.start.date);
        dateKey = format(date, 'yyyy-MM-dd');
      }

      if (dateKey) {
        if (!grouped[dateKey]) {
          grouped[dateKey] = [];
        }
        grouped[dateKey].push(event);
      }
    });
    return grouped;
  };

  const toggleEventDetails = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  const dateHeaderClass = (date) => {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) {
      return 'today';
    } else if (isBefore(parsedDate, startOfDay(new Date()))) {
      return 'past';
    }
    return ''; // Retorna uma string vazia para dias próximos ou futuros
  };

  const navigationDate = format(subDays(new Date(), daysToShow), 'dd/MM/yyyy', {
    locale: ptBR,
  });

  const groupedEvents = groupEventsByDay(events);

  return (
    <div className="agenda-container">
      <h2 className="agenda-title">Agenda</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Digite sua pesquisa"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button onClick={handleSearch}>
          {searchPerformed ? 'Limpar Busca' : 'Buscar'}
        </button>
      </div>
      <div className="navigation-days">
        <div
          className="navigation-arrow-wrapper"
          onClick={handlePreviousDay}
        >
          <ArrowBackIosIcon className="navigation-arrow" />
        </div>
        <span className="navigation-date">{navigationDate}</span>
        <div
          className="navigation-arrow-wrapper"
          onClick={handleNextDay}
        >
          <ArrowForwardIosIcon className="navigation-arrow" />
        </div>
      </div>
      {loading && <p className="loading-message">Carregando eventos...</p>}
      {error && <p className="error-message">Erro: {error}</p>}
      <div className="events-container">
        {Object.entries(groupedEvents).length > 0 ? (
          Object.entries(groupedEvents).map(([date, events]) => (
            <div
              key={date}
              className="date-group"
            >
              <div className={`date-header ${dateHeaderClass(date)}`}>
                {format(parseISO(date), "dd 'de' MMMM', 'EEEE", {
                  locale: ptBR,
                })}
              </div>
              <ul className="event-list">
                {events.map((event) => (
                  <li
                    key={event.id}
                    className={`event-item ${eventStatus(
                      event.start.dateTime || event.start.date,
                      event.end.dateTime || event.end.date,
                      !!event.start.date && !event.start.dateTime
                    )}`}
                    onClick={() => toggleEventDetails(event.id)}
                  >
                    <div className="event-time-date">
                      <span className="event-time">
                        {event.start.dateTime
                          ? format(parseISO(event.start.dateTime), 'HH:mm')
                          : event.start.date
                          ? 'Todo o dia'
                          : 'Horário não informado'}
                      </span>
                    </div>
                    <div className="event-summary-details">
                      <div className="event-details">
                        <strong className="event-summary">
                          {event.summary}
                        </strong>
                        {expandedEventId === event.id && (
                          <div className="event-expanded-details">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.description,
                              }}
                            />
                            <p className="event-place">
                              Local: {event.location}
                            </p>
                          </div>
                        )}
                      </div>
                      {/* Mover o botão de anexo para fora do bloco condicional */}
                      {event.attachments &&
                        event.attachments.length > 0 &&
                        event.attachments.map((attachment, index) => (
                          <Tooltip
                            title="Ver ata de Reunião"
                            key={index}
                          >
                            <button
                              className="attachment-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Impede que o clique no botão também acione o toggleEventDetails
                                window.open(attachment.fileUrl, '_blank');
                              }}
                            >
                              <AttachFileIcon />
                            </button>
                          </Tooltip>
                        ))}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : !loading ? (
          <p className="no-events-message">
            Nenhum evento encontrado ou aguardando autenticação.
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default Agenda;
