/* ***********************************
Autor: Matheus Botelho
Data: 27/05/24
Atividade: SSI 0018 - Página de atualizações Eureka
Função: Página para criação dos reportes das Atualizações do Eureka
************************************ */

import React, { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './CriarAtualizacao.module.css';

const CriarAtualizacao = () => {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [message, setMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/updates/criar`, {
        titulo,
        descricao,
      });
      setMessage(response.data.message);
      setTitulo('');
      setDescricao('');
    } catch (error) {
      setMessage(error.response.data.error || 'Erro ao enviar atualização');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Enviar Atualização</h1>
      <form
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <div className={styles.formGroup}>
          <label className={styles.label}>Título:</label>
          <input
            type="text"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Descrição:</label>
          <ReactQuill
            value={descricao}
            onChange={setDescricao}
            className={styles.textarea}
            modules={{
              toolbar: [
                [{ header: [3, 4, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
              ],
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
            ]}
          />
        </div>
        <button
          type="submit"
          className={styles.button}
        >
          Enviar
        </button>
      </form>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default CriarAtualizacao;
