import React from "react";
import "./PasswordChecker.css";

const PasswordChecker = ({ senha }) => {
	const checkSenha = () => {
		if (!senha) {
			document.getElementById("min").className = "";
			document.getElementById("mai").className = "";
			document.getElementById("num").className = "";
			document.getElementById("especial").className = "";
			document.getElementById("tamanho").className = "";
			return;
		}

		const min = new RegExp("(?=.*[a-z])");
		const mai = new RegExp("(?=.*[A-Z])");
		const num = new RegExp("(?=.*[0-9])");
		const especial = new RegExp("(?=.*[!@#$%^&*])");
		const tamanho = new RegExp("(?=.{7,})");

		document.getElementById("min").className = min.test(senha) ? "valid" : "";
		document.getElementById("mai").className = mai.test(senha) ? "valid" : "";
		document.getElementById("num").className = num.test(senha) ? "valid" : "";
		document.getElementById("especial").className = especial.test(senha)
			? "valid"
			: "";
		document.getElementById("tamanho").className = tamanho.test(senha)
			? "valid"
			: "";
	};

	// Chamar checkSenha toda vez que a senha for alterada
	React.useEffect(() => {
		checkSenha();
	}, [senha]);

	return (
		<div className="validacao">
			<ul>
				<li id="min">Pelo menos 1 letra minúscula</li>
				<li id="mai">Pelo menos 1 letra maiúscula</li>
				<li id="num">Pelo menos 1 número</li>
				<li id="especial">Pelo menos 1 caracter especial</li>
				<li id="tamanho">Pelo menos 7 caracteres</li>
			</ul>
		</div>
	);
};

export default PasswordChecker;
