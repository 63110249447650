import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { distance } from "fastest-levenshtein";
import "./ButtonStyle.css";
import "./ButtonCopy.css";
import "./RateioPage.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const RateioPage = () => {
  const [selectedPlanoId, setSelectedPlanoId] = useState("");
  const [planoRateioEntries, setPlanoRateioEntries] = useState("");
  const [processedRateio, setProcessedRateio] = useState({});
  const [contas, setContas] = useState([]);
  const [todosPlanos, setTodosPlanos] = useState([]);
  const [output, setOutput] = useState("");
  const [etapa, setEtapa] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const app_token = process.env.REACT_APP_APP_TOKEN;
  const access_token = process.env.REACT_APP_ACCESS_TOKEN;

  const titleStyle = {
    textAlign: "center",
    marginBottom: "2px",
    color: "#333",
    fontSize: "28px",
    fontFamily: "sans-serif", // Fonte que está sendo usada no Sidebar.js para os títulos dos departamentos
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const buscarTodosPlanos = async () => {
      try {
        const response = await axios.get(
          "https://api.superlogica.net/v2/condor/planocontas/index/?ID_CONDOMINIO_COND=-1",
          {
            headers: {
              "Content-Type": "application/json",
              app_token: app_token,
              access_token: access_token,
            },
          }
        );
        const data = response.data.sort(
          (a, b) => a.id_planoconta_plc - b.id_planoconta_plc
        );
        data.forEach(
          (d) =>
            (d.st_nome_plc =
              d.st_nome_plc.charAt(0).toUpperCase() +
              d.st_nome_plc.slice(1).toLowerCase())
        );
        setTodosPlanos(data);
        const planoCompleto = data.find(
          (plano) => plano.st_nome_plc === "Plano de contas completo"
        );
        if (planoCompleto) {
          setSelectedPlanoId(planoCompleto.id_planoconta_plc);
        }
      } catch (error) {
        console.error(
          "Não foi possível buscar todos os planos de contas",
          error
        );
      }
    };
    buscarTodosPlanos();
  }, [app_token, access_token]);

  const handlePlanoSelect = async (id) => {
    const selectedPlano = todosPlanos.find(
      (plano) => plano.id_planoconta_plc === id
    );
    setSelectedPlanoId(id);

    // Fetch the accounts
    try {
      const response = await axios.get(
        `https://api.superlogica.net/v2/condor/planocontas?id=${id}`,
        {
          headers: {
            app_token: app_token,
            access_token: access_token,
          },
        }
      );
      const accounts = response.data.filter((a) =>
        a.st_conta_cont.startsWith("2")
      ); // Filter accounts that start with "2"
     // console.log(accounts); // Log the accounts
    } catch (error) {
      console.error("Não foi possível buscar as contas", error);
    }
  };

  const handleRateioEntriesChange = (event) => {
    setPlanoRateioEntries(event.target.value);
  };

  const handleAgruparChange = (index, checked) => {
    //console.log("handleAgruparChange called with index:", index, "and checked:", checked);
    setProcessedRateio((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        agrupar: checked,
        manualAgrupar: true, // Adicione esta linha
      },
    }));
  };

  const handleObservationChange = (index, newObservation) => {
    setProcessedRateio((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        observacao: newObservation,
      },
    }));
  };

  const processRateioEntries = async () => {
    try {
      const response = await axios.get(
        `https://api.superlogica.net/v2/condor/planocontas?id=${selectedPlanoId}`,
        {
          headers: {
            app_token: app_token,
            access_token: access_token,
          },
        }
      );
      const accounts = response.data.filter((a) =>
        a.st_conta_cont.startsWith("2")
      );

      const lines = planoRateioEntries.split("\n");
      let entries = [];
      let accountCounts = {};
      let pendingDescription = "";

      lines.forEach((line) => {
        if (
          line.length > 55 &&
          !/\d{1,3}(?:\.\d{3})*,\d{2}(?=\s|$|\D)/.test(line)
        ) {
          return;
        }

        let description = line.substring(0, 50).trim().toUpperCase();
        let isTotalLine = description.includes("TOTAL");
        let valueMatch = line
          .substring(50)
          .trim()
          .match(/\d{1,3}(?:\.\d{3})*,\d{2}(?=\s|$|\D)/);
        let valueStr = valueMatch ? valueMatch[0] : null;
        let value = valueStr
          ? parseFloat(valueStr.replace(/\./g, "").replace(",", "."))
          : NaN;

        if (isTotalLine) {
          entries.push({
            descricao: description,
            valor: isNaN(value) ? "0,00" : value.toFixed(2).replace(".", ","),
            agrupar: false,
            isTotal: true,
          });
          return;
        }

        if (!valueStr && pendingDescription) {
          pendingDescription += " " + description;
        } else {
          if (pendingDescription) {
            description = pendingDescription + " " + description;
            pendingDescription = "";
          }

          if (!isNaN(value)) {
            let observation = "";
            let account = accounts.find(
              (a) => a.st_descricao_cont.trim().toUpperCase() === description
            );

            // Se a conta não for encontrada pela descrição exata, usa a distância de Levenshtein
            if (!account) {
              const descriptionParts = description.split("-");
              if (descriptionParts.length > 1) {
                description = descriptionParts[0].trim();
                observation = descriptionParts.slice(1).join("-").trim();
              }

              let minDistance = Infinity;
              accounts.forEach((a) => {
                let d = distance(
                  a.st_descricao_cont.trim().toUpperCase(),
                  description
                );
                if (d < minDistance) {
                  minDistance = d;
                  account = a;
                }
              });
            }

            const entry = {
              conta: account ? account.st_conta_cont.replace(/\.$/, "") : "",
              descricao: description,
              observacao: observation,
              valor: isNaN(value) ? "0,00" : value.toFixed(2).replace(".", ","),
              agrupar: false,
            };

            if (entry.conta) {
              accountCounts[entry.conta] =
                (accountCounts[entry.conta] || 0) + 1;
            }

            entries.push(entry);
          } else {
            pendingDescription = description;
          }
        }
      });

      entries = entries.map((entry) => ({
        ...entry,
        agrupar: entry.conta && accountCounts[entry.conta] > 1,
      }));

      const processedEntries = entries.reduce(
        (obj, entry, index) => ({ ...obj, [index]: entry }),
        {}
      );

      setProcessedRateio(processedEntries);
      setEtapa(2);
      window.scrollTo(0, window.innerHeight + 110);
    } catch (error) {
      console.error("Não foi possível processar as entradas de rateio", error);
    }
  };

  const handleExport = () => {
    const entries = Object.values(processedRateio);

    // Lista para guardar as entradas agregadas
    let aggregatedList = [];

    // Dicionário para armazenar as entradas agregadas por número de conta
    let aggregatedEntriesByAccount = {};

    entries.forEach((entry) => {
      // Se a entrada não deve ser agrupada, simplesmente adicione-a à lista agregada
      if (!entry.agrupar) {
        aggregatedList.push(entry);
        return;
      }

      // Se a entrada deve ser agrupada
      if (!aggregatedEntriesByAccount[entry.conta]) {
        aggregatedEntriesByAccount[entry.conta] = { ...entry };
        aggregatedList.push(aggregatedEntriesByAccount[entry.conta]);
      } else {
        const existingEntry = aggregatedEntriesByAccount[entry.conta];
        const newValue =
          parseFloat(existingEntry.valor.replace(",", ".")) +
          parseFloat(entry.valor.replace(",", "."));
        existingEntry.valor = newValue.toFixed(2).replace(".", ",");
      }
    });

    const groups = [];
    let currentGroup = [];

    for (let entry of aggregatedList) {
      if (entry.isTotal) {
        currentGroup.push(entry);
        groups.push(currentGroup);
        currentGroup = [];
      } else {
        currentGroup.push(entry);
      }
    }

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    let output = "DÉBITOS QUOTA CONDOMINIAL\n";

    for (let group of groups) {
      const totalEntry = group.find((entry) => entry.isTotal);
      if (totalEntry) {
        const groupName = totalEntry.descricao
          .replace("TOTAL DE", "")
          .replace(":", "")
          .trim();
        if (groupName !== "TOTAL") {
          output += ` ${groupName}\n`;
        }
      }

      for (let entry of group) {
        const formattedValue = parseFloat(entry.valor.replace(",", "."))
          .toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })
          .slice(3) // Remove o símbolo da moeda
          .padStart(11, " "); // Garante que o valor sempre ocupe 10 espaços

        // Construção da descrição e da observação
        let description = entry.descricao.toUpperCase();
        let observation = entry.observacao
          ? entry.observacao.toUpperCase()
          : "";
        let combinedText = `${description} ${observation}`.trim();

        // Calcula o espaço disponível para descrição + observação
        const availableSpace = 68 - formattedValue.length;

        // Ajusta o texto combinado para caber no espaço disponível
        if (combinedText.length > availableSpace) {
          combinedText = combinedText.substring(0, availableSpace);
        }

        const line =
          combinedText.padEnd(68 - formattedValue.length, " ") + formattedValue;
        output += line + "\n";

        if (entry.isTotal) {
          output += "\n";
        }
      }
    }

    setOutput(output);
    setEtapa(3);
    window.scrollTo(0, 2 * window.innerHeight + 160);
  };

  // Adicione o estado para verificar se o conteúdo foi copiado
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (navigator.clipboard) {
      // Use a API Clipboard se estiver disponível
      navigator.clipboard
        .writeText(output)
        .then(() => {
          setCopied(true);

          // Reset o estado após 3 segundos
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        })
        .catch((err) => {
          console.error("Falha ao copiar para a área de transferência:", err);
        });
    } else {
      // Método alternativo para copiar para a área de transferência
      const textarea = document.createElement("textarea");
      textarea.value = output;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");
        setCopied(true);

        // Reset o estado após 3 segundos
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (err) {
        console.error("Falha ao copiar para a área de transferência:", err);
      }
      document.body.removeChild(textarea);
    }
  };

  const updateScrollPosition = () => {
    // console.log(window.scrollY); // Adicione este log para teste
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScrollPosition);
    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  const getStepColor = (stepNumber) => {
    const thresholds = [70, 400, 1100];
    const isActive = scrollPosition >= thresholds[stepNumber - 1];
    return isActive ? "#0e94ff" : "gray";
  };

  return (
    <div className="rateio-container">
      <div className="menu-passos-rateio">
        <div
          className="rateio-passo"
          style={{ backgroundColor: getStepColor(1) }}
        >
          <span>1</span>
        </div>
        <div
          className="rateio-passo"
          style={{ backgroundColor: getStepColor(2) }}
        >
          {/* Ajuste a altura e a cor da linha aqui */}
          <div
            className="barra-rateio-passo"
            style={{ backgroundColor: getStepColor(2) }}
          ></div>
          <span>2</span>
        </div>
        <div
          className="rateio-passo"
          style={{ backgroundColor: getStepColor(3) }}
        >
          {/* Ajuste a altura e a cor da linha aqui */}
          <div
            className="barra-rateio-passo"
            style={{ backgroundColor: getStepColor(3) }}
          ></div>
          <span>3</span>
        </div>
      </div>

      <div className="rateio-content">
        <div className="rateio-title">Rateio</div>
        <div className="rateio-primeira-parte">
          <div className="rateio-subtitulo">Selecione um plano de contas</div>
          <select
            className="rateio-plano-contas"
            value={selectedPlanoId}
            onChange={(e) => handlePlanoSelect(e.target.value)}
          >
            {todosPlanos.map((plano, index) => (
              <option key={index} value={plano.id_planoconta_plc}>
                {plano.st_nome_plc}
              </option>
            ))}
          </select>
          <div className="rateio-subtitulo">Cole o rateio aqui</div>
          <textarea
            className="rateio-colar"
            value={planoRateioEntries}
            onChange={handleRateioEntriesChange}
          />
          <div className="btn-container">
            <button class="expanding-btn" onClick={processRateioEntries}>
              PRÓXIMO
              <ArrowForwardIosRoundedIcon className="arrow" />
            </button>
          </div>
        </div>

        <div className="rateio-segunda-parte">
          <div className="rateio-subtitulo">Rateio de contas</div>
          <div className="rateio-tabela-container">
            <table className="rateio-segunda-parte-tabela">
              <thead>
                <tr>
                  <th>Conta</th>
                  <th>Descrição</th>
                  <th>Observação</th>
                  <th>Agrupar</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(processedRateio).map((index, i) => (
                  <tr key={index}>
                    <td>{processedRateio[index].conta}</td>
                    <td>{processedRateio[index].descricao}</td>
                    <td>
                      <input
                        type="text"
                        value={processedRateio[index].observacao}
                        onChange={(e) =>
                          handleObservationChange(index, e.target.value)
                        }
                        // Adicionado textAlign: 'center' aqui
                      />
                    </td>
                    <td className="rateio-total-tabela">
                      {!processedRateio[index].descricao.includes("TOTAL") && (
                        <input
                          type="checkbox"
                          checked={processedRateio[index].agrupar}
                          onChange={(e) =>
                            handleAgruparChange(index, e.target.checked)
                          }
                        />
                      )}
                    </td>
                    <td>{processedRateio[index].valor}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="btn-container">
            <button class="expanding-btn" onClick={handleExport}>
              PRÓXIMO
              <ArrowForwardIosRoundedIcon className="arrow" />
            </button>
          </div>
        </div>
        <div className="rateio-terceira-parte">
          <div className="rateio-subtitulo">Exportação</div>
          <textarea className="rateio-dados-prontos" value={output} readOnly />
          <div className="btn-container">
            <button className="copy-btn" onClick={handleCopy}>
              <p>{copied ? "COPIADO!" : "COPIAR"}</p>
              <ContentCopyRoundedIcon className="copy-icon" />
              <span className="effect"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateioPage;
