import React from 'react';
import './TogglePw.css'

const TogglePasswordButton = ({ showPassword, setShowPassword }) => {
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <span 
      id="toggleBtn" 
      className={showPassword ? "hide" : ""} 
      onClick={togglePasswordVisibility}
    />
  );
};

export default TogglePasswordButton;
