/* ***********************************
Autor: Matheus Botelho
Data: 30/04/24
Atividade: SSI 0012 Navegação rápida - Consultar Topbar
Função: Navegação rápida para encontrar responsáveis do condomínio pela Topbar
************************************ */

import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../UserContext';
import {
  Box,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  IconButton,
  Grow,
} from '@mui/material';
import styles from './CondominioPesquisa.module.css';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import { Button } from '@mui/material';

const CondominioPesquisa = () => {
  const { user } = useContext(UserContext);
  const [condominios, setCondominios] = useState([]);
  const [selectedCondominio, setSelectedCondominio] = useState(null);
  const [userNames, setUserNames] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);
    let url = `${API_URL}/condominios/comCidade`;

    if (user.usuario_cidade === 'Sim') {
      url = `${API_URL}/carteira-por-usuario/usuario-cidade/condominios-restritos/${user.id_func}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCondominios(data.filter((cond) => cond.fantasia && cond.cidade));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erro ao buscar os condomínios:', error);
        setLoading(false);
      });
  }, [API_URL, user.id_func, user.usuario_cidade]);

  useEffect(() => {
    if (selectedCondominio) {
      setLoading(true);
      fetch(`${API_URL}/condominios/porId/${selectedCondominio.chave}`)
        .then((response) => response.json())
        .then((data) => {
          const roles = [
            'FINANCEIRO',
            'COBRANÇA',
            'RH',
            'COMUNICADOS_NOTIFICAÇÕES',
            'ASSEMBLEIAS',
          ];
          const roleNames = roles.map((role) => ({
            role: role,
            idFunc: data[role],
          }));
          fetchUserNames(roleNames);
        })
        .catch((error) => {
          console.error('Erro ao buscar detalhes do condomínio:', error);
          setErrorMessage('Erro ao carregar detalhes do condomínio.');
          setLoading(false);
        });
    }
  }, [selectedCondominio, API_URL]);

  const getRoleIcon = (role) => {
    switch (role) {
      case 'Financeiro':
        return <MonetizationOnIcon fontSize="small" />;
      case 'Cobrança':
        return <BusinessCenterIcon fontSize="small" />;
      case 'Rh':
        return <PeopleIcon fontSize="small" />;
      case 'Jurídico':
        return <AnnouncementIcon fontSize="small" />;
      case 'Assembleias':
        return <NotificationsIcon fontSize="small" />;
      default:
        return null;
    }
  };

  //SSI 0027 - 13/06/24

  const fetchUserNames = async (roleNames) => {
    try {
      const fetchPromises = roleNames.map(async ({ role, idFunc }) => {
        const userNameData = await getUserNameById(idFunc);
        const label =
          role === 'COMUNICADOS_NOTIFICAÇÕES'
            ? 'Jurídico'
            : role.charAt(0) + role.slice(1).toLowerCase();
        const displayName = userNameData.alertText
          ? `${userNameData.name} (${userNameData.alertText})`
          : userNameData.name;
        return {
          label,
          name: displayName,
        };
      });

      const results = await Promise.all(fetchPromises);
      const names = results.reduce((acc, result) => {
        if (result) {
          acc[result.label] = result.name;
        }
        return acc;
      }, {});

      setUserNames(names);
      setOpen(true);
    } catch (error) {
      console.error('Erro ao buscar nomes de usuários:', error);
      setErrorMessage('Erro ao buscar responsáveis.');
    }
    setLoading(false);
  };

  const getUserNameById = async (id) => {
    let originalName = await fetchUserNameById(id);
    let name = originalName;
    let alertText = '';
    let dataFormatada = '';

    let absenceDetails = null;

    const absenceResponse = await fetch(
      `${API_URL}/ausencia/check-active-absences/${id}`
    );
    const absenceData = await absenceResponse.json();

    const hasActiveAbsence = absenceData && absenceData.activeAbsencesCount > 0;

    if (hasActiveAbsence) {
      absenceDetails = await fetchAbsenceDetails(id);

      if (absenceDetails && absenceDetails.dt_fim) {
        const dataObjeto = new Date(absenceDetails.dt_fim);
        const dia = String(dataObjeto.getDate()).padStart(2, '0');
        const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0');
        const ano = dataObjeto.getFullYear();
        dataFormatada = `${dia}/${mes}/${ano}`;
      }

      const redirectionUrl = `${API_URL}/ausencia/redirection-by-user/${id}/${selectedCondominio.chave}`;
      const redirectionResponse = await fetch(redirectionUrl);
      const redirectionData = await redirectionResponse.json();

      const hasActiveRedirection =
        redirectionData && redirectionData.redirection;

      if (hasActiveRedirection) {
        name = await fetchUserNameById(
          redirectionData.redirection.id_func_temp
        );
        alertText = 'Cumprindo ausência';
      } else {
        alertText = 'Ausente, necessita redirecionamento';
      }
    }

    return {
      name,
      alertText,
      originalName,
      absenceEndDate: dataFormatada,
      originalNameForAbsence: absenceDetails ? absenceDetails.nome : '',
    };
  };

  //FIM DA ALTERAÇÃO

  const fetchUserNameById = async (id) => {
    try {
      const response = await fetch(`${API_URL}/user/get-user/${id}`);
      const data = await response.json();
      return data && data.user && data.user.nome ? data.user.nome : '';
    } catch (error) {
      return '';
    }
  };

  const fetchAbsenceDetails = async (id) => {
    try {
      const response = await fetch(`${API_URL}/user/get-absence-details/${id}`);
      const data = await response.json();
      return data.success ? data.absenceDetails : null;
    } catch (error) {
      return null;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCondominio(null);
    setUserNames({});
    setInputValue('');
  };

  //SSI 0014 - 08/05/24

  return (
    <Box
      className={styles.condominioPesquisaContainer}
      style={{ position: 'relative' }}
    >
      {!isAutocompleteOpen && (
        <IconButton
          onClick={() => setIsAutocompleteOpen(true)}
          style={{ color: 'white' }}
        >
          <AccountBalanceWalletRoundedIcon />
        </IconButton>
      )}
      {isAutocompleteOpen && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.5s ease-in-out',
          }}
        >
          <Grow
            in={isAutocompleteOpen}
            timeout={300}
          >
            <div className={styles.myAutocompleteContainer}>
              <Autocomplete
                options={condominios}
                size="small"
                getOptionLabel={(option) =>
                  `${option.fantasia} - ${option.chave}`
                }
                sx={{
                  width: 200,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white', // Cor da borda padrão
                    },
                    '&:hover fieldset': {
                      borderColor: 'white', // Cor da borda ao passar o mouse
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white', // Cor da borda quando focado
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condomínio"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: 30, // Altura ajustada
                        fontSize: '0.8rem', // Diminui a fonte do input para melhor alinhamento
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: '0.6rem', // Garante que o tamanho da fonte do label/placeholder seja reduzido
                        fontWeight: 500,
                        color: 'white',
                      },
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  setSelectedCondominio(newValue);
                  setUserNames({});
                  setInputValue(
                    newValue ? `${newValue.fantasia} - ${newValue.chave}` : ''
                  );
                }}
                value={selectedCondominio}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
              />
            </div>
          </Grow>
          <IconButton
            onClick={() => setIsAutocompleteOpen(false)}
            style={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {selectedCondominio && (
        <Paper
          style={{
            position: 'absolute',
            marginTop: '10px',
            top: '100%',
            zIndex: 1000,
            width: '200px',
            left: 0,
            padding: '5px',
          }}
        >
          <List>
            {Object.entries(userNames).map(([label, name]) => (
              <ListItem key={label}>
                <ListItemIcon
                  style={{
                    minWidth: '25px',
                    marginRight: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {getRoleIcon(label)}
                </ListItemIcon>
                <ListItemText
                  primary={`${label}: ${name}`}
                  primaryTypographyProps={{
                    style: {
                      fontSize: '0.7rem',
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontSize: '0.7em', width: '100%' }}
          >
            Fechar
          </Button>
        </Paper>
      )}
    </Box>
  );
};

//FIM DA ALTERAÇÂO

export default CondominioPesquisa;
