import React, { useState } from 'react';

const UserSearchPage = () => {
  const [filters, setFilters] = useState({
    id: '',
    nome: '',
    area: '',
    situacao: '',
  });
  const [users, setUsers] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`http://localhost:3001/search-users?id=${filters.id}&nome=${filters.nome}&area=${filters.area}&situacao=${filters.situacao}`);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <h1>Busca de Usuários</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>ID:</label>
          <input type="text" name="id" value={filters.id} onChange={handleInputChange} />
          <label>Nome:</label>
          <input type="text" name="nome" value={filters.nome} onChange={handleInputChange} />
        </div>
        <div>
          <label>Área:</label>
          <input type="text" name="area" value={filters.area} onChange={handleInputChange} />
          <label>Situação:</label>
          <input type="text" name="situacao" value={filters.situacao} onChange={handleInputChange} />
        </div>
        <button type="submit" style={{ marginBottom: '20px' }}>Buscar</button>
      </form>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <table style={{ textAlign: 'left' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>User</th>
              <th>Área</th>
              <th>Função</th>
              <th>Situação</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id_func}>
                <td>{user.id_func}</td>
                <td>{user.nome}</td>
                <td>{user.user}</td>
                <td>{user.area}</td>
                <td>{user.funcao}</td>
                <td>{user.situacao}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserSearchPage;
