/* ***********************************
Autor: Matheus Botelho
Data: 21/05/24
Atividade: SSI 0016 - Observação de Condomínios em "ConsultarCarteira.js"
Função: Criação do modal de observações de condomínios
************************************ */

import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './InfoCondominios.module.css';
import UserContext from '../UserContext'; // Importe o contexto do usuário
import ReportRoundedIcon from '@mui/icons-material/ReportRounded'; // Importe o ícone
import EditRoundedIcon from '@mui/icons-material/EditRounded'; // Importe o ícone de edição
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'; // Importe o ícone de exclusão
import Tooltip from '@mui/material/Tooltip';

Modal.setAppElement('#root');

const InfoCondominios = ({ open, handleClose, idCond }) => {
  const { user } = useContext(UserContext); // Use o contexto do usuário
  const [observacoes, setObservacoes] = useState([]);
  const [observacao, setObservacao] = useState('');
  const [titulo, setTitulo] = useState(''); // Adicione estado para título
  const [importante, setImportante] = useState(0);
  const [filter, setFilter] = useState('Todos');
  const [isCreating, setIsCreating] = useState(false);
  const [observacaoToEdit, setObservacaoToEdit] = useState(null); // Estado para observação a ser editada

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (open) {
      fetchObservacoesFromAPI();
    } else {
      resetModal();
    }
  }, [open]);

  const handleCloseModal = () => {
    handleClose();
    setObservacaoToEdit(null);
    setObservacao('');
    setTitulo(''); // Resetar título ao fechar o modal
    setImportante(0);
  };

  const resetModal = () => {
    setObservacoes([]);
    setObservacao('');
    setTitulo(''); // Resetar título
    setImportante(0);
    setFilter('Todos');
    setIsCreating(false);
    setObservacaoToEdit(null); // Reset observação a ser editada
  };

  const fetchObservacoesFromAPI = async () => {
    try {
      const response = await fetch(`${API_URL}/observacoes/${idCond}`);
      const data = await response.json();
      if (data.success) {
        // Ordenar as observações, colocando as importantes primeiro
        const sortedObservacoes = data.observacoes.sort(
          (a, b) => b.importante - a.importante
        );
        setObservacoes(sortedObservacoes);
      }
    } catch (error) {
      console.error('Erro ao buscar observações:', error);
    }
  };

  const handleAddObservacao = async () => {
    const area_func =
      user.area === 'Jurídico' ? 'Comunicados e Notificações' : user.area;

    try {
      const response = await fetch(`${API_URL}/observacoes/criar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_cond: idCond,
          id_func: user.id_func,
          nome_func: user.nome,
          area_func,
          titulo, // Enviar título
          observacao,
          importante,
          ativo: 1, // Valor padrão ativo
          id_func_edit: '', // Valores de _edit vazios
          nome_func_edit: '',
          data_edit: '',
        }),
      });

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI(); // Atualizar a lista de observações após adicionar
        setIsCreating(false); // Voltar para a lista de observações após adicionar
        setObservacao('');
        setTitulo(''); // Resetar título após adicionar
        setImportante(0);
      }
    } catch (error) {
      console.error('Erro ao adicionar observação:', error);
    }
  };

  const handleEditObservacao = async () => {
    if (!observacaoToEdit) return;

    const area_func =
      user.area === 'Jurídico' ? 'Comunicados e Notificações' : user.area;

    try {
      const response = await fetch(
        `${API_URL}/observacoes/editar/${observacaoToEdit.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            titulo, // Enviar título
            observacao,
            importante,
            id_func_edit: user.id_func,
            nome_func_edit: user.nome,
            area_func, // Adicionar campo area_func
            data_edit: new Date(),
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI(); // Atualizar a lista de observações após editar
        setIsCreating(false); // Voltar para a lista de observações após editar
        setObservacaoToEdit(null); // Reset observação a ser editada
        setObservacao('');
        setTitulo(''); // Resetar título após editar
        setImportante(0);
      }
    } catch (error) {
      console.error('Erro ao editar observação:', error);
    }
  };

  const handleDeleteObservacao = async (id) => {
    try {
      const response = await fetch(`${API_URL}/observacoes/excluir/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ativo: 0,
        }),
      });

      const data = await response.json();
      if (data.success) {
        fetchObservacoesFromAPI(); // Atualizar a lista de observações após excluir
      }
    } catch (error) {
      console.error('Erro ao excluir observação:', error);
    }
  };

  const handleSubmit = () => {
    if (observacaoToEdit) {
      handleEditObservacao();
    } else {
      handleAddObservacao();
    }
  };

  const handleCreateNew = () => {
    setObservacao('');
    setTitulo(''); // Resetar título ao criar nova observação
    setImportante(0);
    setIsCreating(true);
    setObservacaoToEdit(null);
  };

  const handleCancel = () => {
    setIsCreating(false);
    setObservacaoToEdit(null);
    setObservacao('');
    setTitulo(''); // Resetar título ao cancelar
    setImportante(0);
  };

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((str, index) => (
      <span
        className={styles['observacoes-texto']}
        key={index}
      >
        {str}
        <br />
      </span>
    ));
  };

  const filteredObservacoes = observacoes.filter(
    (obs) => (filter === 'Todos' || obs.area_func === filter) && obs.ativo === 1
  );

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleCloseModal}
      className={styles['modal-obs-condo']}
      overlayClassName={styles['overlay-obs-condo']}
    >
      <div className={styles['modal-content-obs-condo']}>
        <h2>Observações do Condomínio</h2>
        <div className={styles['filter-buttons-obs-condo']}>
          {[
            'Todos',
            'Assembleias',
            'Cobranças',
            'Comunicados e Notificações',
            'Financeiro',
            'RH',
          ].map((area) => (
            <label key={area}>
              <input
                type="radio"
                name="filter"
                value={area}
                checked={filter === area}
                onChange={() => setFilter(area)}
              />
              {area}
            </label>
          ))}
        </div>
        <div className={styles['observacoes-list-obs-condo']}>
          {filteredObservacoes.map((obs) => (
            <div
              key={obs.id}
              className={styles['observacao-obs-condo']}
            >
              <div className={styles['observacao-cabecalho-resp']}>
                <p className={styles['observacoes-responsavel']}>
                  {obs.area_func} - {obs.titulo}
                </p>
                <div className={styles['observacao-icons']}>
                  {obs.importante ? (
                    <Tooltip title="Observação muito importante!">
                      <ReportRoundedIcon className={styles['important-icon']} />
                    </Tooltip>
                  ) : null}
                  <EditRoundedIcon
                    className={styles['edit-icon']}
                    onClick={() => {
                      setIsCreating(true);
                      setTitulo(obs.titulo); // Setar título para edição
                      setObservacao(obs.observacao);
                      setImportante(obs.importante);
                      setObservacaoToEdit(obs); // Definir observação a ser editada
                    }}
                  />
                  <DeleteRoundedIcon
                    className={styles['delete-icon']}
                    onClick={() => handleDeleteObservacao(obs.id)}
                  />
                </div>
              </div>
              <p className={styles['observacoes-texto']}>
                {formatTextWithLineBreaks(obs.observacao)}
              </p>
              <p className={styles['observacoes-criador']}>
                Criado por {obs.nome_func} em{' '}
                {new Date(obs.data).toLocaleDateString()}
                {obs.id_func_edit
                  ? ` | Editado por ${obs.nome_func_edit} em ${new Date(
                      obs.data_edit
                    ).toLocaleDateString()}`
                  : ''}
              </p>
            </div>
          ))}
        </div>
        {isCreating ? (
          <>
            <h2>
              {observacaoToEdit ? 'Editar Observação' : 'Criar Nova Observação'}
            </h2>
            <label>Título</label>
            <input
              type="text"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <label>Observação</label>
            <textarea
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
            />
            <label className={styles['important-label']}>
              <input
                type="checkbox"
                checked={importante === 1}
                onChange={(e) => setImportante(e.target.checked ? 1 : 0)}
              />
              É muito importante?
            </label>
            <div className={styles['modal-buttons-obs-condo']}>
              <button onClick={handleSubmit}>
                {observacaoToEdit ? 'Salvar' : 'Adicionar'}
              </button>
              <button onClick={handleCancel}>Cancelar</button>
            </div>
          </>
        ) : null}
        <div className={styles['footer-buttons']}>
          {!isCreating && (
            <button
              className={styles['create-button-obs-condo']}
              onClick={handleCreateNew}
            >
              Criar Nova Observação
            </button>
          )}
          <button
            className={styles['close-button-obs-condo']}
            onClick={handleCloseModal}
          >
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default InfoCondominios;
