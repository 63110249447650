import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { format, parse, getMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importe o plugin
import './TotalCondominiosChart.css';

// Fora do componente, para evitar recálculos desnecessários a cada renderização
const mesAtualFormatado = format(new Date(), 'MMM/yy', { locale: ptBR });

// Registre o plugin globalmente
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Adicione esta linha para registrar o plugin
);

const GraficoCondominios = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Total de Condomínios',
      data: [],
      backgroundColor: 'rgba(4, 30, 51, 0.9)',
      borderColor: 'rgba(215, 215, 215)',
      borderWidth: 1,
    }],
  });

  useEffect(() => {
    axios.get(`${API_URL}/dados-dashboard/buscar-total-condominios`)
      .then(response => {
        const data = response.data;
        const backgroundColors = data.map(item => {
          const date = parse(item.periodo, 'yyyy-MM', new Date());
          const label = format(date, 'MMM/yy', { locale: ptBR });
          // Define a cor para o mês atual de forma diferente
          return label === mesAtualFormatado ? 'rgb(22, 159, 97)' : 'rgba(4, 30, 51, 0.9)';
        });

        setChartData({
          labels: data.map(item => {
            const date = parse(item.periodo, 'yyyy-MM', new Date());
            return format(date, 'MMM/yy', { locale: ptBR });
          }),
          datasets: [{
            label: 'Total de Condomínios',
            data: data.map(item => item.total_condominios),
            backgroundColor: backgroundColors, // Usa o array de cores
            borderColor: 'rgba(215, 215, 215)',
            borderWidth: 1,
          }],
        });
      })
      .catch(error => console.log(error));
}, [API_URL]);


  let delayed;

  const configTotal = {
    maintainAspectRatio: false,
    type:'bar',
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 150 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
    layout: {
      padding: {
        top: 20 // Ajuste este valor conforme necessário para evitar cortes
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#343434',
          font: {
            size: '10px',
            weight: '600',
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#343434',
          callback: function(value) {
            return value.toLocaleString();
          },
          stepSize: 100,
          min: 0,
          max: 500,
          font: {
            size: '11px',
            weight: '600',
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        color: '#343434',
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          return value.toLocaleString();
        },
        font:{
          size: '11px',
          weight: '750',
        },
      },
    },
  };
  
  return (
    <div className="grafico-container-totalCondos">
      <Bar data={chartData} options={configTotal} />
    </div>
  );
};

export default GraficoCondominios;
