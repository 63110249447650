/* ***********************************
Autor: Matheus Botelho
Data: 27/05/24
Atividade: SSI 0018 - Página de atualizações Eureka
Função: Página para criação de eventos
************************************ */
//SSI 0023 - 03/06/24
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
//FIM DA ALTERAÇÃO
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './CriarEventosEureka.module.css';

const CriarEvento = () => {
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [imagem, setImagem] = useState(null);
  const [message, setMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;
  const [socket, setSocket] = useState(null); //SSI 0023 - 03/06/24
  const [userIdsToSend, setUserIdsToSend] = useState([]); //SSI 0023 - 03/06/24

  //SSI 0023 - 03/06/24

  useEffect(() => {
    const newSocket = io('https://euro-web-757c1b49a8fb.herokuapp.com');
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    const fetchUserIds = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterTodosUsuarios`
        );
        setUserIdsToSend(response.data.map(String));
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    fetchUserIds();
  }, [API_URL]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imagem) {
      const img = new Image();
      img.src = URL.createObjectURL(imagem);
      img.onload = async () => {
        if (img.width === 300 && img.height === 300) {
          const formData = new FormData();
          formData.append('titulo', titulo);
          formData.append('descricao', descricao);
          formData.append('imagem', imagem);

          try {
            const response = await axios.post(
              `${API_URL}/eventos/criar`,
              formData
            );
            setMessage('Evento enviado com sucesso');
            setTitulo('');
            setDescricao('');
            setImagem(null);

            // Emitir notificação via Socket.IO
            if (socket) {
              socket.emit('sendNotificationToCustomList', {
                userIds: userIdsToSend,
                title: `Evento ${titulo}`,
                message: `Confira na aba de eventos!`,
                type: 'general',
              });
            }
          } catch (error) {
            setMessage('Falha ao enviar evento');
          }
        } else {
          setMessage('A imagem deve ter exatamente 300x300 pixels');
        }
      };
    }
  };

  //FIM DA ALTERAÇÃO

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Criar Evento</h1>
      <form
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <div className={styles.formGroup}>
          <label className={styles.label}>Título:</label>
          <input
            type="text"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            className={styles.input}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Descrição:</label>
          <ReactQuill
            value={descricao}
            onChange={setDescricao}
            className={styles.textarea}
            modules={{
              toolbar: [
                [{ header: [3, 4, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link'], // Adiciona a opção de inserir links
              ],
            }}
            formats={[
              'header',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link', // Adiciona o formato de links
            ]}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Imagem:</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImagem(e.target.files[0])}
            className={styles.input}
            required
          />
          <p className={styles.avisoImagem}>
            *O formato da imagem deve ser 300x300px, para redimensionar a imagem
            utilize esse{' '}
            <a
              href="https://www.canva.com/design/DAGHGa1hvSw/PnEY5AKDbAlmqYxLfMMU2w/edit?utm_content=DAGHGa1hvSw&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
              target="_blank"
              rel="noopener noreferrer"
            >
              modelo
            </a>
            .
          </p>
        </div>
        <button
          type="submit"
          className={styles.button}
        >
          Enviar Evento
        </button>
      </form>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default CriarEvento;
