import React from "react";
import TotalCondominiosChart from "./Graficos/TotalCondominiosChart"; // Ajuste o caminho do import conforme necessário
import CondosPorCidade from "./Graficos/CondosPorCidade";
import TotalReceitaPeriodo from "./Graficos/TotalReceitaPeriodo";
import "./Dashboard.css";

function Dashboard() {
  return (
    <div className="dashboard-container">
      <div className="dashboard-top">
        <div className="grafico-total-condominios">
          <h3>Total de Condomínios (últimos 12 meses)</h3>
          {/*<TotalCondominiosChart />*/}
        </div>
        <div className="grafico-condominios-por-cidade">
          <h3>Condomínios por Cidade</h3>
          {/*<CondosPorCidade />*/}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
