/* ***********************************
Autor: Matheus Botelho
Data: 27/05/24
Atividade: SSI 0018 - Página de atualizações Eureka
Função: Página para visualizar os eventos e atualizações
************************************ */

import React, { useState, useEffect, useRef } from 'react';
import ListarEventos from './ListarEventos'; // Importe seu componente de eventos
import AtualizacoesEureka from './AtualizacoesEureka'; // Importe seu componente de atualizações
import styles from './PaginaAtualizacaoEvento.module.css';

const PaginaAtualizacaoEvento = () => {
  const [abaSelecionada, setAbaSelecionada] = useState('Eventos');
  const abaContainerRef = useRef(null);
  const abaBtnRefs = {
    AttEureka: useRef(null),
    Eventos: useRef(null),
  };

  useEffect(() => {
    const abaSelecionadaRef = abaBtnRefs[abaSelecionada].current;
    if (abaSelecionadaRef) {
      const containerRect = abaContainerRef.current.getBoundingClientRect();
      const buttonRect = abaSelecionadaRef.getBoundingClientRect();
      const bubbleWidth = buttonRect.width;
      const bubbleLeft = buttonRect.left - containerRect.left;
      abaContainerRef.current.style.setProperty(
        '--bubble-width',
        `${bubbleWidth}px`
      );
      abaContainerRef.current.style.setProperty(
        '--bubble-left',
        `${bubbleLeft}px`
      );
    }
  }, [abaSelecionada]);

  return (
    <div>
      <div
        ref={abaContainerRef}
        className={styles.abaContainer}
      >
        <button
          ref={abaBtnRefs.AttEureka}
          onClick={() => setAbaSelecionada('AttEureka')}
          className={`aba-btn ${abaSelecionada === 'AttEureka' ? 'ativo' : ''}`}
        >
          Atualizações Eureka
        </button>
        <button
          ref={abaBtnRefs.Eventos}
          onClick={() => setAbaSelecionada('Eventos')}
          className={`aba-btn ${abaSelecionada === 'Eventos' ? 'ativo' : ''}`}
        >
          Eventos
        </button>
      </div>
      <div className={styles.conteudo}>
        {abaSelecionada === 'Eventos' ? (
          <ListarEventos />
        ) : (
          <AtualizacoesEureka />
        )}
      </div>
    </div>
  );
};

export default PaginaAtualizacaoEvento;
