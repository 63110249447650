// 0054 - Gabriel Colle - Filtrar por Lançamentos e nomes ao clicar no Header - 05/09/2024
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, TableContainer, Paper } from '@mui/material';
import './ChartLancamentosCondos.css'
import { CheckCircleOutline, Cancel } from '@mui/icons-material';
import {
    Chart as ChartJS,
  } from 'chart.js';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const centerTextPlugin = {
    id: 'centerText',
    afterDraw(chart) {
      if (chart.config.type !== 'doughnut') {
        return;
      }
  
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
  
      ctx.restore();
      const activeElements = chart.tooltip._active;
      if (activeElements.length > 0) {
        const activeElement = activeElements[0];
        const dataIndex = activeElement.index;
        const datasetIndex = activeElement.datasetIndex;
        const label = chart.data.labels[dataIndex];
        const value = chart.data.datasets[datasetIndex].data[dataIndex];
  
        const fontSize = 12; // Tamanho fixo da fonte em pixels
        ctx.font = `550 ${fontSize}px sans-serif`;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
  
        const text = `${label}: ${value}`;
        const words = text.split(' ');
        let line = '';
        let lines = [];
        const maxWidth = width * 0.2; // Máximo de largura para o texto
  
        words.forEach(word => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > maxWidth && line !== '') {
            lines.push(line);
            line = word + ' ';
          } else {
            line = testLine;
          }
        });
        lines.push(line); // Adiciona o resto do texto como uma última linha
  
        // Calcular o ponto de início do texto para centralizá-lo verticalmente
        const startingY = height / 2 - (fontSize * lines.length) / 2 + fontSize / 2;
  
        // Desenhar cada linha
        lines.forEach((line, i) => {
          ctx.fillText(line, width / 2, startingY + i * fontSize);
        });
      } else {
        const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
  
        const fontSize = 12; // Tamanho fixo da fonte em pixels
        ctx.font = `550 ${fontSize}px sans-serif`;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
  
        const text = `Total: ${total}`;
        ctx.fillText(text, width / 2, height / 2);
      }
      ctx.save();
    }
  };

const LancamentosCondominio = ({ periodo }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [chartKey, setChartKey] = useState(Math.random());
    const [allData, setAllData] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], datasets: [{ data: [] }] });
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [selectedCondominio, setSelectedCondominio] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'numeroLancamentos', direction: 'descending' });

    const handleCondominioClick = async (idCondominio) => {
        console.log('Condomínio ID clicado:', idCondominio);
        try {
            // Inclua o parâmetro de query `periodo` na URL
            const response = await axios.get(`${API_URL}/grafico-lancamentos/condominios/${idCondominio}/detalhes-lancamentos?periodo=${periodo}`);
            if (response.data && response.data.length > 0) {
                setModalData(response.data);
                setSelectedCondominio(response.data[0].nomeCondominio);
                setModalOpen(true);
            }
        } catch (error) {
            console.error('Erro ao buscar detalhes dos lançamentos:', error);
        }
    };

    
    const options = {
        plugins: {
            legend: {
                display: false, // Isso esconde a legenda
            },
            tooltip: {
                enabled: true, // Isso desabilita os tooltips
            },
            datalabels: {
                display: false,
            }
        }
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${API_URL}/grafico-lancamentos/condominios-lancamentos?periodo=${periodo}`);
            if (response.data && Array.isArray(response.data)) {
                let dados = response.data;
                // Ordena os dados pelo número de lançamentos, em ordem decrescente
                dados.sort((a, b) => b.numeroLancamentos - a.numeroLancamentos);
    
                // Atualiza o estado allData com todos os registros, sem a agregação "Outros"
                setAllData(dados);
    
                // Se necessário, mantenha a lógica para os 15 principais e "Outros" apenas para o gráfico
                const top15Dados = dados.slice(0, 15);
                const outrosLancamentos = dados.slice(15).reduce((acc, curr) => acc + curr.numeroLancamentos, 0);
                if (dados.length > 15) {
                    top15Dados.push({ CONDOMINIO: 'Outros', numeroLancamentos: outrosLancamentos });
                }
    
                const labels = top15Dados.map(d => d.CONDOMINIO);
                const data = top15Dados.map(d => d.numeroLancamentos);
    
                setChartData({
                    labels: labels,
                    datasets: [{
                        label: 'Lançamentos por Condomínio',
                        data: data,
                        backgroundColor: [
                            '#4BC0C0',
                            '#FFCD56',
                            '#FF6384',
                            '#36A2EB',
                        ],
                        hoverOffset: 4
                    }]
                });
    
                setChartKey(Math.random());
            } else {
                setChartData({ datasets: [] });
                setAllData([]);
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setChartData({ datasets: [] });
            setAllData([]);
        }
    
        setIsLoading(false);
    };
// 0054 - Gabriel Colle - Filtrar por Lançamentos e nomes ao clicar no Header - 05/09/2024
    const sortData = (key) => {
        let direction = 'descending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        }

        const sortedData = [...allData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setAllData(sortedData);
        setSortConfig({ key, direction });
    };

    const getHeaderWithIcon = (columnName, key) => (
        <div style={{ alignItems: 'center' }}>
            <span>{columnName}</span>
            {sortConfig.key === key && (
                sortConfig.direction === 'ascending' ? 
                <ArrowUpward fontSize="small" style={{ marginLeft: 4, position: 'relative', top: '5px' }} /> :
                <ArrowDownward fontSize="small" style={{ marginLeft: 4, position: 'relative', top: '5px' }} />
            )}
        </div>
    );
    

    useEffect(() => {
        ChartJS.register(
            centerTextPlugin
          );

          setChartKey(Math.random());

        fetchData();
    }, [periodo]);

    // Renderização condicional para evitar tentar renderizar o gráfico sem dados
    return (
        <div className='indicador-lancamentos-condos' >
            {isLoading ? (
                <div className='carregar-lancamento-condos'>
                    <CircularProgress />
                    Aguarde, os dados estão sendo carregados.
                </div>
            ) : (
                <div className="grafico-e-tabela-container">
                    <div className='tabela-container'>
                        {/* 0054 - Gabriel Colle - Filtrar por Lançamentos e nomes ao clicar no Header - 05/09/2024 */}
                        <Paper sx={{ width: '600px', overflow: 'auto' }} >
                            <TableContainer sx={{ maxHeight: 440, overflow: 'auto' }} className="scroll-bar-cond">
                                <Table aria-label="Ranking de Lançamentos">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell onClick={() => sortData('CONDOMINIO')} style={{ cursor: 'pointer' }}>
                                                {getHeaderWithIcon('Condomínio', 'CONDOMINIO')}
                                            </TableCell>
                                            <TableCell align="right" onClick={() => sortData('numeroLancamentos')} style={{ cursor: 'pointer' }}>
                                                {getHeaderWithIcon('Lançamentos', 'numeroLancamentos')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allData.map((item, index) => (
                                            <TableRow key={index} onClick={() => handleCondominioClick(item.id_cond)} style={{ cursor: 'pointer' }}>
                                                <TableCell component="th" scope="row">{index + 1}</TableCell>
                                                <TableCell>{item.CONDOMINIO}</TableCell>
                                                <TableCell align="right">{item.numeroLancamentos}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="grafico-container">
                        {chartData && chartData.datasets.length > 0 && (
                            <Doughnut key={chartKey} data={chartData} options={options} />
                        )}
                    </div>
                </div>
            )}
           <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className="modalBox"
                sx={{
                    width: '80%', // ou qualquer outra largura
                    bgcolor: 'background.paper', // cor de fundo
                    border: '1px solid #000', // borda
                    boxShadow: 24, // sombra
                    p: 4, // padding
                  }}>
                    <Typography variant="h6" component="h2">
                        Detalhes dos Lançamentos - {selectedCondominio}
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="Detalhes dos Lançamentos">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell 
                                    sx={{
                                        width: '300px', // ou 'width': '200px' para largura fixa
                                    }}
                                        >Descrição</TableCell>
                                    <TableCell>Complemento Apro</TableCell>
                                    <TableCell>Complemento Pdes</TableCell>
                                    <TableCell>Data Liquidado</TableCell>
                                    <TableCell>Data Vencimento</TableCell>
                                    <TableCell>Doc Pgto</TableCell>
                                    <TableCell>Doc Fiscal</TableCell>
                                    <TableCell>Comprovante</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {modalData.map((detalhe, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell className='descricao-column'>
                                            <strong>{detalhe.descricao_cont}</strong> para <strong>{detalhe.nome_favorecido}</strong> no valor de <strong>R$ {detalhe.vl_bruto}</strong>
                                        </TableCell>
                                        <TableCell>{detalhe.complementoApro}</TableCell>
                                        <TableCell>{detalhe.complementoPdes}</TableCell>
                                        <TableCell>{new Date(detalhe.dtLiquidacao).toLocaleDateString()}</TableCell>
                                        <TableCell>{new Date(detalhe.dtVencimento).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            {detalhe.docPgto === 1 ? <CheckCircleOutline color="success" /> : <Cancel color="error" />}
                                        </TableCell>
                                        <TableCell>
                                            {detalhe.docFiscal === 1 ? <CheckCircleOutline color="success" /> : <Cancel color="error" />}
                                        </TableCell>
                                        <TableCell>
                                            {detalhe.comprovante === 1 ? <CheckCircleOutline color="success" /> : <Cancel color="error" />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div>
    ); 
};

export default LancamentosCondominio;
