import { jwtDecode } from 'jwt-decode';

const useAuth = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return { isAuthenticated: false, user: null };
  }

  const decodedToken = jwtDecode(token);
  const expirationTime = decodedToken.exp * 1000;

  if (Date.now() >= expirationTime) {
    localStorage.removeItem('token');
    return { isAuthenticated: false, user: null };
  }

  // Retorna os dados do usuário decodificados do token
  return { isAuthenticated: true, user: decodedToken };
};

export default useAuth;
