/* ***********************************
Autor: Matheus Botelho
Data: 28/03/2024
Atividade: SSI 0002 - Automação Cadastro
Função: Modal para envio de email
************************************ */

import React from 'react';
import styles from './EnviarEmailModal.module.css';

const EnviarEmailModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p>Deseja enviar o email de confirmação?</p>
        <div className={styles.modalButtons}>
          <button
            onClick={onConfirm}
            className={styles.confirmButton}
          >
            Sim
          </button>
          <button
            onClick={onCancel}
            className={styles.cancelButton}
          >
            Não
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnviarEmailModal;
