import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import styles from './EditarCarteira.module.css';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EditarCarteira = () => {
  const [condominios, setCondominios] = useState([]);
  const [selectedCondominio, setSelectedCondominio] = useState(null);
  const [usuariosFinanceiro, setUsuariosFinanceiro] = useState([]);
  const [usuariosAssembleias, setUsuariosAssembleias] = useState([]);
  const [usuariosCobranca, setUsuariosCobranca] = useState([]);
  const [usuariosRH, setUsuariosRH] = useState([]);
  const [usuariosComunicados, setUsuariosComunicados] = useState([]);
  const [pesoFinanceiro, setPesoFinanceiro] = useState('');
  const [pesoCobranca, setPesoCobranca] = useState('');
  const [pesoRH, setPesoRH] = useState('');
  const [pesoComunicados, setPesoComunicados] = useState('');
  const [pesoAssembleias, setPesoAssembleias] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [possuiAgendamento, setPossuiAgendamento] = useState(''); // Mudado para string vazia
  const [missingCondosData, setMissingCondosData] = useState({
    missingCondos: [],
    totalDimCondos: 0,
    totalEuroCarteiras: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  //SSI 0024 - 16/04/24

  const [idPholha, setIdPholha] = useState('');
  const [diaPagamentoSalarios, setDiaPagamentoSalarios] = useState('');

  //FIM DA ALTERAÇÃO

  //SSI 0033 - MATHEUS BOTELHO - 08/07/24

  useEffect(() => {
    if (!selectedCondominio) {
      resetFields();
    }
  }, [selectedCondominio]);

  //FIM DA ALTERAÇÃO

  const [diaAdiantamento, setDiaAdiantamento] = useState(''); //SSI 0025 - 25/06/24

  const API_URL = process.env.REACT_APP_API_URL;

  const handleAgendamentoChange = (event) => {
    setPossuiAgendamento(event.target.value);
  };

  const getUsuariosByArea = async (area) => {
    try {
      const response = await fetch(
        `${API_URL}/editar-carteira/buscar-usuarios-por-area/` + area
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao buscar os usuários por área:', error);
      return [];
    }
  };

  useEffect(() => {
    //console.log("[LOG] Carregando condomínios e usuários por área...");

    const usuariosGerais = [
      {
        id: 84,
        id_func: 84,
        nome: 'Não se aplica',
        user: 'NAPLICA',
        senha: '$2a$10$L9m3O6GHY7IeaGr5XPB9zOQUfW6fqrEU13hD1YQp8sG2NfEK7n.4W',
        area: 'Jurídico',
        funcao: 'Agente',
        situacao: 'Ativo',
        carteira_ativa: 1,
      },
      {
        id: 90,
        id_func: 90,
        nome: 'Camboriu',
        user: 'CAMBORIU',
        senha: '$2a$10$Srgb/hZZW3/fhkK97VAXpOjbdYfkjfQiLAAFmmt.cS8TUnYF89aga',
        area: 'Financeiro',
        funcao: 'Agente',
        situacao: 'Ativo',
        carteira_ativa: 1,
      },
      {
        id: 76,
        id_func: 76,
        nome: 'Dra Denise',
        user: 'DRDENISE',
        senha: '$2a$10$ButAVUDOO6d0NpJD4sm14OkxjkXy3de5kqzmFct0.VZDCgGQnyO7m',
        area: 'Cobrança',
        funcao: 'Agente',
        situacao: 'Ativo',
        carteira_ativa: 1,
      },
      {
        id: 69,
        id_func: 69,
        nome: 'Campo Grande',
        user: 'CGRANDE',
        senha: '$2a$10$MVJIltByZf4FvQRchsMlZOG6lK9nMFHT1YQ43rQoqfe5CTHacqMcu',
        area: 'Cobrança',
        funcao: 'Agente',
        situacao: 'Ativo',
        carteira_ativa: 1,
      },
    ];

    console.log('Enviando solicitação para buscar condomínios');
    fetch(`${API_URL}/editar-carteira/buscar-condominios`)
      .then((response) => {
        console.log('Resposta recebida: ', response);
        if (!response.ok) {
          throw new Error('Resposta de rede não ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Dados recebidos: ', data);
        setCondominios(data);
      })
      .catch((error) => console.error('Erro ao buscar os condomínios:', error));

    getUsuariosByArea('Financeiro').then((data) => {
      setUsuariosFinanceiro([...data, ...usuariosGerais]);
    });

    getUsuariosByArea('Assembleias').then((data) => {
      setUsuariosAssembleias([...data, ...usuariosGerais]);
    });

    getUsuariosByArea('Cobrança').then((data) => {
      setUsuariosCobranca([...data, ...usuariosGerais]);
    });

    getUsuariosByArea('RH').then((data) => {
      setUsuariosRH([...data, ...usuariosGerais]);
    });

    getUsuariosByArea('Juridico').then((data) => {
      setUsuariosComunicados([...data, ...usuariosGerais]);
    });
  }, []);

  const [carteiraData, setCarteiraData] = useState({});
  const [selectedFinanceiro, setSelectedFinanceiro] = useState(null);
  const [selectedCobranca, setSelectedCobranca] = useState(null);
  const [selectedRH, setSelectedRH] = useState(null);
  const [selectedComunicados, setSelectedComunicados] = useState(null);
  const [selectedAssembleias, setSelectedAssembleias] = useState(null);

  useEffect(() => {
    // Fetch inicial para obter dados
    fetch(`${API_URL}/editar-carteira/buscar-condominios-faltantes`)
      .then((response) => response.json())
      .then((data) => {
        setMissingCondosData({
          ...data,
          missingCondos: data.missingCondos.filter(
            (condo) => condo.chave > 606
          ),
        });
      })
      .catch((error) =>
        console.error('Erro ao buscar os condomínios que faltam:', error)
      );
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCondoSelect = (condo) => {
    setSelectedCondominio(condo);
    handleModalClose();
    // Aqui você pode adicionar mais lógica para preencher outros estados com os dados do condomínio
  };

  // SSI 0009 - 24/04/24

  useEffect(() => {
    if (selectedCondominio) {
      fetch(
        `${API_URL}/editar-carteira/buscar-detalhes-carteira/${selectedCondominio.chave}`
      )
        .then((response) => {
          if (!response.ok) throw new Error('Carteira não encontrada');
          return response.json();
        })
        .then((data) => {
          if (Object.keys(data).length !== 0) {
            setCarteiraData(data);
            setSelectedFinanceiro(
              usuariosFinanceiro.find((u) => u.id_func === data.FINANCEIRO)
            );
            setSelectedCobranca(
              usuariosCobranca.find((u) => u.id_func === data.COBRANÇA)
            );
            setSelectedRH(usuariosRH.find((u) => u.id_func === data.RH));
            setSelectedComunicados(
              usuariosComunicados.find(
                (u) => u.id_func === data.COMUNICADOS_NOTIFICAÇÕES
              )
            );
            setSelectedAssembleias(
              usuariosAssembleias.find((u) => u.id_func === data.ASSEMBLEIAS)
            );
            setCidade(data.CIDADE || '');
            setEstado(data.ESTADO || '');
            setPossuiAgendamento(data.possui_agendamento ? '1' : '0'); // Atualiza com base na existência de agendamento
            setPesoFinanceiro(data.peso_financeiro || 0);
            setPesoCobranca(data.peso_cobranca || 0);
            setPesoRH(data.peso_rh || 0);
            setPesoComunicados(data.peso_comunicados || 0);
            setPesoAssembleias(data.peso_assembleias || 0);
            setIdPholha(data.id_pholha || ''); // SSI 0024
            setDiaPagamentoSalarios(data.dia_util_salario || 1); // SSI 0024
            setDiaAdiantamento(data.dia_adiantamento || 20); // SSI 0025
          } else {
            resetFields();
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar a carteira:', error);
          setSelectedFinanceiro(null);
          setSelectedCobranca(null);
          setSelectedRH(null);
          setSelectedComunicados(null);
          setSelectedAssembleias(null);
          setCidade('');
          setEstado('');
          setPesoFinanceiro('');
          setPesoCobranca('');
          setPesoRH('');
          setPesoComunicados('');
          setPesoAssembleias('');
          setPossuiAgendamento('');
          setIdPholha(''); //SSI 0024
          setDiaPagamentoSalarios(''); //SSI 0024
          setDiaAdiantamento(''); //SSI 0025
        });
    }
  }, [
    selectedCondominio,
    usuariosFinanceiro,
    usuariosCobranca,
    usuariosRH,
    usuariosComunicados,
    usuariosAssembleias,
  ]);

  //FIM DA ALTERAÇÃO

  //SSI 0024 - 14/06/24

  const estadosBrasil = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' },
  ];

  // Função para encontrar o estado pelo nome
  const encontrarEstado = (nome) =>
    estadosBrasil.find((estado) => estado.nome === nome);

  //FIM DA ALTERAÇÃO

  const resetFields = () => {
    setSelectedCondominio(null);
    setSelectedFinanceiro(null);
    setSelectedCobranca(null);
    setSelectedRH(null);
    setSelectedComunicados(null);
    setSelectedAssembleias(null);
    setCidade('');
    setEstado('');
    setPesoFinanceiro('');
    setPesoCobranca('');
    setPesoRH('');
    setPesoComunicados('');
    setPesoAssembleias('');
    setPossuiAgendamento('');
    setIdPholha(''); //SSI 0024
    setDiaPagamentoSalarios(''); //SSI 0024
    setDiaAdiantamento(''); //SSI 0025
  };

  const handleSalvar = () => {
    fetch(`${API_URL}/editar-carteira/criar-ou-atualizar-carteira`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...carteiraData,
        CONDOMINIO: selectedCondominio.fantasia,
        FINANCEIRO: selectedFinanceiro?.id_func,
        COBRANÇA: selectedCobranca?.id_func,
        RH: selectedRH?.id_func,
        COMUNICADOS_NOTIFICAÇÕES: selectedComunicados?.id_func,
        ASSEMBLEIAS: selectedAssembleias?.id_func,
        CIDADE: cidade,
        ESTADO: estado,
        id_cond: selectedCondominio.chave,
        peso_financeiro: pesoFinanceiro,
        peso_cobranca: pesoCobranca,
        peso_rh: pesoRH,
        peso_comunicados: pesoComunicados,
        peso_assembleias: pesoAssembleias,
        possui_agendamento: possuiAgendamento === '1' ? 1 : 0,
        idPholha: idPholha, // SSI 0024 - 25/06/24
        diaPagamentoSalarios: diaPagamentoSalarios, // SSI 0024 - 25/06/24
        diaAdiantamento: diaAdiantamento, // SSI 0024 - 25/06/24
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          alert(data.message);
          resetFields();
        } else {
          alert('Erro ao salvar a carteira.');
        }
      })
      .catch((error) => {
        console.error('Erro ao salvar a carteira:', error);
      });
  };

  //SSI 0024 - 14/06/24

  const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      height: '40px', // Ajuste a altura conforme necessário
      padding: '0 14px', // Ajuste o padding horizontal conforme necessário
      display: 'flex',
      alignItems: 'center', // Alinha o conteúdo verticalmente
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 0', // Ajuste o padding interno conforme necessário
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 8px) scale(1)', // Ajusta a posição da label
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Ajusta a posição da label quando encolhida
    },
  });

  const CustomSelectField = styled(TextField)({
    '& .MuiInputBase-root': {
      height: '40px', // Ajuste a altura conforme necessário
      padding: '0 14px', // Ajuste o padding horizontal conforme necessário
      display: 'flex',
      alignItems: 'center', // Alinha o conteúdo verticalmente
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 0', // Ajuste o padding interno conforme necessário
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 8px) scale(1)', // Ajusta a posição da label
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Ajusta a posição da label quando encolhida
    },
  });

  return (
    <div className={styles.editarCarteiraContainer}>
      <div className={styles.editarCarteiraContent}>
        <div className={styles.titleContainer}>
          <h3 className={styles.editarCarteiraTitle}>Editar Carteira</h3>
          <div className={styles.titleUnderline}></div>
        </div>
        {missingCondosData.missingCondos.length > 0 && (
          <Button onClick={handleModalOpen}>
            🚨 {missingCondosData.missingCondos.length} cadastros pendentes
          </Button>
        )}
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
        >
          <DialogTitle>Condomínios Pendentes</DialogTitle>
          <DialogContent>
            <List>
              {missingCondosData.missingCondos.map((condo) => (
                <ListItem
                  button
                  key={condo.chave}
                  onClick={() => handleCondoSelect(condo)}
                >
                  <ListItemText
                    primary={`${condo.fantasia} - ID: ${condo.chave}`}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
        <div className={styles.containerCampos}>
          <div className={styles.camposIniciais}>
            <Box className={styles.boxEditarCarteira}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraFull}
                options={condominios}
                getOptionLabel={(option) =>
                  option.fantasia ? `${option.fantasia} - ${option.chave}` : ''
                }
                value={selectedCondominio || null}
                onChange={(event, newValue) => {
                  setSelectedCondominio(newValue);
                  if (newValue) {
                    setCidade(newValue.cidade);
                    setEstado(newValue.uf);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.fantasia} - {option.chave}
                  </li>
                )}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Condomínio"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Box>

            {/* Estrutura para Financeiro */}
            <Box className={styles.boxEditarCarteiraFinanceiro}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraArea}
                options={usuariosFinanceiro}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSelectedFinanceiro(newValue);
                }}
                value={selectedFinanceiro}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Financeiro"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <CustomSelectField
                select
                label="Possui agendamento"
                value={possuiAgendamento}
                onChange={(e) => setPossuiAgendamento(e.target.value)}
                fullWidth
                variant="outlined"
                className={styles.autocompleteEditarCarteiraAgendamento}
              >
                <MenuItem value="0">Não</MenuItem>
                <MenuItem value="1">Sim</MenuItem>
              </CustomSelectField>
              <CustomSelectField
                label="Peso"
                variant="outlined"
                fullWidth
                value={pesoFinanceiro}
                InputProps={{
                  readOnly: true,
                }}
                className={styles.autocompleteEditarCarteiraPeso}
              />
            </Box>

            {/* Estrutura para RH */}
            <Box className={styles.boxEditarCarteira}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraArea}
                options={usuariosRH}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSelectedRH(newValue);
                }}
                value={selectedRH}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="RH"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <CustomSelectField
                className={styles.autocompleteEditarCarteiraPeso}
                select
                label="Peso"
                variant="outlined"
                value={pesoRH || ''}
                onChange={(e) => setPesoRH(parseInt(e.target.value))}
                SelectProps={{ native: true }}
              >
                <option
                  disabled
                  selected
                ></option>
                {[...Array(10)].map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ))}
              </CustomSelectField>
            </Box>

            {/* Estrutura para Assembleias */}
            <Box className={styles.boxEditarCarteira}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraArea}
                options={usuariosAssembleias}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSelectedAssembleias(newValue);
                }}
                value={selectedAssembleias}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Assembleias"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <CustomSelectField
                className={styles.autocompleteEditarCarteiraPeso}
                select
                label="Peso"
                variant="outlined"
                value={pesoAssembleias || ''}
                onChange={(e) => setPesoAssembleias(parseInt(e.target.value))}
                SelectProps={{ native: true }}
              >
                <option
                  disabled
                  selected
                ></option>
                {[...Array(10)].map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ))}
              </CustomSelectField>
            </Box>

            {/* Estrutura para Cobrança */}
            <Box className={styles.boxEditarCarteira}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraArea}
                options={usuariosCobranca}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSelectedCobranca(newValue);
                }}
                value={selectedCobranca}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Cobrança"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <CustomSelectField
                className={styles.autocompleteEditarCarteiraPeso}
                select
                label="Peso"
                variant="outlined"
                value={pesoCobranca || ''}
                onChange={(e) => setPesoCobranca(parseInt(e.target.value))}
                SelectProps={{ native: true }}
              >
                <option
                  disabled
                  selected
                ></option>
                {[...Array(10)].map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ))}
              </CustomSelectField>
            </Box>

            {/* Estrutura para Comunicados e notificações */}
            <Box className={styles.boxEditarCarteira}>
              <Autocomplete
                className={styles.autocompleteEditarCarteiraArea}
                options={usuariosComunicados}
                getOptionLabel={(option) => option.nome}
                onChange={(event, newValue) => {
                  setSelectedComunicados(newValue);
                }}
                value={selectedComunicados}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Comunicados e notificações"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <CustomSelectField
                className={styles.autocompleteEditarCarteiraPeso}
                select
                label="Peso"
                variant="outlined"
                value={pesoComunicados || ''}
                onChange={(e) => setPesoComunicados(parseInt(e.target.value))}
                SelectProps={{ native: true }}
              >
                <option
                  disabled
                  selected
                ></option>
                {[...Array(10)].map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                  >
                    {index + 1}
                  </option>
                ))}
              </CustomSelectField>
            </Box>

            <Box className={styles.boxEditarCarteira}>
              <TextField
                className="editarCarteira-cidade"
                label="Cidade"
                variant="outlined"
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                size="small"
              />
              <Autocomplete
                className={styles.autocompleteEditarCarteiraEstados}
                options={estadosBrasil}
                getOptionLabel={(option) => option.sigla}
                value={encontrarEstado(estado) || null}
                onChange={(event, newValue) => {
                  setEstado(newValue ? newValue.nome : '');
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.sigla}</li>
                )}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    label="Estado"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Box>
          </div>

          <div className={styles.camposAdicionais}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>Configurações Adicionais</h4>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  gap={2}
                >
                  <div className={styles.customField}>
                    <label htmlFor="idPholha">ID Pholha</label>
                    <input
                      id="idPholha"
                      type="text"
                      value={idPholha}
                      onChange={(e) => setIdPholha(e.target.value)}
                    />
                  </div>
                  <div className={styles.customField}>
                    <label htmlFor="diaPagamentoSalarios">
                      Dia de Pagamento Salários
                    </label>
                    <select
                      id="diaPagamentoSalarios"
                      value={diaPagamentoSalarios}
                      onChange={(e) => setDiaPagamentoSalarios(e.target.value)}
                    >
                      <option
                        selected
                        disabled
                      ></option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                  <div className={styles.customField}>
                    <label htmlFor="diaAdiantamento">
                      Dia de Pagamento Adiantamento
                    </label>
                    <select
                      id="diaAdiantamento"
                      value={diaAdiantamento}
                      onChange={(e) => setDiaAdiantamento(e.target.value)}
                    >
                      <option
                        selected
                        disabled
                      ></option>
                      <option value={15}>15</option>
                      <option value={16}>16</option>
                      <option value={17}>17</option>
                      <option value={18}>18</option>
                      <option value={19}>19</option>
                      <option value={20}>20</option>
                    </select>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <Box className={styles.boxEditarCarteiraBotaoSalvar}>
          <Button
            className={styles.botaoSalvarEC}
            onClick={handleSalvar}
          >
            Salvar
          </Button>
        </Box>
      </div>
    </div>
  );
};

//FIM DA ALTERAÇÃO

export default EditarCarteira;
