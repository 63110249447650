/* ***********************************
Autor: Matheus Botelho
Data: 28/03/2024
Atividade: SSI 0002 - Automação Cadastro
Função: Modal de notificação de síndico
************************************ */

import React from 'react';
import styles from './NotificarSindicoModal.module.css'; // Adicione estilos específicos se necessário, ou reutilize ConfirmarModal.css

const NotificarSindico = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p>Deseja notificar o Síndico sobre o novo Morador?</p>
        <div className={styles.modalButtons}>
          <button
            onClick={onConfirm}
            className={styles.confirmButton}
          >
            Sim
          </button>
          <button
            onClick={onCancel}
            className={styles.cancelButton}
          >
            Não
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificarSindico;
