import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import styles from './CarteiraUsuario.module.css';

const CarteiraUsuario = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [redirecionados, setRedirecionados] = useState([]);
  const [todosEmpreendimentos, setTodosEmpreendimentos] = useState([]);
  const [somaTotalPesos, setSomaTotalPesos] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${API_URL}/carteira-consulta/euro-usuarios/not-diretoria`)
      .then((response) => response.json())
      .then((data) => setUsuarios(data))
      .catch((error) => console.error('Erro ao buscar os usuários:', error));
  }, []);

  useEffect(() => {
    if (selectedUsuario) {
      fetch(
        `${API_URL}/carteira-consulta/euro-colaboradores-redirecionamentos/${selectedUsuario.id_func}`
      )
        .then((response) => response.json())
        .then((data) => {
          setEmpreendimentos(data);

          // Calculando o número e peso dos empreendimentos
          const totalEmpreendimentos = data.condominios.length;
          const pesoEmpreendimentos = data.condominios.reduce(
            (acc, curr) => acc + (curr.peso || 1),
            0
          );

          fetch(
            `${API_URL}/carteira-por-usuario/euro-colaboradores-redirecionados/${selectedUsuario.id_func}`
          )
            .then((response) => response.json())
            .then((redirecionadosData) => {
              setRedirecionados(redirecionadosData);

              // Calculando o número e peso dos empreendimentos redirecionados
              const totalRedirecionados = redirecionadosData.length;
              const pesoRedirecionados = redirecionadosData.reduce(
                (acc, curr) => acc + (curr.peso || 1),
                0
              );

              // Calculando o total geral
              const totalGeral = totalEmpreendimentos + totalRedirecionados;
              const pesoTotal = pesoEmpreendimentos + pesoRedirecionados;

              // Atualizando o estado
              setEmpreendimentos({
                ...data,
                totalEmpreendimentos,
                totalRedirecionados,
                pesoEmpreendimentos,
                pesoRedirecionados,
                totalGeral,
                pesoTotal,
              });

              const todos = [...data.condominios, ...redirecionadosData];
              setTodosEmpreendimentos(todos);
            })
            .catch((error) =>
              console.error(
                'Erro ao buscar empreendimentos redirecionados:',
                error
              )
            );
        })
        .catch((error) =>
          console.error('Erro ao buscar empreendimentos do usuário:', error)
        );
    }
  }, [selectedUsuario]);

  return (
    <div className={styles.carteiraUsuarioContainer}>
      <div className={styles.carteiraUsuarioContent}>
        <div className={styles.carteiraUsuarioTitle}>Carteira do Usuário</div>

        <Box sx={{ my: 2 }}>
          <Autocomplete
            options={usuarios}
            getOptionLabel={(option) => option.nome}
            fullWidth
            onChange={(event, newValue) => setSelectedUsuario(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Usuário"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>

        {selectedUsuario && (
          <>
            <div className={styles.carteiraUsuarioTotalgeral}>
              <h6>Total Empreendimentos: {empreendimentos.totalGeral}</h6>
              <h6>Peso Total: {empreendimentos.pesoTotal}</h6>
            </div>

            <div className={styles.carteiraUsuarioTabela}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Empreendimentos: {empreendimentos.totalEmpreendimentos}
                    </TableCell>
                    <TableCell>
                      Peso: {empreendimentos.pesoEmpreendimentos}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empreendimentos.condominios?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.CONDOMINIO}</TableCell>
                      <TableCell>{item.peso || 1}</TableCell>
                    </TableRow>
                  ))}

                  {redirecionados.length > 0 && (
                    <TableRow>
                      <TableCell>
                        Redirecionamentos: {empreendimentos.totalRedirecionados}
                      </TableCell>
                      <TableCell>
                        Peso Redirecionamento:{' '}
                        {empreendimentos.pesoRedirecionados}
                      </TableCell>
                    </TableRow>
                  )}

                  {redirecionados.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.CONDOMINIO}</TableCell>
                      <TableCell>{item.peso || 1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CarteiraUsuario;
