import React, { useEffect, useContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet, // Adicione esta linha
} from 'react-router-dom';
import LoginPage from './components/Login/LoginPage';
import UserRegistrationPage from './components/Usuarios/UserRegistrationPage';
import RateioPage from './components/RateioPage';
import UserContext, { UserProvider } from './components/UserContext';
import GeralPage from './pages/GeralPage';
import FinanceiroPage from './pages/FinanceiroPage';
import MainLayout from './components/MainLayout';
import EditUserPage from './components/Usuarios/EditUserPage';
import UserSearchPage from './components/UserSearchPage';
import ConsultarCarteira from './components/Carteiras/ConsultarCarteira';
import EditarCarteira from './components/Carteiras/EditarCarteira';
import RedirecionarCarteira from './components/Carteiras/RedirecionarCarteira';
import CarteiraUsuario from './components/Carteiras/CarteiraUsuario';
import IndicadorFinanceiro from './components/Indicadores/IndicadorFinanceiro';
import MinhaCarteira from './components/Carteiras/MinhaCarteira';
import UsuarioCidade from './components/Carteiras/UsuarioCidade';
import Inadimplencia from './components/Juridico/Inadimplencia';
import EfetuarAcordo from './components/Juridico/EfetuarAcordo';
import AlterarUsuario from './components/Usuarios/AlterarUsuario';
import Dashboard from './components/Dashboard';
import TransferenciaCondominio from './components/TransferirCarteira';
import FechamentoMensal from './components/Financeiro/FechamentoMensal';
import EditarAusencia from './components/EditarAusencia';
import DashboardInadimplencia from './components/DashboardInadimplencia';
import KpisPageFin from './components/Indicadores/KpisPageFin';
import Agenda from './components/Agenda';
import InativarUsuario from './components/Usuarios/InativarUsuario';
import AlterarTitularidade from './components/Cadastro/AlterarTitularidade';
import useAuth from './hooks/useAuth'; //ALTERAÇÃO - 16/04/2024 - ADICIONADO TOKEN DE LOGIN
import PaginaNotificacao from './components/Notificacoes/PaginaNotificacao';
import Assembleia from './Agendas/Assembleia'; //atividade: Assembleias / data: 01/04/2024 //
import AtualizacoesEureka from './components/updates/AtualizacoesEureka'; //SSI 0018 - 24/05/24
import CriarAtualizacao from './components/updates/CriarAtualizacao'; //SSI 0018 - 27/05/24
import CriarEvento from './components/updates/CriarEventosEureka'; //SSI 0019 - 27/05/24
import ListarEventos from './components/updates/ListarEventos'; //SSI 0019 - 27/05/24
import PaginaAtualizacaoEvento from './components/updates/PaginaAtualizacaoEvento'; //SSI 0019
import CriarSalarios from './components/Rh/CriarSalarios'; //SSI 0024 - 04/06/24

//INICIO DA ALTERAÇÃO

// Componente ProtectedRoute
const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redireciona para a página de login se não autenticado
    return (
      <Navigate
        to="/"
        replace
      />
    );
  }

  // Usuário autenticado, renderiza o componente que está no outlet
  return <Outlet />;
}; //FIM DA ALTERAÇÃO - 16/04/2024 - UTILIZAÇÃO DE TOKEN PARA LOGIN

// Componente ScrollToTop
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <Router>
      <UserProvider>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<LoginPage />}
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/dashboard"
              element={
                <MainLayout>
                  <Dashboard />
                </MainLayout>
              }
            />
            <Route
              path="/geral"
              element={
                <MainLayout>
                  <GeralPage />
                </MainLayout>
              }
            />
            <Route
              path="/financeiro"
              element={
                <MainLayout>
                  <FinanceiroPage />
                </MainLayout>
              }
            />
            <Route
              path="/rateio"
              element={
                <MainLayout>
                  <RateioPage />
                </MainLayout>
              }
            />
            <Route
              path="/cadastrousuario"
              element={
                <MainLayout>
                  <UserRegistrationPage />
                </MainLayout>
              }
            />
            <Route
              path="/edituser"
              element={
                <MainLayout>
                  <EditUserPage />
                </MainLayout>
              }
            />
            <Route
              path="/usersearch"
              element={
                <MainLayout>
                  <UserSearchPage />
                </MainLayout>
              }
            />
            <Route
              path="/consultarcarteira"
              element={
                <MainLayout>
                  <ConsultarCarteira />
                </MainLayout>
              }
            />
            <Route
              path="/editarcarteira"
              element={
                <MainLayout>
                  <EditarCarteira />
                </MainLayout>
              }
            />
            <Route
              path="/redirecionarcarteira"
              element={
                <MainLayout>
                  <RedirecionarCarteira />
                </MainLayout>
              }
            />
            <Route
              path="/carteirausuario"
              element={
                <MainLayout>
                  <CarteiraUsuario />
                </MainLayout>
              }
            />
            <Route
              path="/indicadorfinanceiro"
              element={
                <MainLayout>
                  <IndicadorFinanceiro />
                </MainLayout>
              }
            />
            <Route
              path="/minhacarteira"
              element={
                <MainLayout>
                  <MinhaCarteira />
                </MainLayout>
              }
            />
            <Route
              path="/editarusuarioscidade"
              element={
                <MainLayout>
                  <UsuarioCidade />
                </MainLayout>
              }
            />
            <Route
              path="/inadimplencia"
              element={
                <MainLayout>
                  <Inadimplencia />
                </MainLayout>
              }
            />
            <Route
              path="/efetuar-acordo"
              element={
                <MainLayout>
                  <EfetuarAcordo />
                </MainLayout>
              }
            />
            <Route
              path="/fechamentomensal"
              element={
                <MainLayout>
                  <FechamentoMensal />
                </MainLayout>
              }
            />
            <Route
              path="/alterarusuario"
              element={
                <MainLayout>
                  <AlterarUsuario />
                </MainLayout>
              }
            />
            <Route
              path="/dashboardinadimplencia"
              element={
                <MainLayout>
                  <DashboardInadimplencia />
                </MainLayout>
              }
            />
            <Route
              path="/transferircarteira"
              element={
                <MainLayout>
                  <TransferenciaCondominio />
                </MainLayout>
              }
            />
            <Route
              path="/editarausencia"
              element={
                <MainLayout>
                  <EditarAusencia />
                </MainLayout>
              }
            />
            <Route
              path="/kpisfinanceiro"
              element={
                <MainLayout>
                  <KpisPageFin />
                </MainLayout>
              }
            />
            <Route
              path="/agenda"
              element={
                <MainLayout>
                  <Agenda />
                </MainLayout>
              }
            />
            <Route
              path="/inativarusuario"
              element={
                <MainLayout>
                  <InativarUsuario />
                </MainLayout>
              }
            />
            <Route
              path="/alterartitularidade"
              element={
                <MainLayout>
                  <AlterarTitularidade />
                </MainLayout>
              }
            />
            <Route
              path="/criar-notificacao"
              element={
                <MainLayout>
                  <PaginaNotificacao />
                </MainLayout>
              }
            />
            <Route
              path="/assembleia"
              element={
                <MainLayout>
                  <Assembleia />
                </MainLayout>
              }
            />
            <Route
              path="/atualizacoes"
              element={
                <MainLayout>
                  <AtualizacoesEureka />
                </MainLayout>
              }
            />
            <Route
              path="/criar-atualizacao"
              element={
                <MainLayout>
                  <CriarAtualizacao />
                </MainLayout>
              }
            />
            <Route
              path="/criar-evento"
              element={
                <MainLayout>
                  <CriarEvento />
                </MainLayout>
              }
            />
            <Route
              path="/ver-eventos"
              element={
                <MainLayout>
                  <ListarEventos />
                </MainLayout>
              }
            />
            <Route
              path="/ver-atualizacoes"
              element={
                <MainLayout>
                  <PaginaAtualizacaoEvento />
                </MainLayout>
              }
            />
            <Route
              path="/criar-salarios"
              element={
                <MainLayout>
                  <CriarSalarios />
                </MainLayout>
              }
            />
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default AppRoutes;
