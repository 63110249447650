//0054 - Gabriel Colle - Filtrar por numero de condominio e fixar header tabela - 05/09/2024
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import "./ChartLancamentosUsuarios.css";
import { Modal, Box, Typography } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const LancamentosUsuario = ({ periodo }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [chartKey, setChartKey] = useState(Math.random());
  const [allData, setAllData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [chartData, setChartData] = useState({
    datasets: [], // Inicialização segura
  });

  const options = {
    plugins: {
      legend: {
        display: false, // Isso esconde a legenda
      },
      tooltip: {
        enabled: true, // Isso desabilita os tooltips
      },
      datalabels: {
        display: false,
      },
    },
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/grafico-lancamentos/usuarios-lancamentos?periodo=${periodo}`
      );
      console.log(
        "Dados recebidos para lançamentos de usuários:",
        response.data
      );
      if (response.data && Array.isArray(response.data)) {
        let dados = response.data;
        console.log("Dados processados:", dados);

        // Altere aqui para ordenar inicialmente por "pesoTotal"
        dados.sort((a, b) => b.pesoTotal - a.pesoTotal);

        setAllData(dados);

        const labels = dados.map((d) => d.usuario);
        const data = dados.map((d) => d.numeroLancamentos);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Lançamentos por Usuário",
              data: data,
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              hoverOffset: 4,
            },
          ],
        });
        setChartKey(Math.random());
      } else {
        setChartData({ datasets: [] });
        setAllData([]);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      setChartData({ datasets: [] });
      setAllData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    setChartKey(Math.random());
  }, [periodo]);

  const handleUserClick = async (usuarioId) => {
    console.log("Usuario ID clicado:", usuarioId); // Verifica o ID do usuário clicado
    try {
      const response = await axios.get(
        `${API_URL}/grafico-lancamentos/usuarios/${usuarioId}/lancamentos-detalhados?periodo=${periodo}`
      );
      if (
        response.data &&
        response.data.detalhesLancamentos &&
        response.data.detalhesLancamentos.length > 0
      ) {
        const sortedData = response.data.detalhesLancamentos.sort(
          (a, b) => b.numeroLancamentos - a.numeroLancamentos
        );
        setModalData(sortedData);
        setSelectedUser(response.data.usuario); // Use o nome do usuário da resposta da API
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes dos lançamentos:", error);
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: "pesoTotal",
    direction: "descending",
  });

  const sortData = (key) => {
    let direction = "descending"; // Define a direção inicial como descendente
    if (sortConfig.key === key) {
      // Se a coluna clicada for a mesma que a anterior
      direction =
        sortConfig.direction === "ascending" ? "descending" : "ascending"; // Inverte a direção
    }

    const sortedData = [...allData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setAllData(sortedData);
    setSortConfig({ key, direction }); // Atualiza o estado da configuração de ordenação
  };

  // Adiciona um visual de feedback de qual coluna está sendo ordenada e em qual direção
  const getHeaderWithIcon = (columnName, key) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{columnName}</span>
        {sortConfig.key === key &&
          (sortConfig.direction === "ascending" ? (
            <ArrowUpward fontSize="small" style={{ marginLeft: 4 }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginLeft: 4 }} />
          ))}
      </div>
    );
  };

  // Renderização condicional para evitar tentar renderizar o gráfico sem dados
  return (
    <div className="indicador-lancamentos-usuarios">
      {isLoading ? (
        <div className="carregar-lancamento-usuarios">
          <CircularProgress />
          Aguarde, os dados estão sendo carregados.
        </div>
      ) : (
        <div className="grafico-e-tabela-container">
          <div className="tabela-container">
            {/* 0054 - Gabriel Colle - Filtrar por numero de condominio e fixar header tabela - 05/09/2024 */}
            <Paper sx={{ width: '600px', overflow: 'auto' }} >
            <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }} className="scroll-bar-usuarios">
              <Table aria-label="Ranking de Lançamentos">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell
                      align="right"
                      onClick={() => sortData("numeroLancamentos")}
                      style={{ cursor: "pointer" }}
                    >
                      {getHeaderWithIcon("Lançamentos", "numeroLancamentos")}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={() => sortData("pesoTotal")}
                      style={{ cursor: "pointer" }}
                    >
                      {getHeaderWithIcon("Peso Total", "pesoTotal")}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={() => sortData("numeroCondominios")}
                      style={{ cursor: "pointer" }}
                    >
                      {getHeaderWithIcon("Número de Condomínios", "numeroCondominios")}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={() => sortData("agendamentos")}
                      style={{ cursor: "pointer" }}
                    >
                      {getHeaderWithIcon("Agendamentos", "agendamentos")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData.map((item, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleUserClick(item.usuarioId)}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {item.usuario}
                      </TableCell>
                      <TableCell align="right">
                        {item.numeroLancamentos}
                      </TableCell>
                      <TableCell align="right">{item.pesoTotal}</TableCell>
                      <TableCell align="right">{item.numeroCondominios}</TableCell>
                      <TableCell align="right">{item.agendamentos}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          </div>
          <div className="grafico-container">
            {chartData && chartData.datasets.length > 0 && (
              <Doughnut key={chartKey} data={chartData} options={options} />
            )}
          </div>
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBox">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Detalhes dos Lançamentos - {selectedUser}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Detalhes dos Lançamentos">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Condomínio</TableCell>
                  <TableCell align="center">Lançamentos</TableCell>
                  <TableCell align="center">Percentual</TableCell>
                  <TableCell align="center">Peso Financeiro</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalData.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {detail.nomeCondominio}
                    </TableCell>
                    <TableCell align="center">
                      {detail.numeroLancamentos}
                    </TableCell>
                    <TableCell align="center">{detail.percentual}</TableCell>
                    <TableCell align="center">{detail.peso}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default LancamentosUsuario;
