import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import styles from './RedirecionarCarteira.module.css';

const RedirecionarCarteira = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState(null);
  const [empreendimentos, setEmpreendimentos] = useState(0);
  const [pesoTotal, setPesoTotal] = useState(0);
  const [empreendimentosList, setEmpreendimentosList] = useState([]);
  const [colaboradoresTemp, setColaboradoresTemp] = useState([]);
  const [selectedTemporaryColaboradores, setSelectedTemporaryColaboradores] =
    useState({});
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${API_URL}/ausencia/colaboradores-ausentes`)
      .then((response) => {
        console.log(response); // Imprime a resposta completa
        return response.json();
      })
      .then((data) => setColaboradores(data))
      .catch((error) =>
        console.error('Erro ao buscar os colaboradores:', error)
      );
  }, []);

  useEffect(() => {
    if (selectedColaborador) {
      fetch(
        `${API_URL}/ausencia/colaboradores-mesma-area/${selectedColaborador.id_func}`
      )
        .then((response) => response.json())
        .then((data) => {
          const promises = data.map((colab) => {
            return fetch(
              `${API_URL}/ausencia/condominios-colaborador/${colab.id_func}`
            )
              .then((res) => res.json())
              .then((result) => ({
                ...colab,
                pesoTotal: Number(result.pesoTotal), // Garante que pesoTotal seja um número
                empreendimentos: result.empreendimentos,
                quantidade: result.empreendimentos.length,
              }));
          });
          return Promise.all(promises);
        })
        .then((results) => setColaboradoresTemp(results))
        .catch((error) =>
          console.error('Erro ao buscar colaboradores temporários:', error)
        );
    }
  }, [selectedColaborador]);

  const [empreendimentosColaborador, setEmpreendimentosColaborador] = useState(
    []
  );
  useEffect(() => {
    if (selectedColaborador) {
      fetch(
        `${API_URL}/ausencia/condominios-colaborador/${selectedColaborador.id_func}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('Data from API:', data); // Adicione este log
          setEmpreendimentosColaborador(data.empreendimentos);
          setEmpreendimentos(data.empreendimentos.length);
          setPesoTotal(data.pesoTotal);
        })

        .catch((error) =>
          console.error('Erro ao buscar empreendimentos do colaborador:', error)
        );
    }
  }, [selectedColaborador]);

  // Novos estados
  const [
    numColaboradoresParaRedirecionamento,
    setNumColaboradoresParaRedirecionamento,
  ] = useState(1);
  const [
    selectedColaboradoresParaRedirecionamento,
    setSelectedColaboradoresParaRedirecionamento,
  ] = useState([]);

  // Atualiza os colaboradores selecionados para redirecionamento
  useEffect(() => {
    setSelectedColaboradoresParaRedirecionamento(
      Array(numColaboradoresParaRedirecionamento).fill(null)
    );
  }, [numColaboradoresParaRedirecionamento]);

  const handleRedirectionConfirm = () => {
    const hasEmptyFields = empreendimentosColaborador.some(
      (item) => !selectedTemporaryColaboradores[item.id_cond]
    );

    if (hasEmptyFields) {
      alert(
        'Por favor, selecione colaboradores temporários para todos os empreendimentos.'
      );
      return;
    }

    const promises = empreendimentosColaborador.map((item) => {
      return fetch(`${API_URL}/ausencia/add-redirection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_aus: selectedColaborador.id_aus,
          id_cond: item.id_cond,
          id_func_temp: selectedTemporaryColaboradores[item.id_cond],
          id_func_aus: selectedColaborador.id_func, // Novo campo
          ativo: 1, // Novo campo
          dt_final: selectedColaborador.dt_fim, // Novo campo
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to redirect for ${item.CONDOMINIO}. Status: ${response.status}`
            );
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            console.log(
              `Redirecionamento de ${item.CONDOMINIO} registrado com sucesso!`
            );
          }
        });
    });

    Promise.all(promises)
      .then(() => {
        alert('Todos os redirecionamentos foram registrados com sucesso!');
        // Resete os estados após o sucesso do registro
        setSelectedColaborador(null);
        setSelectedTemporaryColaboradores({});
        setEmpreendimentos(0);
        setPesoTotal(0);
        setEmpreendimentosColaborador([]);
      })
      .catch((error) => {
        console.error('Erro ao redirecionar:', error);
        alert('Ocorreu um erro ao redirecionar. Por favor, tente novamente.');
      });
  };

  const distributeEmpreendimentos = () => {
    if (
      selectedColaboradoresParaRedirecionamento.some(
        (colab) => !colab || colab.nome === 'Não se aplica'
      )
    ) {
      alert('Por favor, selecione colaboradores válidos.');
      return;
    }

    const updatedTemporaryColaboradores = {};
    let index = 0;

    empreendimentosColaborador.forEach((item) => {
      updatedTemporaryColaboradores[item.id_cond] =
        selectedColaboradoresParaRedirecionamento[
          index % numColaboradoresParaRedirecionamento
        ].id_func;
      index++;
    });

    setSelectedTemporaryColaboradores(updatedTemporaryColaboradores);
  };

  const fetchAbsenceIdForColaborador = (id_func) => {
    fetch(`${API_URL}/ausencia/${id_func}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.absence) {
          setSelectedColaborador((prev) => ({
            ...prev,
            id_aus: data.absence.id_aus,
            dt_fim: data.absence.dt_fim, // Adicione esta linha
          }));
        }
      })
      .catch((error) =>
        console.error(
          'Erro ao buscar o ID e a data final da ausência para o colaborador:',
          error
        )
      );
  };

  return (
    <div className={styles.redirecionarCarteiraContainer}>
      <div className={styles.redirecionarCarteiraContent}>
        <div className={styles.redirecionarCarteiraTitle}>
          Redirecionar Carteira
        </div>

        <div className={styles.redirecionarCarteiraEscolhaColaborador}>
          <Autocomplete
            className={styles.redirecionarCarteiraColaborador}
            options={colaboradores}
            getOptionLabel={(option) => option.nome}
            onChange={(event, newValue) => {
              setSelectedColaborador(newValue);
              if (!newValue) {
                setEmpreendimentos(0);
                setPesoTotal(0);
                setEmpreendimentosColaborador([]);
                setColaboradoresTemp([]);
                setSelectedTemporaryColaboradores({});
              } else {
                fetchAbsenceIdForColaborador(newValue.id_func);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Colaborador"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <TextField
            className={styles.redirecionarCarteiraEmpreendimentos}
            label="Empreendimentos"
            value={empreendimentos}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            className={styles.redirecionarCarteiraPeso}
            label="Peso"
            value={pesoTotal}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </div>

        <div className={styles.redirecionarCarteiraQuantidade}>
          <div className={styles.redirecionarCarteiraTexto}>
            Selecione a quantidade de pessoas para distribuir o redirecionamento
          </div>
          <Select
            className={styles.redirecionarCarteiraNumcolab}
            value={numColaboradoresParaRedirecionamento}
            onChange={(e) =>
              setNumColaboradoresParaRedirecionamento(e.target.value)
            }
          >
            {[...Array(10).keys()].map((n) => (
              <MenuItem
                key={n + 1}
                value={n + 1}
              >
                {n + 1}
              </MenuItem>
            ))}
          </Select>
        </div>

        {selectedColaboradoresParaRedirecionamento.map((_, index) => (
          <Autocomplete
            key={index}
            options={colaboradoresTemp}
            getOptionLabel={(option) => option.nome}
            style={{ width: '100%', marginBottom: '20px' }}
            onChange={(event, newValue) => {
              const updatedArray = [
                ...selectedColaboradoresParaRedirecionamento,
              ];
              updatedArray[index] = newValue;
              setSelectedColaboradoresParaRedirecionamento(updatedArray);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Colaborador ${index + 1}`}
                variant="outlined"
                fullWidth
              />
            )}
          />
        ))}

        <div className={styles.redirecionarCarteiraButtonsContainer}>
          <button
            className={styles.botaoRc1}
            onClick={distributeEmpreendimentos}
          >
            Distribuir Empreendimentos
          </button>
        </div>

        <div className={styles.redirecionarCarteiraTabela}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Empreendimentos</TableCell>
                <TableCell>Peso</TableCell>
                <TableCell>Colaboradores Temporários</TableCell>
                <TableCell>Peso Atual</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Novo Peso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {empreendimentosColaborador.map((item, index) => {
                const selectedTempColab = colaboradoresTemp.find(
                  (colab) =>
                    colab.id_func ===
                    selectedTemporaryColaboradores[item.id_cond]
                );
                const novoPeso = selectedTempColab
                  ? Number(item.peso) + (selectedTempColab.pesoTotal || 0)
                  : Number(item.peso);
                return (
                  <TableRow key={index}>
                    <TableCell>{item.CONDOMINIO}</TableCell>
                    <TableCell>{item.peso}</TableCell>
                    <TableCell>
                      <Select
                        labelId="temporary-colab-label"
                        id="temporary-colab"
                        value={
                          selectedTemporaryColaboradores[item.id_cond] || ''
                        }
                        onChange={(event) => {
                          setSelectedTemporaryColaboradores((prev) => ({
                            ...prev,
                            [item.id_cond]: event.target.value,
                          }));
                        }}
                        style={{ width: '100%' }}
                      >
                        {colaboradoresTemp.map((colab) => (
                          <MenuItem
                            key={colab.id_func}
                            value={colab.id_func}
                          >
                            {colab.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      {selectedTempColab ? selectedTempColab.pesoTotal : '-'}
                    </TableCell>
                    <TableCell>
                      {selectedTempColab ? selectedTempColab.quantidade : '-'}
                    </TableCell>
                    <TableCell>{novoPeso}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <div className={styles.redirecionarCarteiraButtonsContainer}>
          <button
            className={styles.botaoRc2}
            onClick={handleRedirectionConfirm}
          >
            Confirmar Redirecionamento
          </button>
        </div>
      </div>
    </div>
  );
};

export default RedirecionarCarteira;
