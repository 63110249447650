/* ***********************************
Autor: Matheus Botelho
Data: 24/05/24
Atividade: SSI 0018 - Página de atualizações Eureka
Função: Página para visualização das Atualizações do Eureka
************************************ */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './AtualizacoesEureka.module.css';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';

const AtualizacoesEureka = () => {
  const [atualizacoes, setAtualizacoes] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAtualizacoes = async () => {
      setCarregando(true);
      try {
        const response = await axios.get(
          `${API_URL}/updates/buscar?page=${pagina}`
        );
        setAtualizacoes(response.data);
        setHasMore(response.data.length > 0);
      } catch (error) {
        console.error('Erro ao buscar atualizações:', error);
      }
      setCarregando(false);
    };

    fetchAtualizacoes();
  }, [pagina]);

  return (
    <div className={styles.atualizacoesContainer}>
      <h1 className={styles.titulo}>Atualizações</h1>
      {carregando ? (
        <p>Carregando...</p>
      ) : (
        atualizacoes.map((atualizacao) => (
          <div
            key={atualizacao.id}
            className={styles.atualizacao}
          >
            <h2>{atualizacao.titulo}</h2>
            <p className={styles.data}>
              {new Date(atualizacao.data).toLocaleDateString()}
            </p>
            <div
              className={styles.descricao}
              dangerouslySetInnerHTML={{ __html: atualizacao.descricao }}
            ></div>
          </div>
        ))
      )}
      <div className={styles.paginacao}>
        <button
          onClick={() => setPagina(pagina - 1)}
          disabled={pagina === 1}
        >
          <ArrowLeftRoundedIcon />
        </button>
        <button
          onClick={() => setPagina(pagina + 1)}
          disabled={!hasMore}
        >
          <ArrowRightRoundedIcon />
        </button>
      </div>
    </div>
  );
};

export default AtualizacoesEureka;
