/* ***********************************
Autor: Matheus
Data: 09/02/2024
Atividade: Editar ausência
Função: Página que envia os dados de alteração para o banco de dados
************************************ */


import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField } from '@mui/material';
import moment from 'moment';
import './EditarAusencia.css';

function EditarAusencia() {
  const [ausencias, setAusencias] = useState([]);
  const [editedAusencia, setEditedAusencia] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${API_URL}/editar-ausencia/ausencias`)
      .then(response => response.json())
      .then(data => setAusencias(data))
      .catch(error => console.error('Erro ao buscar ausências:', error));
  }, []);

  const getStatusColor = (status) => {
    if (status === 'Ativo') {
      return '#35a435';
    } else {
      return '#fca903';
    }
  };

  const handleEdit = (ausencia) => {
    setEditedAusencia({
      ...ausencia,
      dt_inicio: ausencia.dt_inicio.substring(0, 10), // Ajustar formato da data, se necessário
      dt_fim: ausencia.dt_fim.substring(0, 10), // Ajustar formato da data, se necessário
  });
  };

  const handleSave = () => {
    // Ajustar a string da data de início para o formato esperado
    const formattedDtInicio = editedAusencia.dt_inicio.substring(0, 10);
  
    // Ajustar a string da data de fim para o formato esperado
    const formattedDtFim = editedAusencia.dt_fim.substring(0, 10);
  
    fetch(`${API_URL}/editar-ausencia/ausencias/${editedAusencia.id_aus}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dt_inicio: formattedDtInicio,
        dt_fim: formattedDtFim
      }),
    })
    .then(response => response.json())
    .then(data => {
      // console.log('Ausência atualizada com sucesso:', data);
      setEditedAusencia(null);
      window.location.reload();
    })
    .catch(error => console.error('Erro ao atualizar ausência:', error));
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedAusencia({ ...editedAusencia, [name]: value });
  };

  const handleDelete = (id) => {
    if (window.confirm("Tem certeza de que deseja excluir esta ausência?")) {
        fetch(`${API_URL}/editar-ausencia/cancelar/${id}`, {
            method: 'PUT',
        })
        .then(response => response.json())
        .then(data => {
            // console.log('Ausência excluída com sucesso:', data);
            // Atualizar a lista de ausências após a exclusão
            setAusencias(ausencias.filter(ausencia => ausencia.id_aus !== id));
        })
        .catch(error => console.error('Erro ao excluir ausência:', error));
    }
};

  const handleCancelEdit = () => {
    // Limpar o estado editedAusencia para cancelar as alterações
    setEditedAusencia(null);
  };

  return (
    <div className='editar-ausencia-container'>
        <div className='editar-ausencia-content'>
            <div className='editar-ausencia-title'>
                Editar Ausências
            </div>
            <div className='editar-ausencia-tabela'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Motivo</TableCell>
                            <TableCell>Data de Início</TableCell>
                            <TableCell>Data de Fim</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ausencias.map(ausencia => (
                            <TableRow key={ausencia.id_aus}>
                                <TableCell>{ausencia.nome}</TableCell>
                                <TableCell>{ausencia.motivo}</TableCell>
                                <TableCell>
                                    {editedAusencia && editedAusencia.id_aus === ausencia.id_aus ? (
                                        <input
                                            className='caixa-edicao-data-ausencia'
                                            name="dt_inicio"
                                            type="date"
                                            value={editedAusencia.dt_inicio}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        moment(ausencia.dt_inicio).format('YYYY-MM-DD')
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editedAusencia && editedAusencia.id_aus === ausencia.id_aus ? (
                                        <input
                                            className='caixa-edicao-data-ausencia'
                                            name="dt_fim"
                                            type="date"
                                            value={editedAusencia.dt_fim}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        moment(ausencia.dt_fim).format('YYYY-MM-DD')
                                    )}
                                </TableCell>
                                <TableCell>
                                    <div className="statusContainer">
                                        <div className="statusIndicator" style={{ backgroundColor: getStatusColor(ausencia.status) }} />
                                        <div className='editar-ausencia-tabela-status'>{ausencia.status}</div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {editedAusencia && editedAusencia.id_aus === ausencia.id_aus ? (
                                        <div className='cancel-save-editar-ausencia-btn' >
                                            <button className="cancelButton" onClick={handleCancelEdit}>Cancelar</button>
                                            <button className="saveButton" onClick={handleSave}>Salvar</button>
                                        </div>
                                    ) : (
                                        <div className='edit-delete-editar-ausencia-btn' >
                                            <button className="editButton" onClick={() => handleEdit(ausencia)}>Editar</button>
                                            <button className="deleteButton" onClick={() => handleDelete(ausencia.id_aus)}>Excluir</button>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    </div>
  );

}

export default EditarAusencia;
