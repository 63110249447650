import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../UserContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import styles from "./LoginPage.module.css"
import bgImage from "./images/BgLogin.jpg";
import logoImage from "./images/euroLogo.png";

const LoginPage = () => {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { pathname } = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
    const savedLogin = localStorage.getItem("login");
    const savedSenha = localStorage.getItem("senha");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";
    if (savedRememberMe) {
      setLogin(savedLogin);
      setSenha(savedSenha);
    }
    setRememberMe(savedRememberMe);
  }, [pathname]);

  /* const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('login', login);
      localStorage.setItem('senha', senha);
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('login');
      localStorage.removeItem('senha');
      localStorage.removeItem('rememberMe');
    }
  }; */

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/auth`, { login, senha });
      console.log("Resposta do servidor:", response.data);
      if (response.data.success) {
        localStorage.setItem("token", response.data.token); // Garanta que está salvando o token aqui
        setUser(response.data.user); // Atualizando o contexto do usuário
        setErrorMessage("");
        setSuccessMessage("Login efetuado com sucesso ✔️");
        setOpen(true);
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        setErrorMessage(
          response.data.message || "Login ou senha incorretos ❌"
        );
        setSuccessMessage("");
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage("Erro ao realizar o login");
      setSuccessMessage("");
      setOpen(true);
    }
  };

  return (
    <div
      className={styles["background-image"]}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className={styles["logo-euro"]}>
        <img className={styles["logo"]} src={logoImage} alt="Logo Euro Condomínios" />
      </div>
      <div className={styles["login-container"]}>
        <div className={styles["login-eureka"]}>
          <h2>Login Eureka</h2>
        </div>
        <form onSubmit={handleSubmit}>
          {" "}
          {/* Altere a ação do formulário conforme necessário */}
          <div className={styles["campos"]}>
            <label htmlFor="login">Usuário</label>
            <input
              type="text"
              id="login"
              name="login"
              placeholder="Digite seu usuário"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </div>
          <div className={styles["campos"]}>
            <label htmlFor="senha">Senha</label>
            <input
              type="password"
              id="senha"
              name="senha"
              placeholder="Digite a sua senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </div>
          <button type="submit" className={styles["login-btn"]}>
            Entrar
          </button>
        </form>
      </div>
      <div className={styles["footer"]}>
        <p>&copy; 2024 Euro Condomínios. Todos os direitos reservados.</p>
      </div>
      {/* Snackbar para mensagens de erro/sucesso */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {successMessage ? (
          <Alert
            onClose={handleClose}
            severity="success"
            elevation={6}
            variant="filled"
          >
            {successMessage}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            severity="error"
            elevation={6}
            variant="filled"
          >
            {errorMessage}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
};

export default LoginPage;
