/* ***********************************
Autor: Matheus Botelho
Data: 08/04/2024
Atividade: Modal de Sucesso
Função: Padronizar a mensagem de sucesso com um modal
************************************ */

import React from 'react';
import styles from './SuccessModal.module.css'; // Adicione estilos conforme necessário

const SuccessModal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContentSucess}>
        <p>{message}</p>
        <button
          onClick={onClose}
          className={styles.successFecharBtn}
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
