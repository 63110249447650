import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	TextField,
	Autocomplete,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper, // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import styles from "./UsuarioCidade.module.css";
import { toast } from "react-toastify"; // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //

// ------------ SSI 0055 - LCORREA - Corrigir Permissões de usuarios_cidade / advogados_externos - 05/09/2024 ------------ //

const UsuarioCidade = () => {
	const [usuarios, setUsuarios] = useState([]);
	const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
	const [condominios, setCondominios] = useState([]);
	const [todosCondominios, setTodosCondominios] = useState([]);
	const [adicionandoCondominio, setAdicionandoCondominio] = useState(false);
	const [condominiosCidade, setCondominiosCidade] = useState([]);
	const [condominiosEspecificos, setCondominiosEspecificos] = useState([]); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
	const [filtro, setFiltro] = useState(""); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
	const [condominioSelecionado, setCondominioSelecionado] = useState(null); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //

	const API_URL = process.env.REACT_APP_API_URL;

	// Função para buscar usuários
	const fetchUsuarios = async () => {
		try {
			const response = await fetch(`${API_URL}/usuario-cidade/usuarios-cidade`);
			const data = await response.json();
			setUsuarios(data); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		} catch (error) {
			console.error("Erro ao buscar usuários:", error);
		}
	};

	// Função para buscar condomínios relacionados à cidade_sede do usuário selecionado
	const fetchCondominiosCidadeSede = async (cidadeSede) => {
		try {
			const response = await fetch(
				`${API_URL}/usuario-cidade/condominios-cidade/${cidadeSede}`
			);
			const data = await response.json();
			setCondominiosCidade(data); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		} catch (error) {
			console.error("Erro ao buscar condomínios da cidade:", error);
		}
	};

	// Função para buscar os condomínios específicos do usuário selecionado
	const fetchCondominiosEspecificos = async (idFunc) => {
		try {
			const response = await fetch(
				`${API_URL}/usuario-cidade/condominios-especificos/${idFunc}`
			);
			const data = await response.json();
			if (data.length > 0) {
				toast.success(
					`Condomínios encontrados para o usuário ${usuarioSelecionado.nome}`
				); // Sucesso com toast
			} else {
				toast.warn(
					`Nenhum condomínio relacionado com o usuário ${usuarioSelecionado.nome}`
				); // Aviso com toast
			}
			setCondominiosEspecificos(data); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		} catch (error) {
			toast.error(`Erro ao buscar condomínios específicos: ${error.message}`); // Erro com toast
		}
	};

	// Atualização ao selecionar usuário
	useEffect(() => {
		if (usuarioSelecionado && usuarioSelecionado.id_func) {
			fetchCondominiosEspecificos(usuarioSelecionado.id_func); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		} else {
			console.log("ID do usuário selecionado não está disponível"); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		}
	}, [usuarioSelecionado]);

	const fetchCondominios = async (idFunc) => {
		try {
			const response = await fetch(
				`${API_URL}/usuario-cidade/condominios/${idFunc}`
			);
			const data = await response.json();
			setCondominios(data); // Já inclui o id_cond
		} catch (error) {
			console.error("Erro ao buscar condomínios:", error);
		}
	};

	// Função para buscar todos os condomínios
	const fetchTodosCondominios = async () => {
		try {
			const response = await fetch(
				`${API_URL}/usuario-cidade/todos-condominios`
			);
			const data = await response.json();
			setTodosCondominios(data); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		} catch (error) {
			console.error("Erro ao buscar todos os condomínios:", error);
		}
	};

	// Chamada ao carregar o componente
	useEffect(() => {
		fetchUsuarios();
		fetchTodosCondominios(); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
	}, []);

	const iniciarAdicaoCondominio = () => {
		setAdicionandoCondominio(true);
	};

	const confirmarAdicaoCondominio = (novoCondominio) => {
		if (
			novoCondominio &&
			!condominios.find((c) => c.id_cond === novoCondominio.id_cond)
		) {
			const novosCondominios = [...condominios, novoCondominio];
			setCondominios(novosCondominios);
			console.log("Condomínios após adição:", novosCondominios);
		}
		setAdicionandoCondominio(false);
	};

	const reiniciarCampos = () => {
		setUsuarioSelecionado(null);
		setCondominios([]);
		setAdicionandoCondominio(false);
	};

	const cancelarAdicaoCondominio = () => {
		setAdicionandoCondominio(false);
	};

	const salvarCondominios = async () => {
		if (usuarioSelecionado && usuarioSelecionado.id_func) {
			const idsCondominios = condominios.map(
				(condominio) => condominio.id_cond
			);
			console.log("Enviando para salvamento:", idsCondominios);

			try {
				const response = await fetch(
					`${API_URL}/usuario-cidade/salvar-condominios/${usuarioSelecionado.id_func}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ condominios: idsCondominios }),
					}
				);

				if (response.ok) {
					alert("Condomínios salvos com sucesso!");
					reiniciarCampos(); // Reiniciar campos após o salvamento bem-sucedido
				} else {
					alert("Erro ao salvar condomínios.");
				}
			} catch (error) {
				console.error("Erro ao salvar condomínios:", error);
			}
		}
	};

	// Atualização ao selecionar usuário
	useEffect(() => {
		if (usuarioSelecionado && usuarioSelecionado.cidade_sede) {
			fetchCondominiosCidadeSede(usuarioSelecionado.cidade_sede); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
		}
	}, [usuarioSelecionado]);

	// ------------ INICIO - SSI 0055 - LCORREA - 05/09/2024 ------------ //
	const condominiosFiltrados = condominiosCidade.filter((condominio) =>
		condominio.CONDOMINIO.toLowerCase().includes(filtro.toLowerCase())
	);

	const condominiosEspecificosFiltrados = condominiosEspecificos.filter(
		(condominio) =>
			condominio.CONDOMINIO.toLowerCase().includes(filtro.toLowerCase())
	);

	// Filtra os condomínios disponíveis, excluindo aqueles que já estão na lista de específicos
	const condominiosDisponiveis = todosCondominios.filter(
		(condominio) =>
			!condominiosEspecificos.some(
				(especifico) => especifico.id_cond === condominio.id_cond
			)
	);

	const adicionarCondominioEspecifico = async () => {
		if (condominioSelecionado && usuarioSelecionado) {
			try {
				const response = await fetch(
					`${API_URL}/usuario-cidade/adicionar-condominio`, // Endpoint para adicionar o condomínio
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							id_func: usuarioSelecionado.id_func, // ID do usuário selecionado
							id_cond: condominioSelecionado.id_cond, // ID do condomínio selecionado
						}),
					}
				);

				if (response.ok) {
					toast.success("Condomínio adicionado com sucesso!"); // Sucesso com toast
					fetchCondominiosEspecificos(usuarioSelecionado.id_func); // Atualiza a tabela da direita
					setCondominioSelecionado(null); // Limpa o campo de seleção
				} else {
					toast.error("Erro ao adicionar condomínio."); // Erro com toast
				}
			} catch (error) {
				toast.error(`Erro ao adicionar condomínio: ${error.message}`); // Erro com toast
			}
		}
	};
	// ------------ FIM - SSI 0055 - LCORREA - 05/09/2024 ------------ //

	return (
		<div className={styles.usuarioCidadeContainer}>
			<div className={styles.usuarioCidadeContent}>
				{/* Primeira Box */}
				<Box sx={{ marginBottom: 2, textAlign: "left" }}>
					<div className={styles.usuarioCidadeTitle}>
						Editar Usuários Cidade
					</div>
					<Box sx={{ my: 2, width: "25%" }}>
						<Autocomplete
							options={usuarios}
							getOptionLabel={(option) =>
								`${option.nome} - ${option.cidade_sede}`
							}
							fullWidth
							onChange={(event, newValue) => {
								setUsuarioSelecionado(newValue);
								if (newValue && newValue.id_func) {
									fetchCondominiosEspecificos(newValue.id_func); // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
									fetchCondominios(newValue.id_func); // Busca os condomínios relacionados
								} else {
									setCondominios([]);
									setCondominiosEspecificos([]);
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Selecione o usuário"
									variant="outlined"
									fullWidth
								/>
							)}
						/>
					</Box>
				</Box>

				{/* Campo de Filtro */}
				{usuarioSelecionado && (
					<Box sx={{ marginBottom: 2, width: "25%" }}>
						<TextField
							label="Filtrar Condomínios"
							variant="outlined"
							fullWidth
							value={filtro}
							onChange={(e) => setFiltro(e.target.value)} // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
						/>
					</Box>
				)}

				{/* Tabelas lado a lado */}
				<Box sx={{ display: "flex", gap: 2, mt: 4 }}>
					{/* Tabela da Esquerda - Condomínios da cidade */}
					<Box sx={{ flex: 1 }}>
						<Typography variant="h6" sx={{ textAlign: "center" }}>
							Condomínios Relacionados à Cidade
						</Typography>
						<TableContainer
							component={Paper}
							sx={{ maxHeight: 400, minHeight: 400 }}
						>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>ID Eureka</TableCell>
										<TableCell>Condomínio</TableCell>
										<TableCell>Cidade</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{condominiosFiltrados.length > 0 ? (
										condominiosFiltrados.map((condominio, index) => (
											<TableRow key={index}>
												<TableCell>{condominio.id_cond}</TableCell>
												<TableCell>{condominio.CONDOMINIO}</TableCell>
												<TableCell>{condominio.CIDADE}</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												Nenhum condomínio encontrado
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					{/* Tabela da Direita - Condomínios Específicos do Usuário */}
					<Box sx={{ flex: 1 }}>
						<Typography variant="h6" sx={{ textAlign: "center" }}>
							Condomínios Específicos do Usuário
						</Typography>
						<TableContainer
							component={Paper}
							sx={{ maxHeight: 328, minHeight: 328 }}
						>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>ID Eureka</TableCell>
										<TableCell>Condomínio</TableCell>
										<TableCell>Cidade</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{condominiosEspecificosFiltrados.length > 0 ? (
										condominiosEspecificosFiltrados.map((condominio, index) => (
											<TableRow key={index}>
												<TableCell>{condominio.id_cond}</TableCell>
												<TableCell>{condominio.CONDOMINIO}</TableCell>
												<TableCell>{condominio.CIDADE}</TableCell>
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell colSpan={3} align="center">
												Nenhum condomínio encontrado
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								marginTop: 2, // Adicionado espaço entre a tabela e o campo de adicionar condomínio
								justifyContent: "flex-start", // Posiciona na esquerda
							}}
						>
							<Box sx={{ width: "90%" }}>
								{" "}
								{/* Campo ocupa 90% */}
								<Autocomplete
									options={condominiosDisponiveis} // ------------ SSI 0055 - LCORREA - 05/09/2024 ------------ //
									getOptionLabel={(option) =>
										`${option.CONDOMINIO} - ${option.CIDADE}`
									}
									value={condominioSelecionado} // Estado que guarda o condomínio selecionado
									onChange={(event, newValue) =>
										setCondominioSelecionado(newValue)
									} // Atualiza o estado
									renderInput={(params) => (
										<TextField
											{...params}
											label="Adicionar Condomínio"
											variant="outlined"
											fullWidth
										/>
									)}
								/>
							</Box>
							<Button
								variant="contained"
								color="primary"
								onClick={adicionarCondominioEspecifico} // Função chamada ao clicar no botão
								sx={{ marginLeft: 1, width: "10%" }} // Botão ocupa 10%
							>
								<AddIcon />
							</Button>
						</Box>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default UsuarioCidade;
