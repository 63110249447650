/* ***********************************
Autor: Matheus Botelho
Data: 28/03/2024
Atividade: Automação Cadastro
Função: Automação com API para alteração de titularidades de condôminos
************************************ */

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Tooltip from '@mui/material/Tooltip';
import './AlterarTitularidade.css';
import UserContext from '../UserContext';
import moment from 'moment';
import ConfirmarModal from '../Globais/ConfirmarModal';
import SuccessModal from '../Globais/SuccessModal';
import ModalPesquisa from './SearchModal';
import EnviarEmailModal from './EnviarEmailModal';
import NotificarSindicoModal from './NotificarSindicoModal';
import MostrarContatos from './MostrarContatos'; // Ajuste o caminho conforme necessário

const AlterarTitularidade = () => {
  const [allData, setAllData] = useState([]);
  const [currentData, setCurrentData] = useState({
    cond_corresp: '' /* outras propriedades padrão se necessário */,
  });
  const [currentId, setCurrentId] = useState(() => {
    // Tenta ler o currentId do localStorage ou usa 1 como valor padrão
    const savedId = localStorage.getItem('currentId');
    return savedId ? Number(savedId) : 1;
  });

  const [maxId, setMaxId] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
  const APP_TOKEN = process.env.REACT_APP_APP_TOKEN;
  const [condominiums, setCondominiums] = useState([]);
  const [units, setUnits] = useState([]);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackError, setFeedbackError] = useState(false);
  const [observacao, setObservacao] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const [sendInfoName, setSendInfoName] = useState('');
  const [sendInfoDate, setSendInfoDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [cadastrosPendentes, setCadastrosPendentes] = useState([]);
  const [mostrarListaPendentes, setMostrarListaPendentes] = useState(false);
  const [isModalPesquisaOpen, setIsModalPesquisaOpen] = useState(false);
  const [showEnviarEmailModal, setShowEnviarEmailModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [showNotificarSindicoModal, setShowNotificarSindicoModal] =
    useState(false);
  const [isMostrarContatosModalOpen, setIsMostrarContatosModalOpen] =
    useState(false);
  const [tipoCobrancaOptions, setTipoCobrancaOptions] = useState({});
  const [statusCounts, setStatusCounts] = useState({
    Pendente: 0,
    Anulado: 0,
    Enviado: 0,
  });

  const handleSelectCadastro = (id) => {
    setIsModalPesquisaOpen(false);
    // Atualize o estado para navegar para o cadastro selecionado
    setCurrentId(id);
  };

  useEffect(() => {
    const fetchStatusCounts = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/alteracao-cadastro/status-counts`
        );
        setStatusCounts(response.data);
      } catch (error) {
        console.error('Erro ao buscar contagens de status:', error);
      }
    };

    fetchStatusCounts();
  }, []);

  const handleConfirmAction = () => {
    switch (currentAction) {
      case 'enviar':
        enviarDados();
        break;
      case 'invalidar':
        invalidarCadastro(currentId);
        break;
      case 'pendente':
        tornarPendente(currentId);
        break;
      default:
        console.log('Ação não reconhecida');
    }
    setIsModalOpen(false); // Fecha o modal após a ação
  };

  const handleCancelAction = () => {
    setIsModalOpen(false); // Simplesmente fecha o modal sem fazer nada
  };

  const handleVerificacaoChange = (e) => {
    const valor = e.target.value === '1' ? 1 : 0;
    setCurrentData({ ...currentData, verificar_dados: valor });
  };

  const TIPO_UNID_OPTIONS = ['Horizontal', 'Vertical'];

  const TIPO_RESP_OPTIONS = {
    1: 'PROPRIETARIO RESIDENTE',
    2: 'PROPRIETARIO NÃO RESIDENTE',
    3: 'IMOBILIARIA',
    4: 'DEPENDENTE',
    5: 'EMPREGADO',
    7: 'RESIDENTE',
    999: 'PROCURADOR',
  };

  const ESTADO_OPTIONS = {
    1: 'AC',
    2: 'AL',
    3: 'AM',
    4: 'AP',
    5: 'BA',
    6: 'CE',
    7: 'DF',
    8: 'ES',
    9: 'GO',
    10: 'MA',
    11: 'MG',
    12: 'MS',
    13: 'MT',
    14: 'PA',
    15: 'PB',
    16: 'PE',
    17: 'PI',
    18: 'PR',
    19: 'RJ',
    20: 'RN',
    21: 'RO',
    22: 'RS',
    23: 'SC',
    24: 'SE',
    25: 'SP',
    26: 'TO',
    27: 'RR',
  };

  const FORMA_ENTREGA_OPTIONS = {
    '-1': 'ENTREGA TODAS',
    1: 'ENTREGA CORREIO E DIGITAL',
    2: 'ENTREGA PORTARIA E DIGITAL',
    3: 'ENTREGA APENAS DIGITAL',
  };

  const TIPO_IDENTIFICACAO_OPTIONS = {
    0: 'FORNECEDOR',
    1: 'CONDÔMINO',
    2: 'TIPO_OUTROS',
  };

  const FIELD_LABELS = {
    nome_cond: 'Condomínio',
    tipo_unid: 'Tipo de Unidade',
    bloco: 'Bloco',
    apto: 'Apartamento',
    lote: 'Lote',
    quadra: 'Quadra',
    nome_resp: 'Nome do Responsável',
    cpf: 'CPF',
    cnpj: 'CNPJ',
    tel_resp: 'Telefone',
    email_resp: 'E-mail',
    email_resp2: 'E-mail 2',
    email_resp3: 'E-mail 3',
    email_resp4: 'E-mail 4',
    cep: 'CEP',
    endereco: 'Endereço',
    complemento: 'Complemento',
    bairro: 'Bairro',
    cidade: 'Cidade',
    estado: 'Estado',
    tipo_resp: 'Tipo de Responsável',
    cond_corresp: 'Condomínio Correspondente',
  };

  const TIPO_COBRANCA_OPTIONS = {
    1: 'COBRANÇA NORMAL E EXTRA',
    2: 'APENAS COBRANÇAS EXTRA',
    4: 'NÃO RECEBER COBRANÇAS',
    7: 'APENAS COBRANÇAS NORMAL',
  };

  const fetchCadastrosPendentes = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/alteracao-cadastro/pendentes`
      );
      setCadastrosPendentes(response.data);
    } catch (error) {
      console.error('Erro ao buscar cadastros pendentes:', error);
    }
  };

  useEffect(() => {
    fetchCadastrosPendentes();
  }, []);

  useEffect(() => {
    // Salva o currentId no localStorage sempre que ele mudar
    localStorage.setItem('currentId', currentId);
  }, [currentId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/alteracao-cadastro/dados-alteracao-cadastro/${currentId}`
      );
      let data = response.data;

      if (data.data_nascimento) {
        data.data_nascimento = moment(data.data_nascimento).format(
          'DD/MM/YYYY'
        );
      }

      let options = {};
      if (data.tipo_resp === 1 || data.tipo_resp === 2) {
        options = { 1: 'COBRANÇA NORMAL E EXTRA', 4: 'NÃO RECEBER COBRANÇAS' };
      } else {
        options = {
          4: 'NÃO RECEBER COBRANÇAS',
          7: 'APENAS COBRANÇAS NORMAL',
        };
      }

      setTipoCobrancaOptions(options); // Atualiza o estado das opções de cobrança
      setCurrentData(data); // Mantém o currentData limpo de opções de UI
    } catch (error) {
      console.error('Error fetching data for currentId:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentId]); // Dependência de currentId para refetch

  const fetchCondominiums = async () => {
    const config = {
      method: 'get',
      url: 'https://api.superlogica.net/v2/condor/condominios/get?id=-1&somenteCondominiosAtivos=1&ignorarCondominioModelo=1&apenasColunasPrincipais=1&comDadosFechamento=1&apenasDadosDoPlanoDeContas=0',
      headers: {
        'Content-Type': 'application/json',
        app_token: APP_TOKEN, // Use your APP_TOKEN environment variable
        access_token: ACCESS_TOKEN, // Use your ACCESS_TOKEN environment variable
      },
    };

    try {
      const response = await axios(config);
      // Ordena os condomínios em ordem alfabética pelo nome (st_fantasia_cond)
      const sortedCondominiums = response.data.sort((a, b) => {
        const nameA = a.st_fantasia_cond.toUpperCase(); // Ignora case
        const nameB = b.st_fantasia_cond.toUpperCase(); // Ignora case
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // Nomes iguais
        return 0;
      });
      setCondominiums(sortedCondominiums);
    } catch (error) {
      console.error('Error fetching condominiums:', error);
    }
  };

  useEffect(() => {
    fetchCondominiums();
  }, []);

  const fetchAllUnitsForNewCondominium = async (
    condominiumId,
    selectedUnitId
  ) => {
    if (!condominiumId) {
      setUnits([]);
      setLoadingUnits(false);
      return;
    }

    setLoadingUnits(true);
    let fetchedUnits = [];

    try {
      const response = await axios.get(
        `${API_URL}/alteracao-cadastro/unidades/${condominiumId}`
      );

      const unitsData = response.data;

      // Filtrar unidades duplicadas
      const uniqueUnitsMap = new Map();
      unitsData.forEach((unit) => {
        // Utilize uma chave única para cada unidade, como o id_unidade ou uma combinação
        // que faça sentido para garantir a unicidade
        const uniqueKey = `${unit.id_unidade}-${unit.st_bloco}`;
        if (!uniqueUnitsMap.has(uniqueKey)) {
          uniqueUnitsMap.set(uniqueKey, unit);
        }
      });

      fetchedUnits = Array.from(uniqueUnitsMap.values());

      setUnits(fetchedUnits);
      setLoadingUnits(false);

      if (selectedUnitId) {
        setCurrentData((prevData) => ({
          ...prevData,
          unid_corresp: selectedUnitId,
        }));
      }
    } catch (error) {
      console.error('Error fetching units:', error);
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    const fetchMaxId = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/alteracao-cadastro/dados-alteracao-cadastro/max-id`
        );
        setMaxId(response.data.maxId);
      } catch (error) {
        console.error('Error fetching max ID:', error);
      }
    };

    fetchMaxId();
  }, []); // Array de dependências vazio significa que isso roda apenas na montagem do componente

  useEffect(() => {
    const newData = allData.find((item) => item.id === currentId);
    if (newData) {
      setCurrentData(newData);
      // Atualize verificacaoDados com base no valor de newData.verificar_dados
    }
  }, [currentId, allData]);

  const atualizarDadosCadastro = async () => {
    setFeedbackMessage(''); // Limpa a mensagem anterior
    setFeedbackError(false); // Reseta o estado de erro
    try {
      const response = await axios.get(`${API_URL}/importar-dados-cadastro`);
      const novosCadastros = response.data.novosCadastros;
      console.log('Dados atualizados com sucesso:', response.data);

      let message = 'Dados atualizados com sucesso!';
      if (novosCadastros > 0) {
        message = `Dados atualizados com sucesso! ${novosCadastros} novos cadastros adicionados`;
      }
      setFeedbackMessage(message);

      // Chame fetchData() para atualizar os dados exibidos na UI após a importação
      fetchData();

      // Limpa a mensagem após 3 segundos
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackError(false); // Também reseta o estado de erro caso necessário
      }, 3000);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error('Erro ao atualizar dados:', error);
      setFeedbackMessage(
        'Os dados não foram atualizados, por favor tente novamente'
      );
      setFeedbackError(true); // Indica que ocorreu um erro

      // Limpa a mensagem após 3 segundos
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackError(false); // Reseta o estado de erro
      }, 3000);
    }
  };

  useEffect(() => {
    setObservacao(currentData.observacao || '');
  }, [currentData]);

  const updateData = async () => {
    const dataParaAtualizar = {
      ...currentData,
      data_nascimento: currentData.data_nascimento
        ? moment(currentData.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : currentData.data_nascimento,
      observacao,
    };

    if (!dataParaAtualizar.id) {
      console.error('ID must be provided.');
      return;
    }

    try {
      const url = `${API_URL}/alteracao-cadastro/alterar-cadastro/${dataParaAtualizar.id}`;
      await axios.put(url, dataParaAtualizar);
      setIsEditing(false); // Sai do modo de edição
      setSuccessMessage('Dados atualizados com sucesso!'); // Define a mensagem de sucesso
      setShowSuccessModal(true); // Exibe o modal de sucesso
      fetchData(); // Atualiza os dados exibidos
    } catch (error) {
      console.error('Error updating data:', error);
      setFeedbackMessage('Erro ao atualizar os dados.');
      setFeedbackError(true);
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackError(false);
      }, 3000);
    }
  };

  // Function to render email fields conditionally
  const renderEmailFields = () => {
    return (
      <>
        {['email_resp', 'email_resp2', 'email_resp3', 'email_resp4'].map(
          (key, index) => (
            <div
              className="field"
              key={key}
            >
              <label>{FIELD_LABELS[key]}</label>
              <input
                type="email"
                className="large-input"
                value={currentData[key] || ''}
                readOnly={!isEditing}
                onChange={(e) =>
                  setCurrentData({
                    ...currentData,
                    [key]: e.target.value,
                  })
                }
              />
            </div>
          )
        )}
      </>
    );
  };

  const fetchCondominioNome = async (condId) => {
    try {
      const response = await axios.get(
        `${API_URL}/alteracao-cadastro/getCondominioNome/${condId}`
      );
      return response.data.nomeCondominio; // Return the condominium name
    } catch (error) {
      console.error('Error fetching condominium name:', error);
      return ''; // Handle the error appropriately
    }
  };

  const validarDados = () => {
    let camposObrigatorios = [
      'unid_corresp',
      'tipo_cobranca',
      'tipo_resp',
      'forma_entrega',
      'data_nascimento',
      'nome_resp',
      'tipo_contato',
      'cond_corresp',
      'email_resp',
      'tel_resp',
      'bairro',
      'cep',
      'cidade',
      'endereco',
      'estado',
    ];

    // Adiciona cpf ou cnpj à lista de campos obrigatórios com base no tipo_doc
    if (currentData.doc_tipo === 'cpf') {
      camposObrigatorios.push('cpf');
    } else if (currentData.doc_tipo === 'cnpj') {
      camposObrigatorios.push('cnpj');
    }

    let camposFaltando = camposObrigatorios.filter(
      (campo) => !currentData[campo]
    );

    if (camposFaltando.length > 0) {
      let camposFaltandoNomes = camposFaltando
        .map((campo) => FIELD_LABELS[campo] || campo)
        .join(', ');
      setShowSuccessModal(true);
      setSuccessMessage(`Campos faltantes: ${camposFaltandoNomes}`);
      return false;
    }

    return true;
  };

  const enviarDados = async () => {
    if (!validarDados()) {
      return; // Impede o envio dos dados se houver erros
    }

    if (!currentData.id) {
      setSuccessMessage('ID não selecionado!');
      setShowSuccessModal(true);
      return;
    }

    if (currentData.verificar_dados !== 1) {
      setShowSuccessModal(true);
      setSuccessMessage(
        'Por favor, verifique os dados do cadastro, caso estiver correto marque a caixa para SIM!'
      );
      return;
    }
    const dataParaEnviar = {
      ...currentData,
      data_nascimento: currentData.data_nascimento
        ? moment(currentData.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : currentData.data_nascimento,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL}/alteracao-cadastro/enviar-dados/${currentData.id}`,
        dataParaEnviar
      );

      if (response.status === 200) {
        await registrarEnvio();
        await atualizarStatusCadastro(currentData.id, 'Enviado');
        setShowEnviarEmailModal(true); // Pode querer mover para depois da decisão de notificar síndico

        // Abre modal para escolher notificar síndico
        setShowNotificarSindicoModal(true);

        setSuccessMessage(
          'Todos os dados foram enviados e atualizados com sucesso!'
        );
        setCurrentAction('enviarEmail');
        setIsModalOpen(true);
        setCurrentData((prevState) => ({
          ...prevState,
          status_cadastro: 'Enviado',
        }));
        fetchData();
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      let errorMessage = 'Falha ao enviar os dados. Verifique os campos.';
      if (error.response && error.response.status === 400) {
        const camposFaltantes =
          error.response.data.camposFaltando &&
          Array.isArray(error.response.data.camposFaltando)
            ? error.response.data.camposFaltando.join(', ')
            : 'Detalhes do erro não disponíveis.';
        errorMessage = `Informações faltantes: ${camposFaltantes}.`;
      }

      setShowSuccessModal(true);
      setSuccessMessage(errorMessage);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const atualizarStatusCadastro = async (id, novoStatus) => {
    try {
      await axios.patch(
        `${API_URL}/alteracao-cadastro/atualizar-status/${id}`,
        {
          status_cadastro: novoStatus,
        }
      );
    } catch (error) {
      console.error('Erro ao atualizar o status do cadastro:', error);
    }
  };

  const updateEmailStatus = async (status) => {
    try {
      await axios.patch(
        `${API_URL}/alteracao-cadastro/atualizar-status-email/${currentData.id}`,
        {
          status_email: status,
        }
      );
      console.log(`Status do e-mail atualizado para ${status}`);
      // Atualiza o estado local para refletir a mudança
      setCurrentData((prevState) => ({
        ...prevState,
        status_email: status,
      }));
    } catch (error) {
      console.error('Erro ao atualizar o status do e-mail:', error);
    }
  };

  const registrarEnvio = async () => {
    const dataEnvio = new Date().toISOString().slice(0, 19).replace('T', ' ');

    // Encontrando a unidade correspondente para obter informações como o 'st_bloco' e 'st_unidade'
    const unidadeSelecionada = units.find(
      (unit) => unit.id_unidade === currentData.unid_corresp
    );

    const nomeCondominio = await fetchCondominioNome(currentData.cond_corresp);
    if (!nomeCondominio) {
      console.error('Nome do condomínio não encontrado');
      return; // Optionally handle this error appropriately
    }

    try {
      await axios.post(`${API_URL}/alteracao-cadastro/registrar-envio`, {
        id_dados: currentData.id, // Adicionado id_dados
        id_func: user?.id_func,
        nome: user?.nome,
        cond_corresp: currentData.cond_corresp,
        unid_corresp: currentData.unid_corresp,
        nome_cond: nomeCondominio,
        bloco_unid: unidadeSelecionada ? unidadeSelecionada.st_bloco : '',
        nome_unid: unidadeSelecionada ? unidadeSelecionada.st_unidade : '',
        nome_resp: currentData.nome_resp,
        data_envio: dataEnvio,
      });
      console.log('Registro de envio salvo com sucesso.');
    } catch (error) {
      console.error('Erro ao registrar o envio:', error);
    }
  };

  const fetchSendInfo = async (id) => {
    if (currentData.status_cadastro === 'Enviado') {
      try {
        const response = await axios.get(
          `${API_URL}/alteracao-cadastro/log-envio/${id}`
        );
        if (response.data) {
          const { nome, data_envio } = response.data;
          const formattedDate = new Date(data_envio).toLocaleDateString(
            'pt-BR'
          );
          setSendInfoName(nome);
          setSendInfoDate(formattedDate);
        }
      } catch (error) {
        console.error('Erro ao buscar informações de envio:', error);
      }
    }
  };

  useEffect(() => {
    if (currentId && currentData.status_cadastro) {
      fetchSendInfo(currentId);
    }
  }, [currentId, currentData.status_cadastro]);

  const renderStatusIcon = (value, inline = false) => {
    const iconStyle = inline
      ? {
          color: value ? 'green' : 'red',
          verticalAlign: 'middle',
          marginLeft: '10px',
        }
      : { color: value ? 'green' : 'red' };
    return value ? (
      <CheckCircleIcon style={iconStyle} />
    ) : (
      <CancelIcon style={iconStyle} />
    );
  };

  // Incrementa o ID atual, com um limite máximo
  const handleNext = () => {
    setCurrentId((prevId) => Math.min(prevId + 1, maxId));
  };

  // Decrementa o ID atual, com um limite mínimo de 1
  const handlePrevious = () => {
    setCurrentId((prevId) => Math.max(prevId - 1, 1));
  };

  const invalidarCadastro = async (id) => {
    if (!id) {
      setSuccessMessage('ID não selecionado!');
      setShowSuccessModal(true);
      return;
    }

    try {
      setLoading(true);
      const novoStatus = 'Anulado';
      await axios.patch(
        `${API_URL}/alteracao-cadastro/atualizar-status/${id}`,
        { status_cadastro: novoStatus }
      );

      setSuccessMessage('Cadastro invalidado com sucesso!');
      setShowSuccessModal(true);

      // Atualiza o estado local para refletir a mudança de status
      setCurrentData((prevState) => ({
        ...prevState,
        status_cadastro: novoStatus,
      }));
      fetchData(); // Recarrega os dados para refletir as alterações mais recentes
    } catch (error) {
      console.error('Erro ao invalidar o cadastro:', error);
      setShowSuccessModal(true);
      setSuccessMessage('Falha ao invalidar o cadastro');
    } finally {
      setLoading(false);
    }
  };

  const sendConfirmationEmail = async () => {
    const emailsToSend = [
      currentData.email_resp,
      currentData.email_resp2,
      currentData.email_resp3,
      currentData.email_resp4,
    ].filter((email) => Boolean(email)); // Filters out any null or empty email addresses

    try {
      const response = await axios.post(
        `${API_URL}/alteracao-cadastro/enviar-email`,
        {
          emails: emailsToSend,
          id_dados: currentId,
        }
      );
      // Assumindo que a resposta do servidor seja positiva após o envio
      setSuccessMessage('Enviado com sucesso!'); // Define a mensagem de sucesso
      setShowSuccessModal(true); // Exibe o modal de sucesso
      updateEmailStatus('enviado');
    } catch (error) {
      console.error('Erro ao enviar o email:', error);
      updateEmailStatus('não enviado');
      setFeedbackMessage('Falha ao enviar e-mail. Por favor, tente novamente.');
      setFeedbackError(true);
    } finally {
      setLoading(false);
      // Limpa a mensagem de feedback após um certo tempo
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackError(false);
      }, 3000);
    }
  };

  const notificarSindico = async (id) => {
    setShowNotificarSindicoModal(false); // Fechar modal de confirmação
    try {
      const response = await axios.post(
        `${API_URL}/alteracao-cadastro/notificar-sindico/${id}`
      );
      console.log('Resposta:', response.data);
      setSuccessMessage('Notificação enviada com sucesso ao síndico!');
      setShowSuccessModal(true); // Exibir modal de sucesso
    } catch (error) {
      console.error('Erro ao enviar notificação ao síndico:', error);
      alert('Falha ao enviar notificação.');
    }
  };

  const tornarPendente = async (id) => {
    if (!id) {
      setSuccessMessage('ID não selecionado!');
      setShowSuccessModal(true);
      return;
    }

    try {
      setLoading(true);
      await axios.patch(
        `${API_URL}/alteracao-cadastro/atualizar-status/${id}`,
        { status_cadastro: 'Pendente' }
      );

      setSuccessMessage('Cadastro atualizado para pendente com sucesso!');
      setShowSuccessModal(true);

      setCurrentData((prevState) => ({
        ...prevState,
        status_cadastro: 'Pendente',
      }));
      fetchData();
    } catch (error) {
      console.error('Erro ao atualizar o cadastro para pendente:', error);
      setShowSuccessModal(true);
      setSuccessMessage('Falha ao atualizar para pendente');
    } finally {
      setLoading(false);
    }
  };

  const extractFileIdFromUrl = (url) => {
    const match = url.match(/\/file\/d\/(.*?)\//);
    return match ? match[1] : null;
  };

  // Função para iniciar o download do arquivo
  const handleDownload = (fileUrl) => {
    const fileId = extractFileIdFromUrl(fileUrl);
    if (fileId) {
      fetch(`${API_URL}/download/${fileId}`)
        .then((response) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          console.log('Content-Disposition header:', contentDisposition); // Log the header

          let filename = 'default_filename.pdf'; // Default filename
          if (contentDisposition) {
            const matches = contentDisposition.match(
              /filename\*?=(?:UTF-8'')?["']?([^;'"\\]*)["']?;?/i
            );
            filename = matches && matches[1] ? matches[1] : filename;
          }

          console.log('Filename parsed from header:', filename); // Log parsed filename
          return response.arrayBuffer().then((data) => ({
            data,
            filename,
          }));
        })
        .then(({ data, filename }) => {
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // Using the parsed filename
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Erro ao baixar o arquivo:', error));
    }
  };

  //SSI 0010 - 23/04/24

  useEffect(() => {
    // Resetar o arquivo selecionado sempre que o currentId mudar
    setSelectedFile(null);
  }, [currentId]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    } else {
      // Se não houver arquivos selecionados, limpa o estado
      setSelectedFile(null);
    }
  };

  const enviarDadosComArquivo = async () => {
    if (!validarDados()) {
      console.log('Validação dos dados falhou.');
      return;
    }

    const formData = new FormData();
    formData.append('ARQUIVO', selectedFile);
    formData.append('ID_RESPONSAVEL_ARQ', currentData.unid_corresp);
    formData.append('FL_TIPO_ARQ', '2');

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL}/alteracao-cadastro/enviar-arquivo/${currentData.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('Resposta do servidor:', response.data);
      setSuccessMessage('Arquivo enviado com sucesso!');
      setShowSuccessModal(true);

      // Limpa o arquivo selecionado
      setSelectedFile(null);

      // Aguarda 2 segundos e recarrega a página
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
      setFeedbackMessage('Falha ao enviar arquivo.');
      setFeedbackError(true);
    } finally {
      setLoading(false);
    }
  };

  //FIM DA ALTERAÇÃO

  return (
    <div>
      <div className="header-configs">
        <div className="form-header">
          <IconButton
            onClick={() => setIsModalPesquisaOpen(true)}
            aria-label="buscar cadastro"
          >
            <SearchRoundedIcon />
          </IconButton>
          <ModalPesquisa
            isOpen={isModalPesquisaOpen}
            onRequestClose={() => setIsModalPesquisaOpen(false)}
            onSelectCadastro={handleSelectCadastro}
          />
          <div
            className="pendentes-header"
            onClick={() => setMostrarListaPendentes(!mostrarListaPendentes)}
          >
            <div
              className={
                cadastrosPendentes.length > 0 ? 'texto-vermelho' : 'texto-verde'
              }
            >
              {cadastrosPendentes.length} cadastros pendentes
            </div>
          </div>
          {mostrarListaPendentes && (
            <div className="modal-pendentes">
              <div className="modal-pendentes-conteudo">
                <span
                  className="modal-pendentes-fechar"
                  onClick={() => setMostrarListaPendentes(false)}
                >
                  &times;
                </span>
                <h2>Cadastros Pendentes</h2>
                <div className="lista-pendentes">
                  {cadastrosPendentes.map((cadastro) => (
                    <div
                      key={cadastro.id}
                      className="pendente-item"
                      onClick={() => {
                        // Torna todo o item clicável
                        setCurrentId(cadastro.id);
                        setMostrarListaPendentes(false);
                      }}
                      style={{ cursor: 'pointer' }} // Muda o cursor para indicar que é clicável
                    >
                      <span>ID: {cadastro.id}</span>
                      <span> Responsável: {cadastro.nome_resp}</span>
                      <span> Condomínio: {cadastro.nome_cond}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* Botão de Atualizar Dados */}
          <button
            onClick={atualizarDadosCadastro}
            className="atualizar-dados-btn"
          >
            Atualizar Dados
          </button>
          <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <IconButton
              onClick={() => setCurrentId(1)}
              aria-label="first-page"
            >
              <KeyboardDoubleArrowLeftIcon fontSize="small" />
            </IconButton>

            <IconButton
              onClick={handlePrevious}
              aria-label="previous"
            >
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
          </div>
          <div>
            <h2 className="selectedId">ID: {currentId}</h2>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
            <IconButton
              onClick={handleNext}
              aria-label="next"
            >
              <KeyboardArrowRightIcon fontSize="small" />
            </IconButton>

            {/* Add button to navigate to the last ID */}
            <IconButton
              onClick={() => setCurrentId(maxId)}
              aria-label="last-page"
            >
              <KeyboardDoubleArrowRightIcon fontSize="small" />
            </IconButton>
          </div>
          {/* Botões de Editar, Salvar e Cancelar */}
          {isEditing ? (
            <>
              {/* Botões de Cancelar e Salvar aparecem apenas no modo de edição */}
              <button
                type="button"
                className="button button-cancel"
                onClick={() => setIsEditing(!isEditing)}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="button button-save"
                onClick={updateData}
              >
                Salvar
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="button button-edit"
                onClick={() => setIsEditing(!isEditing)}
              >
                Editar
              </button>
              {/* Renderiza o botão Invalidar condicionalmente */}
              {currentData.status_cadastro !== 'Anulado' && (
                <button
                  onClick={() => {
                    setCurrentAction('invalidar');
                    setIsModalOpen(true);
                  }}
                  className="invalidar-dados-btn"
                >
                  Invalidar
                </button>
              )}
              {currentData.status_cadastro === 'Anulado' && (
                <button
                  onClick={() => {
                    setCurrentAction('pendente');
                    setIsModalOpen(true);
                  }}
                  className="pendente-dados-btn"
                >
                  Pendente
                </button>
              )}
            </>
          )}
          {/* SSI 0022 - 03/06/2024 */}

          <button
            onClick={() => {
              if (validarDados()) {
                setCurrentAction('enviar');
                setIsModalOpen(true);
              }
            }}
            className="enviar-dados-btn"
            disabled={isEditing}
          >
            Enviar Super Lógica
          </button>

          {/* FIM DA ALTERAÇÃO */}
          <button
            onClick={() => {
              if (validarDados()) {
                setShowEnviarEmailModal(true);
              }
            }}
            className="send-email-btn"
          >
            Enviar e-mail
          </button>
          <button
            onClick={() => setShowNotificarSindicoModal(true)}
            className="sindico-botao-email"
          >
            Notificar Síndico
          </button>
          <button
            onClick={() => setIsMostrarContatosModalOpen(true)}
            className="mostrar-contatos-botao"
          >
            Mostrar Contatos
          </button>
        </div>

        <div className="status-selection">
          <div className="status-display">
            <span
              className="status-circle"
              style={{
                backgroundColor:
                  currentData.status_cadastro === 'Anulado'
                    ? '#f91818'
                    : currentData.status_cadastro === 'Pendente'
                    ? '#f9df18'
                    : currentData.status_cadastro === 'Enviado'
                    ? '#54b869'
                    : 'grey',
              }}
            ></span>
            <span
              id="status_cadastro"
              className="status-texto-cadastro"
            >
              {currentData.status_cadastro}
              {currentData.status_cadastro === 'Enviado' &&
                sendInfoName &&
                sendInfoDate && (
                  <>
                    <span className="status-texto-cadastro">
                      {' '}
                      por {sendInfoName} em {sendInfoDate}
                    </span>
                  </>
                )}
            </span>
          </div>
          <div className="email-status-display">
            <span
              className="status-circle"
              style={{
                backgroundColor:
                  currentData.status_email === 'enviado'
                    ? '#54b869'
                    : '#f91818',
              }}
            ></span>
            <span className="status-texto-cadastro">
              Email {currentData.status_email}
            </span>
          </div>
          {/* Status de Notificação do Síndico */}
          <div className="sindico-status-display">
            <span
              className="status-circle"
              style={{
                backgroundColor:
                  currentData.status_sindico === 'notificado'
                    ? '#54b869'
                    : '#f91818',
              }}
            ></span>
            <span className="status-texto-cadastro">
              Síndico {currentData.status_sindico || 'Não Notificado'}
            </span>
          </div>
          <div className="arquivo-status-display">
            <span
              className="status-circle"
              style={{
                backgroundColor:
                  currentData.status_arquivo === 'enviado'
                    ? '#54b869'
                    : '#f91818',
              }}
            ></span>
            <span className="status-texto-cadastro">
              Arquivo {currentData.status_arquivo}
            </span>
          </div>
        </div>
      </div>

      {feedbackMessage && (
        <div
          className={`feedback-message ${feedbackError ? 'error' : 'success'}`}
        >
          {feedbackMessage}
        </div>
      )}

      {updateSuccess && (
        <div className="update-success-message">
          Dados atualizados com sucesso!
        </div>
      )}

      {currentData && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateData();
          }}
        >
          {/* Personal Info Section */}
          <div className="section">
            <div className="section-header">
              Dados do Cadastro
              {currentData.link_contrato && (
                <Tooltip title="Visualizar Contrato do Cadastro">
                  <IconButton
                    onClick={() => handleDownload(currentData.link_contrato)}
                    aria-label="ver-contrato"
                    className="link-contrato-icon"
                  >
                    <HistoryEduRoundedIcon style={{ fontSize: '20px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="fields-container">
              <div className="field">
                <label>{FIELD_LABELS['tipo_resp']}</label>
                <select
                  className="large-input"
                  value={currentData['tipo_resp'] || ''}
                  disabled={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      tipo_resp: e.target.value,
                    })
                  }
                >
                  {Object.entries(TIPO_RESP_OPTIONS).map(([value, label]) => (
                    <option
                      key={value}
                      value={value}
                    >
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="field">
                <label>{FIELD_LABELS['nome_resp']}</label>
                <input
                  type="text"
                  className="large-input"
                  value={currentData['nome_resp'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      nome_resp: e.target.value,
                    })
                  }
                />
              </div>
              <div className="field">
                <label>
                  {currentData['doc_tipo'] === 'cpf'
                    ? FIELD_LABELS['cpf']
                    : FIELD_LABELS['cnpj']}
                </label>
                <input
                  type="text"
                  className="small-input"
                  value={currentData[currentData['doc_tipo']] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      [currentData['doc_tipo']]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="field">
                <label>Data de Nascimento</label>
                <input
                  type="text" // Define o tipo de input como date para facilitar a inserção da data
                  className="small-input" // Utiliza a classe small para o estilo, conforme solicitado
                  value={currentData['data_nascimento'] || ''}
                  readOnly={!isEditing} // Garante que o campo só possa ser editado no modo de edição
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      data_nascimento: e.target.value,
                    })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['tel_resp']}</label>
                <input
                  type="text"
                  className="small-input"
                  value={currentData['tel_resp'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, tel_resp: e.target.value })
                  }
                />
              </div>
              {renderEmailFields()}
              <div className="field">
                <label>{FIELD_LABELS['cep']}</label>
                <input
                  type="text"
                  className="small-input"
                  value={currentData['cep'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, cep: e.target.value })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['endereco']}</label>
                <input
                  type="text"
                  className="large-input"
                  value={currentData['endereco'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, endereco: e.target.value })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['complemento']}</label>
                <input
                  type="text"
                  className="small-input"
                  value={currentData['complemento'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      complemento: e.target.value,
                    })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['bairro']}</label>
                <input
                  className="small-input"
                  type="text"
                  value={currentData['bairro'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, bairro: e.target.value })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['cidade']}</label>
                <input
                  className="small-input"
                  type="text"
                  value={currentData['cidade'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, cidade: e.target.value })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['estado']}</label>
                <select
                  className="smaller-input"
                  value={currentData['estado'] || ''}
                  disabled={!isEditing}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, estado: e.target.value })
                  }
                >
                  {Object.entries(ESTADO_OPTIONS).map(([value, label]) => (
                    <option
                      key={value}
                      value={value}
                    >
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="field">
                <label>Forma de Entrega</label>
                <select
                  className="large-input"
                  value={currentData.forma_entrega || ''}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      forma_entrega: e.target.value,
                    })
                  }
                  disabled={!isEditing}
                >
                  {Object.entries(FORMA_ENTREGA_OPTIONS).map(([key, label]) => (
                    <option
                      key={key}
                      value={key}
                    >
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              {/* Novo campo Tipo de Cobrança */}
              <div className="field">
                <label>Tipo de Cobrança</label>
                <select
                  className="large-input"
                  value={currentData.tipo_cobranca || ''}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      tipo_cobranca: e.target.value,
                    })
                  }
                  disabled={!isEditing}
                >
                  {Object.entries(tipoCobrancaOptions).map(([key, label]) => (
                    <option
                      key={key}
                      value={key}
                    >
                      {label}
                    </option>
                  ))}
                </select>
              </div>{' '}
              <div className="field">
                <label>Tipo de Identificação</label>
                <select
                  className="small-input"
                  value={currentData.tipo_contato || '1'} // Valor padrão para garantir que sempre haja um valor selecionado
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      tipo_contato: e.target.value,
                    })
                  }
                  disabled={!isEditing}
                >
                  {Object.entries(TIPO_IDENTIFICACAO_OPTIONS).map(
                    ([key, value]) => (
                      <option
                        key={key}
                        value={key}
                      >
                        {value}
                      </option>
                    )
                  )}
                </select>
              </div>
              {/* Campo status-item (Dados do cadastro verificados?) */}
              <div className="field">
                <label>
                  Dados do cadastro verificados?{' '}
                  <span className="required-asterisk">*</span>
                </label>
                <select
                  value={currentData.verificar_dados || ''}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      verificar_dados: e.target.value,
                    })
                  }
                  disabled={!isEditing}
                  className="large-input"
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              {/* Campo de Observação */}
              <div className="field observacao-container">
                <label htmlFor="observacao">Observação:</label>
                <textarea
                  id="observacao"
                  className="observacao-textarea" // Altere a classe conforme necessário para ajustar ao estilo dos outros campos
                  value={observacao || ''}
                  onChange={(e) => setObservacao(e.target.value)}
                  maxLength={255}
                  placeholder="Nenhuma"
                  disabled={!isEditing}
                ></textarea>
              </div>
            </div>

            {/* Condominium Info Section */}
            <div className="section-header">Informações da Unidade</div>
            <div className="fields-container">
              <div className="field">
                <label>{FIELD_LABELS['tipo_unid']}</label>
                <select
                  className="small-input"
                  value={currentData['tipo_unid'] || ''}
                  disabled={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      tipo_unid: e.target.value,
                    })
                  }
                >
                  {TIPO_UNID_OPTIONS.map((option) => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="field">
                <label>{FIELD_LABELS['nome_cond']}</label>
                <input
                  type="text"
                  className="large-input"
                  value={currentData['nome_cond'] || ''}
                  readOnly={!isEditing}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      nome_cond: e.target.value,
                    })
                  }
                />
              </div>
              <div className="field">
                <label>{FIELD_LABELS['cond_corresp']}</label>
                <select
                  className="larger-input"
                  value={currentData['cond_corresp'] || ''}
                  disabled={!isEditing}
                  onChange={(e) => {
                    const newCondominiumId = e.target.value;
                    // Set new condominium
                    setCurrentData({
                      ...currentData,
                      cond_corresp: newCondominiumId,
                    });
                    // Clear existing units and set loading true
                    setUnits([]);
                    setLoadingUnits(true);
                    // Fetch units for new condominium
                    fetchAllUnitsForNewCondominium(newCondominiumId);
                  }}
                >
                  <label>{FIELD_LABELS['cond_corresp']}</label>
                  <option value="">Selecione o Condomínio Corresponente</option>
                  {condominiums.map((condo) => (
                    <option
                      key={condo.id_condominio_cond}
                      value={condo.id_condominio_cond}
                    >
                      {condo.st_fantasia_cond}
                    </option>
                  ))}
                </select>
              </div>

              {currentData.tipo_unid === 'Vertical' ? (
                <>
                  <div className="field">
                    <label>{FIELD_LABELS['bloco']}</label>
                    <input
                      type="text"
                      className="smaller-input"
                      value={currentData['bloco'] || ''}
                      readOnly={!isEditing}
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          bloco: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label>{FIELD_LABELS['apto']}</label>
                    <input
                      type="text"
                      className="smaller-input"
                      value={currentData['apto'] || ''}
                      readOnly={!isEditing}
                      onChange={(e) =>
                        setCurrentData({ ...currentData, apto: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label>Unidade Correspondente</label>
                    <select
                      className="small-input"
                      disabled={
                        !isEditing || loadingUnits || units.length === 0
                      }
                      value={currentData.unid_corresp || ''}
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          unid_corresp: e.target.value,
                        })
                      }
                    >
                      <option value="">Selecione a Unidade</option>
                      {units.map((unit) => {
                        const unitLabel =
                          unit.st_bloco && unit.st_bloco.trim() !== ''
                            ? `${unit.st_unidade} - ${unit.st_bloco}`
                            : unit.st_unidade;

                        return (
                          <option
                            key={`${currentData.cond_corresp}-${unit.id_unidade}`} // Chave única baseada em condomínio + unidade
                            value={unit.id_unidade}
                          >
                            {unitLabel}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div className="field">
                    <label>{FIELD_LABELS['lote']}</label>
                    <input
                      type="text"
                      className="smaller-input"
                      value={currentData['lote'] || ''}
                      readOnly={!isEditing}
                      onChange={(e) =>
                        setCurrentData({ ...currentData, lote: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label>{FIELD_LABELS['quadra']}</label>
                    <input
                      type="text"
                      className="smaller-input"
                      value={currentData['quadra'] || ''}
                      readOnly={!isEditing}
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          quadra: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label>Unidade Correspondente</label>
                    <select
                      disabled={
                        !isEditing || loadingUnits || units.length === 0
                      }
                      value={currentData.unid_corresp || ''}
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          unid_corresp: e.target.value,
                        })
                      }
                    >
                      <option value="">Selecione a Unidade</option>
                      {units.map((unit) => {
                        const unitLabel =
                          unit.st_bloco && unit.st_bloco.trim() !== ''
                            ? `${unit.st_unidade} - ${unit.st_bloco}`
                            : unit.st_unidade;

                        return (
                          <option
                            key={`${currentData.cond_corresp}-${unit.id_unidade}`} // Chave única baseada em condomínio + unidade
                            value={unit.id_unidade}
                          >
                            {unitLabel}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Botões, Observação e Condicional Verificação de Dados agrupados */}
          <div className="actions-observacao-container">
            {/* Condicional Verificação de Dados */}
            <div className="status-container">
              {/*<div className="status-item">
                {renderStatusIcon(currentData.cond_corresp != null)}
                <span>Condomínio adicionado</span>
              </div>
              <div className="status-item">
                {renderStatusIcon(currentData.unid_corresp != null)}
                <span>Unidade adicionada</span> 
              </div>*/}
              {/* <div className="status-item">
                {renderStatusIcon(currentData.verificar_dados)}
                <label>Dados do cadastro verificados?</label>
                <select
                  value={currentData.verificar_dados}
                  onChange={handleVerificacaoChange}
                  disabled={!isEditing}
                  className="smaller-input"
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
            </div> */}
            </div>

            {/* Seção de Observação 
            <div className="observacao-container">
              <label htmlFor="observacao">Observação:</label>
              <textarea
                id="observacao"
                className="observacao-textarea"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                maxLength={255}
                placeholder="Digite sua observação aqui..."
                disabled={!isEditing}
              ></textarea>
            </div> */}
          </div>
          <div className="enviar-contrato-cadastro">
            <input
              type="file"
              onChange={handleFileChange}
            />
            <button
              onClick={enviarDadosComArquivo}
              disabled={loading}
            >
              Enviar Arquivo
            </button>
          </div>
        </form>
      )}
      <div className="status-cadastro-summary">
        <p>Anulados: {statusCounts.Anulado || 0}</p>
        <p>Pendentes: {statusCounts.Pendente || 0}</p>
        <p>Enviados: {statusCounts.Enviado || 0}</p>
      </div>
      <ConfirmarModal
        isOpen={isModalOpen}
        message="Tem certeza que deseja realizar esta ação?"
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
      />
      <SuccessModal
        isOpen={showSuccessModal}
        message={successMessage}
        onClose={() => setShowSuccessModal(false)}
      />
      <EnviarEmailModal
        isOpen={showEnviarEmailModal}
        onConfirm={() => {
          sendConfirmationEmail(); // Função que realiza o envio do e-mail
          setShowEnviarEmailModal(false); // Fecha o modal após a confirmação
        }}
        onCancel={() => setShowEnviarEmailModal(false)} // Fecha o modal sem enviar o e-mail
      />
      <NotificarSindicoModal
        isOpen={showNotificarSindicoModal}
        onConfirm={() => notificarSindico(currentId)}
        onCancel={() => setShowNotificarSindicoModal(false)}
      />
      <MostrarContatos
        isOpen={isMostrarContatosModalOpen}
        onRequestClose={() => setIsMostrarContatosModalOpen(false)}
        unidCorresp={currentData.unid_corresp}
        condCorresp={currentData.cond_corresp}
      />
    </div>
  );
};

export default AlterarTitularidade;
