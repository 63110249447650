/* ***********************************
Autor: Matheus Botelho
Data: 28/03/2024
Atividade: SSI 0002 - Automação Cadastro
Função: Modal para mostrar contatos e excluir moradores
************************************ */

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styles from './MostrarContatos.module.css';
import ConfirmarModal from '../Globais/ConfirmarModal'; // Ajuste o caminho conforme necessário
import SuccessModal from '../Globais/SuccessModal'; // Ajuste o caminho conforme necessário

Modal.setAppElement('#root');

const traduzirTipoResp = (idTipoResp) => {
  const tipos = {
    1: 'COBRANCA NORMAL E EXTRA',
    2: 'APENAS COBRANCAS EXTRA',
    4: 'NÃO RECEBER COBRANÇAS',
    7: 'APENAS COBRANCAS NORMAL',
  };
  return tipos[idTipoResp] || 'Tipo desconhecido';
};

const traduzirTipoResponsavel = (idLabelTres) => {
  const tiposResponsavel = {
    1: 'PROPRIETARIO RESIDENTE',
    2: 'PROPRIETARIO NÃO RESIDENTE',
    3: 'IMOBILIARIA',
    4: 'DEPENDENTE',
    5: 'EMPREGADO',
    6: 'VISITANTE',
    7: 'RESIDENTE',
    999: 'PROCURADOR',
  };
  return tiposResponsavel[idLabelTres] || 'Tipo desconhecido';
};

const MostrarContatos = ({
  isOpen,
  onRequestClose,
  unidCorresp,
  condCorresp,
}) => {
  const [contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [currentContactId, setCurrentContactId] = useState(null);
  const [deleteAction, setDeleteAction] = useState('single'); // 'single' ou 'all'

  useEffect(() => {
    const fetchContatos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.superlogica.net/v2/condor/unidades/index?idCondominio=${condCorresp}&exibirDadosDosContatos=1&id=${unidCorresp}`,
          {
            headers: {
              app_token: process.env.REACT_APP_APP_TOKEN,
              access_token: process.env.REACT_APP_ACCESS_TOKEN,
            },
          }
        );

        if (
          response.data &&
          response.data.length > 0 &&
          Array.isArray(response.data[0].contatos)
        ) {
          setContatos(response.data[0].contatos);
        } else {
          setContatos([]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar contatos:', error);
        setLoading(false);
        setContatos([]);
      }
    };

    if (isOpen) {
      fetchContatos();
    }
  }, [isOpen, unidCorresp, condCorresp]);

  const handleDeleteClick = (idContato, action = 'single') => {
    setCurrentContactId(idContato);
    setDeleteAction(action);
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    setIsConfirmModalOpen(false);
    if (deleteAction === 'single' && currentContactId) {
      await deleteContact(currentContactId);
      setIsSuccessModalOpen(true);
    } else if (deleteAction === 'all') {
      await deleteAllContacts();
      setIsSuccessModalOpen(true);
    }
  };

  const handleDeleteAllClick = () => {
    handleDeleteClick(null, 'all'); // Passa null como id e 'all' como ação
  };

  const deleteContact = async (idContato) => {
    try {
      const config = {
        method: 'post',
        url: `https://api.superlogica.net/v2/condor/contatos/delete?idUnidade=${unidCorresp}&idCondominio=${condCorresp}&idContato=${idContato}`,
        headers: {
          app_token: process.env.REACT_APP_APP_TOKEN,
          access_token: process.env.REACT_APP_ACCESS_TOKEN,
        },
      };
      const response = await axios(config);
      console.log('Contato excluído:', response.data);
      setContatos(
        contatos.filter((contato) => contato.id_contato_con !== idContato)
      );
    } catch (error) {
      console.error('Erro ao excluir contato:', error);
    }
  };

  const deleteAllContacts = async () => {
    for (let contato of contatos) {
      await deleteContact(contato.id_contato_con);
    }
    setContatos([]); // Limpa a lista de contatos após todos serem excluídos
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={styles.modalOverlayMostrarContatos}
      className={styles.modalContentMostrarContatos}
    >
      <h2>Contatos da Unidade</h2>
      {loading ? (
        <p>Carregando contatos...</p>
      ) : contatos.length > 0 ? (
        <div className={styles.contactListContainer}>
          <ul>
            {contatos.map((contato) => (
              <li
                key={contato.id_contato_con}
                className={styles.contactItem}
              >
                <div className={styles.contactInfo}>
                  <div className={styles.campoInformacaoContatoUnidade}>
                    <strong>Nome:</strong> {contato.st_nome_con}
                  </div>
                  <div className={styles.campoInformacaoContatoUnidade}>
                    <strong>Tipo de Cobrança:</strong>{' '}
                    {traduzirTipoResp(contato.id_tiporesp_tres)}
                  </div>
                  <div className={styles.campoInformacaoContatoUnidade}>
                    <strong>Tipo de Responsável:</strong>{' '}
                    {traduzirTipoResponsavel(contato.id_label_tres)}
                  </div>
                </div>
                <button
                  onClick={() => handleDeleteClick(contato.id_contato_con)}
                  className={styles.deleteButtonContato}
                >
                  Excluir
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Nenhum contato encontrado.</p>
      )}
      <div className={styles.modalButtonsMostrarContatos}>
        <button
          className={styles.deleteAllButton}
          onClick={handleDeleteAllClick}
        >
          Excluir Tudo
        </button>
        <button
          className={styles.cancelButtonMostrarContatos}
          onClick={onRequestClose}
        >
          Fechar
        </button>
      </div>
      <ConfirmarModal
        isOpen={isConfirmModalOpen}
        message={`Tem certeza que deseja excluir ${
          deleteAction === 'all' ? 'todos os contatos' : 'este contato'
        }?`}
        onConfirm={confirmDelete}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        message="Contato(s) excluído(s) com sucesso!"
        onClose={() => setIsSuccessModalOpen(false)}
      />
    </Modal>
  );
};

export default MostrarContatos;
