import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './InativarUsuario.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import { TextField, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function InativarUsuario() {
  const [usuarios, setUsuarios] = useState([]);
  const [order, setOrder] = useState("asc");
  const [areasUnicas, setAreasUnicas] = useState([]);
  const [filtroArea, setFiltroArea] = useState("");
  const [filtroSituacao, setFiltroSituacao] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  //SSI 0052 - GABRIEL - 03/09/24
  // INICIO
  useEffect(() => {
    let url = `${API_URL}/inativar-usuario/usuarios/filtrar?order=${order}`;
    
    if (filtroArea) {
      url += `&area=${filtroArea}`;
    }
    if (filtroSituacao) {
      url += `&situacao=${filtroSituacao}`;
    }

    axios
      .get(url)
      .then((res) => {
        setUsuarios(res.data);

        if (!filtroArea && !filtroSituacao) {
          const areas = [...new Set(res.data.flatMap((usuario) => 
            usuario.area.split(',').map(area => area.trim())
          ))];
          setAreasUnicas(areas);
        }
      })
// FIM
      .catch((err) => console.log(err));
  }, [API_URL, order, filtroArea, filtroSituacao]);

/* ***********************************
    Autor: Leo Correa
    Data: 22/03/24
    Atividade: Filtro para inativar 
    Função:Adicionado filtro para filtrar se um usuario que vai ser inativado possui condominios em seu nome antes de ser inativado 
    ************************************ */

    const inativarUsuario = (id_func) => {
      // Primeiro, verifica se há condomínios pendentes
      axios
        .get(
          `${API_URL}/inativar-usuario/usuarios/verificar-condominios/${id_func}`
        )
        .then((res) => {
          if (res.data.temPendencias) {
            // alteração colle
            toast.warn(
              "Usuário contém condomínios em seu nome, por favor transfira o condomínio antes de inativá-lo."
            );
          } else {
            // Se não há pendências, procede com a inativação
            axios
              .put(`${API_URL}/inativar-usuario/usuarios/inativar/${id_func}`)
              .then((res) => {
                // alteração colle
                toast.success(res.data);
                setUsuarios(
                  usuarios.map((usuario) =>
                    usuario.id_func === id_func
                      ? { ...usuario, situacao: "Inativo" }
                      : usuario
                  )
                );
              })
              .catch((err) => {
                console.log(err);
                toast.error('Erro ao inativar o usuário.');
              });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao verificar condomínios pendentes.');
        });
    };
  
    //  FIM ALTERAÇÃO - 22/03 - Filtro para inativar //

    const ativarUsuario = (id_func) => {
      axios
        .put(`${API_URL}/inativar-usuario/usuarios/ativar/${id_func}`)
        .then((res) => {
          toast.success(res.data);
          setUsuarios(
            usuarios.map((usuario) =>
              usuario.id_func === id_func
                ? { ...usuario, situacao: 'Ativo' }
                : usuario
            )
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao ativar o usuário.');
        });
    };
    

  //SSI 0052 - GABRIEL - 03/09/24
  // INICIO
  const filtrarUsuarios = (situacao) => {
    setFiltroSituacao(situacao);
  };

  const filtrarAreas = (area) => {
    setFiltroArea(area);
  };

  const handleBuscaNome = (e) => {
    const nome = e.target.value;
    axios
      .get(`${API_URL}/inativar-usuario/usuarios/nome?nome=${nome}`)
      .then((res) => setUsuarios(res.data))
      .catch((err) => console.log(err));
  };
  // FIM

  return (
    <div className={styles.inativarUsuarioContainer}>
      <div className={styles.inativarUsuarioContent}>
        <div className={styles.inativarUsuarioTitle}>Lista de Usuários</div>
        <table>
          <thead>
            {/* SSI 0052 - GABRIEL - 03/09/24 */} 
            {/* INICIO */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '-10px',
              marginBottom: '20px',
              width: '200%',
            }}
          >
            <TextField
              label="Digite o nome"
              variant="outlined"
              sx={{ 
                marginRight: '35px', 
                width: '400px'
              }}
              onChange={handleBuscaNome}
            />
          </Box>
            <tr>
              <th>ID</th>
              <th>
                <div className={styles.orderContainer}>
                  <IconButton size="large"
                    onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                    className={styles.orderButton}
                  >
                    {order === "asc" ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    )}
                  </IconButton>
                  Nome
                </div>
              </th>
              <th>
                <div className={styles.filters}>
                  <select onChange={(e) => filtrarAreas(e.target.value)} className={styles.customSelect}>
                    <option value="">Todos</option>
                    {areasUnicas.map((area, index) => (
                      <option key={index} value={area}>
                        {area}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>Situação</th>
              <th>
                <div className={styles.filters}>
                  <select
                    onChange={(e) => filtrarUsuarios(e.target.value)}
                    className={styles.customSelect}
                  >
                    <option value="">Todos</option>
                    <option value="Ativo">Ativo</option>
                    <option value="Inativo">Inativo</option>
                  </select>
                </div>
              </th>
              {/* FIM */}
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr key={usuario.id_func}>
                <td>{usuario.id_func}</td>
                <td>{usuario.nome}</td>
                <td>{usuario.area}</td>
                <td>{usuario.situacao}</td>
                <td>
                  {usuario.situacao === 'Inativo' ? (
                    <button
                      className={styles.activateButton}
                      onClick={() => ativarUsuario(usuario.id_func)}
                    >
                      Ativar
                    </button>
                  ) : (
                    <button
                      className={styles.inactivateButton}
                      onClick={() => inativarUsuario(usuario.id_func)}
                    >
                      Inativar
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default InativarUsuario;
