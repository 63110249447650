/* ***********************************
Autor: Matheus Botelho
Data: 08/04/2024
Atividade: Modal de Sucesso
Função: Padronizar o modal de confirmação
************************************ */

import React from 'react';
import styles from './ConfirmarModal.module.css'; // Adicione estilos conforme necessário

const ConfirmarModal = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <p>{message}</p>
        <div className={styles.modalButtons}>
          <button
            onClick={onConfirm}
            className={styles.confirmButton}
          >
            Confirmar
          </button>
          <button
            onClick={onCancel}
            className={styles.cancelButton}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmarModal;
