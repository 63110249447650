import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { SERVER_URL } from "../../config";
import styles from "./UserRegistrationPage.module.css";
import PasswordChecker from "../PasswordChecker";
import TogglePasswordButton from "../TogglePw";

const schema = yup.object().shape({
	id_func: yup.number().required("Campo obrigatório"),
	nome: yup.string().required("Campo obrigatório"),
	user: yup.string().required("Campo obrigatório"),
	email: yup.string().email("Email inválido").required("Campo obrigatório"),
	cidade_sede: yup.string().required("Campo obrigatório"),
	telefone: yup.string().required("Campo obrigatório"),
	nascimento: yup.date().required("Campo obrigatório"),
	senha: yup
		.string()
		.matches(
			/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{7,}$/,
			"A senha deve ter pelo menos 7 caracteres, 1 letra maiúscula, 1 número e 1 caractere especial."
		)
		.required("Campo obrigatório"),
	area: yup
		.array()
		.min(1, "Selecione ao menos uma área")
		.required("Campo obrigatório"),
	funcao: yup.string().required("Campo obrigatório"),
	usuario_cidade: yup.string().required("Campo obrigatório"),
	advogado_externo: yup.string().required("Campo obrigatório"),
});

const UserRegistrationPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		setMessage("");
	}, []);

	const [message, setMessage] = useState("");
	const [values, setValues] = useState({
		id_func: "",
		nome: "",
		user: "",
		email: "",
		cidade_sede: "",
		telefone: "",
		nascimento: "",
		senha: "",
		confirmar_senha: "",
		area: [],
		funcao: "",
		usuario_cidade: "",
		advogado_externo: "",
	});

	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (type === "checkbox" && name === "area") {
			setValues((prevValues) => {
				const newAreas = [...prevValues.area];
				if (checked) {
					newAreas.push(value);
				} else {
					const index = newAreas.indexOf(value);
					if (index > -1) {
						newAreas.splice(index, 1);
					}
				}
				return { ...prevValues, area: newAreas };
			});
		} else {
			setValues((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	useEffect(() => {
		if (values.area.includes("Diretoria")) {
			setValues((prevValues) => ({
				...prevValues,
				funcao: "admin",
			}));
		} else {
			setValues((prevValues) => ({
				...prevValues,
				funcao: "",
			}));
		}
	}, [values.area]);

	useEffect(() => {
		axios
			.get(`${SERVER_URL}/misc/last-id`)
			.then((response) => {
				setValues((prevValues) => ({
					...prevValues,
					id_func: response.data.id_func + 1,
				}));
			})
			.catch((err) => {
				console.error("Erro ao buscar o último ID:", err);
			});
	}, [setValues]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			await schema.validate(values, { abortEarly: false });
			const { data: userData } = await axios.get(
				`${SERVER_URL}/user/check-user-registration`,
				{ params: { user: values.user } }
			);

			if (userData.exists) {
				setErrors({ user: "Usuário já existe" });
				setIsLoading(false);
				return;
			}

			const response = await axios.post(
				`${SERVER_URL}/user/create-user`,
				values
			);

			if (response.data.success) {
				setMessage(`Usuário ${values.user} cadastrado com sucesso!`);
				setTimeout(() => {
					setValues({
						id_func: "",
						nome: "",
						user: "",
						email: "",
						cidade_sede: "",
						telefone: "",
						nascimento: "",
						senha: "",
						area: [],
						funcao: "",
						usuario_cidade: "",
						advogado_externo: "",
					});
					setMessage("");
					setIsLoading(false);
					navigate("/cadastrousuario");
				}, 2000);
			} else {
				setMessage("Não foi possível cadastrar o novo usuário");
				setIsLoading(false);
			}
		} catch (err) {
			console.error("Erro no processo de submissão:", err);

			if (err instanceof yup.ValidationError) {
				const errors = {};
				err.inner.forEach((error) => {
					errors[error.path] = error.message;
				});
				setErrors(errors);
			}
			setIsLoading(false);
		}
	};

	const handleAreaClick = (areaName) => {
		setValues((prevValues) => {
			const newAreas = [...prevValues.area];
			if (newAreas.includes(areaName)) {
				const index = newAreas.indexOf(areaName);
				newAreas.splice(index, 1);
			} else {
				newAreas.push(areaName);
			}
			return { ...prevValues, area: newAreas };
		});
	};

	const LoadingOverlay = () => (
		<div className={styles.loadingOverlay}>
			<div className={styles.loadingSpinner}></div>
		</div>
	);

	return (
		<div className={styles.userRegistrationParent}>
			{isLoading && <LoadingOverlay />}
			<div className={styles.userRegistrationDiv1}>Cadastro de Usuários</div>
			<div className={styles.userRegistrationDiv2}>
				<div className={styles.userRegistrationGrid}>
					<div className={styles.inputGroup}>
						<label htmlFor="nome">Nome Completo</label>
						<input
							type="text"
							name="nome"
							id="nome"
							value={values.nome}
							onChange={handleChange}
							placeholder="ex. Carlos Silva"
						/>
						{errors.nome && (
							<span className={styles.userRegistrationError}>
								{errors.nome}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="user">Usuário</label>
						<input
							type="text"
							name="user"
							id="user"
							value={values.user}
							onChange={handleChange}
							placeholder="ex. CSILVA"
						/>
						{errors.user && (
							<span className={styles.userRegistrationError}>
								{errors.user}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="email">Email</label>
						<input
							type="email"
							name="email"
							id="email"
							value={values.email}
							onChange={handleChange}
							placeholder="ex. carlos.silva@example.com"
						/>
						{errors.email && (
							<span className={styles.userRegistrationError}>
								{errors.email}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="cidade_sede">Cidade Sede</label>
						<select
							name="cidade_sede"
							id="cidade_sede"
							value={values.cidade_sede}
							onChange={handleChange}
						>
							<option value="" disabled>
								Selecione uma cidade
							</option>
							<option value="Balneário Camboriu">Balneário Camboriu</option>
							<option value="Campinas">Campinas</option>
							<option value="Campo Grande">Campo Grande</option>
							<option value="Curitiba">Curitiba</option>
							<option value="Londrina">Londrina</option>
							<option value="Maringá">Maringá</option>
							<option value="Presidente Prudente">Presidente Prudente</option>
							<option value="Paranavaí">Paranavaí</option>
							<option value="Teresina">Teresina</option>
						</select>
						{errors.cidade_sede && (
							<span className={styles.userRegistrationError}>
								{errors.cidade_sede}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="nascimento">Nascimento</label>
						<input
							type="date"
							name="nascimento"
							id="nascimento"
							value={values.nascimento}
							onChange={handleChange}
						/>
						{errors.nascimento && (
							<span className={styles.userRegistrationError}>
								{errors.nascimento}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="telefone">Telefone com DDD</label>
						<input
							type="tel"
							name="telefone"
							id="telefone"
							value={values.telefone}
							onChange={handleChange}
							placeholder="ex. (11) 99999-9999"
						/>
						{errors.telefone && (
							<span className={styles.userRegistrationError}>
								{errors.telefone}
							</span>
						)}
					</div>

					<div className={`${styles.inputGroup} ${styles.fullWidth}`}>
						<label>Área</label>
						<div className={styles.userRegistrationColumns}>
							{[
								"Assembleias",
								"Cadastro",
								"Cobrança",
								"Financeiro",
								"Jurídico",
								"RH",
								"Seguros",
							].map((areaName) => (
								<div
									className={`${styles.userRegistrationCheckboxes} ${
										values.area.includes(areaName) ? styles.selected : ""
									}`}
									key={areaName}
									onClick={() => handleAreaClick(areaName)}
								>
									<input
										type="checkbox"
										name="area"
										value={areaName}
										checked={values.area.includes(areaName)}
										onChange={handleChange}
									/>
									<label>{areaName}</label>
								</div>
							))}
						</div>
						{errors.area && (
							<span className={styles.userRegistrationError}>
								{errors.area}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="funcao">Função</label>
						<select
							name="funcao"
							id="funcao"
							value={values.funcao}
							onChange={handleChange}
						>
							<option value="" disabled>
								Selecione uma opção
							</option>
							{values.area.includes("Diretoria") ? (
								<option value="admin">admin</option>
							) : (
								<>
									<option value="Gestor">Gestor</option>
									<option value="Agente">Agente</option>
								</>
							)}
						</select>
						{errors.funcao && (
							<span className={styles.userRegistrationError}>
								{errors.funcao}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="advogado_externo">Advogado Externo?</label>
						<select
							name="advogado_externo"
							id="advogado_externo"
							value={values.advogado_externo}
							onChange={handleChange}
						>
							<option value="" disabled>
								Selecione uma opção
							</option>
							<option value="Não">Não</option>
							<option value="Sim">Sim</option>
						</select>
						{errors.advogado_externo && (
							<span className={styles.userRegistrationError}>
								{errors.advogado_externo}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="usuario_cidade">Usuário Cidade?</label>
						<select
							name="usuario_cidade"
							id="usuario_cidade"
							value={values.usuario_cidade}
							onChange={handleChange}
						>
							<option value="" disabled>
								Selecione uma opção
							</option>
							<option value="Não">Não</option>
							<option value="Sim">Sim</option>
						</select>
						{errors.usuario_cidade && (
							<span className={styles.userRegistrationError}>
								{errors.usuario_cidade}
							</span>
						)}
					</div>

					<div className={styles.inputGroup}>
						<label htmlFor="senha">Senha</label>
						<div className={styles.userRegistrationBox}>
							<div className={styles.userRegistrationInputBox}>
								<input
									type={showPassword ? "text" : "password"}
									name="senha"
									id="senha"
									value={values.senha}
									onChange={handleChange}
								/>
								<TogglePasswordButton
									showPassword={showPassword}
									setShowPassword={setShowPassword}
								/>
							</div>
							<PasswordChecker senha={values.senha} />
							{errors.senha && (
								<span className={styles.userRegistrationError}>
									{errors.senha}
								</span>
							)}
						</div>
					</div>

					<div className={styles.userRegistrationMessage}>{message}</div>
				</div>
			</div>

			<div className={styles.userRegistrationDiv3}>
				<div className={styles.profilePhotoContainer}>
					<h3>Foto do Usuário</h3>
					<div className={styles.profilePhotoPlaceholder}>
						<img src="placeholder.png" alt="" className={styles.profilePhoto} />
						<p className={styles.photoText}>Foto de Perfil</p>
						<p>Em breve: Adicionar/Excluir Foto</p>
					</div>
				</div>
			</div>

			<div className={styles.userRegistrationDiv4}></div>

			<div className={styles.userRegistrationDiv5}>
				<button
					className={styles.userRegistrationButton}
					onClick={handleSubmit}
				>
					Cadastrar
				</button>
			</div>
		</div>
	);
};

export default UserRegistrationPage;
