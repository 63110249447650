import React from 'react';
import ChartTotalInadimplencia from './ChartTotalInadimplencia';

function DashboardInadimplencia() {
  return (
    <div>
      <ChartTotalInadimplencia />
    </div>
  );
}

export default DashboardInadimplencia;
