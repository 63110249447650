import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import {
  Box,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import AddIcon from "@mui/icons-material/Add";
import Popover from "@mui/material/Popover";
import Autocomplete from "@mui/material/Autocomplete";
import "./EfetuarAcordo.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const EfetuarAcordo = () => {
  //console.log("Componente EfetuarAcordo montado");
  const [searchParams] = useSearchParams();
  const unidade = searchParams.get("unidade");
  const idUnidade = searchParams.get("idUnidade");
  const idsCobrancas = searchParams.get("idsCobrancas")
    ? searchParams.get("idsCobrancas").split(",")
    : [];
  const totalDevedor = searchParams.get("totalDevedor");
  const [detalhesCobrancas, setDetalhesCobrancas] = useState([]);
  const idCondominio = searchParams.get("idCondominio");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [honorario, setHonorario] = useState("taxaConvencionada");
  const [valorHonorario, setValorHonorario] = useState("10"); // 10% como padrão
  const [taxaAdministradora, setTaxaAdministradora] = useState("naoCobrar"); // Não cobrar como padrão
  const [valorTaxaAdministradora, setValorTaxaAdministradora] = useState("");
  const [tipoRecebimentoTaxa, setTipoRecebimentoTaxa] =
    useState("todasParcelas");
  const detalhesCobrancasAnterioresRef = useRef();
  const [total, setTotal] = useState(0);
  const [taxaJuros, setTaxaJuros] = useState(1);
  const [taxaMulta, setTaxaMulta] = useState(2);
  const [desconto, setDesconto] = useState(0);
  const [formaRecebimento, setFormaRecebimento] = useState(
    "Escolhida pelo cliente"
  );
  const [contaBancaria, setContaBancaria] = useState("");
  const [registrarCRM, setRegistrarCRM] = useState(false);
  const [editarTaxas, setEditarTaxas] = useState(false);
  const [dadosApiPaginaInicial, setDadosApiPaginaInicial] = useState({});
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // Pode ser "error", "success", etc.
  const [carregandoDados, setCarregandoDados] = useState(false);

  // Esta função `useEffect` mantém a referência dos dados anteriores de detalhesCobrancas
  useEffect(() => {
    detalhesCobrancasAnterioresRef.current = detalhesCobrancas;
  }, [detalhesCobrancas]);

  useEffect(() => {
    const buscarDadosCobrancas = async () => {
      if (idsCobrancas.length === 0) {
        //console.log("Sem IDs de cobranças para buscar.");
        return;
      }

     /*console.log(
        "Iniciando busca de dados das cobranças para idCondominio:",
        idCondominio,
        "e idsCobrancas:",
        idsCobrancas
      ); */
      const detalhesCobrancasAtualizados = await processarDadosCobrancas();

      if (
        !saoIguais(
          detalhesCobrancasAtualizados,
          detalhesCobrancasAnterioresRef.current
        )
      ) {
       // console.log("Dados das cobranças atualizados. Atualizando estado.");
        setDetalhesCobrancas(detalhesCobrancasAtualizados);
      } else {
       // console.log("Dados das cobranças não mudaram.");
      }
    };

    buscarDadosCobrancas();
  }, []); // Array de dependências vazio

  const saoIguais = (dados1, dados2) => {
   // console.log("Iniciando comparação dos arrays.");

    // Verificar se os tamanhos são diferentes
    if (dados1.length !== dados2.length) {
     /* console.log(
        `Os arrays têm tamanhos diferentes: dados1.length = ${dados1.length}, dados2.length = ${dados2.length}`
      ); */
      return false;
    }

    // Comparar elementos relevantes
    for (let i = 0; i < dados1.length; i++) {
      const item1 = dados1[i];
      const item2 = dados2[i];

      // Compare as propriedades relevantes. Exemplo:
      if (
        item1.idCobranca !== item2.idCobranca ||
        item1.total_receita !== item2.total_receita ||
        item1.st_conta_cont !== item2.st_conta_cont
      ) {
       // console.log(`Elementos de índice ${i} são diferentes.`);
       // console.log("Item 1:", item1);
       // console.log("Item 2:", item2);
        return false;
      }
    }

   // console.log("Os arrays são iguais.");
    return true;
  };

  const handleOpenModal = () => {
    // Coletar as cobranças selecionadas
    const cobrancasSelecionadas = detalhesCobrancas.filter(
      (cobranca) => selecionadas[cobranca.idCobranca]
    );

    // Verificar se alguma das cobranças selecionadas está em processo judicial ou já teve acordo
    const possuiRestricoes = cobrancasSelecionadas.some(
      (cobranca) => cobranca.emProcessoJudicial || cobranca.acordoFeito
    );

    if (possuiRestricoes) {
      alert(
        "Não é possível parcelar cobranças que estão em processo judicial ou já tiveram acordo feito."
      );
      return; // Interrompe a função se existirem restrições
    }

    // Definindo valores padrões ao abrir o modal
    setValorHonorario("10"); // Valor padrão para 10% ao abrir o modal
    setTaxaAdministradora("naoCobrar"); // Definir para não cobrar ao abrir o modal
    setTipoRecebimentoTaxa("todasParcelas"); // Definir para receber em todas as parcelas ao abrir o modal

   // console.log("Abrindo modal com configurações padrão");

    // Preparar os dados para a API
    const dadosApi = {
      TIPO_ACORDO: 0,
      ID_UNIDADE_UNI: idUnidade,
      ID_CONDOMINIO_COND: idCondominio,
      PRIORIZARHONORARIOS: "0",
      COBS_ORIGEM: cobrancasSelecionadas.map((cobranca) => ({
        ID_RECEBIMENTO_RECB: cobranca.idCobranca,
        VL_ACORDOJUROS_RECB: cobranca.juros.toFixed(2),
        VL_ACORDOMULTA_RECB: cobranca.multa.toFixed(2),
        VL_ACORDOATUALIZACAO_RECB: cobranca.atualizacao.toFixed(2),
        VL_ACORDOHONORARIOS_RECB: cobranca.honorarios.toFixed(2),
        VL_ACORDODESCONTO_RECB: "0",
        VL_ACORDOCUSTOS_RECB: "0",
      })),
    };

   // console.log("Dados preparados para a API:", dadosApi);

    // Armazenar no estado como um objeto normal
    setDadosApiPaginaInicial(dadosApi);

    // Abrir o modal
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setPaginaModal(1); // Redefinir para a primeira página
    setSelecionadas({}); // Limpar as cobranças selecionadas
    setIsModalOpen(false);
  };

  const handleHonorarioChange = (event) => {
    setHonorario(event.target.value);
    //console.log("Honorário atualizado para:", event.target.value); // Para depuração
  };
  const handleValorHonorarioChange = (event) =>
    setValorHonorario(event.target.value);
  const handleTaxaAdministradoraChange = (event) =>
    setTaxaAdministradora(event.target.value);
  const handleValorTaxaAdministradoraChange = (event) =>
    setValorTaxaAdministradora(event.target.value);
  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = data.getDate().toString().padStart(2, "0");
    const mes = (data.getMonth() + 1).toString().padStart(2, "0");
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };
  const [escritorioCobranca, setEscritorioCobranca] = useState(""); // Adicione este estado
  const handleEscritorioCobrancaChange = (event) => {
    setEscritorioCobranca(event.target.value); // Função para manipular a mudança de escritório
  };
  const handleAddEscritorio = () => {
    // Adicione a lógica para adicionar um novo escritório de cobrança
  };

 /* console.log("Parâmetros recebidos:", {
    unidade,
    idsCobrancas,
    totalDevedor,
    idCondominio,
  }); */

  // Função para ajustar a porcentagem
  const handlePorcentagemChange = (e) => {
    const novaPorcentagem = Math.max(17, Number(e.target.value));
    setPorcentagem(novaPorcentagem);
  };
  const [selecionadas, setSelecionadas] = useState({});

  const processarDadosCobrancas = async () => {
    try {
      const url = `/api/efetuar-acordos/buscar-detalhes-cobrancas/${idCondominio}`;
      const resposta = await fetch(url);
      const dados = await resposta.json();
     // console.log("Dados recebidos da API:", dados);

      const todosRecebimentos = dados.flatMap((dado) => dado.recebimento);

      let totalJuros = 0,
        totalMultas = 0,
        totalHonorarios = 0;

      const detalhesCobrancasFiltradas = idsCobrancas
        .map((idCobranca) => {
          const cobranca = todosRecebimentos.find(
            (item) => item.id_recebimento_recb === idCobranca
          );
          if (
            !cobranca ||
            !cobranca.encargos ||
            cobranca.encargos.length === 0
          ) {
            console.error(
              `Cobrança com ID ${idCobranca} não encontrada ou sem encargos.`
            );
            return null;
          }

          const encargosDetalhes = cobranca.encargos[0].detalhes;
          if (selecionadas[cobranca.id_recebimento_recb]) {
            totalJuros += Number(encargosDetalhes.juros) || 0;
            totalMultas += Number(encargosDetalhes.multa) || 0;
            totalHonorarios += Number(encargosDetalhes.honorarios) || 0;
          }

          return {
            idCobranca: cobranca.id_recebimento_recb,
            vencimento: cobranca.dt_vencimento_recb
              ? formatarData(cobranca.dt_vencimento_recb)
              : "",
            principal: Number(cobranca.vl_emitido_recb) || 0,
            multa: Number(encargosDetalhes.multa) || 0,
            juros: Number(encargosDetalhes.juros) || 0,
            atualizacao: Number(encargosDetalhes.atualizacaomonetaria) || 0,
            honorarios: Number(encargosDetalhes.honorarios) || 0,
            atualizado: Number(cobranca.encargos[0].valorcorrigido) || 0,
            emProcessoJudicial:
              cobranca.id_processo_proc &&
              cobranca.id_processo_proc.trim() !== "",
            acordoFeito: cobranca.st_label_recb === "ACORDO",
          };
        })
        .filter((cobranca) => cobranca != null);

      return detalhesCobrancasFiltradas;
    } catch (erro) {
      console.error("Erro ao buscar dados do backend para as cobranças:", erro);
    }
  };

  // Função para gerar o conteúdo do exemplo da tabela
  const renderTabelaExemplo = () => {
    return (
      <Box>
        {/* Adicionando o resumo do acordo acima da tabela */}
        <Typography variant="body1" sx={{ mb: 2 }}>
          Cotas mês: R$ 5000,00
          <br />
          Taxas de cobrança: R$ 1000,00
          <br />
          Total do acordo: R$ 6000,00
        </Typography>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 300 }}
            size="small"
            aria-label="tabela de exemplo"
          >
            <TableHead>
              <TableRow>
                <TableCell>Parcela</TableCell>
                <TableCell align="right">Cotas mês</TableCell>
                <TableCell align="right">Taxas de cobrança</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Geração dinâmica das linhas da tabela com alternância de cores */}
              {[...Array(10).keys()].map((_, index) => (
                <TableRow
                  key={index}
                  sx={{ bgcolor: index % 2 === 0 ? "white" : "#f2f2f2" }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">500,00</TableCell>
                  <TableCell align="right">100,00</TableCell>
                  <TableCell align="right">600,00</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // Função para calcular os valores de cada parcela
  const calcularValoresParcelas = (
    porcentagem,
    totalCotasMes,
    totalTaxasCobranca,
    totalAcordo
  ) => {
    const parcelas = [];
    const totalParcelas = 10; // Número total de parcelas
    const valorParcela = totalAcordo / totalParcelas;
    let saldoTaxasCobranca = totalTaxasCobranca; // Saldo total da taxa de cobrança
    let valorTaxaPorParcela = valorParcela * (porcentagem / 100); // Valor da taxa de cobrança por parcela

    for (let i = 0; i < totalParcelas; i++) {
      let valorTaxaCobranca, valorCotasMes;

      if (saldoTaxasCobranca > 0) {
        valorTaxaCobranca = Math.min(
          valorTaxaPorParcela,
          saldoTaxasCobranca,
          valorParcela
        );
        saldoTaxasCobranca -= valorTaxaCobranca;
        valorCotasMes = valorParcela - valorTaxaCobranca;
      } else {
        valorTaxaCobranca = 0;
        valorCotasMes = valorParcela;
      }

      parcelas.push({
        parcela: i + 1,
        cotasMes: valorCotasMes,
        taxaCobranca: valorTaxaCobranca,
        total: valorParcela,
      });
    }

    return parcelas;
  };

  // Função para renderizar o conteúdo do exemplo
  const renderConteudoExemplo = () => {
    const parcelas = calcularValoresParcelas(porcentagem, 5000, 1000, 6000);

    return (
      <Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Cotas mês: R$ 5000,00
          <br />
          Taxas de cobrança: R$ 1000,00
          <br />
          Total do acordo: R$ 6000,00
        </Typography>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 300 }}
            size="small"
            aria-label="tabela de exemplo"
          >
            <TableHead>
              <TableRow>
                <TableCell>Parcela</TableCell>
                <TableCell align="right">Cotas mês</TableCell>
                <TableCell align="right">Taxas de cobrança</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelas.map(({ parcela, cotasMes, taxaCobranca, total }) => (
                <TableRow
                  key={parcela}
                  sx={{ bgcolor: parcela % 2 === 0 ? "white" : "#f2f2f2" }}
                >
                  <TableCell component="th" scope="row">
                    {parcela}
                  </TableCell>
                  <TableCell align="right">{cotasMes.toFixed(2)}</TableCell>
                  <TableCell align="right">{taxaCobranca.toFixed(2)}</TableCell>
                  <TableCell align="right">{total.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const processarDetalhesCobrancas = (dados) => {
    return dados
      .flatMap((dado) =>
        dado.receita_apropriacao.map((item) => ({
          contaCategoria: `${item.st_conta_cont} - ${item.st_descricao_cont}`,
          complemento: item.st_complemento_rec || "",
          valor: item.vl_valor_rec,
        }))
      )
      .sort((a, b) => a.contaCategoria.localeCompare(b.contaCategoria));
  };

  // Estado e funções para o popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickExemplo = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Estados para o popover da segunda opção
  const [porcentagem, setPorcentagem] = useState(100);
  const [anchorElSegundaOpcao, setAnchorElSegundaOpcao] = useState(null);

  const handleClickExemploSegundaOpcao = (event) => {
    setAnchorElSegundaOpcao(event.currentTarget);
  };

  const handleCloseSegundaOpcao = () => {
    setAnchorElSegundaOpcao(null);
  };

  const openSegundaOpcao = Boolean(anchorElSegundaOpcao);
  const idSegundaOpcao = openSegundaOpcao ? "segunda-opcao-popover" : undefined;

  const handleCheckboxChange = (idCobranca, isChecked) => {
    setSelecionadas((prev) => ({
      ...prev,
      [idCobranca]: isChecked,
    }));
  };

  // Adicionar um novo estado para controlar a página do modal
  const [paginaModal, setPaginaModal] = useState(1);

  const handleNext = async () => {
   // console.log("Clicado em Próximo na Primeira Página");

    // Criando um objeto para armazenar as configurações
    const configuracoes = {
      honorario: honorario,
      valorHonorario: parseFloat(valorHonorario) || 0, // Certifique-se de converter para número
      taxaAdministradora: taxaAdministradora,
      valorTaxaAdministradora: valorTaxaAdministradora,
      tipoRecebimentoTaxa: tipoRecebimentoTaxa,
      porcentagemRecebimento:
        tipoRecebimentoTaxa === "primeirasParcelas" ? porcentagem : null,
    };
   // console.log("Dados da primeira pagina:", configuracoes);

    // Indicar que a segunda página ainda está sendo carregada
    setSegundaPaginaRenderizada(false);

    // Iniciar o carregamento
    setCarregandoDados(true);

    // Mudar para a segunda página do modal
    setPaginaModal(2);

    // Carregar dados necessários para a segunda página
    await buscarDetalhesCobrancasPorUnidade(
      idCondominio,
      unidade,
      idsCobrancas,
      configuracoes
    );
    await buscarPlanoDeContas();

    // Carregamento concluído
    setCarregandoDados(false);
  };

  // Esta função decide qual conteúdo renderizar
  const renderConteudoModal = () => {
    if (paginaModal === 1) {
      return renderPrimeiraPagina();
    } else if (paginaModal === 2) {
      return renderSegundaPagina();
    } else if (paginaModal === 3) {
      return renderTerceiraPagina();
    } else if (paginaModal === 4) {
      return renderQuartaPagina();
    } else if (paginaModal === 5) {
      return renderQuintaPagina(); // Adiciona a quinta página
    }
  };

  const renderPrimeiraPagina = () => {
    return (
      <>
        {/* Seção TAXAS DE COBRANÇA */}
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: "bold",
            fontSize: "1rem",
            textAlign: "left",
          }}
        >
          TAXAS DE COBRANÇA
        </Typography>
        <Box
          sx={{
            border: "1px solid grey",
            padding: 2,
            borderRadius: "4px",
            mb: 4,
          }}
        >
          {/* Seção do Campo Honorário */}
          <Box
            sx={{ display: "flex", alignItems: "center", fontSize: "0.9rem" }}
          >
            <FormControl sx={{ m: 1, width: "320px" }}>
              <InputLabel>Honorário</InputLabel>
              <Select
                value={honorario}
                label="Honorário"
                onChange={handleHonorarioChange}
              >
                <MenuItem value="taxaConvencionada">
                  Taxa convencionada
                </MenuItem>
                <MenuItem value="escritorioCobranca">
                  De um escritório de cobrança
                </MenuItem>
                <MenuItem value="naoCobrar">Não cobrar honorários</MenuItem>
              </Select>
            </FormControl>

            {/* Campo Escritório de Cobrança */}
            {honorario === "escritorioCobranca" && (
              <>
                <FormControl sx={{ m: 1, width: "425px" }}>
                  <InputLabel>Escritório de Cobrança</InputLabel>
                  <Select
                    value={escritorioCobranca}
                    label="Escritório de Cobrança"
                    onChange={handleEscritorioCobrancaChange}
                  >
                    {/* Opções dos escritórios de cobrança */}
                  </Select>
                </FormControl>
                <IconButton onClick={handleAddEscritorio} sx={{ m: 1 }}>
                  <AddIcon /> {/* Ícone de adicionar */}
                </IconButton>
              </>
            )}

            {/* Campo Valor (Honorário) */}
            {honorario !== "naoCobrar" && (
              <TextField
                label="Valor (%)"
                value={valorHonorario}
                onChange={handleValorHonorarioChange}
                sx={{ m: 1, width: "125px" }}
                inputProps={{ style: { fontSize: 12 } }}
              />
            )}
          </Box>

          {/* Seção do Campo Taxa da Administradora */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.9rem",
              mt: 2,
            }}
          >
            <FormControl sx={{ m: 1, width: "180px" }}>
              <InputLabel>Taxa da administradora</InputLabel>
              <Select
                value={taxaAdministradora}
                label="Taxa da administradora"
                onChange={handleTaxaAdministradoraChange}
              >
                <MenuItem value="naoCobrar">Não cobrar</MenuItem>
                <MenuItem value="cobrar">Cobrar</MenuItem>
              </Select>
            </FormControl>

            {/* Campo Valor (Taxa da Administradora) */}
            {taxaAdministradora === "cobrar" && (
              <TextField
                label="Valor (%)"
                value={valorTaxaAdministradora}
                onChange={handleValorTaxaAdministradoraChange}
                sx={{ m: 1, width: "125px" }}
                inputProps={{ style: { fontSize: 12 } }}
              />
            )}
          </Box>
        </Box>{" "}
        {/* Fim da seção TAXAS DE COBRANÇA */}
        {/* Seção COMO RECEBER AS TAXAS DE COBRANÇA */}
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: "bold",
            fontSize: "1rem",
            textAlign: "left",
          }}
        >
          COMO RECEBER AS TAXAS DE COBRANÇA?
        </Typography>
        <Box sx={{ border: "1px solid grey", padding: 2, borderRadius: "4px" }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="taxa-recebimento"
              name="taxaRecebimento"
              value={tipoRecebimentoTaxa} // Estado controlando o valor selecionado
            >
              {/* Primeira opção */}
              <FormControlLabel
                value="todasParcelas"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Receber em todas as parcelas
                    <Typography
                      sx={{
                        ml: 1,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClickExemplo}
                      aria-describedby={id}
                    >
                      Clique aqui para ver um exemplo
                    </Typography>
                  </Box>
                }
              />
              {/* Segunda opção desativada */}
              <FormControlLabel
                value="primeirasParcelas"
                disabled={true} // Desativar esta opção
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Receber
                    <TextField
                      type="number"
                      value={porcentagem}
                      onChange={handlePorcentagemChange} // Use a função modificada aqui
                      sx={{ mx: 1, width: "100px" }}
                      inputProps={{
                        style: { fontSize: 12 },
                        min: 10,
                        max: 100,
                      }} // Defina o valor mínimo como 10
                    />
                    % do valor das primeiras parcelas
                    <Typography
                      sx={{
                        ml: 1,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClickExemploSegundaOpcao}
                      aria-describedby={idSegundaOpcao}
                    >
                      Clique aqui para ver um exemplo
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>{" "}
        {/* Fim da seção COMO RECEBER AS TAXAS DE COBRANÇA */}
        {/* Popover da primeira opção */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {renderTabelaExemplo()} {/* Conteúdo da tabela com o resumo */}
        </Popover>
        {/* Popover da segunda opção */}
        <Popover
          id={idSegundaOpcao}
          open={openSegundaOpcao}
          anchorEl={anchorElSegundaOpcao}
          onClose={handleCloseSegundaOpcao}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {renderConteudoExemplo()}
        </Popover>
      </>
    );
  };

  const [planoDeContas, setPlanoDeContas] = useState([]);
  const [detalhesCobrancasIndividuais, setDetalhesCobrancasIndividuais] =
    useState([]);
  const [adicionandoNovaLinha, setAdicionandoNovaLinha] = useState(false);

  // Função para formatar os dados para o componente Autocomplete
  const formatarDadosAutocomplete = (dados) => {
    return dados
      .filter((conta) => conta.st_conta_cont.split(".").length > 1)
      .map((conta) => ({
        label: `${conta.st_conta_cont} - ${conta.st_descricao_cont}`,
        value: conta.st_conta_cont,
      }));
  };

  const formatarDadosParaApi = (detalhesCobrancas) => {
    const dadosFormatados = {};
    let total = 0;

    detalhesCobrancas.forEach((detalhe, index) => {
      // Garantir que total_receita seja uma string antes de chamar replace
      const totalReceitaStr =
        typeof detalhe.total_receita === "number"
          ? detalhe.total_receita.toString()
          : detalhe.total_receita;

      const valorNumerico = parseFloat(totalReceitaStr.replace(",", "."));
      total += valorNumerico;

      dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_CONTA_CONT]`] =
        detalhe.st_conta_cont;
      dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_DESCRICAO_CONT]`] =
        detalhe.st_descricao_cont;
      dadosFormatados[`RECEITA_APROPRIACAO[${index}][ST_COMPLEMENTO_REC]`] =
        detalhe.complemento || "";
      dadosFormatados[`RECEITA_APROPRIACAO[${index}][VL_VALOR_REC]`] =
        valorNumerico.toFixed(2);
      dadosFormatados[`RECEITA_APROPRIACAO[${index}][APROPRIARPRIMEIRO]`] = "0";
    });

    dadosFormatados["TOTAL_RECEITAAPRO"] = total.toFixed(2);

    return dadosFormatados;
  };

  const handleNextSegundaPagina = () => {
    const dadosFormatados = formatarDadosParaApi(detalhesCobrancasUnidade);
   // console.log("Dados formatados da segunda página:", dadosFormatados);

    const totalCalculado = calcularTotal(detalhesCobrancasUnidade);
   // console.log("Total sendo passado para a terceira página:", totalCalculado);

    setDadosApiSegundaPagina(dadosFormatados);
    setTotal(totalCalculado);

    setPaginaModal(3);
  };

  const buscarPlanoDeContas = async () => {
   // console.log("Iniciando buscarPlanoDeContas.");

    try {
      const url = `/api/efetuar-acordos/buscar-plano-de-contas/${idCondominio}`;
      const response = await fetch(url);
      const data = await response.json();
    //  console.log("Lista de contas recebida da API:", data);

      const listaFormatada = formatarDadosAutocomplete(data);
      setPlanoDeContas(listaFormatada);
    } catch (erro) {
      console.error("Erro ao buscar plano de contas:", erro);
    }
  };

  /*
-----##############################################################################----- 
----- Código da segunda página do modal ----- 14/12/2023 -----
*/
  const [detalhesCobrancasUnidade, setDetalhesCobrancasUnidade] = useState([]);

  const buscarDetalhesCobrancasPorUnidade = async (
    idCondominio,
    idUnidade,
    idsCobrancas,
    configuracoes
  ) => {
   /* console.log(
      "Configurações recebidas em buscarDetalhesCobrancasPorUnidade:",
      configuracoes
    ); */
    try {
      let detalhesCompletos = [];

      // Filtrar apenas as IDs das cobranças selecionadas
      const idsCobrancasSelecionadas = Object.keys(selecionadas).filter(
        (key) => selecionadas[key]
      );

      for (let idCobranca of idsCobrancasSelecionadas) {
        const url = `/api/efetuar-acordos/buscar-detalhes-cobrancas-unidade/${idCondominio}/${idUnidade}/${idCobranca}`;
       // console.log("URL de requisição:", url);
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Resposta da API não foi bem-sucedida");
        }

        const data = await response.json();
       // console.log("Dados recebidos da API:", data);

        if (data.length > 0 && data[0].receita_apropriacao) {
          detalhesCompletos.push(...data[0].receita_apropriacao);
        }
      }

     /* console.log(
        "Detalhes completos antes do processamento:",
        detalhesCompletos
      ); */
      const receitasProcessadas = processarReceitas(
        detalhesCompletos,
        configuracoes
      );
     /* console.log(
        "Receitas processadas antes de atualizar o estado:",
        receitasProcessadas
      ); */
      setDetalhesCobrancasUnidade(receitasProcessadas);
    } catch (error) {
      console.error("Erro ao buscar detalhes das cobranças:", error);
    }
  };

  const calcularHonorarios = (totalInicial, configuracoes) => {
    if (
      configuracoes.honorario === "taxaConvencionada" &&
      configuracoes.valorHonorario > 0
    ) {
      const valorHonorarios =
        totalInicial * (configuracoes.valorHonorario / 100);
      return {
        st_conta_cont: "1.4.2.1",
        st_descricao_cont: "Arrec. Cobrança Inadimplência - Individual",
        total_receita: parseFloat(valorHonorarios.toFixed(2)),
        encargo: "honorarios",
      };
    }
    return null;
  };

  const calcularTaxaAdministradora = (totalInicial, configuracoes) => {
   /* console.log("Entrando em calcularTaxaAdministradora", {
      totalInicial,
      configuracoes,
    }); */

    if (
      configuracoes.taxaAdministradora === "cobrar" &&
      configuracoes.valorTaxaAdministradora > 0
    ) {
      const valorTaxaAdministradora =
        totalInicial *
        (parseFloat(configuracoes.valorTaxaAdministradora) / 100);
      return {
        st_conta_cont: "1.2.4",
        st_descricao_cont: "Multas e Infrações",
        total_receita: parseFloat(valorTaxaAdministradora.toFixed(2)),
        encargo: "taxaAdministradora",
      };
    }
    return null;
  };

  const processarReceitas = (receitas, configuracoes) => {
    let receitasAgrupadas = {};
    let totalInicial = 0;

    receitas.forEach((receita) => {
      // Ignora contas específicas e honorários/taxas vindos da API se a configuração for para não cobrar
      if (
        (receita.st_conta_cont === "1.4.2.1" &&
          configuracoes.honorario === "naoCobrar") ||
        (receita.st_conta_cont === "1.2.4" &&
          configuracoes.taxaAdministradora === "naoCobrar") ||
        receita.st_conta_cont === "" ||
        receita.total_receita === "0"
      ) {
       /* console.log(
          `Receita ${receita.st_conta_cont} ignorada devido às condições de filtro.`
        ); */
        return;
      }

      // Ignora as receitas com encargos já tratados
      if (["honorarios", "taxaAdministradora"].includes(receita.encargo)) {
        return;
      }

      const chave = `${receita.st_conta_cont}-${
        receita.encargo || "principal"
      }`;
      const valor = parseFloat(receita.total_receita.replace(",", "."));
      if (receitasAgrupadas[chave]) {
        receitasAgrupadas[chave].total_receita += valor;
      } else {
        receitasAgrupadas[chave] = { ...receita, total_receita: valor };
      }

      totalInicial += valor;
    });

    const honorarios = calcularHonorarios(totalInicial, configuracoes);
    if (honorarios) {
      receitasAgrupadas["1.4.2.1-honorarios"] = honorarios;
    }

    const taxaAdministradora = calcularTaxaAdministradora(
      totalInicial,
      configuracoes
    );
    if (taxaAdministradora) {
      receitasAgrupadas["1.2.4-taxaAdministradora"] = taxaAdministradora;
    }

   // console.log("Valores processados antes da formatação:", receitasAgrupadas);

    const valoresFormatados = Object.values(receitasAgrupadas).map(
      (receita) => {
       // console.log(`Valor antes da formatação: ${receita.total_receita}`);
        const valorFormatado = receita.total_receita
          .toFixed(2)
          .replace(".", ",");
       // console.log(`Valor formatado: ${valorFormatado}`);
        return {
          ...receita,
          total_receita: valorFormatado,
        };
      }
    );

   // console.log("Valores formatados para retorno:", valoresFormatados);

    return valoresFormatados;
  };

  const [segundaPaginaRenderizada, setSegundaPaginaRenderizada] =
    useState(false);

  const [novaLinhaTemp, setNovaLinhaTemp] = useState({
    contaCategoria: "",
    complemento: "",
    valor: "",
  });

  // Defina calcularTotal aqui, fora de outras funções, mas dentro de EfetuarAcordo
  const calcularTotal = (detalhes) => {
    const totalCalculado = detalhes.reduce((acumulador, detalhe) => {
    //  console.log("Detalhe atual:", detalhe);

      let valorAtual;

      // Verifica se total_receita é um número ou string e converte corretamente
      if (typeof detalhe.total_receita === "number") {
        valorAtual = detalhe.total_receita;
      //  console.log("Valor atual é um número:", valorAtual);
      } else if (typeof detalhe.total_receita === "string") {
        valorAtual = parseFloat(detalhe.total_receita.replace(",", "."));
       /* console.log(
          "Valor atual é uma string convertida para número:",
          valorAtual
        ); */
      } else {
        console.warn(
          "Formato desconhecido para total_receita, ignorando valor:",
          detalhe.total_receita
        );
        return acumulador;
      }

      if (isNaN(valorAtual)) {
        console.warn("Valor atual é NaN, será ignorado na soma");
        return acumulador;
      }

      return acumulador + valorAtual;
    }, 0);

    //console.log("Total calculado:", totalCalculado);

    // Retorna o total formatado com vírgula como separador decimal
    return totalCalculado.toFixed(2).replace(".", ",");
  };

  const renderSegundaPagina = () => {
    if (paginaModal !== 2 || detalhesCobrancasUnidade.length === 0) {
      return null;
    }

    // Se os dados ainda estão carregando, exibir o símbolo de carregamento
    if (carregandoDados) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    /*console.log(
      "Renderizando a segunda página. Detalhes das cobranças:",
      detalhesCobrancasUnidade
    ); */

    // Atualize esta função para lidar com os novos campos
    const formatarDadosAutocomplete = (dados) => {
      return dados.map((conta) => ({
        label: `${conta.st_conta_cont} - ${conta.st_descricao_cont} (${
          conta.encargo || "Principal"
        })`,
        value: conta.st_conta_cont,
        encargo: conta.encargo,
        id_receita_rec: conta.id_receita_rec,
      }));
    };

    const handleAdicionarNovaLinha = () => {
      setAdicionandoNovaLinha(true);
    };

    const handleConfirmarNovaLinha = () => {
      //console.log("Adicionando nova linha:", novaLinhaTemp);
      if (!novaLinhaTemp.contaCategoria || novaLinhaTemp.valor === "") {
        //console.log("Preencha todos os campos necessários.");
        return;
      }

      const contaSelecionada = planoDeContas.find(
        (conta) => conta.value === novaLinhaTemp.contaCategoria
      );

      const novaLinha = {
        st_conta_cont: novaLinhaTemp.contaCategoria,
        st_descricao_cont: contaSelecionada
          ? contaSelecionada.label.split(" - ")[1]
          : "",
        complemento: novaLinhaTemp.complemento,
        total_receita: parseFloat(novaLinhaTemp.valor) || 0,
        isNew: false,
      };

      const novasLinhas = [...detalhesCobrancasUnidade, novaLinha];
      setDetalhesCobrancasUnidade(novasLinhas);
      setTotal(calcularTotal(novasLinhas)); // Calcula o total

      setAdicionandoNovaLinha(false);
      setNovaLinhaTemp({ contaCategoria: "", complemento: "", valor: "" });
    };

    // Função handleUpdateLinha
    const handleUpdateLinha = (index, campo, valor) => {
      let linhasAtualizadas = [...detalhesCobrancasUnidade];

      if (campo === "total_receita") {
       // console.log(`Valor recebido para atualização: ${valor}`);
        // Verifique se o valor é uma string antes de tentar substituir vírgula por ponto
        if (typeof valor === "string") {
          valor = valor.replace(",", ".");
        }
        const valorNumerico = parseFloat(valor);
       // console.log(`Valor convertido para número: ${valorNumerico}`);
        linhasAtualizadas[index] = {
          ...linhasAtualizadas[index],
          total_receita: valorNumerico,
        };
      } else {
        linhasAtualizadas[index] = {
          ...linhasAtualizadas[index],
          [campo]: valor,
        };
      }

      setDetalhesCobrancasUnidade(linhasAtualizadas);
      setTotal(calcularTotal(linhasAtualizadas));
    };

    return (
      <>
        <Box
          sx={{
            border: "1px solid grey",
            padding: 2,
            borderRadius: "4px",
            mb: 4,
          }}
        >
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Conta Categoria</TableCell>
                  <TableCell>Complemento da Conta</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                // Renderização da tabela
                {detalhesCobrancasUnidade.map((detalhe, index) => {
                  // Certifique-se de que o valor é um número antes de formatar
                  let valorNumerico =
                    typeof detalhe.total_receita === "number"
                      ? detalhe.total_receita
                      : parseFloat(detalhe.total_receita.replace(",", "."));

                  const valorFormatado = valorNumerico
                    .toFixed(2)
                    .replace(".", ",");
                /*  console.log(
                    `Renderizando linha ${index}, valor recebido: ${detalhe.total_receita}, valor formatado: ${valorFormatado}`
                  ); */

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {detalhe.st_conta_cont} - {detalhe.st_descricao_cont}
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={detalhe.complemento || ""}
                          onChange={(e) =>
                            handleUpdateLinha(
                              index,
                              "complemento",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={valorFormatado}
                          onChange={(e) => {
                            const valorAlterado = e.target.value.replace(
                              ",",
                              "."
                            );
                           /* console.log(
                              `Valor alterado na linha ${index}, campo total_receita: ${valorAlterado}`
                            ); */
                            // Verifique se o valor é numérico antes de atualizar
                            if (
                              !isNaN(parseFloat(valorAlterado)) &&
                              isFinite(valorAlterado)
                            ) {
                              handleUpdateLinha(
                                index,
                                "total_receita",
                                parseFloat(valorAlterado)
                              );
                            }
                          }}
                          type="text"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Renderização dos campos de entrada fora da tabela */}
          {adicionandoNovaLinha && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  mb: 1,
                }}
              >
                <Autocomplete
                  options={planoDeContas}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "40%" }} // Reduzir o tamanho horizontal
                  onChange={(event, newValue) => {
                    setNovaLinhaTemp({
                      ...novaLinhaTemp,
                      contaCategoria: newValue ? newValue.value : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Conta Categoria" />
                  )}
                />
                <TextField
                  label="Complemento da Conta"
                  sx={{ mx: 2, flexGrow: 1 }} // Centralizar e permitir crescimento
                  value={novaLinhaTemp.complemento}
                  onChange={(e) =>
                    setNovaLinhaTemp({
                      ...novaLinhaTemp,
                      complemento: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Valor"
                  type="number"
                  sx={{ width: "15%" }} // Tamanho específico para o campo de valor
                  value={novaLinhaTemp.valor}
                  onChange={(e) =>
                    setNovaLinhaTemp({
                      ...novaLinhaTemp,
                      valor: e.target.value,
                    })
                  }
                />
              </Box>
              <Button
                onClick={handleConfirmarNovaLinha}
                color="primary"
                variant="contained"
                sx={{ width: "200px" }}
              >
                Confirmar
              </Button>
            </Box>
          )}
          {!adicionandoNovaLinha && (
            <Button onClick={handleAdicionarNovaLinha} sx={{ mt: 2 }}>
              +
            </Button>
          )}
          <Typography sx={{ mt: 2, fontWeight: "bold" }}>
            Valor total: R$ {calcularTotal(detalhesCobrancasUnidade)}
          </Typography>
        </Box>
      </>
    );
  };

  // FIM - Código da segunda página do modal //
  const [dadosApiSegundaPagina, setDadosApiSegundaPagina] = useState({});

  const [valorEntrada, setValorEntrada] = useState(0);
  const [numParcelas, setNumParcelas] = useState(1);

  const formatarDataAtual = () => {
    const dataAtual = new Date();
    return dataAtual.toISOString().split("T")[0];
  };

  const [dataVencimento, setDataVencimento] = useState(formatarDataAtual());

  // Para converter a data de 'DD/MM/YYYY' para 'YYYY-MM-DD'
  const formatarDataParaExibicao = (dataISO) => {
    if (!dataISO || typeof dataISO !== "string") return "";
    const [ano, mes, dia] = dataISO.split("-");
    return `${dia}/${mes}/${ano}`;
  };

  const handleDataChange = (e) => {
    // Converter a data de 'DD/MM/YYYY' para 'YYYY-MM-DD'
    const dataFormatada = e.target.value.split("/").reverse().join("-");
    setDataVencimento(dataFormatada);
  };

  const [dadosApiTerceiraPagina, setDadosApiTerceiraPagina] = useState({});

  const formatarDataParaApi = (data) => {
    const partesData = data.split("/");
    return `${partesData[1]}/${partesData[0]}/${partesData[2]}`;
  };

  const renderTerceiraPagina = () => {
   // console.log("Total (antes da conversão, tipo):", typeof total, total);
   // console.log("Valor da Entrada (antes da conversão):", valorEntrada);
   // console.log("Número de Parcelas (antes da conversão):", numParcelas);

    // Verifique se 'total' é uma string antes de tentar substituir
    const totalNumerico =
      typeof total === "number" ? total : parseFloat(total.replace(",", "."));
    const valorEntradaNumerico = parseFloat(valorEntrada);

   // console.log("Total (após a conversão):", totalNumerico);
   // console.log("Valor da Entrada (após a conversão):", valorEntradaNumerico);

    const valorAParcelar = totalNumerico - valorEntradaNumerico;
    const numParcelasValidas = numParcelas > 0 ? numParcelas : 1;
    const valorDaParcela = valorAParcelar / numParcelasValidas;
    const parcelaNegativa = valorDaParcela < 0;
    const valorDaParcelaFormatado = isNaN(valorDaParcela)
      ? "0.00"
      : valorDaParcela.toFixed(2);

    // Se 'total' for um número, não é necessário substituir '.' por ','
    const totalFormatadoParaExibicao =
      typeof total === "number" ? total.toFixed(2).replace(".", ",") : total;

   // console.log("Valor a Parcelar:", valorAParcelar);
   // console.log("Número de Parcelas Válidas:", numParcelasValidas);
   // console.log("Valor de Cada Parcela:", valorDaParcelaFormatado);

    return (
      <Box sx={{ p: 2 }}>
        {/* Subtítulo com estilo aprimorado */}
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: "bold",
            fontSize: "1rem",
            textAlign: "left",
          }}
        >
          Configurações do Acordo
        </Typography>

        {/* Box externo com borda e margem */}
        <Box
          sx={{
            border: "1px solid grey",
            padding: 2,
            borderRadius: "4px",
            mb: 4,
          }}
        >
          {/* Box para a seção de cálculo */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography>Total = R$ {totalFormatadoParaExibicao}</Typography>
            <Typography sx={{ mx: 2 }}>=</Typography>
            <TextField
              label="Entrada"
              type="number"
              value={valorEntrada}
              onChange={(e) => setValorEntrada(parseFloat(e.target.value))}
              sx={{ width: 100 }}
              disabled // Adicionando esta linha
            />
            <Typography sx={{ mx: 2 }}>+</Typography>
            <TextField
              label="Parcelas"
              type="number"
              value={numParcelas}
              onChange={(e) => setNumParcelas(parseInt(e.target.value))}
              sx={{ width: 100 }}
            />
            <Typography sx={{ ml: 2 }}>
              de R$ {isNaN(valorDaParcela) ? "0.00" : valorDaParcela.toFixed(2)}
            </Typography>
          </Box>

          {parcelaNegativa && (
            <Typography color="error" sx={{ mb: 2 }}>
              Parcela não pode ficar com valor negativo.
            </Typography>
          )}

          {/* Box para a seção de vencimento da primeira parcela */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              QUANDO VENCE A PRIMEIRA PARCELA?
            </Typography>
            <TextField
              label="Vencimento da primeira parcela"
              type="text"
              value={formatarDataParaExibicao(dataVencimento)}
              onChange={(e) =>
                setDataVencimento(e.target.value.split("/").reverse().join("-"))
              }
              sx={{ width: 200 }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const [parcelasInfo, setParcelasInfo] = useState([]);

  // Função para converter a data do formato ISO 'YYYY-MM-DD' para 'MM/DD/YYYY'
  const formatarDataParaApis = (dataISO) => {
    if (!dataISO || typeof dataISO !== "string") return "";
    const [ano, mes, dia] = dataISO.split("-");
    return `${mes}/${dia}/${ano}`;
  };

  const handleNextTerceiraPagina = () => {
   // console.log("Clicado em Próximo na Terceira Página");

    // Garantir que os valores sejam numéricos
    const valorTotalNumerico = parseFloat(total.replace(",", "."));
    const valorEntradaNumerico = parseFloat(valorEntrada);

    // Calcula o valor restante a ser parcelado
    const valorAParcelar = valorTotalNumerico - valorEntradaNumerico;
    const valorDaParcela = valorAParcelar / numParcelas;

    // Prepara os dados das parcelas
    let novasParcelas = [];
    let dataParcela = new Date(dataVencimento);

    if (valorEntradaNumerico > 0) {
      // Adiciona a entrada como a primeira "parcela"
      novasParcelas.push({
        vencimento: dataVencimento,
        valor: valorEntradaNumerico.toFixed(2),
        observacao: "Entrada",
      });
    }

    for (let i = 1; i <= numParcelas; i++) {
      let valorParcela = valorDaParcela;

      // Ajustar a última parcela para compensar a diferença de arredondamento
      if (i === numParcelas) {
        let somaParcelasAtual = novasParcelas.reduce(
          (soma, parcela) => soma + parseFloat(parcela.valor),
          0
        );
        valorParcela = valorTotalNumerico - somaParcelasAtual;
      }

      novasParcelas.push({
        vencimento: dataParcela.toISOString().split("T")[0],
        valor: valorParcela.toFixed(2),
        observacao: `Parcela ${i} de ${numParcelas}`,
      });

      // Incrementa a data para o próximo mês
      dataParcela.setMonth(dataParcela.getMonth() + 1);
    }

    setParcelasInfo(novasParcelas);

    // Preparar dados para a API
    const dadosTerceiraPagina = {
      SIMULA_TOTAL: valorTotalNumerico.toFixed(2),
      SIMULA_ENTRADA: valorEntradaNumerico.toFixed(2),
      NM_PARCELA_ACO: numParcelas.toString(),
      FL_EMITIR_RECIBO: "0",
      DT_PRIMEIRA_PARCELA: formatarDataParaApis(dataVencimento),
      VL_PRIMEIRA_PARCELA:
        novasParcelas.length > 0 ? novasParcelas[0].valor : "0.00",
    };

   // console.log("Dados formatados da Terceira Página:", dadosTerceiraPagina);

    // Armazena os dados formatados para uso posterior
    setDadosApiTerceiraPagina(dadosTerceiraPagina);

    // Mudar para a quarta página do modal
    setPaginaModal(4);
  };

  const [dadosApiQuartaPagina, setDadosApiQuartaPagina] = useState({});

  const formatarDadosQuartaPagina = () => {
    const dados = {};

    parcelasInfo.forEach((parcela, index) => {
      // Converte a data de ISO (AAAA-MM-DD) para MM/DD/AAAA
      const [ano, mes, dia] = parcela.vencimento.split("-");
      const dataFormatada = `${mes}/${dia}/${ano}`;

      dados[`PARCELAS[${index}][VENCIMENTO]`] = dataFormatada;
      dados[`PARCELAS[${index}][VALOR]`] = parcela.valor;
      dados[`PARCELAS[${index}][OBSERVACAO]`] = parcela.observacao;
    });

    return dados;
  };

  const renderQuartaPagina = () => {
    return (
      <Box sx={{ p: 2 }}>
        {/* Título destacado */}
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: "bold",
            fontSize: "1rem",
            textAlign: "left",
          }}
        >
          As parcelas estão corretas?
        </Typography>

        {/* Box externo com borda e margem para a tabela */}
        <Box
          sx={{
            border: "1px solid grey",
            padding: 2,
            borderRadius: "4px",
            mb: 4,
          }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vencimento</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Observação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parcelasInfo.map((parcela, index) => (
                  <TableRow key={index}>
                    <TableCell>{parcela.vencimento}</TableCell>
                    <TableCell>
                      R${" "}
                      {isNaN(parcela.valor)
                        ? "0.00"
                        : parseFloat(parcela.valor).toFixed(2)}
                    </TableCell>
                    <TableCell>{parcela.observacao}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };

  // QUINTA PARTE

  const handleNextQuartaPagina = async () => {
   // console.log("Clicado em Próximo na Quarta Página");

    const dadosFormatadosQuartaPagina = formatarDadosQuartaPagina();
   /* console.log(
      "Dados formatados da Quarta Página:",
      dadosFormatadosQuartaPagina
    ); */

    setDadosApiQuartaPagina(dadosFormatadosQuartaPagina);

    await buscarContasBancarias(); // Aguarda a conclusão da busca antes de prosseguir

    setPaginaModal(5); // Ajuste para a quinta página
  };

  const [contasBancariasAtivas, setContasBancariasAtivas] = useState([]);

  const buscarContasBancarias = async () => {
    try {
     // console.log("ID do Condomínio:", idCondominio); // Verifique se o idCondominio está correto
      const url = `/api/efetuar-acordos/buscar-contas-bancarias/${idCondominio}`;
      const response = await fetch(url);
      const data = await response.json();

     // console.log("Dados da Resposta:", data); // Verifique a resposta da API

      // Filtrando contas que possuem 'st_secret_cb' não vazio
      const contasComSecret = data.filter((conta) => conta.st_secret_cb !== "");

    //  console.log("Contas com st_secret_cb não vazio:", contasComSecret); // Verifique o resultado da filtragem

      setContasBancariasAtivas(
        contasComSecret.map((conta) => ({
          label: conta.st_descricao_cb,
          value: conta.id_contabanco_cb,
        }))
      );

      // Definindo a primeira conta como padrão, se houver alguma
      if (contasComSecret.length > 0) {
        setContaBancaria(contasComSecret[0].id_contabanco_cb);
      }
    } catch (error) {
      console.error("Erro ao buscar contas bancárias:", error);
    }
  };

  const renderQuintaPagina = () => {
    // Data de hoje para a data de geração do acordo
    const dataHoje = new Date().toISOString().split("T")[0];

    return (
      <Box sx={{ p: 2 }}>
        {/* Box para Taxas das Parcelas */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "1rem" }}
          >
            Taxas das Parcelas
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={editarTaxas}
                onChange={(e) => setEditarTaxas(e.target.checked)}
              />
            }
            label="Alterar taxas das parcelas"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Taxa de juros (%)"
              type="number"
              value={taxaJuros}
              onChange={(e) => setTaxaJuros(parseFloat(e.target.value))}
              disabled={!editarTaxas}
            />
            <TextField
              label="Taxa de multa (%)"
              type="number"
              value={taxaMulta}
              onChange={(e) => setTaxaMulta(parseFloat(e.target.value))}
              disabled={!editarTaxas}
            />
            <TextField
              label="Desconto (%)"
              type="number"
              value={desconto}
              onChange={(e) => setDesconto(parseFloat(e.target.value))}
              disabled={!editarTaxas}
            />
          </Box>
        </Box>
        {/* Box para Mais Opções */}
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ mb: 2, fontWeight: "bold", fontSize: "1rem" }}
          >
            Mais Opções
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Select
              label="Forma de recebimento"
              value={formaRecebimento}
              onChange={(e) => setFormaRecebimento(e.target.value)}
            >
              <MenuItem value="Escolhida pelo cliente">
                Escolhida pelo cliente
              </MenuItem>
              <MenuItem value="Boleto">Boleto</MenuItem>
              <MenuItem value="Cheque">Cheque</MenuItem>
              <MenuItem value="Dinheiro">Dinheiro</MenuItem>
              <MenuItem value="Cartao de credito">Cartão de Crédito</MenuItem>
              <MenuItem value="Cartao de debito">Cartão de Débito</MenuItem>
              <MenuItem value="Deposito">Depósito</MenuItem>
              <MenuItem value="Cheque pre-datado">Cheque Pré-datado</MenuItem>
            </Select>
            <Select
              labelId="conta-bancaria-label"
              id="conta-bancaria"
              value={contaBancaria}
              onChange={(e) => setContaBancaria(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">
                <em>Nenhuma selecionada</em>
              </MenuItem>
              {contasBancariasAtivas.map((conta, index) => (
                <MenuItem key={index} value={conta.value}>
                  {conta.label}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Data de geração do acordo"
              type="date"
              defaultValue={dataHoje}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={registrarCRM}
                  onChange={(e) => setRegistrarCRM(e.target.checked)}
                />
              }
              label="Registrar no CRM de cobrança"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const handleBack = () => {
    // Reduz a paginaModal em 1, para voltar à página anterior
    setPaginaModal(paginaModal - 1);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const obterTituloModal = (pagina) => {
    switch (pagina) {
      case 1:
        return "Como as taxas de cobranças serão cobradas?";
      case 2:
        return "Como o acordo será composto?";
      case 3:
        return "Como será o pagamento?";
      case 4:
        return "As parcelas estão corretas?";
      case 5:
        return "Outras opções";
      default:
        return "Detalhes do Acordo"; // Um título padrão para segurança
    }
  };

  const finalizarAcordo = async () => {
    // Mapeamento da forma de recebimento para o valor numérico correspondente
    const mapeamentoFormaRecebimento = {
      "Escolhida pelo cliente": 1,
      Boleto: 2,
      Cheque: 3,
      Dinheiro: 4,
      "Cartão de crédito": 5,
      "Cartão de débito": 6,
      Depósito: 7,
      "Cheque pré-datado": 8,
      // Adicione outros mapeamentos conforme necessário
    };

    const dadosQuintaPagina = {
      ALTERAR_ENCARGOS: editarTaxas ? 1 : 0,
      NM_TXJUROS_COND: taxaJuros,
      NM_TXMULTA_COND: taxaMulta,
      NM_TXDESCONTO_RECB: desconto,
      ID_FORMA_RECB: mapeamentoFormaRecebimento[formaRecebimento],
      ID_CONTABANCO_CB: contaBancaria,
      ID_CONTA_BANCO: contaBancaria,
      DT_ACORDO_ACO: new Date().toLocaleDateString("en-US"),
      ID_IMPRESSAO_FIMP: "",
      naosepararcomposicao: 2,
    };

    // Consolidação de todos os dados
    const dadosConsolidados = {
      ...dadosApiPaginaInicial,
      NUMERO_UNIDADE: unidade,
      ...dadosApiSegundaPagina,
      ...dadosApiTerceiraPagina,
      ...formatarDadosQuartaPagina(),
      ...dadosQuintaPagina,
    };

    // Log para verificar todos os dados consolidados
   /* console.log(
      "Dados consolidados para envio:",
      JSON.stringify(dadosConsolidados, null, 2)
    ); */

    // Converter os dados consolidados para URLSearchParams
    const dadosParaEnvio = new URLSearchParams();
    Object.entries(dadosConsolidados).forEach(([chave, valor]) => {
      if (Array.isArray(valor)) {
        valor.forEach((item, index) => {
          Object.entries(item).forEach(([key, val]) => {
            dadosParaEnvio.append(`${chave}[${index}][${key}]`, val);
          });
        });
      } else {
        dadosParaEnvio.append(chave, valor);
      }
    });

    try {
      const response = await fetch("/api/efetuar-acordos/efetuar-acordo", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: dadosParaEnvio,
      });

      const data = await response.json();
     /* console.log(
        "Resposta completa do servidor:",
        JSON.stringify(data, null, 2)
      ); */

      if (data.mensagem) {
       // console.log("Acordo realizado com sucesso:", data.mensagem);
        setSnackbarMessage("Acordo realizado com sucesso!");
        setSnackbarSeverity("success"); // Define a cor da mensagem como sucesso
        setSnackbarOpen(true);
        setIsModalOpen(false); // Fecha o modal
      } else {
        console.error("Erro ao finalizar acordo:", data.erro);
        setSnackbarMessage("Erro ao realizar acordo. Tente novamente.");
        setSnackbarOpen(true);
        setSnackbarSeverity("error"); // Mantém a cor da mensagem como erro
      }
    } catch (error) {
      console.error("Erro ao enviar dados para o servidor:", error);
      setSnackbarMessage("Erro na comunicação com o servidor.");
      setSnackbarOpen(true);
      setSnackbarSeverity("error"); // Mantém a cor da mensagem como erro
    }
  };
  return (
    <div className="acordo-inicio-container">
      <div className="acordo-inicio-content">
        <div className="acordo-inicio-title">Acordo</div>
        <div
          className="acordo-inicio-valores"
          sx={{ display: "flex", flexDirection: "column", marginBottom: 2 }}
        >
          <h6>Unidade: {unidade}</h6>
          <h6>Total Devedor: R$ {totalDevedor}</h6>
        </div>
        <div className="acordo-inicio-botoes">
          <button className="acordo-botao-confissaoDivida">
            Ver Confissão de Dívida
          </button>
          <button className="acordo-botao-parcelar" onClick={handleOpenModal}>
            Parcelar
          </button>
        </div>
        <div className="acordo-inicio-tabela" component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="tabela de cobranças">
            <TableHead>
              <TableRow>
                <TableCell>Selecionar</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Cobrança</TableCell>
                <TableCell>Principal</TableCell>
                <TableCell>Multa</TableCell>
                <TableCell>Juros</TableCell>
                <TableCell>Atualização</TableCell>
                <TableCell>Honorários</TableCell>
                <TableCell>Atualizado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detalhesCobrancas.map((cobranca, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={!!selecionadas[cobranca.idCobranca]}
                      onChange={(e) =>
                        handleCheckboxChange(
                          cobranca.idCobranca,
                          e.target.checked
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>{cobranca.vencimento}</TableCell>
                  <TableCell>
                    {cobranca.idCobranca}
                    {cobranca.emProcessoJudicial && (
                      <GavelIcon sx={{ ml: 1, fontSize: "small" }} />
                    )}{" "}
                    {/* Ícone de processo judicial */}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.principal === "number"
                      ? cobranca.principal.toFixed(2)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.multa === "number"
                      ? cobranca.multa.toFixed(2)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.juros === "number"
                      ? cobranca.juros.toFixed(2)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.atualizacao === "number"
                      ? cobranca.atualizacao.toFixed(2)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.honorarios === "number"
                      ? cobranca.honorarios.toFixed(2)
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {typeof cobranca.atualizado === "number"
                      ? cobranca.atualizado.toFixed(2)
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="acordo-inicio-final">
          <div className="acordo-inicio-final-texto">
            Listando {detalhesCobrancas.length} cobranças com valor principal
            total de R$ {totalDevedor}
          </div>
          <div className="acordo-inicio-final-texto">
            Marcadas{" "}
            {
              Object.keys(selecionadas).filter((key) => selecionadas[key])
                .length
            }{" "}
            cobranças no valor total de R$
            {detalhesCobrancas
              .filter((cobranca) => selecionadas[cobranca.idCobranca])
              .reduce((total, cobranca) => total + cobranca.atualizado, 0)
              .toFixed(2)}
          </div>
        </div>
      </div>

      {/* Início do Modal */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            width: "1000px", // Largura fixa
            height: "615px", // Altura fixa
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle
          className="dialog-title"
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {obterTituloModal(paginaModal)}
        </DialogTitle>

        <DialogContent
          className="dialog-content"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {renderConteudoModal()}
        </DialogContent>

        {/* Botões na parte inferior do Modal */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            padding: 2,
          }}
          className="button-container"
        >
          {/* Exibe o botão Voltar exceto na primeira página */}
          {paginaModal > 1 && (
            <Button variant="contained" color="secondary" onClick={handleBack}>
              Voltar
            </Button>
          )}

          {/* Botões Próximo ou Finalizar Acordo */}
          {paginaModal < 5 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={
                paginaModal === 1
                  ? handleNext
                  : paginaModal === 2
                  ? handleNextSegundaPagina
                  : paginaModal === 3
                  ? handleNextTerceiraPagina
                  : handleNextQuartaPagina
              }
            >
              Próximo
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={finalizarAcordo} // Substitua com a função apropriada
              sx={{ marginLeft: "auto" }} // Alinha o botão à direita
            >
              Finalizar Acordo
            </Button>
          )}

          {/* Botão Fechar */}
          <Button variant="outlined" onClick={handleCloseModal}>
            Fechar
          </Button>
        </Box>
      </Dialog>
      {/* Snackbar atualizado para exibir mensagens de sucesso */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000} // Duração de 4 segundos
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // opcional, para definir a posição
        message={
          <span style={{ color: "green" }}>{snackbarMessage}</span> // Mensagem em verde
        }
        action={
          <IconButton
            size="small"
            aria-label="fechar"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            X {/* Ou use um ícone de fechar, se preferir */}
          </IconButton>
        }
      />
    </div>
  );
};

export default EfetuarAcordo;
