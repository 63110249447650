/* ***********************************
Autor: Matheus Botelho
Data: 27/05/24
Atividade: SSI 0018 - Página de atualizações Eureka
Função: Página para listar todos os eventos do Eureka
************************************ */

import React, { useEffect, useState } from 'react';
import styles from './ListarEventos.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

//SSI 0035 - MATHEUS BOTELHO - 09/07/24

const ListarEventos = () => {
  const [eventos, setEventos] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const eventosPorPagina = 5; // Número de eventos por página
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEventos = async () => {
      setCarregando(true);
      try {
        const response = await fetch(
          `${API_URL}/eventos/buscar?page=${pagina}&limit=${eventosPorPagina}`
        );
        const data = await response.json();
        if (data && data.eventos && Array.isArray(data.eventos)) {
          setEventos(data.eventos);
        } else {
          setEventos([]);
        }
        if (data && typeof data.totalPaginas === 'number') {
          setTotalPaginas(data.totalPaginas);
        } else {
          setTotalPaginas(1);
        }
      } catch (error) {
        console.error('Erro ao buscar eventos:', error);
        setEventos([]);
        setTotalPaginas(1);
      }
      setCarregando(false);
    };

    fetchEventos();
  }, [API_URL, pagina]);

  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  return (
    <div className={styles.container}>
      {carregando ? (
        <div className={styles.loader}>
          <CircularProgress />
          <p>Carregando eventos...</p>
        </div>
      ) : (
        <>
          {eventos.length > 0 ? (
            eventos.map((evento) => (
              <div
                key={evento.id}
                className={styles.evento}
              >
                <div className={styles.content}>
                  <h2>{evento.titulo}</h2>
                  <p
                    className={styles.texto}
                    dangerouslySetInnerHTML={{ __html: evento.descricao }}
                  />
                  <p className={styles.data}>{formatDate(evento.createdAt)}</p>
                </div>
                <img
                  src={evento.imagem}
                  alt={evento.titulo}
                  onError={(e) => {
                    e.target.src = 'https://via.placeholder.com/300';
                  }}
                />
              </div>
            ))
          ) : (
            <p>Nenhum evento encontrado.</p>
          )}
          <div className={styles.paginacao}>
            <Button
              onClick={() => setPagina(pagina - 1)}
              disabled={pagina === 1}
            >
              Anterior
            </Button>
            <span>{`Página ${pagina} de ${totalPaginas}`}</span>
            <Button
              onClick={() => setPagina(pagina + 1)}
              disabled={pagina === totalPaginas}
            >
              Próxima
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

//FIM DA ALTERAÇÃO

export default ListarEventos;
