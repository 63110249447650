//LCORREA - SSI 0058 - 11/09/2024 - Correção da assembleia para quando for AGI

import React, { useEffect, useState, useContext } from "react";
import "./Assembleia.css";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import UserContext from "../components/UserContext";
import customParseFormat from "dayjs/plugin/customParseFormat";
// Importação agregada dos componentes do MUI
import {
	TextField,
	Checkbox,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Autocomplete,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	Button,
	InputAdornment,
	ListSubheader,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search"; // Importação do ícone Search
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import axios from "axios";
import io from "socket.io-client";
import AssembleiaStatus from "./AssembleiaStatus";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import businessDays from "dayjs-business-days";

dayjs.extend(customParseFormat);
dayjs.extend(businessDays);
dayjs.locale("pt-br");

const Assembleia = () => {
	const { user } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [condominios, setCondominios] = useState([]);
	const [filtro, setFiltro] = useState("");
	const [condominioId, setCondominioId] = useState("");
	const API_URL = process.env.REACT_APP_API_URL;
	const [selectedDate, setSelectedDate] = useState(dayjs());
	const [dataAtual, setDataAtual] = useState(dayjs());
	const [condominio, setCondominio] = useState(null);
	const [tipoAssembleia, setTipoAssembleia] = useState(null);
	const [formatoAssembleia, setFormatoAssembleia] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [assembleias, setAssembleias] = useState([]);
	const [periodoSelecionado, setPeriodoSelecionado] = useState(dayjs());
	const [diasConvocacao, setDiasConvocacao] = useState("");
	const [dataConvocacao, setDataConvocacao] = useState("");
	const [editavel, setEditavel] = useState(false);
	const [order, setOrder] = useState({ field: "condominio", direction: "asc" });
	const [filtroTexto, setFiltroTexto] = useState("");
	const [filtroFormato, setFiltroFormato] = useState("");
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [secaoExpandida, setSecaoExpandida] = useState("campos");
	const [checklistStatus, setChecklistStatus] = useState(Array(15).fill(false));
	const [assembleiaAtual, setAssembleiaAtual] = useState(null);
	const [showAuxiliar, setShowAuxiliar] = useState(false);
	const [secretarios, setSecretarios] = useState([]);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [activeUsers, setActiveUsers] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [idFuncAuxiliar, setIdFuncAuxiliar] = useState("");
	const [isGoogleEventCreated, setIsGoogleEventCreated] = useState(false);
	const [dataSelecionada, setDataSelecionada] = useState(
		dayjs().format("DD-MM-YYYY")
	);
	const [eventos, setEventos] = useState([]);
	const nomeDoDia = dayjs(dataSelecionada, "DD-MM-YYYY").format("dddd");
	const [isDataSaved, setIsDataSaved] = useState(false);
	const [isAssemblyPublished, setIsAssemblyPublished] = useState(false);
	const [isMinutesPublished, setIsMinutesPublished] = useState(false);
	const [filteredAssembleias, setFilteredAssembleias] = useState([]);
	const [mostrarTodos, setMostrarTodos] = useState(true);
	const [socket, setSocket] = useState(null); // Adicionado useState para socket
	const [groupedUserOptions, setGroupedUserOptions] = useState({});
	const [selectedUser, setSelectedUser] = useState("");
	const [tipoDia, setTipoDia] = useState("corridos");
	const [mostrarFinalizadas, setMostrarFinalizadas] = useState(false); // SSI 0051 - LCORREA - 30/08/2024
	const [showAlertPrevia, setShowAlertPrevia] = useState(false);
	const [alertMessagePrevia, setAlertMessagePrevia] = useState("");

	const [anoSelecionado, setAnoSelecionado] = useState(
		new Date().getFullYear()
	);
	const [mesSelecionado, setMesSelecionado] = useState(
		new Date().getMonth() + 1
	);

	// Função para redefinir os estados dos campos
	const resetFormFields = () => {
		setCondominio(null);
		setSelectedDate(dayjs()); // Reinicializa para a data atual ou qualquer outra lógica de inicialização que deseje
		setTipoAssembleia(""); // Certifique-se de que o valor inicial corresponda ao esperado pelo seu componente
		setFormatoAssembleia(""); // Similarmente, um valor inicial adequado
	};

	const openModal = async () => {
		setIsModalOpen(true);
		await fetchCondominios();
		resetFormFields(); // Chama a função para redefinir os campos ao abrir o modal
	};

	// Supondo que você tenha uma função para fechar o modal
	const closeModal = () => {
		setIsModalOpen(false);
		resetFormFields(); // Também redefinir os campos ao fechar para garantir que o modal reabra limpo na próxima vez
	};

	const fetchCondominios = async () => {
		const url = `${API_URL}/assembleia/condominios`;
		//console.log("Tentando acessar a URL:", url);
		try {
			const response = await fetch(url);
			if (response.ok) {
				const data = await response.json();
				setCondominios(data);
				//console.log("Condomínios recebidos:", data);
			} else {
				//console.error("Erro ao buscar condomínios:", response.statusText);
			}
		} catch (error) {
			console.error("Erro na requisição para:", url, error);
		}
	};

	const handleFilterChange = (event) => {
		const novoFiltro = event.target.value.toLowerCase();
		setFiltro(novoFiltro);
		//console.log("Filtro atualizado:", novoFiltro);
	};

	const condominiosFiltrados = condominios.filter((condominio) =>
		condominio.fantasia.toLowerCase().includes(filtro)
	);

	const renderCondominioOptions = () => {
		return condominios.map((condominio) => (
			<option key={condominio.chave} value={condominio.chave}>
				{condominio.fantasia}
			</option>
		));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (editavel) {
			toast.warn("Confirme a data de convocação antes de prosseguir.");
			return;
		}

		if (
			!condominio ||
			!tipoAssembleia ||
			!formatoAssembleia ||
			!selectedDate ||
			!dataConvocacao
		) {
			toast.warn("Preencha todos os campos antes de prosseguir.");
			return;
		}

		const dataConvocacaoFormatada =
			dataConvocacao && dataConvocacao.includes("/")
				? dataConvocacao.split("/").reverse().join("-")
				: "";

		const formData = {
			condominio: condominio ? condominio.fantasia : "",
			id_cond: condominio ? condominio.chave : "",
			dt_assembleia: selectedDate.format("YYYY-MM-DD"),
			hr_assembleia: "00:00:00",
			dt_convocacao: dataConvocacaoFormatada,
			tipo_assembleia: tipoAssembleia,
			formato_assembleia: formatoAssembleia,
			nome_responsavel: null,
			id_func_responsavel: null,
			nome_auxiliar: null,
			id_func_auxiliar: null,
			assuntos_assembleia: null,
			anexo_assembleia: null,
			status_assembleia: 1,
			tipo_dia: tipoDia,
			id_func_criador: user?.id_func, // Adicionado aqui
		};

		try {
			const response = await fetch(`${API_URL}/assembleia/cadastrar`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				toast.success("Assembleia cadastrada com sucesso!");
				closeModal();
				const anoAtual = new Date().getFullYear();
				const mesAtual = new Date().getMonth() + 1;
				fetchAssembleiasPorPeriodo(anoAtual, mesAtual);
			} else {
				const errorText = await response.text();
				toast.error(`Erro ao cadastrar assembleia: ${errorText}`);
			}
		} catch (error) {
			toast.error(`Erro na requisição: ${error.message}`);
		}
	};

	const fetchDiasConvocacao = async (idCond) => {
		const url = `${API_URL}/assembleia/dias_convocacao/${idCond}`;
		try {
			const response = await fetch(url);
			if (response.ok) {
				const data = await response.json();
				const dias =
					data.dias_convocacao !== null ? data.dias_convocacao.toString() : "";
				setDiasConvocacao(dias);
				setEditavel(!dias);
			} else {
				setDiasConvocacao("");
				setEditavel(true);
			}
		} catch (error) {
			console.error("Erro ao buscar dias de convocação:", error);
			setDiasConvocacao("");
			setEditavel(true);
		}
	};

	const atualizarDataConvocacao = (tipoDia, diasConvocacao, selectedDate) => {
		if (selectedDate && diasConvocacao) {
			let diasParaSubtrair = parseInt(diasConvocacao);
			let dataAssembleia = dayjs(selectedDate);
			let dataDaConvocacao = dataAssembleia;

			if (tipoDia === "corridos") {
				dataDaConvocacao = dataAssembleia.subtract(diasParaSubtrair, "day");
			} else {
				while (diasParaSubtrair > 0) {
					dataDaConvocacao = dataDaConvocacao.subtract(1, "day");
					if (dataDaConvocacao.day() !== 0 && dataDaConvocacao.day() !== 6) {
						diasParaSubtrair--;
					}
				}
			}

			setDataConvocacao(dataDaConvocacao.format("DD/MM/YYYY"));
		} else {
			setDataConvocacao("");
		}
	};

	const salvarDiasConvocacao = async () => {
		const url = `${API_URL}/assembleia/atualizar_dias_convocacao/${condominio.chave}`;
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ dias_convocacao: diasConvocacao }),
			});
			if (response.ok) {
				atualizarDataConvocacao(tipoDia, diasConvocacao, selectedDate);
			} else {
				console.error("Erro ao atualizar dias para convocação.");
			}
		} catch (error) {
			console.error("Erro ao tentar atualizar dias para convocação:", error);
		}
		setEditavel(false);
	};

	useEffect(() => {
		atualizarDataConvocacao(tipoDia, diasConvocacao, selectedDate);
	}, [selectedDate, diasConvocacao, tipoDia]);

	// Fim - Processos para o modal de cadastro de assembleias

	const formataData = (data) => {
		return data.format("MMM YYYY"); // Formata como 'Abr 2024' para abril de 2024, por exemplo
	};

	const proximoMes = () => {
		setDataAtual(dataAtual.add(1, "month"));
	};

	const mesAnterior = () => {
		setDataAtual(dataAtual.subtract(1, "month"));
	};

	// PROCESSOS PARA A TABELA

	// SSI 0041 - 19/07/24

	useEffect(() => {
		const today = dayjs();
		fetchAssembleiasPorPeriodo(today.year(), today.month() + 1);
	}, [user]);

	const fetchAssembleiasPorPeriodo = async (ano, mes) => {
		try {
			const token = localStorage.getItem("token");

			const response = await fetch(
				`${API_URL}/assembleia/assembleias/${ano}/${mes}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.ok) {
				const data = await response.json();
				const today = dayjs().format("YYYY-MM-DD");

				const formattedData = data.map((assembleia) => ({
					...assembleia,
					dt_convocacao: formatDate(assembleia.dt_convocacao),
					dt_assembleia: formatDate(assembleia.dt_assembleia),
					dt_criacao: formatDate(assembleia.dt_criacao),
					dt_publicacao: formatDate(assembleia.dt_publicacao),
					statusData: getStatusData(assembleia.dt_convocacao),
				}));

				setAssembleias(formattedData);
				setAnoSelecionado(ano);
				setMesSelecionado(mes);
				if (user.funcao === "Agente") {
					fetchCondominiosPorUsuario(user.id_func, formattedData);
				} else {
					applyFilterAndSetAssembleias(formattedData, mostrarFinalizadas);
				}
			} else {
				console.error("Erro ao buscar assembleias:", response.statusText);
				setAssembleias([]);
			}
		} catch (error) {
			console.error("Erro na requisição para buscar assembleias:", error);
			setAssembleias([]);
		}
	};

	const applyFilterAndSetAssembleias = (assembleias, showFinalizadas) => {
		const filtered = showFinalizadas
			? assembleias
			: assembleias.filter((assembleia) => {
					/*	
				console.log("Status Assembleia:", assembleia.status_assembleia);
					console.log(
						"Comparação:",
						parseInt(assembleia.status_assembleia) !== 16
					);
					*/
					return parseInt(assembleia.status_assembleia) !== 16;
			  });

		//console.log("Assembleias filtradas:", filtered);
		setFilteredAssembleias(filtered);
	};

	const handleToggleFinalizadas = () => {
		setMostrarFinalizadas(!mostrarFinalizadas);
	};

	const getStatusData = (dateString) => {
		// Adicionar log para verificar o formato da data de convocação
		//console.log("Data de convocação recebida:", dateString);

		const today = dayjs().startOf("day");
		// Converter a data de 'dd/MM/yyyy' para 'YYYY-MM-DD'
		const [day, month, year] = dateString.split("/");
		const date = dayjs(`${year}-${month}-${day}`).startOf("day");

		// Adicionar log para verificar a comparação
		//console.log("Comparando:", {
		//	today: today.format("YYYY-MM-DD"),
		//	date: date.format("YYYY-MM-DD"),
		//	isSameDay: date.isSame(today, "day"),
		//	isBefore: date.isBefore(today, "day"),
		//});

		if (date.isSame(today, "day")) {
			return "no dia";
		} else if (date.isBefore(today, "day")) {
			return "atrasado";
		} else {
			return "no prazo";
		}
	};

	//FIM DA ALTERAÇÃO

	const fetchCondominiosPorUsuario = async (id_func, assembleias) => {
		try {
			const response = await fetch(
				`${API_URL}/assembleia/condominios/${id_func}`
			);

			if (response.ok) {
				const idConds = await response.json();
				//console.log("ID_COND recebidos:", idConds);
				const filtered = assembleias.filter((assembleia) =>
					idConds.includes(assembleia.id_cond)
				);
				setFilteredAssembleias(filtered);
			} else {
				console.error(
					"Erro ao buscar condomínios do agente:",
					response.statusText
				);
				setFilteredAssembleias([]);
			}
		} catch (error) {
			console.error(
				"Erro na requisição para buscar condomínios do agente:",
				error
			);
			setFilteredAssembleias([]);
		}
	};

	const handleToggleTodos = () => {
		setMostrarTodos(!mostrarTodos);
		// Se necessário, aplique o filtro aqui também
	};

	const displayedAssembleias = mostrarTodos ? assembleias : filteredAssembleias;

	// Função para filtrar assembleias finalizadas
	const filterFinalizadas = (assembleias) => {
		//console.log(
		//	"Filtrando assembleias, mostrarFinalizadas:",
		//	mostrarFinalizadas
		//);
		if (!mostrarFinalizadas) {
			const filtered = assembleias.filter(
				(assembleia) => assembleia.status_assembleia !== 16
			);
			//console.log("Assembleias após filtro (ocultar finalizadas):", filtered);
			return filtered;
		}
		//console.log("Mostrando todas as assembleias (incluindo finalizadas)");
		return assembleias;
	};

	const displayedFilteredAssembleias = filterFinalizadas(displayedAssembleias);

	const formatDate = (dateString) => {
		if (!dateString || dateString === "00/00/0000") return "-";
		const [day, month, year] = dateString.split("/");
		return `${day}/${month}/${year}`;
	};

	const changeOrder = (field) => {
		setOrder((prevOrder) => ({
			field,
			direction:
				prevOrder.field === field && prevOrder.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	const sortAssembleias = (assembleias) => {
		return [...assembleias].sort((a, b) => {
			const isAsc = order.direction === "asc";

			if (order.field === "dt_convocacao" || order.field === "dt_assembleia") {
				const [dayA, monthA, yearA] = a[order.field].split("/");
				const [dayB, monthB, yearB] = b[order.field].split("/");

				const dateA = new Date(yearA, monthA - 1, dayA);
				const dateB = new Date(yearB, monthB - 1, dayB);

				if (dateA < dateB) return isAsc ? -1 : 1;
				if (dateA > dateB) return isAsc ? 1 : -1;
				return 0;
			} else {
				if (a[order.field] < b[order.field]) return isAsc ? -1 : 1;
				if (a[order.field] > b[order.field]) return isAsc ? 1 : -1;
				return 0;
			}
		});
	};

	const alterarFiltroFormato = () => {
		const formatos = ["", "presencial", "híbrida", "remota"];
		const indexAtual = formatos.indexOf(filtroFormato);
		const novoIndex = (indexAtual + 1) % formatos.length;
		setFiltroFormato(formatos[novoIndex]);
	};

	const statusMapa = {
		1: "Assembleia criada",
		2: "Envio da Convocação",
		3: "Envio Lembrete",
		4: "Confirmação Equipamento",
		5: "Confirmação Documentos",
		6: "Confirmação Inadimplência",
		7: "Publicação Interna Ata",
		8: "Publicação Externa Ata",
		9: "Alteração Responsáveis Legais",
		10: "Alteração Cadastro Octadesk / 3CX",
		11: "Tarefa de alteração de síndico",
		12: "Coleta de Assinaturas",
		13: "Envio para Registro",
		14: "Publicação Ata Registrada",
		15: "Envio Tarefa de alteração financeiro",
	};

	useEffect(() => {
		const ano = dataAtual.year();
		const mes = dataAtual.month() + 1;
		fetchAssembleiasPorPeriodo(ano, mes);
	}, [dataAtual]);

	const isToday = (dateString) => {
		const today = dayjs().startOf("day");
		const date = dayjs(dateString).startOf("day");
		return date.isSame(today, "day");
	};

	const isPastDue = (dateString, status) => {
		const today = dayjs().startOf("day");
		const date = dayjs(dateString);
		return date.isBefore(today, "day") && status === "Assembleia criada";
	};

	// PROCESSOS PARA O MODAL DE EDIÇÃO DE ASSEMBLEIAS

	const abrirModalEdicao = async (dadosAssembleia) => {
		setIsLoading(true);
		//console.log("Dados recebidos para edição:", dadosAssembleia);

		try {
			const secretariosResponse = await fetch(
				`${API_URL}/assembleia/assembleia/usuarios`
			);
			if (secretariosResponse.ok) {
				const secretarios = await secretariosResponse.json();
				setSecretarios(secretarios);
				//console.log("Secretários carregados:", secretarios);
			}

			// Configura o estado inicial com os IDs dos responsáveis
			setAssembleiaAtual({
				...dadosAssembleia,
				id_func_responsavel: dadosAssembleia.id_func_responsavel,
				id_func_auxiliar: dadosAssembleia.id_func_auxiliar,
				anexo_assembleia: dadosAssembleia.anexo_assembleia, // Certifique-se de que este campo está sendo setado
				nome_arquivo: dadosAssembleia.nome_arquivo, // Certifique-se de que este campo está sendo setado
			});

			setShowAuxiliar(!!dadosAssembleia.nome_auxiliar);
			const statusIndex = parseInt(dadosAssembleia.status_assembleia, 10);
			const newChecklistStatus = checklistStatus.map(
				(_, index) => index < statusIndex
			);
			setChecklistStatus(newChecklistStatus);
			setIsEditModalOpen(true);
		} catch (error) {
			console.error("Erro na requisição:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const fecharModalEdicao = () => {
		setIsEditModalOpen(false);
		fetchAssembleiasPorPeriodo(anoSelecionado, mesSelecionado); // Use os estados atuais
	};

	const alternarSecaoExpandida = (secao) => {
		const novaSecao = secaoExpandida === secao ? null : secao;
		//console.log(`Alterando seção expandida para: ${novaSecao}`);
		setSecaoExpandida(novaSecao);
	};

	const handleChecklistChange = (index) => {
		const lastIndexChecked = checklistStatus.lastIndexOf(true); // Último index marcado
		if (index === lastIndexChecked || index === lastIndexChecked + 1) {
			const updatedStatus = [...checklistStatus];
			updatedStatus[index] = !updatedStatus[index];
			setChecklistStatus(updatedStatus);
		}
	};

	const checklistItems = [
		"Assembleia criada",
		"Envio da Convocação",
		"Envio Lembrete",
		"Confirmação Equipamento",
		"Confirmação Documentos",
		"Confirmação Inadimplência",
		"Publicação Interna Ata",
		"Publicação Externa Ata",
		"Alteração Responsáveis Legais",
		"Alteração Cadastro Octadesk",
		"Tarefa de alteração de síndico",
		"Coleta de Assinaturas",
		"Envio para Registro",
		"Publicação Ata Registrada",
		"Envio Tarefa de alteração financeiro",
		"Assembleia Finalizada",
	];

	const getStatusDescription = (status) => {
		return checklistItems[status - 1] || "Status desconhecido";
	};

	const fetchAssembleiaDetalhes = async (id) => {
		try {
			// Verifica e atualiza o token
			const tokenResponse = await fetch(
				`${API_URL}/assembleia/verificar-token`
			);
			const tokenData = await tokenResponse.json();

			if (tokenData.success) {
				const response = await fetch(`${API_URL}/assembleia/detalhes/${id}`);
				if (response.ok) {
					const data = await response.json();

					// Atualiza o estado da assembleia atual com os detalhes recebidos
					setAssembleiaAtual(data);

					// Formata a data da assembleia para exibição
					const dataAssembleia = dayjs(data.dt_assembleia).format("DD-MM-YYYY");
					setDataSelecionada(dataAssembleia);

					// Abre o modal de edição com os detalhes da assembleia
					abrirModalEdicao(data);

					// Carrega eventos relacionados à data da assembleia
					carregarEventos(dataAssembleia);

					// Verifica se todos os campos necessários estão preenchidos
					const isDataComplete = verificaCamposNecessarios(data);
					setIsDataSaved(isDataComplete);

					// Atualiza o estado de publicação da assembleia e da ata
					setIsAssemblyPublished(data.assembleia_publicada === 1);
					setIsMinutesPublished(data.ata_publicada === 1);
				} else {
					console.error("Erro ao buscar detalhes da assembleia");
				}
			} else {
				console.error(
					"Erro ao verificar e atualizar o token:",
					tokenData.message
				);
			}
		} catch (error) {
			console.error(
				"Erro na requisição para buscar detalhes da assembleia:",
				error
			);
		}
	};

	const handleUserSelect = (event) => {
		setSelectedUser(event.target.value);
		if (event.target.value) {
			addUserToAssembly(event.target.value);
		}
	};

	const handleAddAuxiliary = () => {
		setShowAuxiliar(!showAuxiliar);
	};

	//alteracao leo
	useEffect(() => {
		if (isUserModalOpen) {
			const fetchAllUsers = async () => {
				try {
					const response = await fetch(`${API_URL}/assembleia/usuarios/ativos`);
					if (response.ok) {
						const users = await response.json();
						//("Fetched Users:", users); // Log para verificar os usuários retornados
						// Agrupar usuários por cidade
						const groupedUsers = users.reduce((acc, user) => {
							const group = user.cidade_sede || "Sem cidade sede";
							if (!acc[group]) {
								acc[group] = [];
							}
							acc[group].push(user);
							return acc;
						}, {});
						//console.log("Grouped Users:", groupedUsers); // Log para verificar os usuários agrupados
						// Ordenar os usuários dentro de cada grupo
						Object.keys(groupedUsers).forEach((key) => {
							groupedUsers[key].sort((a, b) => a.nome.localeCompare(b.nome));
						});
						//console.log("Sorted Grouped Users:", groupedUsers); // Log para verificar os usuários agrupados e ordenados

						// Ordenar os grupos
						const sortedGroups = Object.keys(groupedUsers).sort((a, b) => {
							if (a === "Maringá") return -1;
							if (b === "Maringá") return 1;
							if (a === "Sem cidade sede") return 1;
							if (b === "Sem cidade sede") return -1;
							return a.localeCompare(b);
						});

						const orderedGroupedUsers = sortedGroups.reduce((acc, key) => {
							acc[key] = groupedUsers[key];
							return acc;
						}, {});

						setGroupedUserOptions(orderedGroupedUsers);
					} else {
						console.error("Failed to fetch users:", response.status);
					}
				} catch (error) {
					console.error("Error fetching users:", error);
				}
			};
			fetchAllUsers();
		}
	}, [isUserModalOpen, API_URL]);

	const fetchActiveUsers = async () => {
		const response = await fetch(`${API_URL}/assembleia/assembleia/usuarios`);
		if (response.ok) {
			const users = await response.json();
			setActiveUsers(users);
		} else {
			console.error("Failed to fetch active users");
		}
	};

	const addUserToAssembly = async (userId) => {
		const response = await fetch(`${API_URL}/assembleia/assembleia/add-user`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ id_func: userId }),
		});

		if (response.ok) {
			//console.log("User added successfully");
			fetchActiveUsers(); // Refresh the list after adding
		} else {
			console.error("Error adding user");
		}
	};

	const handleOpenUserModal = () => {
		setIsUserModalOpen(true);
		fetchActiveUsers();
	};

	const fetchSecretarios = async () => {
		try {
			const response = await fetch(`${API_URL}/assembleia/assembleia/usuarios`);
			if (response.ok) {
				const secretariosAtualizados = await response.json();
				setSecretarios(secretariosAtualizados);
			} else {
				console.error("Erro ao buscar secretários");
			}
		} catch (error) {
			console.error("Erro na requisição para buscar secretários:", error);
		}
	};

	const handleCloseUserModal = () => {
		setIsUserModalOpen(false);
		fetchSecretarios(); // Atualiza a lista de secretários após fechar o modal de usuários
	};

	const handleAddSecretary = () => {
		// Esta função irá alternar a visibilidade de um campo para adicionar um auxiliar
		setShowAuxiliar(!showAuxiliar); // Alterna a visibilidade do campo auxiliar
	};

	const removeUserFromAssembly = async (userId) => {
		const response = await fetch(
			`${API_URL}/assembleia/assembleia/remove-user`, // Ajuste para a nova rota
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id_func: userId }),
			}
		);

		if (response.ok) {
			//console.log("User removed successfully");
			fetchActiveUsers(); // Refresh the list after removing
		} else {
			console.error("Error removing user", await response.text());
		}
	};

	// FUNÇÕES DA DIV SALVAR //
	const getStatusFromChecklist = () => {
		// Retorna o índice do último item verdadeiro + 1, pois os status começam do 1 e não do 0
		return checklistStatus.lastIndexOf(true) + 1;
	};

	const handleSave = async () => {
		const dadosCompletos = verificaCamposNecessarios(assembleiaAtual);

		let novoStatus = assembleiaAtual.status_assembleia;
		const lastCheckedIndex = checklistStatus.lastIndexOf(true);
		if (lastCheckedIndex >= 0 && lastCheckedIndex + 1 > novoStatus) {
			novoStatus = lastCheckedIndex + 1;
		}

		const hrAssembleia = assembleiaAtual.hr_assembleia.includes(":")
			? assembleiaAtual.hr_assembleia
			: `${assembleiaAtual.hr_assembleia}:00`;

		const hrAssembleiaComSegundos =
			hrAssembleia.length === 5 ? `${hrAssembleia}:00` : hrAssembleia;

		const updatedAssembleia = {
			...assembleiaAtual,
			status_assembleia: novoStatus,
			dados_salvos: dadosCompletos ? 1 : 0,
			hr_assembleia: hrAssembleiaComSegundos,
			observacoes: assembleiaAtual.observacoes, // Adicionado o campo observacoes
		};

		const checkPublishedUrl = `${API_URL}/assembleia/check-published/${assembleiaAtual.id}`;

		try {
			const publishedResponse = await fetch(checkPublishedUrl);
			const { isPublished, eventId } = await publishedResponse.json();

			const url = `${API_URL}/assembleia/atualizar`;
			const response = await fetch(url, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(updatedAssembleia),
			});

			if (response.ok) {
				setIsDataSaved(dadosCompletos);
				toast.success("Dados salvos com sucesso!");

				if (isPublished && eventId) {
					const startDate = dayjs(assembleiaAtual.dt_assembleia).format(
						"YYYY-MM-DD"
					);
					const startTime = `${startDate}T${hrAssembleiaComSegundos}`;
					const endTime = dayjs(startTime)
						.add(30, "minutes")
						.format("YYYY-MM-DDTHH:mm:ss");

					const locationUrl = `${API_URL}/assembleia/location/${assembleiaAtual.id_cond}`;
					const locationResponse = await fetch(locationUrl);
					const locationData = await locationResponse.json();

					let cidade = "";
					let estado = "";
					let location = "Local a ser definido";

					if (locationData && locationData.cidade && locationData.estado) {
						cidade = locationData.cidade;
						estado = locationData.estado;
						location = `${cidade}, ${estado}`;
					}

					if (assembleiaAtual.formato_assembleia === "Remota") {
						location = `Online (${cidade}, ${estado})`;
					}

					const tipoAssembleiaSigla =
						assembleiaAtual.tipo_assembleia === "Extraordinária"
							? "AGE"
							: assembleiaAtual.tipo_assembleia === "Permanente"
							? "PERM"
							: assembleiaAtual.tipo_assembleia === "Instalação" //LCORREA - SSI 0058 - 11/09/2024
							? "AGI" //LCORREA - SSI 0058 - 11/09/2024
							: "AGO"; //LCORREA - SSI 0058 - 11/09/2024

					const responsavel =
						assembleiaAtual.nome_responsavel || "Sem responsável";
					const auxiliar = assembleiaAtual.nome_auxiliar || "";

					const sindicoResponse = await fetch(
						`${API_URL}/assembleia/sindico/${assembleiaAtual.id_cond}`
					);
					if (!sindicoResponse.ok) {
						toast.error("Erro ao buscar os e-mails do síndico");
						console.error("Erro ao buscar os e-mails do síndico");
						return;
					}
					const sindicoData = await sindicoResponse.json();

					let secretarioEmail = null;
					if (assembleiaAtual.id_func_responsavel) {
						const secretarioResponse = await fetch(
							`${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_responsavel}`
						);
						if (secretarioResponse.ok) {
							const secretarioData = await secretarioResponse.json();
							secretarioEmail = secretarioData.email;
						}
					}

					let auxiliarEmail = null;
					if (assembleiaAtual.id_func_auxiliar) {
						const auxiliarResponse = await fetch(
							`${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_auxiliar}`
						);
						if (auxiliarResponse.ok) {
							const auxiliarData = await auxiliarResponse.json();
							auxiliarEmail = auxiliarData.email;
						}
					}

					const emails = [
						...sindicoData.emails,
						secretarioEmail,
						auxiliarEmail,
					].filter(Boolean);

					const calendarUpdateUrl = `${API_URL}/assembleia/editar-evento`;
					const eventUpdates = {
						id: eventId,
						updates: {
							condominio: assembleiaAtual.condominio,
							formato_assembleia: assembleiaAtual.formato_assembleia,
							tipo_assembleia: assembleiaAtual.tipo_assembleia,
							nome_responsavel: assembleiaAtual.nome_responsavel,
							nome_auxiliar: assembleiaAtual.nome_auxiliar,
							summary: `${
								assembleiaAtual.condominio
							} - ${tipoAssembleiaSigla} - ${
								assembleiaAtual.formato_assembleia
							} (${responsavel}${auxiliar ? ` - ${auxiliar}` : ""})`,
							location: location,
							description:
								assembleiaAtual.assuntos_assembleia || "Sem pautas definidas",
							start: {
								dateTime: startTime,
								timeZone: "America/Sao_Paulo",
							},
							end: {
								dateTime: endTime,
								timeZone: "America/Sao_Paulo",
							},
							attendees: emails.map((email) => ({ email })),
						},
						sendUpdates: "all",
					};

					const calendarResponse = await fetch(calendarUpdateUrl, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(eventUpdates),
					});

					if (calendarResponse.ok) {
						toast.success("Evento do Google Calendar atualizado com sucesso!");
					} else {
						const calendarErrorText = await calendarResponse.text();
						toast.error(
							`Erro ao atualizar o evento do Google Calendar: ${calendarErrorText}`
						);
						console.error(
							"Erro ao atualizar o evento do Google Calendar:",
							calendarErrorText
						);
					}
				}
			} else {
				const errorText = await response.text();
				toast.error(`Erro ao salvar os dados: ${errorText}`);
				console.error("Erro ao salvar os dados:", errorText);
			}
		} catch (error) {
			toast.error(`Erro ao fazer a requisição: ${error.message}`);
			console.error("Erro ao fazer a requisição:", error);
		}
	};

	const handleDelete = async () => {
		try {
			if (isAssemblyPublished) {
				const checkPublishedUrl = `${API_URL}/assembleia/check-published/${assembleiaAtual.id}`;
				const publishedResponse = await fetch(checkPublishedUrl);
				const { isPublished, eventId } = await publishedResponse.json();

				if (isPublished && eventId) {
					const deleteUrl = `${API_URL}/assembleia/excluir-evento`;
					const response = await fetch(deleteUrl, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ eventId }),
					});

					if (!response.ok) {
						toast.error("Erro ao excluir assembleia no Google Calendar");
						return;
					}
				}
			}

			const deleteAssembleiaUrl = `${API_URL}/assembleia/excluir-assembleia`;
			const deleteAssembleiaResponse = await fetch(deleteAssembleiaUrl, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ id: assembleiaAtual.id }),
			});

			if (deleteAssembleiaResponse.ok) {
				setIsAssemblyPublished(false);
				fecharModalEdicao();
				setAssembleias((prevAssembleias) =>
					prevAssembleias.filter(
						(assembleia) => assembleia.id !== assembleiaAtual.id
					)
				);
				toast.success("Assembleia excluída com sucesso!");
			} else {
				toast.error("Erro ao excluir assembleia do banco de dados");
			}
		} catch (error) {
			toast.error(`Erro ao excluir a assembleia: ${error.message}`);
		}
	};

	const verificaCamposNecessarios = (assembleia) => {
		const camposNecessarios = [
			"condominio",
			"dt_assembleia",
			"dt_convocacao",
			"tipo_assembleia",
			"formato_assembleia",
			"nome_responsavel",
			"id_func_responsavel",
			"id_func_auxiliar",
			"assuntos_assembleia",
		];

		return camposNecessarios.every((campo) => {
			const valor = assembleia[campo];
			if (typeof valor === "string") {
				return valor.trim() !== "";
			} else {
				return valor !== undefined && valor !== null;
			}
		});
	};

	// Publicar assembleia
	const handlePublish = async () => {
		try {
			if (!assembleiaAtual || !assembleiaAtual.id_cond) {
				toast.error(
					"Assembleia atual ou ID do condomínio não estão definidos."
				);
				return;
			}

			const sindicoResponse = await fetch(
				`${API_URL}/assembleia/sindico/${assembleiaAtual.id_cond}`
			);
			if (!sindicoResponse.ok) {
				toast.error("Erro ao buscar os e-mails do síndico");
				return;
			}
			const sindicoData = await sindicoResponse.json();

			let secretarioEmail = null;
			if (assembleiaAtual.id_func_responsavel) {
				const secretarioResponse = await fetch(
					`${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_responsavel}`
				);
				if (secretarioResponse.ok) {
					const secretarioData = await secretarioResponse.json();
					secretarioEmail = secretarioData.email;
				}
			}

			let auxiliarEmail = null;
			if (assembleiaAtual.id_func_auxiliar) {
				const auxiliarResponse = await fetch(
					`${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_auxiliar}`
				);
				if (auxiliarResponse.ok) {
					const auxiliarData = await auxiliarResponse.json();
					auxiliarEmail = auxiliarData.email;
				}
			}

			const emails = [
				...sindicoData.emails,
				secretarioEmail,
				auxiliarEmail,
			].filter(Boolean);

			//LCORREA
			const tipoAssembleiaSigla =
				assembleiaAtual.tipo_assembleia === "Extraordinária"
					? "AGE"
					: assembleiaAtual.tipo_assembleia === "Permanente"
					? "PERM"
					: assembleiaAtual.tipo_assembleia === "Instalação" //LCORREA - SSI 0058 - 11/09/2024
					? "AGI" //LCORREA - SSI 0058 - 11/09/2024
					: "AGO"; //LCORREA - SSI 0058 - 11/09/2024

			const responsavel = assembleiaAtual.nome_responsavel || "Sem responsável";
			const auxiliar = assembleiaAtual.nome_auxiliar || "";

			const publishResponse = await fetch(
				`${API_URL}/assembleia/publicar-assembleia`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
					body: JSON.stringify({
						id: assembleiaAtual.id,
						emails,
						tipoAssembleia: assembleiaAtual.tipo_assembleia,
						tipoAssembleiaSigla,
						responsavel,
						auxiliar,
						id_func_publicador: user.id_func,
					}),
				}
			);

			if (!publishResponse.ok) {
				toast.error("Erro ao publicar assembleia");
				return;
			}

			const publishData = await publishResponse.json();
			setAssembleias((prevAssembleias) =>
				prevAssembleias.map((assembleia) =>
					assembleia.id === assembleiaAtual.id
						? { ...assembleia, assembleia_publicada: true }
						: assembleia
				)
			);

			setIsAssemblyPublished(true);
			toast.success("Assembleia publicada com sucesso!");
		} catch (error) {
			toast.error(`Erro ao publicar assembleia: ${error.message}`);
		}
	};

	const updateTable = async () => {
		const response = await fetch("/api/assembleias");
		const data = await response.json();
		setAssembleias(data); // Supondo que você tenha um estado `setAssembleias` para a tabela
	};

	const removerAnexo = async () => {
		try {
			const response = await fetch(`${API_URL}/assembleia/remover-anexo`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: assembleiaAtual.id,
				}),
			});

			const data = await response.json();
			if (response.ok) {
				setAssembleiaAtual({
					...assembleiaAtual,
					anexo_assembleia: null,
					nome_arquivo: null,
				});
				toast.success("Anexo removido com sucesso!");
			} else {
				toast.error(`Falha ao remover anexo: ${data.message}`);
			}
		} catch (error) {
			toast.error(`Erro ao remover anexo: ${error.message}`);
		}
	};

	const atualizarAnexo = async (fileData, fileName) => {
		try {
			const response = await fetch(`${API_URL}/assembleia/atualizar-anexo`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: assembleiaAtual.id,
					anexo_assembleia: fileData,
					nome_arquivo: fileName,
				}),
			});

			if (response.ok) {
				setAssembleiaAtual({
					...assembleiaAtual,
					anexo_assembleia: fileData,
					nome_arquivo: fileName,
				});
				toast.success("Anexo atualizado com sucesso!");
			} else {
				const errorText = await response.text();
				toast.error(`Falha ao atualizar anexo: ${errorText}`);
			}
		} catch (error) {
			toast.error(`Erro ao atualizar anexo: ${error.message}`);
		}
	};

	useEffect(() => {
		const newSocket = io("https://euro-web-757c1b49a8fb.herokuapp.com");
		setSocket(newSocket);
		newSocket.on("errorNotification", (errorMessage) => {
			//console.log("Received error notification:", errorMessage);
		});
		return () => newSocket.close();
	}, []);

	async function publicarAta() {
		try {
			if (!assembleiaAtual) {
				toast.error("Nenhuma assembleia selecionada.");
				return;
			}

			const assembleiaId = assembleiaAtual.id;
			const condominioId = assembleiaAtual.id_cond; // Pega o id do condomínio correto
			const response = await fetch(
				`${API_URL}/assembleia/download/${assembleiaId}`
			);
			if (!response.ok) {
				throw new Error("Falha ao obter a URL do anexo da assembleia.");
			}

			const blob = await response.blob();
			const reader = new FileReader();

			reader.readAsArrayBuffer(blob);
			reader.onloadend = async () => {
				const arrayBuffer = reader.result;
				const buffer = Array.from(new Uint8Array(arrayBuffer));

				const driveResponse = await fetch(
					`${API_URL}/assembleia/upload-to-drive`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ assembleiaId, buffer }),
					}
				);

				if (!driveResponse.ok) {
					throw new Error("Falha ao fazer upload para o Drive.");
				}
				const driveData = await driveResponse.json();

				const attachResponse = await fetch(
					`${API_URL}/assembleia/attach-to-event`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ assembleiaId, fileId: driveData.fileId }),
					}
				);

				if (!attachResponse.ok) {
					const attachError = await attachResponse.json();
					console.error(
						"Erro ao anexar o documento ao evento do calendário:",
						attachError
					);
					throw new Error(
						"Falha ao anexar o documento ao evento do calendário."
					);
				}

				const markPublishedResponse = await fetch(
					`${API_URL}/assembleia/marcar-ata-como-publicada`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({ assembleiaId }),
					}
				);

				if (!markPublishedResponse.ok) {
					throw new Error("Falha ao marcar ATA como publicada.");
				}

				setIsMinutesPublished(true);
				toast.success("ATA publicada com sucesso!");

				const userResponse = await fetch(
					`${API_URL}/assembleia/usuarios/${condominioId}`
				); // Use o ID do condomínio aqui
				if (!userResponse.ok) {
					throw new Error("Erro ao recuperar IDs dos usuários.");
				}
				const userData = await userResponse.json();
				const userIds = userData.userIds.map(String);

				socket.emit("sendNotificationToCustomList", {
					userIds,
					title: `Ata publicada para condominio ${assembleiaAtual.condominio}`,
					message: "A ata foi publicada",
					type: "general",
				});

				socket.on("notificationResponse", (response) => {
					//console.log("Resposta do servidor:", response);
				});

				socket.on("errorNotification", (error) => {
					toast.error(`Erro ao enviar notificação: ${error}`);
				});
			};
		} catch (error) {
			toast.error(
				`Erro durante o processo de publicação da ATA: ${error.message}`
			);
		}
	}

	// Função para carregar os eventos
	const carregarEventos = async (data) => {
		const formattedDate = dayjs(data, "DD-MM-YYYY").format("YYYY-MM-DD"); // Assegure-se de que a conversão está correta aqui
		//console.log("Carregando eventos para a data formatada:", formattedDate);

		try {
			const response = await fetch(
				`${API_URL}/assembleia/eventos?data=${data}`
			);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const eventos = await response.json();
			//console.log(`Total de eventos recebidos: ${eventos.length}`);

			const filteredEvents = eventos.filter((evento) => {
				const isAllDayEvent = evento.start.date
					? dayjs(evento.start.date, "YYYY-MM-DD").isSame(
							dayjs(data, "DD-MM-YYYY"),
							"day"
					  )
					: false;
				const isTimedEvent = dayjs(evento.start.dateTime).isSame(
					dayjs(data, "DD-MM-YYYY"),
					"day"
				);
				return isAllDayEvent || isTimedEvent;
			});

			//console.log(`Eventos após filtragem: ${filteredEvents.length}`);
			setEventos(filteredEvents);
		} catch (error) {
			console.error("Erro ao buscar eventos:", error);
		}
	};

	// Efeito para carregar eventos ao mudar a data
	useEffect(() => {
		//console.log("useEffect disparado, dataSelecionada:", dataSelecionada);
		carregarEventos(dataSelecionada);
	}, [dataSelecionada]);

	// Função para manipular mudança de data
	const mudarData = (delta) => {
		const novaData = dayjs(dataSelecionada, "DD-MM-YYYY")
			.add(delta, "day")
			.format("DD-MM-YYYY");
		//console.log(`Mudando a data de: ${dataSelecionada} para: ${novaData}`);
		setDataSelecionada(novaData);
		carregarEventos(novaData);
	};

	// ------------ SSI 0051 - LCORREA - 02/09/2024 - INICIO ------------

	// Função para calcular a data de convocação
	const calcularDataConvocacao = async (novaDataAssembleia, idCond) => {
		try {
			//console.log("Iniciando cálculo da nova rota de convocação...");

			const response = await fetch(
				`${API_URL}/assembleia/calc_convocacao/${idCond}/${novaDataAssembleia.format(
					"YYYY-MM-DD"
				)}`
			);

			if (!response.ok) {
				throw new Error(`Erro na requisição: ${response.statusText}`);
			}

			const { dt_convocacao } = await response.json();
			//console.log(`Data de convocação calculada: ${dt_convocacao}`);

			setAssembleiaAtual((prevAssembleia) => ({
				...prevAssembleia,
				dt_convocacao: dt_convocacao,
			}));
		} catch (error) {
			console.error("Erro ao calcular a data de convocação:", error);
		}
	};

	// No onChange da data de assembleia, calcular e atualizar a data de convocação
	const handleDataAssembleiaChange = (e) => {
		const novaDataAssembleia = dayjs(e.target.value);
		/*console.log(
			`Nova data de assembleia selecionada: ${novaDataAssembleia.format(
				"YYYY-MM-DD"
			)}`
		);
		*/
		if (!novaDataAssembleia.isValid()) {
			console.error("Data de assembleia inválida");
			return;
		}

		setAssembleiaAtual((prevAssembleia) => {
			/*console.log("Atualizando assembleia com nova data...");
			console.log(
				`Data de assembleia anterior: ${prevAssembleia.dt_assembleia}`
			);
			console.log(
				`Nova data de assembleia: ${novaDataAssembleia.format(
					"YYYY-MM-DDTHH:mm:ss"
				)}`
			);*/
			return {
				...prevAssembleia,
				dt_assembleia: novaDataAssembleia.format("YYYY-MM-DDTHH:mm:ss"),
			};
		});

		// Calcular a nova data de convocação com base na nova data de assembleia
		calcularDataConvocacao(novaDataAssembleia, assembleiaAtual.id_cond);
	};

	// ------------ SSI 0051 - LCORREA - 02/09/2024 - FIM DA ALTERAÇÃO ------------

	return (
		<div className="container-assembleia">
			<div className="titulo-assembleia">Painel de Assembleias</div>
			<div className="periodo-assembleia">
				<div className="navegacao-periodo-assembleia">
					<button onClick={mesAnterior} title="Mês Anterior">
						<WestIcon />
					</button>
					<span>{formataData(dataAtual)}</span>
					<button onClick={proximoMes} title="Próximo Mês">
						<EastIcon />
					</button>
				</div>
			</div>

			<div className="assembleia-container">
				<div className="filtro-assembleia">
					<TextField
						label="Filtrar por Condomínio ou Responsável"
						variant="outlined"
						fullWidth
						value={filtroTexto}
						onChange={(e) => setFiltroTexto(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div className="assembleia-status-wrapper">
					<AssembleiaStatus mesAtual={dataAtual.month()} />
					<div
						className="assembleia-status icone_adicionar-assembleia"
						title="Nova assembleia"
						onClick={openModal}
					>
						<AddCircleOutlineIcon style={{ fontSize: 48, color: "#007bff" }} />
						<h4>Adicionar</h4>
					</div>
				</div>
			</div>

			<div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
				<Button onClick={handleToggleTodos}>
					{mostrarTodos ? "Mostrar Usuário" : "Mostrar Todos"}
				</Button>
				<Button onClick={handleToggleFinalizadas}>
					{mostrarFinalizadas
						? "Ocultar Finalizadas"
						: "Visualizar Finalizadas"}
				</Button>
			</div>

			<div className="tabela-assembleia">
				<table>
					<thead>
						<tr>
							<th>#</th>
							<th onClick={() => changeOrder("condominio")}>Condomínio</th>
							<th onClick={() => changeOrder("cidade")}>Cidade</th>
							<th onClick={() => changeOrder("dt_convocacao")}>Convocação</th>
							<th onClick={() => changeOrder("dt_assembleia")}>Assembleia</th>
							<th onClick={() => changeOrder("tipo_assembleia")}>Tipo</th>
							<th onClick={() => changeOrder("formato_assembleia")}>Formato</th>
							<th onClick={() => changeOrder("nome_responsavel")}>
								Secretário
							</th>
							<th onClick={() => changeOrder("nome_auxiliar")}>Auxiliar</th>
							<th onClick={() => changeOrder("status_assembleia")}>Status</th>
							<th>ID</th>
							<th>Ações</th>
						</tr>
					</thead>
					<tbody>
						{sortAssembleias(displayedAssembleias)
							.filter((assembleia) => {
								const textoFiltro = filtroTexto.toLowerCase();
								const condMatch = assembleia.condominio
									.toLowerCase()
									.includes(textoFiltro);
								const respMatch = assembleia.nome_responsavel
									? assembleia.nome_responsavel
											.toLowerCase()
											.includes(textoFiltro)
									: false;
								const secMatch = assembleia.nome_auxiliar
									? assembleia.nome_auxiliar.toLowerCase().includes(textoFiltro)
									: false;
								return condMatch || respMatch || secMatch;
							})
							.filter(
								(assembleia) =>
									filtroFormato === "" ||
									assembleia.formato_assembleia.toLowerCase() === filtroFormato
							)
							.filter((assembleia) => {
								return (
									mostrarFinalizadas ||
									parseInt(assembleia.status_assembleia) !== 16
								);
							})

							.map((assembleia, index) => {
								let highlightClass = "";

								// Verifica se a assembleia está no dia anterior à data de convocação
								const dataAtual = dayjs().format("YYYY-MM-DD");

								// Corrigindo a formatação da data de convocação
								const dataConvocacaoParts = assembleia.dt_convocacao.split("/");
								const dataConvocacao = dayjs(
									`${dataConvocacaoParts[2]}-${dataConvocacaoParts[1]}-${dataConvocacaoParts[0]}`
								).format("YYYY-MM-DD");

								// Adicionando logs para verificar os valores
								/*console.log("Assembleia ID:", assembleia.id);
								console.log("Data Atual:", dataAtual);
								console.log("Data Convocação Corrigida:", dataConvocacao);
								console.log(
									"Comparação:",
									dayjs(dataAtual).isSame(
										dayjs(dataConvocacao).subtract(1, "day")
									)
								);*/

								if (
									dayjs(dataAtual).isSame(
										dayjs(dataConvocacao).subtract(1, "day")
									)
								) {
									highlightClass = "highlight-green-assembleia";
								} else if (parseInt(assembleia.status_assembleia, 10) === 1) {
									if (assembleia.statusData === "no dia") {
										highlightClass = "highlight-assembleia";
									} else if (assembleia.statusData === "atrasado") {
										highlightClass = "highlight-red-assembleia";
									}
								}

								// Adicionando log para verificar se a classe foi aplicada
								//console.log("Highlight Class:", highlightClass);

								return (
									<tr key={assembleia.id} className={highlightClass}>
										<td>{index + 1}</td>
										<td>{assembleia.condominio}</td>
										<td>{assembleia.cidade}</td>
										<td>{assembleia.dt_convocacao}</td>
										<td>{assembleia.dt_assembleia}</td>
										<td>{assembleia.tipo_assembleia}</td>
										<td>{assembleia.formato_assembleia}</td>
										<td>{assembleia.nome_responsavel || "-"}</td>
										<td>{assembleia.nome_auxiliar || "-"}</td>
										<td>
											{getStatusDescription(assembleia.status_assembleia)}
										</td>
										<td>
											<Tooltip
												title={
													<div>
														<div>
															Assembleia criada por:{" "}
															{assembleia.nome_criador || "Desconhecido"}, Data:{" "}
															{assembleia.dt_criacao}
														</div>
														{assembleia.evento_google_calendar_id ? (
															<div>
																Publicada por:{" "}
																{assembleia.nome_publicador || "Desconhecido"},
																Data: {assembleia.dt_publicacao}
															</div>
														) : (
															<div>Ainda não publicada</div>
														)}
													</div>
												}
												arrow
											>
												<span>{assembleia.id}</span>
											</Tooltip>
										</td>
										<td style={{ textAlign: "center" }}>
											<button
												style={{ margin: "auto", display: "block" }}
												onClick={() => fetchAssembleiaDetalhes(assembleia.id)}
											>
												<EditIcon />
											</button>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>

			{isModalOpen && (
				<div className="modal-background-assembleia" onClick={closeModal}>
					<div
						className="modal-content-assembleia"
						onClick={(e) => e.stopPropagation()}
					>
						<div className="modal-header-assembleia">
							<span className="close-button-placeholder-assembleia"></span>
							<h2assembleias>Cadastro prévio de assembleia</h2assembleias>
							<Button
								variant="contained"
								color="error"
								onClick={closeModal}
								sx={{
									minWidth: "auto",
									padding: "6px",
								}}
							>
								<CloseIcon />
							</Button>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="form-section-assembleia">
								<div className="two-columns-assembleia">
									<div className="left-column-assembleia">
										<div className="form-section form-section-spacing-assembleia">
											<div className="form-title form-title-spacing-assembleia">
												Condomínio
											</div>
											<Autocomplete
												id="condominioAutocomplete"
												options={condominiosFiltrados}
												getOptionLabel={(option) => option.fantasia}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Digite para filtrar..."
														variant="outlined"
														size="small"
														sx={{ width: 375 }}
													/>
												)}
												value={condominio}
												onChange={(event, newValue) => {
													setCondominio(newValue);
													if (newValue) {
														fetchDiasConvocacao(newValue.chave);
													} else {
														setDiasConvocacao(""); // Reset caso nenhum condomínio esteja selecionado
													}
												}}
												isOptionEqualToValue={(option, value) =>
													option.chave === value.chave
												}
											/>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												gap: "20px",
												marginBottom: "10px",
												marginTop: "-10px",
											}}
										>
											<TextField
												label="Convocação"
												variant="outlined"
												size="small"
												disabled={!condominio || !editavel}
												sx={{
													width: 120,
													".MuiInputBase-input": {
														textAlign: "center",
													},
												}}
												value={diasConvocacao}
												onChange={(e) => {
													const novosDiasConvocacao = e.target.value;
													setDiasConvocacao(novosDiasConvocacao);
												}}
											/>
											<Button
												variant="contained"
												style={{
													minWidth: "30px",
													height: "30px",
													borderRadius: "50%",
													color: "white",
													padding: "0px",
													backgroundColor: editavel ? "red" : "green",
												}}
												onClick={() => {
													if (editavel) {
														salvarDiasConvocacao();
													} else {
														setEditavel(true);
													}
												}}
											>
												<EditIcon style={{ fontSize: "20px" }} />
											</Button>
											<TextField
												label="Data da Convocação"
												variant="outlined"
												size="small"
												sx={{
													width: 165,
													".MuiInputBase-input": {
														textAlign: "center",
													},
												}}
												value={dataConvocacao}
												InputProps={{
													readOnly: true,
												}}
											/>
										</div>
										<FormControl
											component="fieldset"
											className="form-section-spacing-assembleia"
											sx={{ width: "100%" }}
										>
											<FormLabel
												component="legend"
												sx={{
													justifyContent: "center",
													display: "flex",
													textAlign: "center",
													fontWeight: "bold",
													color: "#333",
												}}
											>
												Tipo de Dias para Convocação
											</FormLabel>
											<RadioGroup
												row
												name="tipo_dia"
												value={tipoDia}
												onChange={(event) => {
													setTipoDia(event.target.value);
													atualizarDataConvocacao(
														event.target.value,
														diasConvocacao,
														selectedDate
													); // Atualizar a data de convocação ao mudar o tipo de dia
												}}
												sx={{
													justifyContent: "center",
													"& .MuiFormGroup-root": {
														justifyContent: "center",
													},
												}}
											>
												<FormControlLabel
													value="corridos"
													control={<Radio size="small" />}
													label="Dias Corridos"
												/>
												<FormControlLabel
													value="uteis"
													control={<Radio size="small" />}
													label="Dias Úteis"
												/>
											</RadioGroup>
										</FormControl>
										<FormControl
											component="fieldset"
											className="form-section-spacing-assembleia"
											sx={{ width: "100%" }}
										>
											<FormLabel
												component="legend"
												sx={{
													justifyContent: "center",
													display: "flex",
													textAlign: "center",
													fontWeight: "bold",
													color: "#333",
												}}
											>
												Tipo
											</FormLabel>
											<RadioGroup
												name="tipo"
												value={tipoAssembleia}
												onChange={(event) =>
													setTipoAssembleia(event.target.value)
												}
												sx={{
													display: "flex",
													flexDirection: "row",
													flexWrap: "wrap",
													justifyContent: "center",
													"& .MuiFormControlLabel-root": {
														width: "33%",
														margin: "2px 5px", // Ajuste a margem conforme necessário para deixar os campos mais próximos
													},
												}}
											>
												<FormControlLabel
													value="Ordinária"
													control={<Radio size="small" />}
													label="Ordinária"
												/>
												<FormControlLabel
													value="Extraordinária"
													control={<Radio size="small" />}
													label="Extraordinária"
												/>
												<FormControlLabel
													value="Instalação"
													control={<Radio size="small" />}
													label="Instalação"
												/>
												<FormControlLabel
													value="Permanente"
													control={<Radio size="small" />}
													label="Permanente"
												/>
											</RadioGroup>
										</FormControl>

										<FormControl
											component="fieldset"
											className="form-section-spacing-assembleia"
											sx={{ width: "100%", textAlign: "center" }}
										>
											<FormLabel
												component="legend"
												sx={{
													justifyContent: "center",
													display: "flex",
													textAlign: "center",
													fontWeight: "bold",
													color: "#333",
												}}
											>
												Formato
											</FormLabel>
											<RadioGroup
												row
												name="formato"
												value={formatoAssembleia}
												onChange={(event) =>
													setFormatoAssembleia(event.target.value)
												}
												sx={{
													justifyContent: "center",
													"& .MuiFormGroup-root": {
														justifyContent: "center",
													},
												}}
											>
												<FormControlLabel
													value="Presencial"
													control={<Radio size="small" />}
													label="Presencial"
												/>
												<FormControlLabel
													value="Remota"
													control={<Radio size="small" />}
													label="Remota"
												/>
												<FormControlLabel
													value="Híbrida"
													control={<Radio size="small" />}
													label="Híbrida"
												/>
											</RadioGroup>
										</FormControl>
									</div>
									<div className="right-column-assembleia">
										<div className="form-section-assembleia">
											<div className="form-title-assembleia">
												Data da assembleia
											</div>
											<LocalizationProvider
												dateAdapter={AdapterDayjs}
												adapterLocale="pt-br"
											>
												<StaticDatePicker
													displayStaticWrapperAs="desktop"
													openTo="day"
													value={selectedDate}
													onChange={(newValue) => {
														setSelectedDate(newValue);
														atualizarDataConvocacao(
															tipoDia,
															diasConvocacao,
															newValue
														); // Atualizar a data de convocação ao mudar a data da assembleia
													}}
												/>
											</LocalizationProvider>
										</div>
									</div>
								</div>
							</div>
							<div
								className="form-section submit-section-assembleia"
								style={{ minHeight: "60px" }}
							>
								{showAlertPrevia && (
									<div className="alert-message">{alertMessagePrevia}</div>
								)}
								<Button type="submit" variant="outlined" color="success">
									Cadastrar
								</Button>
							</div>
						</form>
					</div>
				</div>
			)}

			{isEditModalOpen && (
				<div className="modalEdicao-assembleia">
					<div className="modalEdicaoTitulo-assembleia">
						Agendamento de Assembleia
					</div>
					<div className="modalEdicaoCorpo-assembleia">
						<div
							className={`colunaEsquerda-assembleia ${
								!secaoExpandida ? "nenhumaSelecionada-assembleia" : ""
							}`}
						>
							<div
								className={`divCampo-assembleia ${
									secaoExpandida === "campos"
										? "expandido-assembleia"
										: "minimizado-assembleia"
								}`}
								onClick={() => alternarSecaoExpandida("campos")}
							>
								<div className="abaTitulo-assembleia">
									<BorderColorIcon className="abaIcone-assembleia" /> Dados
								</div>
								{secaoExpandida === "campos" && (
									<div
										className="conteudoDados-assembleia"
										onClick={(event) => event.stopPropagation()}
									>
										<TextField
											id="condominio"
											label="Condomínio"
											value={assembleiaAtual ? assembleiaAtual.condominio : ""}
											variant="outlined"
											disabled
											fullWidth
											sx={{
												mb: 2,
												".MuiInputBase-input": { fontSize: "1.2rem" },
											}}
										/>

										<div
											style={{
												display: "flex",
												gap: "20px",
												marginBottom: "20px",
											}}
										>
											<TextField
												id="dataAssembleia"
												label="Data da Assembleia"
												type="date"
												value={
													assembleiaAtual && assembleiaAtual.dt_assembleia
														? dayjs(assembleiaAtual.dt_assembleia).format(
																"YYYY-MM-DD"
														  )
														: ""
												}
												onChange={(e) => {
													const novaDataAssembleia = dayjs(e.target.value);
													setAssembleiaAtual({
														...assembleiaAtual,
														dt_assembleia: novaDataAssembleia.format(
															"YYYY-MM-DDTHH:mm:ss"
														),
													});

													// Calcular a nova data de convocação com base na nova data de assembleia
													calcularDataConvocacao(
														novaDataAssembleia,
														assembleiaAtual.id_cond
													);
												}}
												variant="outlined"
												fullWidth
												sx={{
													mb: 2,
													".MuiInputBase-input": { fontSize: "1.2rem" },
													flex: 1,
												}}
												InputLabelProps={{ shrink: true }}
											/>
											<TextField
												id="horario"
												label="Horário"
												type="time"
												value={
													assembleiaAtual && assembleiaAtual.hr_assembleia
														? assembleiaAtual.hr_assembleia.substring(0, 5)
														: ""
												}
												onChange={(e) => {
													const time = e.target.value;
													const minutes = parseInt(time.split(":")[1]);
													if (minutes % 5 === 0) {
														setAssembleiaAtual({
															...assembleiaAtual,
															hr_assembleia: time,
														});
													} else {
														const adjustedMinutes = Math.round(minutes / 5) * 5;
														const adjustedTime =
															time.split(":")[0] +
															":" +
															(adjustedMinutes < 10 ? "0" : "") +
															adjustedMinutes;
														setAssembleiaAtual({
															...assembleiaAtual,
															hr_assembleia: adjustedTime,
														});
													}
												}}
												variant="outlined"
												fullWidth
												sx={{
													mb: 2,
													".MuiInputBase-input": { fontSize: "1.2rem" },
													flex: 1,
												}}
												InputLabelProps={{ shrink: true }}
												inputProps={{
													step: 300, // 300 segundos = 5 minutos
												}}
											/>
										</div>

										<TextField
											id="dtConvocacao"
											label="Data da Convocação"
											type="date"
											value={
												assembleiaAtual
													? dayjs(assembleiaAtual.dt_convocacao).format(
															"YYYY-MM-DD"
													  )
													: ""
											}
											variant="outlined"
											fullWidth
											sx={{
												mb: 2,
												".MuiInputBase-input": { fontSize: "1.2rem" },
											}}
											InputLabelProps={{ shrink: true }}
											disabled
										/>

										<div className="assemblyTypeSection-assembleia">
											<FormControl component="fieldset" sx={{ mb: 2 }}>
												<FormLabel component="legend">
													Tipo de Assembleia
												</FormLabel>
												<RadioGroup
													row
													aria-label="tipoAssembleia"
													name="tipoAssembleia"
													value={
														assembleiaAtual
															? assembleiaAtual.tipo_assembleia
															: ""
													}
													onChange={(e) =>
														setAssembleiaAtual({
															...assembleiaAtual,
															tipo_assembleia: e.target.value,
														})
													}
												>
													<FormControlLabel
														value="Ordinária"
														control={<Radio />}
														label="Ordinária"
													/>
													<FormControlLabel
														value="Extraordinária"
														control={<Radio />}
														label="Extraordinária"
													/>
													<FormControlLabel
														value="Instalação"
														control={<Radio />}
														label="Instalação"
													/>
													<FormControlLabel
														value="Permanente"
														control={<Radio />}
														label="Permanente"
													/>
												</RadioGroup>
											</FormControl>
										</div>

										<div className="formatSection-assembleia">
											<FormControl component="fieldset">
												<FormLabel component="legend">Formato</FormLabel>
												<RadioGroup
													row
													aria-label="formato"
													name="formato"
													value={
														assembleiaAtual
															? assembleiaAtual.formato_assembleia
															: ""
													}
													onChange={(e) =>
														setAssembleiaAtual({
															...assembleiaAtual,
															formato_assembleia: e.target.value,
														})
													}
												>
													<FormControlLabel
														value="Presencial"
														control={<Radio />}
														label="Presencial"
													/>
													<FormControlLabel
														value="Remota"
														control={<Radio />}
														label="Remota"
													/>
													<FormControlLabel
														value="Híbrida"
														control={<Radio />}
														label="Híbrida"
													/>
												</RadioGroup>
											</FormControl>
										</div>
									</div>
								)}
							</div>

							<div
								className={`divPauta-assembleia ${
									secaoExpandida === "pauta"
										? "expandido-assembleia"
										: "minimizado-assembleia"
								}`}
								onClick={() => alternarSecaoExpandida("pauta")}
							>
								<div className="abaTitulo-assembleia">
									<LibraryBooksIcon className="abaIcone-assembleia" /> Pauta
								</div>
								{secaoExpandida === "pauta" && (
									<div
										className="conteudoPauta-assembleia"
										onClick={(event) => event.stopPropagation()} // Adicionado para impedir a propagação do evento
									>
										<TextField
											id="assuntosPauta"
											label="Assuntos da Pauta"
											multiline
											rows={4}
											value={
												assembleiaAtual
													? assembleiaAtual.assuntos_assembleia || ""
													: ""
											}
											onChange={(e) =>
												setAssembleiaAtual({
													...assembleiaAtual,
													assuntos_assembleia: e.target.value,
												})
											}
											variant="outlined"
											fullWidth
											sx={{
												mb: 2,
												".MuiInputBase-input": { fontSize: "1.2rem" },
											}}
										/>
										<div>
											<div style={{ display: "flex", alignItems: "center" }}>
												<FormControl fullWidth sx={{ mr: 2, mb: 2 }}>
													<InputLabel id="secretario-label">
														Secretário
													</InputLabel>
													<Select
														labelId="secretario-label"
														id="secretario"
														value={assembleiaAtual.id_func_responsavel || ""}
														onChange={(e) => {
															const selectedSecretario = secretarios.find(
																(secretario) =>
																	secretario.id_func === e.target.value
															);
															setAssembleiaAtual({
																...assembleiaAtual,
																id_func_responsavel: e.target.value,
																nome_responsavel: selectedSecretario
																	? selectedSecretario.nome
																	: "",
															});
														}}
														label="Secretário"
													>
														<MenuItem value="">
															<em>Nenhum</em>
														</MenuItem>
														{secretarios.map((secretario) => (
															<MenuItem
																key={secretario.id_func}
																value={secretario.id_func}
															>
																{secretario.nome}
															</MenuItem>
														))}
													</Select>
												</FormControl>

												<IconButton
													aria-label="add"
													size="small"
													onClick={handleAddSecretary}
													style={{ marginBottom: "8px" }}
												>
													<AddIcon />
												</IconButton>
												<IconButton
													aria-label="edit-note"
													onClick={handleOpenUserModal}
													style={{ marginBottom: "8px" }}
												>
													<EditNoteIcon />
												</IconButton>
											</div>

											{showAuxiliar && (
												<FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
													<InputLabel id="auxiliar-label">Auxiliar</InputLabel>
													<Select
														labelId="auxiliar-label"
														id="auxiliar"
														value={assembleiaAtual.id_func_auxiliar || ""}
														onChange={(e) => {
															const selectedAuxiliar = secretarios.find(
																(secretario) =>
																	secretario.id_func === e.target.value
															);
															setAssembleiaAtual({
																...assembleiaAtual,
																id_func_auxiliar: e.target.value,
																nome_auxiliar: selectedAuxiliar
																	? selectedAuxiliar.nome
																	: "",
															});
														}}
														label="Auxiliar"
													>
														<MenuItem value="">
															<em>Nenhum</em>
														</MenuItem>
														{secretarios
															.filter(
																(secretario) =>
																	secretario.id_func !==
																	assembleiaAtual.id_func_responsavel
															)
															.map((filteredSecretario) => (
																<MenuItem
																	key={filteredSecretario.id_func}
																	value={filteredSecretario.id_func}
																>
																	{filteredSecretario.nome}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											)}

											{/* Novo campo de Observações */}
											<TextField
												id="observacoes"
												label="Observações"
												multiline
												rows={4}
												value={
													assembleiaAtual
														? assembleiaAtual.observacoes || ""
														: ""
												}
												onChange={(e) =>
													setAssembleiaAtual({
														...assembleiaAtual,
														observacoes: e.target.value,
													})
												}
												variant="outlined"
												fullWidth
												sx={{
													mt: 2,
													".MuiInputBase-input": { fontSize: "1.2rem" },
												}}
											/>

											<Dialog
												open={isUserModalOpen}
												onClose={handleCloseUserModal}
												fullWidth
												maxWidth="sm"
												sx={{
													"& .MuiDialog-paper": {
														width: "470px",
														height: "600px",
														borderRadius: "10px",
														padding: "30px",
														backgroundColor: "#f5f5f5",
														boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
														transition: "transform 0.3s ease-in-out",
														overflow: "hidden",
													},
												}}
											>
												<DialogTitle
													sx={{ color: "#5c6bc0", fontWeight: "bold" }}
												>
													Gerenciar Usuários para Assembleia
												</DialogTitle>
												<DialogContent
													sx={{
														overflowY: "auto",
														paddingTop: 0,
														paddingBottom: 0,
													}}
												>
													<FormControl fullWidth>
														<InputLabel htmlFor="grouped-select">
															Adicionar Usuário
														</InputLabel>
														<Select
															value={selectedUser}
															onChange={handleUserSelect}
															id="grouped-select"
															label="Adicionar Usuário"
															MenuProps={{
																PaperProps: {
																	style: {
																		maxHeight: 300, // Tamanho máximo da lista
																	},
																},
																anchorOrigin: {
																	vertical: "bottom",
																	horizontal: "left",
																},
																transformOrigin: {
																	vertical: "top",
																	horizontal: "left",
																},
															}}
														>
															<MenuItem value="">
																<em>Nenhum</em>
															</MenuItem>
															{Object.keys(groupedUserOptions)
																.sort((a, b) => {
																	if (a === "Maringá") return -1;
																	if (b === "Maringá") return 1;
																	if (a === "Sem cidade sede") return 1;
																	if (b === "Sem cidade sede") return -1;
																	return a.localeCompare(b);
																})
																.map((city) => [
																	<ListSubheader key={city}>
																		{city}
																	</ListSubheader>,
																	...groupedUserOptions[city].map((user) => (
																		<MenuItem
																			key={user.id_func}
																			value={user.id_func}
																		>
																			{user.nome}
																		</MenuItem>
																	)),
																])}
														</Select>
													</FormControl>

													<List
														sx={{
															maxHeight: "360px",
															overflow: "auto",
															bgcolor: "#f7f7f7",
															borderRadius: "5px",
															marginY: "20px",
														}}
													>
														{activeUsers.map((user) => (
															<ListItem
																key={user.id_func}
																secondaryAction={
																	<IconButton
																		edge="end"
																		aria-label="delete"
																		onClick={() =>
																			removeUserFromAssembly(user.id_func)
																		}
																		sx={{ color: "#ef5350" }}
																	>
																		<CloseIcon />
																	</IconButton>
																}
																sx={{
																	bgcolor: "white",
																	marginBottom: "10px",
																	boxShadow: 3,
																}}
															>
																<ListItemText primary={user.nome} />
															</ListItem>
														))}
													</List>
												</DialogContent>
											</Dialog>
										</div>
									</div>
								)}
							</div>

							<div
								className={`divChecklist-assembleia ${
									secaoExpandida === "checklist"
										? "expandido-assembleia"
										: "minimizado-assembleia"
								}`}
								onClick={() => alternarSecaoExpandida("checklist")}
							>
								<div className="abaTitulo-assembleia">
									<ChecklistIcon className="abaIcone-assembleia" /> Checklist
								</div>
								{secaoExpandida === "checklist" && (
									<div
										className="conteudoChecklist-assembleia"
										onClick={(event) => event.stopPropagation()}
									>
										<div className="checklistColumn-assembleia">
											<h2assembleias>Pré-Assembléia</h2assembleias>
											{checklistItems.slice(0, 7).map((item, index) => (
												<FormControlLabel
													key={index}
													control={
														<Checkbox
															checked={checklistStatus[index]}
															onChange={() => handleChecklistChange(index)}
															disabled={
																!(
																	checklistStatus[index] ||
																	index ===
																		checklistStatus.lastIndexOf(true) + 1
																)
															}
														/>
													}
													label={`${index + 1}. ${item}`}
												/>
											))}
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													gap: "10px",
													marginTop: "20px",
													alignItems: "center", // Adiciona alinhamento vertical ao centro para os ícones
												}}
											>
												{assembleiaAtual.anexo_assembleia ? (
													<>
														<Tooltip title="Baixar Anexo Atual">
															<a
																href={`${API_URL}/assembleia/download/${assembleiaAtual.id}`}
																download={
																	assembleiaAtual.nome_arquivo ||
																	"Download_Assembleia.pdf"
																}
																style={{
																	textDecoration: "none",
																	display: "flex",
																	alignItems: "center",
																	color: "inherit",
																}}
															>
																<CloudDownloadIcon style={{ marginRight: 8 }} />
															</a>
														</Tooltip>
														<Tooltip title="Remover Anexo">
															<IconButton
																onClick={removerAnexo}
																style={{ color: "#cc0000" }}
															>
																<DeleteIcon />
															</IconButton>
														</Tooltip>
														<Tooltip title="Publicar Ata">
															<IconButton
																onClick={() => publicarAta()}
																style={{ color: "#33cc33" }}
															>
																<PublishIcon />
															</IconButton>
														</Tooltip>
													</>
												) : (
													<TextField
														id="anexo"
														type="file"
														fullWidth
														sx={{
															mb: 2,
															".MuiInputBase-input": { fontSize: "1.2rem" },
														}}
														onChange={(e) => {
															if (e.target.files[0]) {
																const file = e.target.files[0];
																const reader = new FileReader();
																reader.onload = async (event) => {
																	await atualizarAnexo(
																		event.target.result,
																		file.name
																	);
																};
																reader.readAsDataURL(file);
															}
														}}
													/>
												)}
											</div>
										</div>
										<div className="checklistColumn-assembleia">
											<h2assembleias>Pós-Assembléia</h2assembleias>
											{checklistItems.slice(7, 16).map((item, index) => {
												let realIndex = index + 7;
												return (
													<FormControlLabel
														key={realIndex}
														control={
															<Checkbox
																checked={checklistStatus[realIndex]}
																onChange={() =>
																	handleChecklistChange(realIndex)
																}
																disabled={
																	!isAssemblyPublished ||
																	!(
																		checklistStatus[realIndex] ||
																		realIndex ===
																			checklistStatus.lastIndexOf(true) + 1
																	)
																}
															/>
														}
														label={`${realIndex + 1}. ${item}`}
													/>
												);
											})}
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="colunaDireita-assembleia">
							<div className="divAgenda-assembleia">
								<div className="fixedHeader-assembleia">
									<h2assembleias>Agenda</h2assembleias>
									<div className="dateNavigation-assembleia">
										<button
											onClick={() => mudarData(-1)}
											aria-label="Previous Day"
										>
											&lt;
										</button>
										<span>{dataSelecionada}</span>
										<button onClick={() => mudarData(1)} aria-label="Next Day">
											&gt;
										</button>
									</div>
									<div className="dayName-assembleia">{nomeDoDia}</div>{" "}
									{/* Exibe o nome do dia */}
								</div>
								<div className="scrollableContent-assembleia">
									{eventos.map((evento) => (
										<div key={evento.id} className="eventItem-assembleia">
											<h3>{evento.summary}</h3>
											<p>
												{dayjs(evento.start.dateTime).format("HH:mm")} -
												{dayjs(evento.end.dateTime).format("HH:mm")}
											</p>
										</div>
									))}
								</div>
							</div>
							<div className="divsalvar-container-assembleia">
								<div className="divsalvar-button-column-assembleia">
									<Button
										className="divsalvar-save-button-assembleia"
										onClick={handleSave}
										variant="contained"
										color="primary"
									>
										Salvar Dados
									</Button>
									<Button
										className="divsalvar-delete-button-assembleia"
										onClick={handleDelete}
										variant="contained"
										color="secondary"
									>
										Excluir
									</Button>
									{!isAssemblyPublished && (
										<Button
											className="divsalvar-publish-button-assembleia"
											onClick={handlePublish}
											variant="contained"
											color="secondary"
										>
											Publicar
										</Button>
									)}
								</div>
								<div className="divsalvar-status-column-assembleia">
									<div className="divsalvar-status-item-assembleia">
										<div
											className={
												isDataSaved
													? "divsalvar-icon-green-assembleia"
													: "divsalvar-icon-red-assembleia"
											}
										/>
										<span>Pronto para publicar</span>
									</div>

									<div className="divsalvar-status-item-assembleia">
										<div
											className={
												isAssemblyPublished
													? "divsalvar-icon-green-assembleia"
													: "divsalvar-icon-red-assembleia"
											}
										/>
										<span>Assembleia publicada</span>
									</div>

									<div className="divsalvar-status-item-assembleia">
										<div
											className={
												isMinutesPublished
													? "divsalvar-icon-green-assembleia"
													: "divsalvar-icon-red-assembleia"
											}
										/>
										<span>Ata Publicada</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button
						onClick={fecharModalEdicao}
						className="fecharBotao-assembleia"
					>
						Fechar
					</button>
				</div>
			)}
		</div>
	);
};

export default Assembleia;
