import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { format, parse, getMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importe o plugin
import './TotalReceitaPeriodo.css';

const mesAtual = format(new Date(), 'MMM/yy', { locale: ptBR });


// Registre o plugin globalmente
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Adicione esta linha para registrar o plugin
);

const GraficoReceitaPeriodo = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [periodo, setPeriodo] = useState('ano');
    const [totalAcumulado, setTotalAcumulado] = useState(0);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
          label: 'Total de Receita',
          data: [],
          backgroundColor: 'rgba(4, 30, 51, 0.9)',
          borderColor: 'rgba(215, 215, 215)',
          borderWidth: 1,
        }],
      });
    
    const handlePeriodoChange = (event) => {
      setPeriodo(event.target.value);
    };
    
    useEffect(() => {
        axios.get(`${API_URL}/dados-dashboard/receitas-por-periodo?periodo=${periodo}`)
          .then(response => {
            const data = response.data;
            if (Array.isArray(data)) {
              const total = data.reduce((acc, curr) => acc + curr.total, 0);
              setTotalAcumulado(total);
              const backgroundColors = data.map(item => {
                return format(parse(item.mes, 'yyyy-MM', new Date()), 'MMM/yy', { locale: ptBR }) === mesAtual
                  ? 'rgb(22, 159, 97)' // Cor para o mês atual
                  : 'rgba(4, 30, 51, 0.9)'; // Cor padrão para outros meses
              });
              setChartData({
                labels: data.map(item => format(parse(item.mes, 'yyyy-MM', new Date()), 'MMM/yy', { locale: ptBR })),
                datasets: [{
                  label: 'Total de Receita',
                  data: data.map(item => item.total),
                  backgroundColor: backgroundColors,
                }],
              });
            } else {
              console.error('Os dados recebidos não são um array:', data);
            }
          })
          .catch(error => console.log(error));
    }, [API_URL, periodo, mesAtual]); // Adicione `mesAtual` como dependência se ela for calculada fora do useEffect
    

  let delayed;

  const configTotal = {
    maintainAspectRatio: false,
    type:'bar',
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === 'data' && context.mode === 'default' && !delayed) {
          delay = context.dataIndex * 50 + context.datasetIndex * 50;
        }
        return delay;
      },
    },
    layout: {
      padding: {
        top: 20 // Ajuste este valor conforme necessário para evitar cortes
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#343434',
          font: {
            size: '10px',
            weight: '600',
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
            display: false,
          color: '#343434',
          callback: function(value) {
            return value.toLocaleString();
          },
          stepSize: 5000000,
          min: 0,
          max: 50000000,
          font: {
            size: '11px',
            weight: '600',
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: false,
      },
    },
  };
  
  return (
    <div className='container-receita-por-periodo'>
        <div className="seletor-periodo">
            <label htmlFor="periodo">Selecione o período:</label>
            <select id="periodo" value={periodo} onChange={handlePeriodoChange}>
                <option value="mes">Último Mês</option>
                <option value="trimestre">Últimos 3 Meses</option>
                <option value="semestre">Últimos 6 Meses</option>
                <option value="ano">Último Ano</option>
            </select>
        </div>
        <div className="grafico-total-receita-por-periodo">
            <Bar data={chartData} options={configTotal} />
        </div>
        <div className="total-acumulado">
            <h3>Total do período: R$ {totalAcumulado.toLocaleString()}</h3>
        </div>
    </div>
  );
  
  
};

export default GraficoReceitaPeriodo;
