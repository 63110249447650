import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Add this import statement
import useAuth from '../hooks/useAuth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const auth = useAuth();
  const [user, setUser] = useState(auth.user);

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;

      if (Date.now() >= expirationTime) {
        localStorage.removeItem('token');
        setUser(null);
      } else {
        setUser(decodedToken);
      }
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {
    if (auth.user && JSON.stringify(auth.user) !== JSON.stringify(user)) {
      setUser(auth.user);
    }
  }, [auth.user]);

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
