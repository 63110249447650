import React, { useState, useEffect, useContext } from "react";
import UserContext from "../UserContext";
import moment from "moment-timezone";
import "./FechamentoMensal.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
	Box,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	RadioGroup,
	Radio,
	Button,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import VisaoGeralFechamento from "./VisaoGeralFechamento"; // SSI 0029 - LCORREA - 20/06/2024

const FechamentoMensal = () => {
	const { user } = useContext(UserContext);
	//console.log("Informações do usuário logado:", user);
	const initialUserSelected = user.funcao === "Agente" ? user.nome : "";
	const [usuarios, setUsuarios] = useState([]);
	const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
	const [selecionarTodos, setSelecionarTodos] = useState(false);
	const [reloadTrigger, setReloadTrigger] = useState(false); // Usado para forçar a re-renderização
	const [mesSelecionado, setMesSelecionado] = useState("");
	const [anoSelecionado, setAnoSelecionado] = useState(
		new Date().getFullYear().toString()
	);
	const [filtroAtual, setFiltroAtual] = useState({ coluna: "", direcao: "" });
	const [dadosFechamento, setDadosFechamento] = useState([]);
	const [dadosOrdenados, setDadosOrdenados] = useState([]);
	const API_URL = process.env.REACT_APP_API_URL;
	const [buscaRealizada, setBuscaRealizada] = useState(false); // Ajuste: Usado para iniciar a busca apenas após o clique
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [historicoCondominio, setHistoricoCondominio] = useState(null);
	const [events, setEvents] = useState([]);
	const axios = require("axios");
	const [carregando, setCarregando] = useState(false);
	const [abaSelecionada, setAbaSelecionada] = useState("PorUsuario");
	const [mesSelecionadoCondominio, setMesSelecionadoCondominio] = useState("");
	const [anoSelecionadoCondominio, setAnoSelecionadoCondominio] = useState("");
	const [periodoAtualCondominio, setPeriodoAtualCondominio] = useState(true);
	const [documentoSelecionado, setDocumentoSelecionado] = useState("");
	const [inicioPeriodo, setInicioPeriodo] = useState(
		moment().subtract(11, "months").format("YYYY-MM-DD")
	);
	const [fimPeriodo, setFimPeriodo] = useState(moment().format("YYYY-MM-DD"));
	const [dadosFechamentoCondominio, setDadosFechamentoCondominio] = useState(
		[]
	);
	const [exibirTabela, setExibirTabela] = useState(false);
	const [isTransitioning, setIsTransitioning] = useState(false);
	const isGestorOuAdmin = user.funcao === "admin" || user.funcao === "Gestor";
	const [documentosEnviadosState, setDocumentosEnviadosState] = useState(0);
	const [totalDocumentosState, setTotalDocumentosState] = useState(0);
	const [resumoMensal, setResumoMensal] = useState({});
	const [condominiosFaltantes, setCondominiosFaltantes] = useState([]);
	const [mostrarModalFaltantes, setMostrarModalFaltantes] = useState(false);
	const [periodoSelecionado, setPeriodoSelecionado] = useState("");
	const [filtroCondominio, setFiltroCondominio] = React.useState("");

	useEffect(() => {
		const fetchUsuarios = async () => {
			try {
				const response = await fetch(`${API_URL}/fechamento-mensal/usuarios`);
				const data = await response.json();
				//console.log("Usuários carregados:", data);
				// Adiciona a opção "Todos" à lista de usuários
				const todosOption = { nome: "Todos", id: "todos" };
				setUsuarios([todosOption, ...data]);
			} catch (error) {
				console.error("Erro ao buscar usuários:", error);
			}
		};

		// Corrigindo a condição para chamar fetchUsuarios apenas se o usuário for admin ou Gestor
		if (user.funcao === "admin" || user.funcao === "Gestor") {
			fetchUsuarios();
		} else {
			// Inicializa usuarioSelecionado para usuários que não são admin ou Gestor
			setUsuarioSelecionado({
				nome: user.nome,
				id: user.id_func, // Corrigido de id_func para id
			});
			setSelecionarTodos(false);
		}
	}, [user, API_URL]);

	const handleUsuarioChange = (event, newValue) => {
		//console.log("Usuário selecionado:", newValue); // Loga o valor selecionado
		setUsuarioSelecionado(newValue);
	};

	// Garante que o mapeamento de estado esteja correto, especialmente para consumosIndividuais
	const adaptHistoricoToStateFormat = (historico) => {
		const registro = historico.registro || historico; // Ajuste para considerar a estrutura de dados

		const processedData = {
			balancetes_enviado: Boolean(registro.balancetes_enviado),
			boleto_enviado: Boolean(registro.boleto_enviado),
			leitura_enviado: Boolean(registro.leitura_enviado),
			consumos_individuais_enviado: Boolean(
				registro.consumos_individuais_enviado
			),
			reservas_enviado: Boolean(registro.reservas_enviado),
			reinf_enviado: Boolean(registro.reinf_enviado), // Novo campo
			balancetes_data_envio: registro.balancetes_data_envio,
			boleto_data_envio: registro.boleto_data_envio,
			leitura_data_envio: registro.leitura_data_envio,
			consumos_individuais_data_envio: registro.consumos_individuais_data_envio,
			reservas_data_envio: registro.reservas_data_envio,
			reinf_data_envio: registro.reinf_data_envio, // Novo campo
		};

		//console.log(`[adaptHistoricoToStateFormat] Processed Data:`, processedData);
		return processedData;
	};

	const buscarCondominiosDoUsuario = (idUsuario) => {
		const endpoint =
			idUsuario === "todos"
				? `${API_URL}/fechamento-mensal/condominios`
				: `${API_URL}/fechamento-mensal/condominios/${idUsuario}`;

		fetch(endpoint)
			.then((response) => response.json())
			.then((data) => {
				//console.log("[LOG] Dados recebidos:", data); // Log para observar os dados
				processCondominios(data);
			})
			.catch((error) =>
				console.error("[LOG] Erro ao buscar condomínios:", error)
			);
	};

	const realizarBusca = () => {
		setPeriodoReferencia({
			ano: anoSelecionado,
			mes: meses.findIndex((mes) => mes === mesSelecionado) + 1,
		});

		//console.log("[LOG] Busca iniciada com período de referência:", {
		//	ano: anoSelecionado,
		//	mes: mesSelecionado,
		//});

		if (selecionarTodos) {
			buscarCondominiosDoUsuario("todos");
		} else if (usuarioSelecionado && usuarioSelecionado.id) {
			buscarCondominiosDoUsuario(usuarioSelecionado.id);
		} else {
			console.error("Usuário não selecionado.");
		}
	};

	const processCondominios = (condominios) => {
		//console.log("[LOG] Processando condomínios:", condominios);
		const mesIndex = meses.indexOf(mesSelecionado) + 1; // Asegura que mesIndex seja numérico e correto.
		const ano = anoSelecionado; // Usa o anoSelecionado do estado.

		const promises = condominios.map((condominio) => {
			// Para cada condomínio, verifica se existe registro no fechamento_condominios para o mês/ano.
			return fetch(
				`${API_URL}/fechamento-mensal/historico-condominios/${condominio.id}/${ano}/${mesIndex}`
			)
				.then((response) => response.json())
				.then((historico) => {
					const processedHistorico = adaptHistoricoToStateFormat(historico);
					//console.log(
					//	`[Data Transformation] Processed data for ${condominio.id}:`,
					//	processedHistorico
					//);
					return { ...condominio, historico: processedHistorico };
				});
		});

		Promise.all(promises).then((dadosProcessados) => {
			setDadosFechamento(dadosProcessados);
			setCarregando(false); // Finaliza o carregamento
		});
	};

	const [periodoReferencia, setPeriodoReferencia] = useState({
		ano: null,
		mes: null,
	});

	const handleBuscarClick = () => {
		//console.log("Busca Iniciada - Detalhes:", {
		//	Usuario:
		//		usuarioSelecionado && usuarioSelecionado.nome
		//			? usuarioSelecionado.nome
		//			: "Nenhum usuário selecionado",
		//	Ano: anoSelecionado,
		//	Mes: mesSelecionado,
		//});

		if (
			!selecionarTodos &&
			(!usuarioSelecionado || usuarioSelecionado === "")
		) {
			alert("O campo Funcionário está vazio");
			return;
		}

		// Verificação para garantir que um ano foi selecionado
		if (!anoSelecionado || anoSelecionado === "") {
			alert("O campo Ano está vazio");
			return; // Interrompe a execução se o ano não for selecionado
		}

		// Verificação para garantir que um mês foi selecionado
		if (!mesSelecionado || mesSelecionado === "") {
			alert("O campo Mês está vazio");
			return; // Interrompe a execução se o mês não for selecionado
		}

		setCarregando(true); // Inicia o carregamento

		setDadosFechamento([]); // Limpa os dados para visualização de "carregamento"
		setTimeout(() => {
			realizarBusca(); // Executa a busca após um breve atraso para permitir a visualização de limpeza
		}, 1000); // Atraso pode ser ajustado conforme a necessidade
	};

	const LoadingIndicator = () => <div className="spinner"></div>;

	const handleSelecionarTodosChange = (event) => {
		setSelecionarTodos(event.target.checked);
		if (event.target.checked) {
			// Atualiza o estado para refletir a seleção de "Todos"
			setUsuarioSelecionado({ nome: "Todos", id: "todos" });
		} else {
			setUsuarioSelecionado(null);
		}
	};

	const recarregarDados = () => {
		realizarBusca(); // Chama a busca para recarregar os dados
	};

	useEffect(() => {
		//onsole.log("Dados de fechamento atualizados:", dadosFechamento);
	}, [dadosFechamento]);

	const fieldMapping = {
		balancetes: "balancetes_enviado",
		boleto: "boleto_enviado",
		leitura: "leitura_enviado",
		consumosIndividuais: "consumos_individuais_enviado",
		reservas: "reservas_enviado",
		reinf: "reinf_enviado", // Novo campo
	};

	const handleItemChange = async (condominioId, fieldName, isChecked) => {
		const columnName = fieldMapping[fieldName];

		if (!columnName) {
			console.error(`[handleItemChange] Campo '${fieldName}' não encontrado.`);
			return;
		}

		const requestBody = {
			item: columnName,
			isChecked: isChecked ? 1 : 0,
		};

		const mesIndex = meses.indexOf(mesSelecionado) + 1;
		const url = `${API_URL}/fechamento-mensal/historico-condominios/update/${condominioId}/${anoSelecionado}/${mesIndex}`;

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody),
			});

			if (!response.ok)
				throw new Error(
					`Falha na resposta do servidor. Status: ${response.status}`
				);

			//console.log("[handleItemChange] Atualização bem-sucedida.");
			recarregarDados();
		} catch (error) {
			console.error(`[handleItemChange] Erro na atualização:`, error);
		}
	};

	const formatDate = (dateString) => {
		if (
			!dateString ||
			dateString === "null" ||
			!periodoReferencia.ano ||
			!periodoReferencia.mes
		)
			return "";

		// Ajustar a string de data para garantir o formato ISO 8601
		let isoDateString = dateString.replace(
			/(\d{4})-(\d{1,2})-(\d{1,2})/,
			(match, year, month, day) => {
				return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
			}
		);

		const diferencaEmMeses = calcularAtrasoEmMeses(
			isoDateString,
			periodoReferencia.ano,
			periodoReferencia.mes
		);
		const classeCor = diferencaEmMeses >= 2 ? "data-atrasada" : "data-envio";

		const date = moment.utc(isoDateString).format("DD/MM/YYYY"); // Formata apenas a data

		return `<div class="${classeCor}">${date}</div>`; // Remove a exibição do horário
	};

	// Funções para manipular os meses e anos
	const meses = [
		"Jan",
		"Fev",
		"Mar",
		"Abr",
		"Mai",
		"Jun",
		"Jul",
		"Ago",
		"Set",
		"Out",
		"Nov",
		"Dez",
	];

	// Atualiza o array de anos para incluir até 2030
	const anos = Array.from({ length: 2030 - 2022 + 1 }, (_, i) => i + 2023);

	const atualizarFiltro = (coluna) => {
		//console.log(`[atualizarFiltro] Chamado para coluna: ${coluna}`);
		setFiltroAtual((estadoAtual) => {
			//console.log(`[atualizarFiltro] Estado atual do filtro:`, estadoAtual);

			let novoEstado = { ...estadoAtual };
			if (
				estadoAtual.coluna === coluna &&
				estadoAtual.direcao === "false-true"
			) {
				novoEstado = { coluna: "", direcao: "" };
			} else if (estadoAtual.coluna !== coluna || estadoAtual.direcao === "") {
				novoEstado = {
					coluna,
					direcao: coluna === "condominio" ? "A-Z" : "true-false",
				};
			} else if (
				estadoAtual.direcao === "A-Z" ||
				estadoAtual.direcao === "true-false"
			) {
				novoEstado.direcao =
					estadoAtual.direcao === "A-Z" ? "Z-A" : "false-true";
			} else {
				novoEstado = { coluna: "", direcao: "" };
			}

			//console.log(`[atualizarFiltro] Novo estado do filtro:`, novoEstado);
			return novoEstado;
		});
	};

	const mapeamentoColunaPropriedade = {
		condominio: "CONDOMINIO",
		balancetes: "balancetes_enviado",
		boleto: "boleto_enviado",
		leitura: "leitura_enviado",
		consumosIndividuais: "consumos_individuais_enviado",
		reservas: "reservas_enviado",
		reinf: "reinf_enviado", // Novo campo
	};

	const ordenarDados = (dados) => {
		//console.log(
		//  `[ordenarDados] Iniciando ordenação com filtroAtual:`,
		//  filtroAtual
		//);

		if (!filtroAtual.coluna) {
			//console.log(
			//  "[ordenarDados] Nenhum filtro aplicado, retornando dados originais."
			//);
			return dados; // Retorna os dados originais se não houver filtro
		}

		const dadosOrdenados = [...dados].sort((a, b) => {
			let valorA, valorB;

			if (filtroAtual.coluna === "condominio") {
				valorA = a[mapeamentoColunaPropriedade[filtroAtual.coluna]];
				valorB = b[mapeamentoColunaPropriedade[filtroAtual.coluna]];
			} else {
				valorA = a.historico[mapeamentoColunaPropriedade[filtroAtual.coluna]];
				valorB = b.historico[mapeamentoColunaPropriedade[filtroAtual.coluna]];
			}

			//console.log(`[ordenarDados] Comparando A: ${valorA}, B: ${valorB}`);

			if (filtroAtual.coluna === "condominio") {
				return filtroAtual.direcao === "A-Z"
					? valorA.localeCompare(valorB)
					: valorB.localeCompare(valorA);
			} else {
				return filtroAtual.direcao === "true-false"
					? (valorB ? 1 : 0) - (valorA ? 1 : 0)
					: (valorA ? 1 : 0) - (valorB ? 1 : 0);
			}
		});

		//console.log(`[ordenarDados] Dados ordenados:`, dadosOrdenados);
		return dadosOrdenados;
	};

	const ordemCategoriasTotais = [
		"reinf",
		"leitura",
		"consumosIndividuais",
		"reservas",
		"balancetes",
		"boleto",
	];

	// Ajuste a função calcularTotais para usar essa nova ordem
	const calcularTotais = (dados) => {
		let totais = {
			balancetes: { marcados: 0, total: dados.length },
			boleto: { marcados: 0, total: dados.length },
			leitura: { marcados: 0, total: dados.length },
			consumosIndividuais: { marcados: 0, total: dados.length },
			reservas: { marcados: 0, total: dados.length },
			reinf: { marcados: 0, total: dados.length },
		};

		dados.forEach((item) => {
			Object.keys(totais).forEach((categoria) => {
				if (item.historico[mapeamentoColunaPropriedade[categoria]]) {
					totais[categoria].marcados++;
				}
			});
		});

		return totais;
	};

	const totais = calcularTotais(dadosOrdenados);

	useEffect(() => {
		/*console.log(
      `[useEffect] DadosFechamento e FiltroAtual:`,
      dadosFechamento,
      filtroAtual
    );*/
		const dadosOrdenadosTemporarios = ordenarDados(dadosFechamento);
		/*
    console.log(
      `[useEffect] DadosOrdenados após ordenação:`,
      dadosOrdenadosTemporarios
    );
    */
		setDadosOrdenados(dadosOrdenadosTemporarios);
	}, [dadosFechamento, filtroAtual]);

	const buscarHistoricoCondominio = async (
		idCondominio,
		id_cond,
		nomeCondominio
	) => {
		try {
			// Busca o histórico do condomínio com base no ID da tabela euro_carteiras
			const urlHistorico = `${API_URL}/fechamento-mensal/historico-condominios/completo/${idCondominio}`;
			const responseHistorico = await fetch(urlHistorico);
			const dataHistorico = await responseHistorico.json();

			// Busca o ID do funcionário responsável pelo financeiro do condomínio com base no id_cond
			const urlCarteira = `${API_URL}/fechamento-mensal/euro_carteiras/${id_cond}`;
			const responseCarteira = await fetch(urlCarteira);
			const dataCarteira = await responseCarteira.json();
			const financeiroId = dataCarteira.FINANCEIRO;

			// Busca o nome do funcionário usando o ID financeiro
			const urlUsuario = `${API_URL}/fechamento-mensal/euro_usuarios/${financeiroId}`;
			const responseUsuario = await fetch(urlUsuario);
			const dataUsuario = await responseUsuario.json();
			const nomeFuncionario = dataUsuario.nome;

			// Atualiza o estado incluindo o nome do funcionário responsável
			setHistoricoCondominio({
				data: dataHistorico,
				nomeCondominio,
				nomeFuncionario, // Nome do funcionário responsável pelo financeiro
			});

			setIsModalOpen(true);
		} catch (error) {
			console.error("Erro ao buscar informações:", error);
		}
	};

	// Função para fechar o modal
	const handleCloseModal = () => setIsModalOpen(false);

	const calcularAtrasoEmMeses = (dataEnvio, ano, mes) => {
		// Garante que mês e dia sejam preenchidos com dois dígitos
		const mesFormatado = mes.toString().padStart(2, "0");

		// Constrói a string da data de referência no formato ISO 8601
		const dataReferenciaISO = `${ano}-${mesFormatado}-01`;
		//console.log(`Data de referência formatada: ${dataReferenciaISO}`);

		const periodoReferencia = moment(dataReferenciaISO);
		//console.log(
		//  `Momento do período de referência: ${periodoReferencia.format(
		//  "YYYY-MM-DD"
		//)}`
		//);

		// A data de envio já está em formato ISO, então podemos passá-la diretamente
		const dataEnvioMoment = moment(dataEnvio);
		//console.log(
		//  `Momento da data de envio: ${dataEnvioMoment.format("YYYY-MM-DD HH:mm")}`
		//);

		const diferencaEmMeses = periodoReferencia.diff(
			dataEnvioMoment,
			"months",
			true
		);
		//console.log(`Diferença em meses: ${diferencaEmMeses}`);

		return Math.abs(diferencaEmMeses);
	};

	//FUNCOES PARA AS ABAS //
	const mudarAba = (aba) => {
		console.log("Mudando aba para:", aba);
		setAbaSelecionada(aba);
	};

	useEffect(() => {
		const activeBtn = document.querySelector(".aba-btn.ativo"); // Seleciona o botão ativo
		const container = document.querySelector(".abas-container"); // Seleciona o container das abas

		if (activeBtn) {
			const btnRect = activeBtn.getBoundingClientRect(); // Obtém as dimensões e posição do botão ativo
			const containerRect = container.getBoundingClientRect(); // Obtém as dimensões e posição do container

			// Calcula a largura e a posição esquerda da bolha de seleção
			const bubbleWidth = btnRect.width;
			const bubbleLeft =
				btnRect.left - containerRect.left + (container.scrollLeft || 0) + "px";

			// Atualiza as variáveis CSS para posicionar e dimensionar a bolha de seleção
			container.style.setProperty("--bubble-width", `${bubbleWidth}px`);
			container.style.setProperty("--bubble-left", bubbleLeft);
		}
	}, [abaSelecionada]); // Dependência: abaSelecionada

	//console.log("Aba selecionada:", abaSelecionada);

	// PARTE DA ABA DE CONDOMINIOS

	const handleMesSelecionadoCondominioChange = (event) => {
		setMesSelecionadoCondominio(event.target.value);
		setPeriodoAtualCondominio(false);
		atualizarPeriodo(event.target.value, anoSelecionadoCondominio, false);
		setExibirTabela(false); // Esconde a tabela
	};

	const handleAnoSelecionadoCondominioChange = (event) => {
		setAnoSelecionadoCondominio(event.target.value);
		setPeriodoAtualCondominio(false);
		atualizarPeriodo(mesSelecionadoCondominio, event.target.value, false);
		setExibirTabela(false); // Esconde a tabela
	};

	const handlePeriodoAtualCondominioChange = (event) => {
		const isPeriodoAtualSelected = event.target.value === "atual";
		setPeriodoAtualCondominio(isPeriodoAtualSelected);
		if (isPeriodoAtualSelected) {
			setMesSelecionadoCondominio("");
			setAnoSelecionadoCondominio("");
			atualizarPeriodo(null, null, true);
		}
		setExibirTabela(false); // Esconde a tabela
	};

	const handleDocumentoSelecionadoChange = (event) => {
		setDocumentoSelecionado(event.target.value);
		setExibirTabela(false); // Esconde a tabela ao mudar a seleção de documento
	};

	const buscarCondominiosAbaCondominios = async () => {
		setCarregando(true);
		//console.log(`Iniciando busca...`);
		//console.log(`Documento Selecionado: ${documentoSelecionado}`);
		const isPeriodoAtual = periodoAtualCondominio;
		//console.log(`Período Atual? ${isPeriodoAtual ? "Sim" : "Não"}`);

		const referenciaMes = isPeriodoAtual
			? moment().month() + 1
			: mesSelecionadoCondominio;
		const referenciaAno = isPeriodoAtual
			? moment().year()
			: anoSelecionadoCondominio;

		//console.log(`Período de Referência: ${referenciaMes}/${referenciaAno}`);

		const inicioPeriodoCalculado = moment(
			`${referenciaAno}-${referenciaMes}`,
			"YYYY-MM"
		)
			.subtract(11, "months")
			.format("YYYY-MM-DD");
		const fimPeriodoCalculado = moment(
			`${referenciaAno}-${referenciaMes}`,
			"YYYY-MM"
		).format("YYYY-MM-DD");

		//console.log(
		//	`Buscar registros de ${documentoSelecionado} de ${inicioPeriodoCalculado} até ${fimPeriodoCalculado}`
		//);

		try {
			const url = `${API_URL}/fechamento-mensal/buscar-historico-documentos?documento=${documentoSelecionado}&inicio=${inicioPeriodoCalculado}&fim=${fimPeriodoCalculado}`;
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();

			//console.log("Dados recebidos da API:", data);

			if (!Array.isArray(data.resultados)) {
				console.error(
					"Expected 'data.resultados' to be an array, but it's not:",
					data.resultados
				);
				return;
			}

			let dadosProcessados = data.resultados.map((resultado, index) => {
				const documentosMes = {};
				for (
					let mes = moment(inicioPeriodoCalculado);
					mes.isSameOrBefore(fimPeriodoCalculado);
					mes.add(1, "month")
				) {
					documentosMes[mes.format("MM/YYYY")] = "✕";
				}
				resultado.documentos.forEach((doc) => {
					const mesAno = moment(`${doc.ano}-${doc.mes}`, "YYYY-MM").format(
						"MM/YYYY"
					);
					documentosMes[mesAno] =
						doc[`${documentoSelecionado}_enviado`] === 1 ? "✓" : "✕";
				});

				return {
					numeroLinha: index + 1,
					nomeCondominio: resultado.fantasia || "Desconhecido",
					responsavel: resultado.responsavel || "Não informado",
					documentosPorMes: documentosMes,
				};
			});

			let resumoPorMes = {};
			let documentosEnviados = 0; // Reset para calcular dentro do loop
			let totalDocumentos = 0; // Reset para calcular dentro do loop

			dadosProcessados.forEach((condominio) => {
				Object.entries(condominio.documentosPorMes).forEach(([mes, status]) => {
					if (!resumoPorMes[mes]) {
						resumoPorMes[mes] = { enviados: 0, total: 0 };
					}
					resumoPorMes[mes].total += 1;
					if (status === "✓") {
						resumoPorMes[mes].enviados += 1;
						documentosEnviados += 1; // Aqui contabiliza os enviados
					}
					totalDocumentos += 1; // Contabiliza o total independentemente do status
				});
			});

			// Atualização dos estados com os valores calculados
			setResumoMensal(resumoPorMes);
			setDadosFechamentoCondominio(dadosProcessados);
			setDocumentosEnviadosState(documentosEnviados);
			setTotalDocumentosState(totalDocumentos);

			setExibirTabela(true);
		} catch (error) {
			console.error("Erro ao buscar dados:", error);
		} finally {
			setCarregando(false);
		}
	};

	// Ajusta a renderização dos meses na tabela
	const renderizarMeses = () => {
		const meses = [];
		let mesAtual = moment(inicioPeriodo);

		while (mesAtual.isSameOrBefore(fimPeriodo)) {
			meses.push(mesAtual.format("MM/YYYY"));
			mesAtual.add(1, "month");
		}

		return meses.map((mes, index) => <TableCell key={index}>{mes}</TableCell>);
	};

	const atualizarPeriodo = (mes, ano, periodoAtual) => {
		let inicio, fim;
		if (periodoAtual) {
			inicio = moment().subtract(11, "months").startOf("month");
			fim = moment().endOf("month");
		} else {
			inicio = moment(`${ano}-${mes}`, "YYYY-MM")
				.subtract(11, "months")
				.startOf("month");
			fim = moment(`${ano}-${mes}`, "YYYY-MM").endOf("month");
		}
		setInicioPeriodo(inicio.format("YYYY-MM-DD"));
		setFimPeriodo(fim.format("YYYY-MM-DD"));
	};

	const mesesCondominio = [
		"Janeiro",
		"Fevereiro",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Setembro",
		"Outubro",
		"Novembro",
		"Dezembro",
	];

	const StatusIcon = ({ status }) => {
		return (
			<span
				style={{
					color: status === "✓" ? "green" : "red",
					display: "block",
					textAlign: "center",
				}}
			>
				{status}
			</span>
		);
	};

	const [ordenacao, setOrdenacao] = React.useState({
		campo: null, // "n", "condominio", "responsavel", ou um identificador de mês
		direcao: "asc", // 'asc' para ascendente, 'desc' para descendente
	});

	const [filtroMes, setFiltroMes] = React.useState({
		mes: null, // Identificador do mês, ex: "01/2022"
		status: null, // 'feito' ou 'naoFeito'
	});

	const ordenarDadosCondominio = (dados) => {
		//console.log(
		//	"Ordenando dados por campo:",
		//	ordenacao.campo,
		//	"Direção:",
		//	ordenacao.direcao
		//);
		if (!ordenacao.campo) return dados;

		return [...dados].sort((a, b) => {
			if (ordenacao.campo === "n") {
				return ordenacao.direcao === "asc"
					? a.numeroLinha - b.numeroLinha
					: b.numeroLinha - a.numeroLinha;
			} else {
				// Usando optional chaining e coalescência nula para um fallback seguro
				let campoA = a[ordenacao.campo]?.toLowerCase() ?? "";
				let campoB = b[ordenacao.campo]?.toLowerCase() ?? "";

				if (campoA < campoB) return ordenacao.direcao === "asc" ? -1 : 1;
				if (campoA > campoB) return ordenacao.direcao === "asc" ? 1 : -1;
				return 0;
			}
		});
	};

	const filtrarDadosPorMes = (dados) => {
		//console.log(
		//	"Filtrando dados por mês. Mês:",
		//	filtroMes.mes,
		//	"Status:",
		//	filtroMes.status
		//);
		if (!filtroMes.mes || filtroMes.status === null) return dados;

		return dados.filter((condominio) => {
			const statusDocumento = condominio.documentosPorMes[filtroMes.mes];
			return filtroMes.status === "feito" ? statusDocumento : !statusDocumento;
		});
	};

	const identificarCondominiosFaltantes = (mesSelecionado) => {
		//console.log(
		//	`Identificando condomínios faltantes para o mês: ${mesSelecionado}`
		//);
		setPeriodoSelecionado(mesSelecionado); // Atualiza o estado com o período selecionado

		const faltantes = dadosFechamentoCondominio
			.filter(
				(condominio) => condominio.documentosPorMes[mesSelecionado] === "✕"
			)
			.map((condominio) => ({
				nome: condominio.nomeCondominio,
				responsavel: condominio.responsavel,
			}));

		//console.log(`Condomínios faltantes:`, faltantes);
		setCondominiosFaltantes(faltantes);
		setMostrarModalFaltantes(true);
	};

	const [ordenacaoModal, setOrdenacaoModal] = useState({
		campo: null, // "nome" ou "responsavel"
		direcao: null, // null, "asc", "desc"
	});

	const atualizarOrdenacaoModal = (campo) => {
		if (ordenacaoModal.campo === campo) {
			setOrdenacaoModal({
				campo,
				direcao:
					ordenacaoModal.direcao === "asc"
						? "desc"
						: ordenacaoModal.direcao === "desc"
						? null
						: "asc",
			});
		} else {
			setOrdenacaoModal({
				campo,
				direcao: "asc",
			});
		}
	};

	const condominiosOrdenadosModal = React.useMemo(() => {
		if (!ordenacaoModal.direcao) return condominiosFaltantes; // Sem ordenação

		return [...condominiosFaltantes].sort((a, b) => {
			if (ordenacaoModal.direcao === "asc") {
				return a[ordenacaoModal.campo].localeCompare(b[ordenacaoModal.campo]);
			} else if (ordenacaoModal.direcao === "desc") {
				return b[ordenacaoModal.campo].localeCompare(a[ordenacaoModal.campo]);
			}
			return 0;
		});
	}, [condominiosFaltantes, ordenacaoModal]);

	const dadosFiltrados = dadosFechamentoCondominio.filter((condominio) =>
		condominio.nomeCondominio
			.toLowerCase()
			.includes(filtroCondominio.toLowerCase())
	);

	React.useEffect(() => {
		//console.log("Dados antes da ordenação:", [...dadosFechamentoCondominio]);
		const dadosReordenados = ordenarDadosCondominio([
			...dadosFechamentoCondominio,
		]);
		//console.log("Dados depois da ordenação:", dadosReordenados);
		setDadosFechamentoCondominio(dadosReordenados);
	}, [ordenacao]);

	React.useEffect(() => {
		if (isTransitioning) {
			//console.log("Iniciando transição");
			const timer = setTimeout(() => {
				//console.log("Atualizando dados após transição");
				const dadosReordenados = ordenarDadosCondominio([
					...dadosFechamentoCondominio,
				]);
				//console.log("Dados depois da ordenação:", dadosReordenados);
				setDadosFechamentoCondominio(dadosReordenados);
				setIsTransitioning(false); // Finaliza a transição
			}, 500); // Corresponde ao tempo de duração da transição CSS

			return () => clearTimeout(timer); // Limpa o timer se o componente desmontar
		}
	}, [isTransitioning]); // Dependência agora é `isTransitioning`

	return (
		<div className="fechamento-container">
			<div className="fechamento-content">
				<div className="titulo-container">
					<h1 className="fechamento-title">Fechamento Mensal</h1>
				</div>

				<div
					className={`abas-container ${
						!isGestorOuAdmin ? "centralizar-aba" : ""
					}`}
				>
					{isGestorOuAdmin && (
						<>
							<button
								onClick={() => setAbaSelecionada("PorCondominio")}
								className={`aba-btn ${
									abaSelecionada === "PorCondominio" ? "ativo" : ""
								}`}
							>
								Por Condomínio
							</button>
							<button
								onClick={() => setAbaSelecionada("VisaoGeral")}
								className={`aba-btn ${
									abaSelecionada === "VisaoGeral" ? "ativo" : ""
								}`}
							>
								Visão Geral
							</button>
						</>
					)}
					<button
						onClick={() => setAbaSelecionada("PorUsuario")}
						className={`aba-btn ${
							abaSelecionada === "PorUsuario" ? "ativo" : ""
						}`}
					>
						Por Usuário
					</button>
				</div>

				{abaSelecionada === "PorUsuario" && (
					<>
						<div className="selecao-wrapper">
							<div className="selecao-usuario-periodo-container">
								<div className="usuario-periodo">
									<div className="linha-usuario-periodo">
										<div className="campo-usuario">
											{user &&
											(user.funcao === "admin" || user.funcao === "Gestor") ? (
												<>
													<Autocomplete
														className="menu-selecao-usuario"
														value={usuarioSelecionado}
														onChange={handleUsuarioChange}
														options={usuarios}
														sx={{ minWidth: 225 }}
														getOptionLabel={(option) => option.nome ?? ""}
														isOptionEqualToValue={(option, value) =>
															option.id === value.id || value.id === "todos"
														}
														renderInput={(params) => (
															<TextField
																{...params}
																label={
																	selecionarTodos
																		? "Todos"
																		: "Selecione um usuário"
																}
																variant="outlined"
																size="small"
															/>
														)}
														disabled={selecionarTodos}
													/>
												</>
											) : (
												<TextField
													fullWidth
													label="Usuário"
													variant="outlined"
													value={user.nome}
													InputProps={{ readOnly: true }}
												/>
											)}
										</div>

										<div className="controles-data">
											<FormControl
												size="small"
												sx={{ minWidth: 120, marginRight: 2 }}
											>
												<InputLabel>Mês</InputLabel>
												<Select
													value={mesSelecionado}
													onChange={(e) => setMesSelecionado(e.target.value)}
													label="Mês"
												>
													{meses.map((mes, index) => (
														<MenuItem key={index} value={mes}>
															{mes}
														</MenuItem>
													))}
												</Select>
											</FormControl>

											<FormControl size="small" sx={{ minWidth: 120 }}>
												<InputLabel>Ano</InputLabel>
												<Select
													value={anoSelecionado}
													onChange={(e) => setAnoSelecionado(e.target.value)}
													label="Ano"
												>
													{anos.map((ano) => (
														<MenuItem key={ano} value={ano}>
															{ano}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</div>
									</div>
								</div>
								{user &&
									(user.funcao === "Gestor" || user.funcao === "admin") && (
										<div className="checkbox-selecionar-todos">
											<FormControlLabel
												control={
													<Checkbox
														checked={selecionarTodos}
														onChange={handleSelecionarTodosChange}
													/>
												}
												label="Selecionar todos os usuários"
											/>
										</div>
									)}
							</div>
						</div>

						<div className="buscar-container">
							<button onClick={handleBuscarClick} className="buscar-button">
								Buscar
							</button>
							{carregando && (
								<div className="loading-container">
									<LoadingIndicator />
								</div>
							)}
						</div>

						{dadosOrdenados.length > 0 && (
							<div className="fechamento-tabela">
								<table>
									<thead>
										<tr className="header-row">
											<th
												style={{ width: "600px" }}
												className="sticky-header"
												onClick={() => atualizarFiltro("condominio")}
											>
												Condomínios{" "}
												{filtroAtual.coluna === "condominio" &&
													(filtroAtual.direcao === "A-Z"
														? "↓"
														: filtroAtual.direcao === "Z-A"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("reinf")}
											>
												ReInf{" "}
												{filtroAtual.coluna === "reinf" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("leitura")}
											>
												Leitura{" "}
												{filtroAtual.coluna === "leitura" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("consumosIndividuais")}
											>
												Consumos Individuais{" "}
												{filtroAtual.coluna === "consumosIndividuais" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("reservas")}
											>
												Reservas{" "}
												{filtroAtual.coluna === "reservas" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("balancetes")}
											>
												Balancetes{" "}
												{filtroAtual.coluna === "balancetes" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
											<th
												className="sticky-header"
												onClick={() => atualizarFiltro("boleto")}
											>
												Boleto{" "}
												{filtroAtual.coluna === "boleto" &&
													(filtroAtual.direcao === "true-false"
														? "↓"
														: filtroAtual.direcao === "false-true"
														? "↑"
														: "")}
											</th>
										</tr>
										<tr className="header-row">
											<th className="sticky-header"></th>
											{ordemCategoriasTotais.map((categoria, index) => (
												<th
													key={index}
													className="sticky-header"
													style={{
														color:
															totais[categoria].marcados ===
															totais[categoria].total
																? "green"
																: "inherit",
													}}
												>
													{totais[categoria].marcados ===
													totais[categoria].total
														? `${totais[categoria].marcados}/${totais[categoria].total} 👍`
														: `${totais[categoria].marcados}/${totais[categoria].total}`}
												</th>
											))}
										</tr>
									</thead>

									<tbody>
										{dadosOrdenados.map((condominio) => (
											<tr key={condominio.id}>
												{/* Nome do Condomínio */}
												<td
													onClick={() =>
														buscarHistoricoCondominio(
															condominio.id,
															condominio.id_cond,
															condominio.fantasia
														)
													}
												>
													<span className="condominio-tabela">
														{condominio.fantasia}{" "}
														{/* Aqui é onde a mudança é aplicada */}
													</span>
												</td>
												{/* Re Inf */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={condominio.historico.reinf_enviado}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"reinf",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico.reinf_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico.reinf_data_envio
																),
															}}
														></div>
													)}
												</td>
												{/* Leitura - Replicando o padrão de Balancetes */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={condominio.historico.leitura_enviado}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"leitura",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico.leitura_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico.leitura_data_envio,
																	anoSelecionado,
																	mesSelecionado
																),
															}}
														></div>
													)}
												</td>
												{/* Consumos Individuais - Replicando o padrão de Balancetes */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={
																condominio.historico
																	.consumos_individuais_enviado
															}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"consumosIndividuais",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico
														.consumos_individuais_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico
																		.consumos_individuais_data_envio,
																	anoSelecionado,
																	mesSelecionado
																),
															}}
														></div>
													)}
												</td>
												{/* Célula para Reservas */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={condominio.historico.reservas_enviado}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"reservas",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico.reservas_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico.reservas_data_envio,
																	anoSelecionado,
																	mesSelecionado
																),
															}}
														></div>
													)}
												</td>
												{/* Balancetes */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={condominio.historico.balancetes_enviado}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"balancetes",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico.balancetes_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico.balancetes_data_envio,
																	anoSelecionado,
																	mesSelecionado
																),
															}}
														></div>
													)}
												</td>
												{/* Boleto - Replicando o padrão de Balancetes */}
												<td>
													<label
														className="custom-checkbox"
														onClick={(e) => e.stopPropagation()}
													>
														<input
															type="checkbox"
															checked={condominio.historico.boleto_enviado}
															onChange={(e) =>
																handleItemChange(
																	condominio.id,
																	"boleto",
																	e.target.checked
																)
															}
														/>
														<span className="checkmark"></span>
													</label>
													{condominio.historico.boleto_enviado && (
														<div
															className="data-envio"
															dangerouslySetInnerHTML={{
																__html: formatDate(
																	condominio.historico.boleto_data_envio,
																	anoSelecionado,
																	mesSelecionado
																),
															}}
														></div>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}

						{isModalOpen && historicoCondominio && (
							<div className="modal-fechamento">
								<div className="modal-content-fechamento">
									<span className="close" onClick={handleCloseModal}>
										&times;
									</span>
									<h2 className="modal-title">
										{historicoCondominio.nomeCondominio}
									</h2>
									{/* Adiciona o nome do funcionário responsável abaixo do título */}
									<h3
										style={{
											textAlign: "center",
											fontSize: "18px",
											marginTop: "10px",
											color: "#343434",
										}}
									>
										Responsável Financeiro:{" "}
										{historicoCondominio.nomeFuncionario}
									</h3>
									<table>
										<thead>
											<tr>
												<th style={{ width: "16.66%" }}>Período</th>
												<th style={{ width: "16.66%" }}>Balancetes</th>
												<th style={{ width: "16.66%" }}>Boleto</th>
												<th style={{ width: "16.66%" }}>Leitura</th>
												<th style={{ width: "16.66%" }}>Consumos I.</th>
												<th style={{ width: "16.66%" }}>Reservas</th>
											</tr>
										</thead>
										<tbody>
											{historicoCondominio.data.map((registro, index) => (
												<tr className="periodo-fechamento-mensal" key={index}>
													<td>{`${String(registro.mes).padStart(2, "0")}/${
														registro.ano
													}`}</td>

													{/* Balancetes */}
													<td>
														<div
															style={
																calcularAtrasoEmMeses(
																	registro.balancetes_data_envio,
																	registro.ano,
																	registro.mes
																) > 2
																	? { marginLeft: "53px" }
																	: {}
															}
														>
															{registro.balancetes_enviado ? (
																<>
																	<span className="checkmark">✓</span>
																	{calcularAtrasoEmMeses(
																		registro.balancetes_data_envio,
																		registro.ano,
																		registro.mes
																	) > 2 && (
																		<span
																			style={{
																				color: "orange",
																				marginLeft: "30px",
																			}}
																		>
																			⏰
																		</span>
																	)}
																</>
															) : (
																<span className="crossmark">✕</span>
															)}
														</div>
													</td>

													{/* Boleto */}
													<td>
														<div
															style={
																calcularAtrasoEmMeses(
																	registro.boleto_data_envio,
																	registro.ano,
																	registro.mes
																) > 2
																	? { marginLeft: "53px" }
																	: {}
															}
														>
															{registro.boleto_enviado ? (
																<>
																	<span className="checkmark">✓</span>
																	{calcularAtrasoEmMeses(
																		registro.boleto_data_envio,
																		registro.ano,
																		registro.mes
																	) > 2 && (
																		<span
																			style={{
																				color: "orange",
																				marginLeft: "30px",
																			}}
																		>
																			⏰
																		</span>
																	)}
																</>
															) : (
																<span className="crossmark">✕</span>
															)}
														</div>
													</td>

													{/* Leitura */}
													<td>
														<div
															style={
																calcularAtrasoEmMeses(
																	registro.leitura_data_envio,
																	registro.ano,
																	registro.mes
																) > 2
																	? { marginLeft: "53px" }
																	: {}
															}
														>
															{registro.leitura_enviado ? (
																<>
																	<span className="checkmark">✓</span>
																	{calcularAtrasoEmMeses(
																		registro.leitura_data_envio,
																		registro.ano,
																		registro.mes
																	) > 2 && (
																		<span
																			style={{
																				color: "orange",
																				marginLeft: "30px",
																			}}
																		>
																			⏰
																		</span>
																	)}
																</>
															) : (
																<span className="crossmark">✕</span>
															)}
														</div>
													</td>

													{/* Consumos Individuais */}
													<td>
														<div
															style={
																calcularAtrasoEmMeses(
																	registro.consumos_individuais_data_envio,
																	registro.ano,
																	registro.mes
																) > 2
																	? { marginLeft: "53px" }
																	: {}
															}
														>
															{registro.consumos_individuais_enviado ? (
																<>
																	<span className="checkmark">✓</span>
																	{calcularAtrasoEmMeses(
																		registro.consumos_individuais_data_envio,
																		registro.ano,
																		registro.mes
																	) > 2 && (
																		<span
																			style={{
																				color: "orange",
																				marginLeft: "30px",
																			}}
																		>
																			⏰
																		</span>
																	)}
																</>
															) : (
																<span className="crossmark">✕</span>
															)}
														</div>
													</td>

													{/* Reservas */}
													<td>
														<div
															style={
																calcularAtrasoEmMeses(
																	registro.reservas_data_envio,
																	registro.ano,
																	registro.mes
																) > 2
																	? { marginLeft: "53px" }
																	: {}
															}
														>
															{registro.reservas_enviado ? (
																<>
																	<span className="checkmark">✓</span>
																	{calcularAtrasoEmMeses(
																		registro.reservas_data_envio,
																		registro.ano,
																		registro.mes
																	) > 2 && (
																		<span
																			style={{
																				color: "orange",
																				marginLeft: "30px",
																			}}
																		>
																			⏰
																		</span>
																	)}
																</>
															) : (
																<span className="crossmark">✕</span>
															)}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						)}
					</>
				)}

				{abaSelecionada === "PorCondominio" && (
					<div className="por-condominio-container">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 2,
							}}
						>
							<RadioGroup
								row
								name="periodo-radio-group"
								value={periodoAtualCondominio ? "atual" : "especifico"} // Ajustado para refletir o estado diretamente
								onChange={handlePeriodoAtualCondominioChange}
								sx={{ marginBottom: "0px" }}
							>
								<FormControlLabel
									value="atual"
									control={<Radio />}
									label={
										<Typography variant="h6" sx={{ color: "#343434" }}>
											Período Atual
										</Typography>
									}
								/>
							</RadioGroup>

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									gap: 2,
									width: "100%",
									maxWidth: 360,
								}}
							>
								<FormControl variant="outlined" size="small" fullWidth>
									<InputLabel id="mes-select-label-condominio">Mês</InputLabel>
									<Select
										labelId="mes-select-label-condominio"
										id="mes-select-condominio"
										value={mesSelecionadoCondominio}
										onChange={handleMesSelecionadoCondominioChange}
										label="Mês"
									>
										{mesesCondominio.map((mes, index) => (
											<MenuItem key={index} value={index + 1}>
												{mes}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl variant="outlined" size="small" fullWidth>
									<InputLabel id="ano-select-label-condominio">Ano</InputLabel>
									<Select
										labelId="ano-select-label-condominio"
										id="ano-select-condominio"
										value={anoSelecionadoCondominio}
										onChange={handleAnoSelecionadoCondominioChange} // Assegure que esta função esteja corretamente referenciada aqui
										label="Ano"
									>
										{Array.from({ length: 7 }, (_, i) => 2024 + i).map(
											(ano) => (
												<MenuItem key={ano} value={ano}>
													{ano}
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							</Box>

							<FormControl
								variant="outlined"
								fullWidth
								size="small"
								sx={{ mt: 2, width: "100%", maxWidth: 360 }}
							>
								<InputLabel id="dropdown-select-label-condominio">
									Selecionar Documento
								</InputLabel>
								<Select
									labelId="dropdown-select-label-condominio"
									id="dropdown-select-condominio"
									value={documentoSelecionado} // Atualize para usar o estado
									onChange={handleDocumentoSelecionadoChange} // Adicione a função de manipulação
									label="Selecionar Documento" // Certifique-se de que a label corresponda
									displayEmpty
								>
									<MenuItem value="balancetes">Balancetes</MenuItem>
									<MenuItem value="boleto">Boleto</MenuItem>
									<MenuItem value="consumosIndividuais">Consumos Ind.</MenuItem>
									<MenuItem value="leitura">Leitura</MenuItem>
									<MenuItem value="reinf">Re Inf</MenuItem>
									<MenuItem value="reservas">Reservas</MenuItem>
								</Select>
							</FormControl>

							{/* Botão Buscar */}
							<div className="buscar-container">
								<button
									onClick={buscarCondominiosAbaCondominios}
									className="buscar-button"
								>
									Buscar
								</button>
								{carregando && <LoadingIndicator />}
							</div>

							{/* Condição para exibir a tabela somente após a busca */}
							{exibirTabela && (
								<div className="tabela-condominio">
									<Box sx={{ mt: 2, width: "100%" }}>
										<TableContainer component={Paper}>
											<Table aria-label="Tabela de Fechamento">
												<TableHead>
													<TableRow>
														{/* Cabeçalho Nº com evento onClick para alterar a ordenação */}
														<TableCell
															className="coluna-n"
															sx={{
																textAlign: "left",
																padding: "2px",
																borderBottom: "2px solid #0e94ff",
																cursor: "pointer",
															}}
															onClick={() =>
																setOrdenacao({
																	campo: "n",
																	direcao:
																		ordenacao.campo === "n" &&
																		ordenacao.direcao === "asc"
																			? "desc"
																			: "asc",
																})
															}
														>
															Nº{" "}
															{ordenacao.campo === "n"
																? ordenacao.direcao === "asc"
																	? "↑"
																	: "↓"
																: ""}
														</TableCell>

														<TableCell
															className="coluna-condominio"
															sx={{
																borderBottom: "2px solid #0e94ff",
																cursor: "pointer",
															}}
															onClick={() =>
																setOrdenacao({
																	campo: "nomeCondominio",
																	direcao:
																		ordenacao.campo === "nomeCondominio" &&
																		ordenacao.direcao === "asc"
																			? "desc"
																			: "asc",
																})
															}
														>
															Condomínio{" "}
															{ordenacao.campo === "nomeCondominio"
																? ordenacao.direcao === "asc"
																	? "↑"
																	: "↓"
																: ""}
														</TableCell>

														<TableCell
															className="coluna-responsavel"
															sx={{
																borderBottom: "2px solid #0e94ff",
																cursor: "pointer",
															}}
															onClick={() =>
																setOrdenacao({
																	campo: "responsavel",
																	direcao:
																		ordenacao.campo === "responsavel" &&
																		ordenacao.direcao === "asc"
																			? "desc"
																			: "asc",
																})
															}
														>
															Responsável{" "}
															{ordenacao.campo === "responsavel"
																? ordenacao.direcao === "asc"
																	? "↑"
																	: "↓"
																: ""}
														</TableCell>

														{renderizarMeses().map((mes, idx) => (
															<TableCell
																key={idx}
																className="coluna-mes"
																sx={{
																	textAlign: "right",
																	padding: "2px 4px",
																	borderBottom: "2px solid #0e94ff",
																}}
															>
																{mes}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{/* Linha de resumo detalhado por mês */}
													<TableRow
														style={{
															backgroundColor: "#6495ED",
															height: "20px",
														}}
													>
														{" "}
														{/* Definindo a altura da linha para 35px */}
														<TableCell
															colSpan={3}
															style={{
																paddingLeft: "189px",
																color: "black",
																textAlign: "left",
																maxWidth: "200px",
															}}
														>
															<input
																className="input-filtro"
																style={{
																	backgroundColor: "#6495ED",
																	color: "black",
																	border: "none",
																}}
																placeholder="Digite para filtrar..."
																value={filtroCondominio}
																onChange={(e) =>
																	setFiltroCondominio(e.target.value)
																}
															/>
														</TableCell>
														{Object.entries(
															dadosFechamentoCondominio[0]?.documentosPorMes ||
																{}
														).map(([mes, _], idx) => (
															<TableCell
																key={idx}
																style={{
																	textAlign: "center",
																	paddingLeft: "", // Ajuste o padding conforme necessário
																	cursor: "pointer",
																	color: "white", // Adicionando cor branca ao texto para manter o contraste
																}}
																onClick={() =>
																	identificarCondominiosFaltantes(mes)
																}
															>
																{resumoMensal[mes]?.enviados || 0}/
																{resumoMensal[mes]?.total || 0}
															</TableCell>
														))}
													</TableRow>

													{/* Linhas de dados da tabela */}
													{dadosFiltrados.map((condominio, index) => (
														<TableRow
															key={index}
															className={`transition-row ${
																isTransitioning ? "transparent-row" : ""
															}`}
														>
															<TableCell
																style={{
																	textAlign: "center",
																	padding: "0px 0px",
																}}
															>
																{condominio.numeroLinha}
															</TableCell>
															<TableCell>{condominio.nomeCondominio}</TableCell>
															<TableCell>{condominio.responsavel}</TableCell>
															{Object.entries(condominio.documentosPorMes).map(
																([mesAno, status], idx) => (
																	<TableCell
																		key={idx}
																		style={{
																			textAlign: "right",
																			padding: "2px 4px",
																		}}
																	>
																		<StatusIcon status={status} />
																	</TableCell>
																)
															)}
														</TableRow>
													))}
												</TableBody>
											</Table>
											{mostrarModalFaltantes && (
												<div
													style={{
														position: "fixed",
														top: "10%",
														left: "25%",
														right: "25%",
														backgroundColor: "white",
														padding: "20px",
														zIndex: 1000,
														overflowY: "auto",
														maxHeight: "80%",
														boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
													}}
												>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
															alignItems: "center",
														}}
													>
														<h2
															style={{
																textAlign: "center",
																width: "100%",
																color: "#FF6347",
															}}
														>
															Condomínios Pendentes
														</h2>
														<button
															onClick={() => setMostrarModalFaltantes(false)}
															style={{
																position: "absolute",
																top: "20px",
																right: "20px",
																border: "none",
																background: "none",
																cursor: "pointer",
																fontSize: "1.5rem",
															}}
														>
															X
														</button>
													</div>
													<div
														style={{
															textAlign: "center",
															marginBottom: "20px",
														}}
													>
														<h3>{periodoSelecionado}</h3>
													</div>
													<table
														style={{
															width: "100%",
															marginBottom: "20px",
															textAlign: "left",
														}}
													>
														<thead>
															<tr>
																<th style={{ textAlign: "center" }}>#</th>
																<th
																	style={{
																		textAlign: "center",
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		atualizarOrdenacaoModal("nome")
																	}
																>
																	Condomínio{" "}
																	{ordenacaoModal.campo === "nome"
																		? ordenacaoModal.direcao === "asc"
																			? "↑"
																			: ordenacaoModal.direcao === "desc"
																			? "↓"
																			: ""
																		: ""}
																</th>
																<th
																	style={{
																		textAlign: "center",
																		cursor: "pointer",
																	}}
																	onClick={() =>
																		atualizarOrdenacaoModal("responsavel")
																	}
																>
																	Responsável{" "}
																	{ordenacaoModal.campo === "responsavel"
																		? ordenacaoModal.direcao === "asc"
																			? "↑"
																			: ordenacaoModal.direcao === "desc"
																			? "↓"
																			: ""
																		: ""}
																</th>
															</tr>
														</thead>
														<tbody>
															{condominiosOrdenadosModal.map(
																(condominio, index) => (
																	<tr key={index}>
																		<td style={{ textAlign: "center" }}>
																			{index + 1}
																		</td>
																		<td>{condominio.nome}</td>
																		<td>{condominio.responsavel}</td>
																	</tr>
																)
															)}
														</tbody>
													</table>
													<div style={{ textAlign: "right" }}>
														<button
															onClick={() => setMostrarModalFaltantes(false)}
															style={{
																padding: "2px 6px",
																backgroundColor: "#0e94ff",
																color: "white",
																border: "none",
																borderRadius: "4px",
																cursor: "pointer",
															}}
														>
															Fechar
														</button>
													</div>
												</div>
											)}
										</TableContainer>
									</Box>
								</div>
							)}
						</Box>
					</div>
				)}

				{abaSelecionada === "VisaoGeral" && <VisaoGeralFechamento />}
			</div>
		</div>
	);
};

export default FechamentoMensal;
