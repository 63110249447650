// 0054 - Gabriel Colle - Botão para recarregar banco de dados novamente, com requisição superlogica - 05/09/2024
import React, { useState } from 'react';
import './KpisPageFin.css';
import LancamentosUsuario from './ChartLancametosUsuarios';
import LancamentosCondominio from './ChartLancamentosCondos';
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function KpisPageFin() {
  const API_URL = process.env.REACT_APP_API_URL;
  // 0054 - Gabriel Colle - Botão para recarregar banco de dados novamente, com requisição superlogica - 04/09/2024
  // INICIO
  const [periodo, setPeriodo] = useState('mesAtual');
  const [isLoading, setIsLoading] = useState(false);
  
  // Função para recarregar os dados
  const recarregarDados = async () => {
    setIsLoading(true);
    toast.info('Recarregando os dados, aguarde até ser concluído...');
    await axios.get(`${API_URL}/lancamentos/recarregarDados`);
  };
  
  const iniciarTimeoutAtualizacao = () => {
    setTimeout(() => {
      window.location.reload(); 
    }, 60000); 
  };
  // FIM

  return (
    <div className="kpis-financeiro-graficos">
        <Button
          size="small"
          onClick={recarregarDados}
          onClickCapture={iniciarTimeoutAtualizacao}
          disabled={isLoading}
          sx={{
            position: 'absolute',
            right: '25px',
            marginTop: '5px',
            padding: '12px 20px',
            borderRadius: '5px',
            backgroundColor: '#0e94ff',
            color: 'white',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#0e93ffe1',
            },
          }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'RECARREGAR'}
        </Button>
      <div className="filtro-periodo">
        <h4 className="titulo-kpi-lancamentos">Selecione o período</h4>
        <select
          value={periodo}
          onChange={(e) => setPeriodo(e.target.value)}
        >
          <option value="mesAtual">Mês Atual</option>
          <option value="ultimoMes">Mês anterior</option>
          <option value="ultimos3Meses">Últimos 3 Meses</option>
          <option value="ultimos6Meses">Últimos 6 Meses</option>
        </select>
      </div>
      <div className="grafico-usuarios-kpis">
        <h3>Lançamentos por Usuário</h3>
        <LancamentosUsuario periodo={periodo} />
      </div>
      <div className="grafico-condominios-kpis">
        <h3>Lançamentos por Condomínio</h3>
        <LancamentosCondominio periodo={periodo} />
      </div>
    </div>
  );
}

export default KpisPageFin;
