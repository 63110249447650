/* ***********************************
Autor: Matheus Botelho
Data: 
Atividade: SSI 0013
Função: Página de notificação com SocketIO
************************************ */

import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import './PaginaNotificacao.css';
import { Autocomplete, TextField, Alert } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const PaginaNotificacao = () => {
  const [userIdToSend, setUserIdToSend] = useState('');
  const [userIdsToSend, setUserIdsToSend] = useState([]);
  const [titleToSend, setTitleToSend] = useState('');
  const [messageToSend, setMessageToSend] = useState('');
  const [socket, setSocket] = useState(null);
  const [userType, setUserType] = useState('Todos os Usuários');
  const [areaToSend, setAreaToSend] = useState('');
  const [usuariosAtivos, setUsuariosAtivos] = useState([]);
  const [gestores, setGestores] = useState([]);
  const [customUserList, setCustomUserList] = useState([{ id: '', name: '' }]);
  const [condominioId, setCondominioId] = useState('');
  const [condominios, setCondominios] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedCondominio, setSelectedCondominio] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const newSocket = io('https://euro-web-757c1b49a8fb.herokuapp.com');
    setSocket(newSocket);
    newSocket.on('errorNotification', (errorMessage) => {
      setErrorMessage(errorMessage);
      console.log('Received error notification:', errorMessage);
    });
    return () => newSocket.close();
  }, []);

  useEffect(() => {
    const fetchUsuariosAtivos = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterUsuariosAtivos`
        );
        setUsuariosAtivos(response.data);
      } catch (error) {
        console.error('Erro ao buscar usuários ativos:', error);
      }
    };

    const fetchGestores = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterGestores`
        );
        setGestores(response.data);
      } catch (error) {
        console.error('Erro ao buscar gestores:', error);
      }
    };

    const fetchCondominios = async () => {
      // Fetch the list of condominiums
      try {
        const response = await axios.get(
          `${API_URL}/criar-notificacao/obterCondominios`
        );
        setCondominios(response.data);
      } catch (error) {
        console.error('Erro ao buscar condomínios:', error);
      }
    };

    fetchUsuariosAtivos();
    fetchGestores();
    fetchCondominios();
  }, [API_URL]);

  useEffect(() => {
    const fetchUserIds = async () => {
      try {
        if (userType === 'Todos os Usuários') {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterTodosUsuarios`
          );
          setUserIdsToSend(response.data.map(String));
        } else if (userType === 'Departamento' && areaToSend) {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterUsuariosPorArea/${areaToSend}`
          );
          setUserIdsToSend(response.data.map(String));
        } else if (userType === 'Gestores') {
          const gestorIds = gestores.map((gestor) => String(gestor.id_func));
          setUserIdsToSend(gestorIds);
        } else if (userType === 'Condomínio (Carteira)' && condominioId) {
          const response = await axios.get(
            `${API_URL}/criar-notificacao/obterUsuariosCondominio/${condominioId}`
          );
          const condUserIds = response.data.map(String);

          // Inclui IDs dos gestores
          const gestorIds = gestores.map((gestor) => String(gestor.id_func));
          setUserIdsToSend([...condUserIds, ...gestorIds]);

          // Ajusta o nome do condomínio selecionado
          const selectedCondo = condominios.find(
            (condo) => condo.id_cond === condominioId
          );
          const condoName = selectedCondo ? selectedCondo.CONDOMINIO : '';
          setSelectedCondominio(condoName);
        }
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    fetchUserIds();
  }, [userType, areaToSend, gestores, condominioId, condominios]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const sendInfoToServer = async () => {
    if (!titleToSend || !messageToSend) {
      console.log('Erro: Campos de título ou mensagem estão vazios.');
      setErrorMessage('Por favor, verifique todos os campos novamente.');
      return;
    }

    console.log(
      'Sending notification:',
      userType,
      titleToSend,
      messageToSend,
      areaToSend
    );
    console.log('User IDs to send:', userIdsToSend);

    if (!socket) {
      console.error('Socket not initialized');
      return;
    }

    try {
      const type =
        userType === 'Condomínio (Carteira)' ? 'condominium' : 'general';
      const finalTitle =
        userType === 'Condomínio (Carteira)'
          ? `${selectedCondominio} | ${titleToSend}`
          : titleToSend;

      // Remover IDs duplicados
      const uniqueUserIds = [...new Set(userIdsToSend)];

      socket.emit('sendNotificationToCustomList', {
        userIds: uniqueUserIds,
        title: finalTitle,
        message: messageToSend,
        type, // Include the type field
      });

      // Limpa os campos e define a mensagem de sucesso
      setTitleToSend('');
      setMessageToSend('');
      setUserType('Todos os Usuários');
      setAreaToSend('');
      setCondominioId('');
      setCustomUserList([{ id: '', name: '' }]);
      setSuccessMessage('Sucesso, as notificações foram enviadas!');
      setErrorMessage(''); // Limpa a mensagem de erro
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const areas = [
    'Financeiro',
    'Jurídico',
    'Assembleias',
    'Cobrança',
    'RH',
    'Cadastro',
  ];

  const userTypes = [
    'Todos os Usuários',
    'Departamento',
    'Gestores',
    'Usuários',
    'Condomínio (Carteira)', // Added new user type
  ];

  const handleAddUserToList = () => {
    setCustomUserList([...customUserList, { id: '', name: '' }]);
  };

  const handleRemoveLastUserFromList = () => {
    const newList = customUserList.slice(0, -1);
    setCustomUserList(newList);
    setUserIdsToSend(newList.map((user) => String(user.id)));
  };

  const handleCustomUserChange = (index, newValue) => {
    const newList = customUserList.map((user, i) =>
      i === index
        ? {
            id: newValue ? String(newValue.id_func) : '',
            name: newValue ? newValue.nome : '',
          }
        : user
    );
    setCustomUserList(newList);
    setUserIdsToSend(newList.map((user) => String(user.id)));
  };

  useEffect(() => {
    if (socket) {
      socket.on('errorNotification', (errorMessage) => {
        setErrorMessage(errorMessage);
      });

      return () => {
        socket.off('errorNotification');
      };
    }
  }, [socket]);

  return (
    <div className="container-pagina-notificacao">
      <div className="content-pagina-notificacao">
        <h1 className="paginaNotificacao-title">Enviar Notificação</h1>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Autocomplete
          options={userTypes}
          value={userType}
          onChange={(e, newValue) => setUserType(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo de Usuário"
              variant="outlined"
            />
          )}
        />
        {userType === 'Departamento' && (
          <Autocomplete
            options={areas}
            value={areaToSend}
            onChange={(e, newValue) => setAreaToSend(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione a área"
                variant="outlined"
              />
            )}
            style={{ marginTop: '16px' }}
          />
        )}
        {userType === 'Usuários' && (
          <>
            {customUserList.map((user, index) => (
              <div
                key={index}
                className="custom-user-item"
              >
                <Autocomplete
                  options={usuariosAtivos}
                  getOptionLabel={(option) => option.nome}
                  onChange={(e, newValue) =>
                    handleCustomUserChange(index, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Usuário ${index + 1}`}
                      variant="outlined"
                    />
                  )}
                  style={{ marginTop: '16px', marginRight: '8px' }}
                />
              </div>
            ))}
            <div className="botoes-lista-personalizada-notificacoes">
              {customUserList.length > 0 && (
                <button
                  className="botao-remove-lista-personalizada-notificacoes"
                  onClick={handleRemoveLastUserFromList}
                  style={{ marginTop: '16px' }}
                >
                  <RemoveRoundedIcon />
                </button>
              )}
              <button
                className="botao-add-lista-personalizada-notificacoes"
                onClick={handleAddUserToList}
                style={{ marginTop: '16px', marginRight: '8px' }}
              >
                <AddRoundedIcon />
              </button>
            </div>
          </>
        )}
        {userType === 'Condomínio (Carteira)' && (
          <Autocomplete
            options={condominios}
            getOptionLabel={(option) => option.CONDOMINIO}
            onChange={(e, newValue) => {
              setCondominioId(newValue ? newValue.id_cond : '');
              setSelectedCondominio(newValue ? newValue.CONDOMINIO : '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione o Condomínio"
                variant="outlined"
              />
            )}
            style={{ marginTop: '16px' }}
          />
        )}
        <TextField
          label="Título da Notificação"
          variant="outlined"
          value={titleToSend}
          onChange={(e) => {
            setTitleToSend(e.target.value);
            if (e.target.value) setErrorMessage('');
          }}
          style={{ marginTop: '16px' }}
        />
        <TextField
          label="Mensagem para Enviar"
          variant="outlined"
          value={messageToSend}
          onChange={(e) => {
            setMessageToSend(e.target.value);
            if (e.target.value) setErrorMessage('');
          }}
          style={{ marginTop: '16px' }}
        />

        <button
          className={`botao-pagina-notificacao ${
            titleToSend !== '' && messageToSend !== ''
              ? ''
              : 'botao-pagina-notificacao-disabled'
          }`}
          onClick={sendInfoToServer}
          style={{ marginTop: '16px' }}
        >
          Enviar Notificação
        </button>
      </div>
    </div>
  );
};

export default PaginaNotificacao;
