/* *****************************************************************************************
Autor: Matheus
Data: 05/02/2024
Atividade: Transferir Carteira
Função: Realizar a transferência permanente de condomínios entre colaboradores
***************************************************************************************** */


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TransferirCarteira.css';
import { 
  Table, TableHead, TableRow, TableCell, TableBody, CircularProgress 
} from '@mui/material';

const TransferenciaCondominios = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState('');
  const [condominios, setCondominios] = useState([]);
  const [transferencias, setTransferencias] = useState([]);
  const [colaboradoresArea, setColaboradoresArea] = useState([]);
  const [numeroCondominios, setNumeroCondominios] = useState(0);
  const [totalPeso, setTotalPeso] = useState(0);
  const [filtrosColaboradores, setFiltrosColaboradores] = useState([]);
  const [quantidadeFiltros, setQuantidadeFiltros] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const carregarColaboradores = async () => {
      try {
        const response = await axios.get(`${API_URL}/transferir-carteira/colaboradores`);
        setColaboradores(response.data);
      } catch (error) {
        console.error('Erro ao buscar colaboradores:', error);
      }
    };
  
    carregarColaboradores();
  }, [API_URL]);
  
  const selecionarColaborador = async (id) => {
    setColaboradorSelecionado(id);
    if (!id) {
      setCondominios([]);
      setTransferencias([]);
      setNumeroCondominios(0); // Resetar ao mudar de seleção
      setTotalPeso(0); // Resetar ao mudar de seleção
      return;
    }
    try {
      const responseCondominios = await axios.get(`${API_URL}/transferir-carteira/condominios-colaborador/${id}`);
      setCondominios(responseCondominios.data.empreendimentos);
      setTransferencias(responseCondominios.data.empreendimentos.map(cond => ({
        id_cond: cond.id_cond,
        id_func_novo: ''
      })));
      // Atualizar os novos estados com os dados recebidos
      setNumeroCondominios(responseCondominios.data.numeroCondominios);
      setTotalPeso(responseCondominios.data.totalPeso);
  
      const responseColaboradoresArea = await axios.get(`${API_URL}/transferir-carteira/colaboradores-mesma-area/${id}`);
      setColaboradoresArea(responseColaboradoresArea.data);
    } catch (error) {
      console.error('Erro ao buscar condomínios ou colaboradores da mesma área:', error);
    }
  };

  const atualizarTransferencia = (index, idFuncNovo) => {
    const novasTransferencias = transferencias.map((t, i) => {
      if (i === index) {
        return { ...t, id_func_novo: idFuncNovo };
      }
      return t;
    });
    setTransferencias(novasTransferencias);
  };

  const realizarTransferencias = async () => {
    try {
      setLoading(true); // Inicia o carregamento
      setMessage('Transferindo Carteira, por favor aguarde...'); // Mensagem inicial
  
      const areaDoColaborador = colaboradores.find(col => col.id_func.toString() === colaboradorSelecionado)?.area;
  
      const transferenciasCompletas = transferencias
        .filter(t => t.id_func_novo)
        .map(t => ({
          ...t,
          id_func_atual: colaboradorSelecionado,
          area: areaDoColaborador
        }));
  
      if (transferenciasCompletas.length > 0) {
        await axios.put(`${API_URL}/transferir-carteira/transferir-condominios`, transferenciasCompletas);
  
        // Aqui você mostra o alerta e recarrega a página
        alert('Transferências realizadas com sucesso!');
        window.location.reload(); // Isso recarrega a página
      } else {
        setMessage('Nenhuma transferência válida para realizar.'); // Mensagem de aviso
      }
    } catch (error) {
      console.error('Erro ao realizar transferências:', error);
      setMessage('Erro ao realizar transferências. Veja o console para mais detalhes.'); // Mensagem de erro
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };
  
  const renderizarFiltrosColaboradores = () => {
    // Verifica se um colaborador foi selecionado antes de renderizar os filtros
    if (colaboradorSelecionado) {
      return Array.from({ length: quantidadeFiltros }, (_, index) => (
        <div key={index} className="filtro-colaborador">
          <select
            value={filtrosColaboradores[index] || ''}
            onChange={(e) => handleSelecaoFiltro(index, e.target.value)}
          >
            <option value="">Selecione um Colaborador</option>
            {colaboradoresArea.map((colaborador) => (
              <option key={colaborador.id_func} value={colaborador.id_func}>{colaborador.nome}</option>
            ))}
          </select>
        </div>
      ));
    } else {
      // Se nenhum colaborador foi selecionado, retorna null ou um componente JSX vazio
      return null;
    }
  };
  
  const handleSelecaoFiltro = (index, valor) => {
    const novosFiltros = [...filtrosColaboradores];
    novosFiltros[index] = valor;
    setFiltrosColaboradores(novosFiltros);
  };

  const distribuirColaboradores = () => {
    const colaboradoresFiltrados = filtrosColaboradores.filter(Boolean); // Remove valores vazios
    const transferenciasAtualizadas = transferencias.map((transf, index) => {
      const indiceColaborador = index % colaboradoresFiltrados.length; // Distribuição equilibrada
      return {
        ...transf,
        id_func_novo: colaboradoresFiltrados[indiceColaborador] || transf.id_func_novo,
      };
    });
    setTransferencias(transferenciasAtualizadas);
  };

  const calcularNovoPesoPorColaborador = () => {
    const pesosPorColaborador = {};
  
    // Inicializa os pesos atuais dos colaboradores
    colaboradoresArea.forEach(colab => {
      pesosPorColaborador[colab.id_func] = parseFloat(colab.pesoTotal) || 0;
    });
  
    // Adiciona o peso dos condomínios transferidos aos colaboradores
    transferencias.forEach((transf, index) => {
      const idFuncNovo = transf.id_func_novo;
      const pesoCondominio = parseFloat(condominios[index].peso);
  
      if (idFuncNovo && pesosPorColaborador.hasOwnProperty(idFuncNovo)) {
        pesosPorColaborador[idFuncNovo] += pesoCondominio;
      }
    });
  
    return pesosPorColaborador;
  };
  
  
  const novoPesoPorColaborador = calcularNovoPesoPorColaborador();

  const limparSelecoes = () => {
    const transferenciasResetadas = transferencias.map(transf => ({
      ...transf,
      id_func_novo: '' // Ou null, se preferir
    }));
    setTransferencias(transferenciasResetadas);
  };
  

  return (
    <div className='transferirCarteira-container'>
      <div className='transferirCarteira-content'>
        <div className='transferirCarteira-title'>
          Transferência de Condomínios
        </div>
        <div className='transferirCarteira-colabAtual'>
          <label>Selecione um Colaborador</label>
          <select value={colaboradorSelecionado} onChange={e => selecionarColaborador(e.target.value)}>
            <option value="">Selecione um Colaborador</option>
            {colaboradores.map(colaborador => (
              <option key={colaborador.id_func} value={colaborador.id_func}>{colaborador.nome}</option>
            ))}
          </select>
        </div>
        <div className='total-pesos-selecionado'>
          <p>Número total de condomínios: {numeroCondominios}</p>
          <p>Soma dos pesos: {totalPeso}</p>
        </div>
        {colaboradorSelecionado && (
          <>
            <div className="selecao-quantidade-filtros">
              <label>Quantidade de Filtros para distribuição automática: </label>
              <input
                type="number"
                value={quantidadeFiltros}
                onChange={(e) => setQuantidadeFiltros(Number(e.target.value))}
                min="0"
                max="10"
              />
            </div>
            <div className='filtro-renderizados-quantidade'>
              {renderizarFiltrosColaboradores()}
            </div>
            {/* Botões de ação e tabela de condomínios */}
            <div className='botoes-distribuicao-automatica'>
              <button className='botao-distribuir' onClick={distribuirColaboradores}>Distribuir Colaboradores</button>
              <button className='botao-limpar-distribuicao' onClick={limparSelecoes}>Limpar Distribuições</button>
            </div>
        </>
        )}
        {condominios.length > 0 && (
          <div className='transferirCarteira-tabela'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Condomínio</TableCell>
                <TableCell>Peso</TableCell>
                <TableCell>Novo Colaborador</TableCell>
                <TableCell>Peso Atual</TableCell> {/* Nova Coluna */}
                <TableCell>Quantidade Atual</TableCell> {/* Nova Coluna */}
                <TableCell>Novo Peso</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {condominios.map((condominio, index) => (
              <TableRow key={condominio.id_cond}>
                <TableCell>{condominio.CONDOMINIO}</TableCell>
                <TableCell>{condominio.peso}</TableCell>
                <TableCell>
                  <select
                    value={transferencias[index].id_func_novo}
                    onChange={(e) => atualizarTransferencia(index, e.target.value)}
                  >
                    <option value="">Novo Colaborador</option>
                    {colaboradoresArea.map(colab => (
                      <option key={colab.id_func} value={colab.id_func}>
                        {colab.nome}
                      </option>
                    ))}
                  </select>
                </TableCell>
                {/* Inserir os valores de Peso Atual e Quantidade Atual baseado na seleção */}
                <TableCell>
                  {transferencias[index].id_func_novo ? 
                    colaboradoresArea.find(colab => colab.id_func.toString() === transferencias[index].id_func_novo)?.pesoTotal : '-'}
                </TableCell>
                <TableCell>
                  {transferencias[index].id_func_novo ? 
                    colaboradoresArea.find(colab => colab.id_func.toString() === transferencias[index].id_func_novo)?.numeroCondominios : '-'}
                </TableCell>
                {/* Calcular e mostrar o novo peso recalculado */}
                <TableCell>
                {transferencias[index].id_func_novo ? 
                  novoPesoPorColaborador[transferencias[index].id_func_novo] || '-'
                  : '-'}
              </TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
          </div>
        )}
        {/* Exibir informações adicionais */}
        {loading ? (
          <div className='loading-container'>
            <CircularProgress /> {/* Indicador de carregamento */}
            <p>{message}</p> {/* Mensagem de status */}
          </div>
        ) : transferencias.some(t => t.id_func_novo) && (
          <div className='btn-transferencia-carteira-container'>
            <button className='botao-realizar-transferencia' onClick={realizarTransferencias}>Realizar Transferência</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransferenciaCondominios;