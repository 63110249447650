import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../UserContext';
import {
  Typography,
  Box,
  TextField,
  Autocomplete,
  Chip,
  CircularProgress,
  Badge,
} from '@mui/material';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import styles from './ConsultarCarteira.module.css';
import InfoCondominios from './InfoCondominios'; // SSI 0016 - 21/05/24

const ConsultarCarteira = () => {
  const { user } = useContext(UserContext);
  const [condominios, setCondominios] = useState([]);
  const [selectedCondominio, setSelectedCondominio] = useState(null);
  const [carteiraData, setCarteiraData] = useState({});
  const [userNames, setUserNames] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ordenacao, setOrdenacao] = useState('alfabetica');
  const [ordenacaoAscendente, setOrdenacaoAscendente] = useState(true);
  //SSI 0016
  const [modalOpen, setModalOpen] = useState(false);
  const [observacaoToEdit, setObservacaoToEdit] = useState(null);
  const [observacoesCount, setObservacoesCount] = useState(0);
  //FIM DA ALTERAÇÃO

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true);

    const urlParams = new URLSearchParams({
      ordenacao: ordenacao,
      direcao: ordenacaoAscendente ? 'ASC' : 'DESC',
    });

    const url =
      user.usuario_cidade === 'Sim'
        ? `${API_URL}/carteira-por-usuario/usuario-cidade/condominios-restritos/${user.id_func}?${urlParams}`
        : `${API_URL}/condominios/comCidade?${urlParams}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setCondominios(data.filter((cond) => cond.fantasia && cond.cidade));
        setErrorMessage('');
      })
      .catch((error) => {
        console.error('Erro ao buscar os condomínios:', error);
        setErrorMessage('Falha ao carregar a lista de condomínios.');
      })
      .finally(() => setLoading(false));
  }, [
    user.id_func,
    user.usuario_cidade,
    ordenacao,
    ordenacaoAscendente,
    API_URL,
  ]);

  useEffect(() => {
    const fetchUserNames = async () => {
      setLoading(true);
      if (selectedCondominio) {
        let names = {};
        for (let role in carteiraData) {
          if (
            [
              'FINANCEIRO',
              'COBRANÇA',
              'RH',
              'COMUNICADOS_NOTIFICAÇÕES',
              'ASSEMBLEIAS',
            ].includes(role) &&
            carteiraData[role]
          ) {
            names[role] = await getUserNameById(carteiraData[role]);
          }
        }
        setUserNames(names);
      }
      setLoading(false);
    };

    fetchUserNames();
  }, [selectedCondominio, carteiraData]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (selectedCondominio) {
      fetch(`${API_URL}/condominios/porId/${selectedCondominio.chave}`, {
        signal,
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Carteira ainda não existe para condominio');
            }
            throw new Error('Erro ao buscar a carteira');
          }
          return response.json();
        })
        .then((data) => {
          setCarteiraData(data);
          setErrorMessage('');
        })
        .catch((error) => {
          setErrorMessage(error.message);
          console.error(error);
        });
    }

    return () => {
      abortController.abort();
    };
  }, [selectedCondominio]);

  useEffect(() => {
    if (selectedCondominio) {
      fetchObservacoesCount(selectedCondominio.chave);
    }
  }, [selectedCondominio]);

  const fetchObservacoesCount = async (idCond) => {
    try {
      const response = await fetch(
        `${API_URL}/observacoes/contar-obs/${idCond}`
      );
      const data = await response.json();
      setObservacoesCount(data.count);
    } catch (error) {
      console.error('Erro ao buscar o número de observações:', error);
    }
  };

  //SSI 0016 - 21/05/24

  const handleOpenModalAdd = () => {
    setObservacaoToEdit(null);
    setModalOpen(true);
  };

  const handleOpenModalEdit = (observacao) => {
    setObservacaoToEdit(observacao);
    setModalOpen(true);
  };

  //FIM DA ALTERAÇÃO

  const StyledAlert = ({
    text,
    originalName,
    originalNameForAbsence,
    absenceEndDate,
  }) => {
    let color;
    switch (text) {
      case 'Cumprindo ausência':
        color = 'primary';
        break;
      case 'Ausente, necessita redirecionamento':
        color = 'warning';
        break;
      default:
        color = 'default';
        break;
    }

    return (
      <Chip
        label={text}
        className={styles.alertCustom}
        style={{
          marginLeft: 10,
          width: 250,
          justifyContent: 'center',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          maxWidth: '100%',
          padding: '5px 10px',
          display: 'inline-flex', // Mudar para inline-flex para utilizar flexbox
          alignItems: 'center', // Alinhar itens no centro verticalmente
          textAlign: 'center', // Alinhar texto no centro
        }}
        title={
          text === 'Cumprindo ausência'
            ? `Originalmente: ${originalNameForAbsence}, Data de término: ${absenceEndDate}`
            : ''
        }
      />
    );
  };

  const getUserNameById = async (id) => {
    let originalName = await fetchUserNameById(id);
    let name = originalName;
    let alertText = '';
    let dataFormatada = '';

    let absenceDetails = null;

    const absenceResponse = await fetch(
      `${API_URL}/ausencia/check-active-absences/${id}`
    );
    const absenceData = await absenceResponse.json();

    const hasActiveAbsence = absenceData && absenceData.activeAbsencesCount > 0;

    if (hasActiveAbsence) {
      absenceDetails = await fetchAbsenceDetails(id);

      if (absenceDetails && absenceDetails.dt_fim) {
        const dataObjeto = new Date(absenceDetails.dt_fim);
        const dia = String(dataObjeto.getDate()).padStart(2, '0');
        const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0');
        const ano = dataObjeto.getFullYear();
        dataFormatada = `${dia}/${mes}/${ano}`;
      }

      const redirectionUrl = `${API_URL}/ausencia/redirection-by-user/${id}/${selectedCondominio.chave}`;
      const redirectionResponse = await fetch(redirectionUrl);
      const redirectionData = await redirectionResponse.json();

      const hasActiveRedirection =
        redirectionData && redirectionData.redirection;

      if (hasActiveRedirection) {
        name = await fetchUserNameById(
          redirectionData.redirection.id_func_temp
        );
        alertText = 'Cumprindo ausência';
      } else {
        alertText = 'Ausente, necessita redirecionamento';
      }
    }

    return {
      name,
      alertText,
      originalName,
      absenceEndDate: dataFormatada,
      originalNameForAbsence: absenceDetails ? absenceDetails.nome : '',
    };
  };

  const fetchUserNameById = async (id) => {
    try {
      const response = await fetch(`${API_URL}/user/get-user/${id}`);
      const data = await response.json();
      return data && data.user && data.user.nome ? data.user.nome : '';
    } catch (error) {
      return '';
    }
  };

  const fetchAbsenceDetails = async (id) => {
    try {
      const response = await fetch(`${API_URL}/user/get-absence-details/${id}`);
      const data = await response.json();
      return data.success ? data.absenceDetails : null;
    } catch (error) {
      return null;
    }
  };

  const departmentOrder = [
    { key: 'ASSEMBLEIAS', icon: <GroupsRoundedIcon />, label: 'Assembleias' },
    { key: 'COBRANÇA', icon: <PaymentRoundedIcon />, label: 'Cobrança' },
    {
      key: 'COMUNICADOS_NOTIFICAÇÕES',
      icon: <MailOutlineRoundedIcon />,
      label: 'Comunicados e Notificações',
    },
    {
      key: 'FINANCEIRO',
      icon: <AttachMoneyRoundedIcon />,
      label: 'Financeiro',
    },
    { key: 'RH', icon: <Person2RoundedIcon />, label: 'RH' },
  ];

  return (
    <div className={styles.consultarCarteiraContainer}>
      <div className={styles.consultarCarteiraContent}>
        <div className={styles.consultarCarteiraTitle}>
          Consulta de Carteiras
        </div>
        <div className={styles.filtroEIconeContainer}>
          <div className={styles.filtroOrdenacaoCondominios}>
            <select
              value={ordenacao}
              onChange={(e) => setOrdenacao(e.target.value)}
              className={styles.filtroOrdemCarteiras}
            >
              <option value="alfabetica">Alfabética</option>
              <option value="chave">ID</option>
              <option value="cidade">Cidade</option>
            </select>
            <button
              className={styles.botaoOrdenarDirec}
              onClick={() => setOrdenacaoAscendente(!ordenacaoAscendente)}
            >
              {ordenacaoAscendente ? (
                <ArrowDownwardRoundedIcon />
              ) : (
                <ArrowUpwardRoundedIcon />
              )}
            </button>
          </div>
          {selectedCondominio && (
            <button
              className={styles.botaoObservacoesCondominios}
              onClick={handleOpenModalAdd}
            >
              <Badge
                badgeContent={observacoesCount}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#f3e542',
                    color: 'black',
                  },
                }}
              >
                <ErrorRoundedIcon />
              </Badge>
            </button>
          )}
        </div>
        <Autocomplete
          className={styles.consultarCarteiraAutocomplete}
          options={condominios}
          getOptionLabel={(option) =>
            `${option.fantasia} - ${option.chave}${
              option.cidade ? ' - ' + option.cidade : ''
            }`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buscar Condomínio"
              variant="outlined"
              fullWidth
            />
          )}
          onChange={(event, newValue) => {
            setUserNames({});
            setCarteiraData({});
            setSelectedCondominio(newValue);
          }}
        />
        {errorMessage && (
          <Box
            className={styles.consultarCarteiraBox}
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="h6"
              color="error"
            >
              {errorMessage}
            </Typography>
          </Box>
        )}
        {loading && (
          <Box className={styles.consultarCarteiraCircularProgress}>
            <CircularProgress />
          </Box>
        )}
        {selectedCondominio && (
          <Box className={styles.consultarCarteiraBox}>
            {departmentOrder.map((department, index) => {
              const userData = userNames[department.key];
              return (
                userData && (
                  <Box key={department.key}>
                    <div className={styles.consultarCarteiraTypography}>
                      <span>{department.icon}</span>
                      <label>
                        {department.label}: {userData.name}
                        {userData.alertText && (
                          <StyledAlert
                            text={userData.alertText}
                            originalName={userData.originalName}
                            originalNameForAbsence={
                              userData.originalNameForAbsence
                            }
                            absenceEndDate={userData.absenceEndDate}
                          />
                        )}
                      </label>
                    </div>
                  </Box>
                )
              );
            })}
          </Box>
        )}
      </div>
      <InfoCondominios
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        idCond={selectedCondominio ? selectedCondominio.chave : null}
        fetchObservacoes={() => {}}
        observacaoToEdit={observacaoToEdit}
      />
    </div>
  );
};

export default ConsultarCarteira;
